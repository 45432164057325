import React from 'react';
import style from './Systems.module.css';

class SystemErrorIcon extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: false
		};
	}

	renderIcon() {
		if (this.state.error) {
			return (
				<span className='error'>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1500"><title>No Preview</title><path d="M72,69H1427V1432.92H72V69h0M1427,39H72A30,30,0,0,0,42,69V1432.92a30,30,0,0,0,30,30H1427a30,30,0,0,0,30-30V69a30,30,0,0,0-30-30Z"/><path d="M802.19,865.91A52.21,52.21,0,1,1,750,813.7,52.26,52.26,0,0,1,802.19,865.91ZM703.83,600.44l8.87,177.5a15.66,15.66,0,0,0,15.64,14.88h43.3a15.66,15.66,0,0,0,15.64-14.88l8.87-177.5A15.66,15.66,0,0,0,780.51,584h-61A15.66,15.66,0,0,0,703.83,600.44Z"/></svg>
				</span>
			);
		}

		return (
			<div className={style.previewIconContainer}>
				<img
					title="System Preview"
					className={style.previewIcon}
					src={this.props.src}
					onError={() => {
						this.setState({error: true});
					}}
				/>
			</div>
		);
	}

	render() {
		return (
			<React.Fragment>
				{this.renderIcon()}
			</React.Fragment>
		);
	}
}

export default SystemErrorIcon;