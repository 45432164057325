import React, { useState, useEffect } from 'react';
import style from './Library.module.css';
import QRCode from 'qrcode.react';

function QrCodeComponent(props) {
  const [selectedOption, setSelectedOption] = useState('call');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [emailMessage, setEmailMessage] = useState('');
  const [url, setUrl] = useState('');
  const [updateValue, setUpdateValue] = useState('');
  
  // Get settingUrl and details based on layoutGroup or listing props
  let settingUrl;
  let entitySetting;
  let m2Key;
  if (props.component.settings.isListings) {
	settingUrl = props.component.settings.url
  } else {
	settingUrl = props.url
	entitySetting = props.componentSettingMap["url"];
	m2Key = `setting.${entitySetting.uuid}`;
  }

  const options = [
    { value: 'call', label: 'Call' },
    { value: 'text', label: 'Text' },
    { value: 'email', label: 'Email' },
    { value: 'url', label: 'URL' },
  ];

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Rebuild qr code string on page load
  useEffect(() => {
    if (settingUrl && settingUrl.startsWith("tel:")) {
        const phoneNumber = settingUrl.split(":")[1];
        setSelectedOption("call");
        setPhoneNumber(phoneNumber);
    } else if (settingUrl && settingUrl.startsWith("sms:")) {
        const [protocol, message] = settingUrl.split("?body=");
        const phoneNumber = protocol.split(":")[1];
        setSelectedOption("text");
        setPhoneNumber(phoneNumber);
        setMessage(message);
    } else if (settingUrl && settingUrl.startsWith("mailto:")) {
        const [emailAddress, message] = settingUrl.split("?body=");
        setEmail(emailAddress.substring(7)); // Remove the "mailto:" prefix
        setEmailMessage(message);
        setSelectedOption("email");
    } else if (settingUrl) {
        setUrl(settingUrl);
        setSelectedOption("url");
    }
  }, [settingUrl]);

  const handleInputChange = (event, inputType) => {
	const { target: { value } } = event;
	let updatedValue;
	switch (inputType) {
	  case 'phone':
		setPhoneNumber(value);
		updatedValue = `tel:${value}`;
		break;
	  case 'message':
		setMessage(value);
		updatedValue = `sms:${phoneNumber}?body=${value}`;
		break;
	  case 'email':
		setEmail(value);
		updatedValue = `mailto:${value}?body=${emailMessage}`;
		break;
	  case 'emailMessage':
		setEmailMessage(value);
		updatedValue = `mailto:${email}?body=${value}`;
		break;
	  case 'url':
		setUrl(value);
		updatedValue = value;
		break;
	  default:
		break;
	}
	if (updatedValue && settingUrl !== updatedValue) {
	  setUpdateValue(updatedValue);
	}
  };

  const handleUpdate = () => {
	if (props.component.settings.isListings) {
		// update to listing_column
		name = props.entity.name
		props.app.setValue('', name, updateValue);

	} else {
		let m1Key = `component.${props.component.uuid}`;
		let entitySetting = props.componentSettingMap["url"];
		let m2Key = `setting.${entitySetting.uuid}`;
		let entityName = `${m1Key}.${m2Key}.${entitySetting.type}`;
		props.app.setValue('', entityName, updateValue);
	}
  };

  const inputStyle = {
    display: 'block',
    margin: '0.5rem 0',
    padding: '0.5rem',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '1rem',
    width: '100%',
    boxSizing: 'border-box',
  };
  const selectStyle = {
    display: 'block',
    margin: '0.5rem 0',
    padding: '0.5rem',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '1rem',
    width: '100%',
    boxSizing: 'border-box',
    appearance: 'none',
    background: `url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 3l5 5 5-5H0z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 8px`,
  };

  return (
   
	<div>
		<div className="label">
			<label>{props.label || props.attr.label || ''}</label>
		</div>
		<div className={style.qrCard}>
				
			<label htmlFor="qr-code-type-select">Choose a QR code type:</label>
			<select value={selectedOption} onChange={handleOptionChange} style={selectStyle}>
				<option value="call">Call</option>
				<option value="text">Text</option>
				<option value="email">Email</option>
				<option value="url">URL</option>
			</select>

			{selectedOption === 'call' && (
				<div>
					<label htmlFor="phone-input">Phone Number:</label>
					<input type="tel" id="phone-input" value={phoneNumber} onChange={(event) => handleInputChange(event, 'phone')} style={inputStyle} />
				</div>
			)}

			{selectedOption === 'text' && (
			<div>
				<label htmlFor="phone-input">Phone Number:</label>
				<input type="tel" id="phone-input" value={phoneNumber} onChange={(event) => handleInputChange(event, 'phone')} style={inputStyle} />

				<label htmlFor="message-input">Message:</label>
				<textarea id="message-input" value={message} onChange={(event) => handleInputChange(event, 'message')} style={inputStyle} />
			</div>
			)}

			{selectedOption === 'email' && (
			<div>
				<label htmlFor="email-input">Email Address:</label>
				<input type="email" id="email-input" value={email} onChange={(event) => handleInputChange(event, 'email')} style={inputStyle} />

				<label htmlFor="email-message-input">Message:</label>
				<textarea id="email-message-input" value={emailMessage} onChange={(event) => handleInputChange(event, 'emailMessage')} style={inputStyle} />
			</div>
			)}

			{selectedOption === 'url' && (
			<div>
				<label htmlFor="url-input">URL:</label>
				<input type="url" id="url-input" value={url} onChange={(event) => handleInputChange(event, 'url')} style={inputStyle} />
			</div>
			)}  

			<div>
				<button onClick={() => handleUpdate(updateValue)}>
					Generate QR
				</button>
        	</div>
		</div>


	  {settingUrl !== '' && (
		<div className={style.qrCard}>
				<QRCode
					bgColor={"#fff"} // Color of the bright squares
					fgColor={"#000"} // Color of the dark squares
					level="L" // QR Error correction level: L, M, Q, H
					style={{width: '100%', height: '100%', border: `10px solid #fff`}} // Size in pixels
					value={settingUrl} // The url
				/>
		</div>
	  )}  

	</div>
  );
}

export default QrCodeComponent;