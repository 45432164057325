import React, {
} from 'react';
import Select from 'react-select';
import CustomIcon from '../Common/CustomIcon/CustomIcon.js';
import TextSmall from 'Vulture/Field/TextSmall.js';
import style from './DataPresets.module.css';
// import style from './ListingConfig.module.css';

const styles = {
	control: (base) => {
		return {
			...base,
			maxHeight: 30,
		};
	},
	dropdownIndicator: (base) => {
		return {
			...base,
			paddingTop:    0,
			paddingBottom: 0,
		};
	},
	clearIndicator: (base) => {
		return {
			...base,
			paddingTop:    0,
			paddingBottom: 0,
		};
	},
	menu: (base) => {
		return {
			...base,
			top:      20,
			position: 'absolute',
		};
	},
};

function DataPresetEntity(props) {
	const {
		addData,
		deleteData,
		addTier,
		deleteTier,
		updateDataLabel,
		updateDataType,
		updateTier,
		dataSet,
		listingColumnTypeOptions,
	} = props;

	const tierCount = dataSet.tiers.length;

	return (
		<div
			className={style.DataPresetEntity}
		>
			{dataSet.tiers.map((tier, tierIndex) => {
				return (
					<div
						className={style.listingConfigContainer}
						key={tierIndex}
					>
						<div className={style.listingConfigLabel}>
							<h1><span>Tier {tierIndex + 1}</span>
								{(tierIndex === tierCount - 1) &&
									<div
										className={style.deleteIcon}
										onClick={(e) => {
											e.preventDefault();
											deleteTier(tierIndex);
										}}
									>
										<CustomIcon icon='minus-circle' />
									</div>
								}
							</h1>
						</div>
						<div className={style.listingConfigData}>
							<div className={style.listingConfigDataRow}>
								<div className={style.listingConfigDataRowInput}>
									<div>List Name</div>
									<div
										className={style.crowForm}
									>
										<TextSmall
											setValue={(e) => {
												updateTier(e, tierIndex);
											}}
											value={tier.listName}
										/>
									</div>
								</div>
							</div>
							{tier.data.map((data, dataIndex) => {
								return (
									<div className={style.listingConfigDataRow} key={dataIndex}>
										<div className={style.listingConfigDataRowInput}>
											<div>Data {dataIndex + 1}</div>
											<TextSmall
												setValue={(e) => {
													updateDataLabel(e, tierIndex, dataIndex);
												}}
												value={data.label}
											/>
										</div>

										<div className={style.listingConfigDataRowInput}>
											{<div>Type:</div>}
											<div className={style.listingConfigSubcontainer}>
												<Select
													className={style.listingConfigSelectLabel}
													defaultValue={listingColumnTypeOptions[0]}
													options={listingColumnTypeOptions}
													styles={styles}
													onChange={(e) => {
														updateDataType(e, tierIndex, dataIndex);
													}}
													value={data.type}
												/>
												<div
													className={style.deleteIcon}
													onClick={() => {
														deleteData(tierIndex, dataIndex);
													}}
												>
													<CustomIcon icon='minus-circle' />
												</div>
											</div>
										</div>
									</div>
								);
							})}
							<div className={style.buttonContainer}>
								<button
									className={`button ${style.button}`}
									onClick={() => {
										addData(tierIndex);
									}}
								>
									<CustomIcon icon='plus' /> Data
								</button>
								{(tierIndex === tierCount - 1) &&
								<button
									className={`button ${style.button}`}
									onClick={() => {
										addTier();
									}}
								>
									<CustomIcon icon='plus' /> Tier
								</button>
								}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default DataPresetEntity;