import axios from 'axios';

import {
	broncoURL,
} from 'Vulture/ENV.js';

import {
	getSession,
} from './Session';

const fetchComponentGroupConfigs = async function (body) {
	let url = `${broncoURL}/componentGroup/configs`;
	const session = getSession();

	const headers = {
		headers: {
			'X-Auth-Session': session.session,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		console.log(res);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		// debugger;
	}
};

const fetchComponentGroupConfig = async function (body) {
	let url = `${broncoURL}/componentGroup/config`;
	const session = getSession();

	const headers = {
		headers: {
			'X-Auth-Session': session.session,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		console.log(res);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		// debugger;
	}
};

const fetchComponentTreeInfo = async function (body) {
	let url = `${broncoURL}/componentTree/info`;

	try {
		let res = await axios.post(url, body, {});
		console.log(res);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		// debugger;
	}
};

const fetchComponentTreeDiff = async function (body) {
	let url = `${broncoURL}/componentTree/diff`;

	try {
		let res = await axios.post(url, body, {});
		console.log(res);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		// debugger;
	}
};

const updateComponentGroup = async function (body = {}) {
	let url = `${broncoURL}/componentGroup/update`;

	try {
		let res = await axios.post(url, body, {});
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		debugger;
		// error handling to be done on a per-page basis to more easily add Retry buttons
	}
};

export {
	fetchComponentGroupConfigs,
	fetchComponentGroupConfig,
	fetchComponentTreeInfo,
	fetchComponentTreeDiff,
	updateComponentGroup,
};