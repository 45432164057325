import axios from 'axios';
import kali from 'kali';

import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
} from 'Vulture/ENV.js';

import {
	getFDValue,
} from 'Vulture/Helpers.js';

const fetchAccounts = async function () {
	const session = getSession();
	const commitHash = window._getEnv('COMMITHASH');
	let url = `${broncoURL}/accounts`;
	let body = {
		user: session.user,
	};

	const headers = {
		headers: {
			'X-Auth-Session': session.session,
			'X-Spark-API-version': commitHash,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		console.log(res);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		// debugger;
	}
};

const fetchAccount = async function (accountUUID) {
	const session = getSession();

	let url = `${broncoURL}/account`;
	let body = {
		user:    session.user,
		account: accountUUID,
	};

	const headers = {
		headers: {
			'X-Auth-Session': session.session,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

const fetchAccountFD = async function (accountUUID) {
	const session = getSession();

	let url = `${broncoURL}/account/data`;
	let body = {
		user:    session.user,
		account: accountUUID,
	};

	try {
		let res = await axios.post(url, body, {});
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

const fetchAccountTemplates = async function (accountUUID) {
	const session = getSession();
	const commitHash = window._getEnv('COMMITHASH');

	let url = `${broncoURL}/account/templates`;
	let body = {
		user:    session.user,
		account: accountUUID,
	};

	const headers = {
		headers: {
			'X-Auth-Session':      session.session,
			'X-Spark-API-version': commitHash,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

const fetchAccountSystems = async function (accountUUID) {
	const session = getSession();
	const commitHash = window._getEnv('COMMITHASH');

	let url = `${broncoURL}/account/systems`;
	let body = {
		user:    session.user,
		account: accountUUID,
	};

	const headers = {
		headers: {
			'X-Auth-Session':      session.session,
			'X-Spark-API-version': commitHash,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

const fetchAccountSystem = async function (accountUUID, systemUUID) {
	const session = getSession();
	const commitHash = window._getEnv('COMMITHASH');

	let url = `${broncoURL}/account/system`;
	let body = {
		user:    session.user,
		account: accountUUID,
		system:  systemUUID,
	};

	const headers = {
		headers: {
			'X-Auth-Session':      session.session,
			'X-Spark-API-version': commitHash,
		},
	};

	try {
		let res = await axios.post(url, body, headers);
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

const updateSearch = async (columnName, value, uuids = []) => {
	const sessionKey = getSession().session;

	const body = {};

	switch (columnName) {
	case 'nameFilter':
		body.col = 'name';
		body.search = value;
		break;
	case 'salesforceFilter':
		body.col = 'sfid';
		body.search = value;
		break;
	case 'systemGroupNameFilter':
		body.col = 'system_group';
		body.search = value;
		break;
	case 'macAddressFilter':
		body.col = 'mac_address';
		body.search = value.split(':').join('');
		break;
	default:
		break;
	}

	if (uuids.length > 0) {
		body.uuid = uuids;
	}

	const opts = {
		method:  'POST',
		headers: {
			'content_type':   'application/json',
			'X-Auth-Session': sessionKey,
		},
		body,
	};

	let retVal;

	await new kali(opts).get(`${broncoURL}/account/search`, {
		success: (_kail, res, data) => {
			retVal = data;
		},

		failure: (_kali, err) => {
			let errorMessage = String(err);
			if (errorMessage.indexOf('Failed to fetch') !== -1) {
				retVal = {
					err: errorMessage,
				};
			}
		},
	}, true);

	return retVal;
};

const saveNewAccount = async (accountName, id) => {
	const sessionKey = getSession().session;

	let opts = {
		method:  'POST',
		headers: {
			'content_type':   'application/json',
			'X-Auth-Session': sessionKey,
		},
		body: {
			'display_name':      accountName,
			'salesforce_id_val': id,
		},
	};

	let retVal;

	await new kali(opts).post(`${broncoURL}/account/new`, {
		success: (_kali, res, data) => {
			retVal = data;
		},

		failure: (_kali, res, err) => {
			if (err !== undefined) {
				retVal = {
					err,
				};
			}
		},
	});

	return retVal;
};

const deleteAccount = async (fd, accountUUID) => {
	const sessionKey = getSession().session;

	const opts = {
		method:  'POST',
		headers: {
			'content_type':   'application/json',
			'X-Auth-Session': sessionKey,
		},
		body: {},
	};

	let retVal;

	await new kali(opts).post(`${broncoURL}/w/delete/account/${accountUUID}`, {
		success: (_kali, res, data) => {
			console.log(data);

			const bundleList = getFDValue(fd, `accountUUID.${accountUUID}._bundle`) || [];
			bundleList.forEach(async (bundle) => {
				let opts = {
					method:  'POST',
					headers: {
						'content_type':   'application/json',
						'X-Auth-Session': sessionKey,
					},
					body: {},
				};

				await new kali(opts).post(`${broncoURL}/w/delete/bundle/${bundle.uuid}`, {
					success: (_kali, res, data) => {
						console.log(data);
					},

					failure: (_kali, res, err) => {
						console.error(err);
					},
				});
			});

			retVal = true;
		},

		failure: (_kali, res, err) => {
			retVal = {
				err,
			};
			console.error(err);

			alert('Please make sure the accountUUID is not associated to any other users.');
		},
	});

	return retVal;
};

const fetchDataSets = (setDataSets) => {
	const session = getSession();

	let url = `${broncoURL}/data_sets`;

	let body = {
		user: session.user,
	};

	let headers = {
		'X-Auth-Session': session.session,
	};

	axios
		.post(url, body, {
			headers,
		})
		.then((res) => {
			setDataSets(res.data.dataSets);
		})
		.catch((err) => {
			alert(err);
		});
};

export {
	fetchAccount,
	fetchAccountFD,
	fetchAccounts,
	fetchAccountSystem,
	fetchAccountSystems,
	fetchAccountTemplates,
	updateSearch,
	saveNewAccount,
	deleteAccount,
	fetchDataSets,
};