import React from 'react';
import Select from 'react-select';
import timezones from './timezones.json';

function CrowFieldCustomCommonDateTimezone(props) {
	const {
		entity,
	} = props;
	let {
		value,
	} = props;
	let {
		attr = {},
	} = entity || {};
	let {
		htmlattr,
	} = attr || {};

	let options = [];
	timezones.forEach((entry) => {
		entry.utc.forEach((timezone) => {
			let tz = {
				'label': `${timezone} (${entry.abbr} - ${entry.value})`,
				'value': timezone,
			};

			options.push(tz);

			// If props.value matches timezone, set to react-select object format
			if (value && value === timezone) {
				value = tz;
			}
		});
	});

	// Sort timezones alphabetically
	options.sort((a, b) => {
		if (a.label < b.label) {
			return -1;
		}
		if (a.label > b.label) {
			return 1;
		}
		return 0;
	});

	return (
		<div className={`text-small ${(props.classNameList || []).join(' ')}`}>
			<div className="label">
				<label>{entity.attr.label}</label>
			</div>
			<Select
				id={attr['data-test-id'] || attr.name}
				options={options}
				value={value}
				onChange={(e) => {
					props.app.setValue('todo', entity.name, e.value);
				}}
				{...htmlattr}
			/>
		</div>
	);
}

const _exports = {
	name:  'custom.common.date.timezone',
	desc:  'Standard Timezone Field',
	scope: {},
	field: CrowFieldCustomCommonDateTimezone,
};

export default _exports;