import React, {
	useState,
} from 'react';
import Select from 'react-select';

import style from './Users.module.css';
import TextSmall from 'Vulture/Field/TextSmall';

import {
	buildTestData,
} from 'Vulture/TestData.js';

import {
	providerListToModalContent,
} from './listingIntegrationModels';

const styles = {
	control: (base) => {
		return {
			...base,
			maxHeight: 30,
		};
	},
	dropdownIndicator: (base) => {
		return {
			...base,
			paddingTop:    0,
			paddingBottom: 0,
		};
	},
	clearIndicator: (base) => {
		return {
			...base,
			paddingTop:    0,
			paddingBottom: 0,
		};
	},
	menu: (base) => {
		return {
			...base,
			top:      20,
			position: 'absolute',
		};
	},
};


const mallOptions = [
	{
		label: 'Wisconsin Pl',
		value: '2633',
	},
	{
		label: 'Arden Fair',
		value: '166',
	},
];

const ListingIntegrationModal = (props) => {
	const {
		closeModal,
		accountUUID,
		listingConfigUUID,
		provider,
		providerList,
		app,
	} = props;

	const [ email, setEmail, ] = useState('');
	const [ authCode, setAuthCode, ] = useState('');
	const [ mallID, setMallID, ] = useState(mallOptions[0].value);

	const {
		fields,
	} = providerListToModalContent[provider];

	const authenticate = async () => {
		await app.registerListingIntegration(
			accountUUID,
			listingConfigUUID,
			provider,
			providerList,
			email,
			authCode,
			mallID,
		);
		closeModal();
	};

	return (
		<div className={style.userModal}>
			<div className={style.integrationInputsContainer}>
				{props.hasSetCredentials && (
					<div className={style.hasCredentialsWarning}>There are already set credentials. Setting new ones will override the previous credentials.</div>
		        )}
				{'email' in fields &&
					<>
						<div className={style.label}>{fields.email}</div>
						<TextSmall
							className={style.select}
							value={email}
							setValue={(e) => {
								setEmail(e);
							}}
							dataTestingInfo={buildTestData('')}
						/>
					</>
				}
				{'authCode' in fields &&
					<>
						<div className={style.label}>{fields.authCode}</div>
						<TextSmall
							className={style.select}
							value={authCode}
							setValue={(e) => {
								setAuthCode(e);
							}}
							dataTestingInfo={buildTestData('')}
						/>
					</>
				}
				{'mallID' in fields &&
					<>
						<div className={style.label}>{fields.mallID}</div>
						<Select
							defaultValue={mallOptions[0]}
							options={mallOptions}
							styles={styles}
							onChange={(newType) => {
								setMallID(newType.value);
							}}
							value={mallOptions.find((option) => {
								return option.value === mallID;
							})}
						/>
					</>
				}
				<br/>
				<button
					className={`button ${style.button}`}
					key='add-tier-btn'
					onClick={(e) => {
						e.preventDefault();
						authenticate();
					}}
					data-testing-info={buildTestData('button--tier')}
				>
					Log In
				</button>
			</div>
		</div>
	);
};

export default ListingIntegrationModal;