import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
} from 'Vulture/ENV.js';

export const applySystemTemplateAssignment = async (accountUUID, assignmentMap) => {
	const session = getSession();

	let url = `${broncoURL}/system/template_assignment`;
	let body = {
		account_uuid:   accountUUID,
		assignment_map: assignmentMap,
	};

	const headers = {
		'X-Auth-Session': session.session,
		'Content-Type':   'application/json',
	};

	try {
		let res = await fetch(url, {

			method: 'POST',
			headers,
			body:   JSON.stringify(body),
		});

		const resJSON = await res.json();

		return resJSON.data;
	} catch (err) {
		console.error(err);
	}
};