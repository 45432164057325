import React from 'react';

import style from './Accounts.module.css';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import AccountsTable from './AccountsTable';
import CustomCheckbox from './Elements/Checkbox';
import debounce from 'lodash.debounce';

import {
	buildTestData,
} from 'Vulture/TestData';

// import getSession
import {
	getSession,
} from 'Vulture/Session.js';

import {
	saveNewAccount,
	updateSearch,
} from 'Vulture/Account';

class AccountsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accountObject: {},
			accountsCount: 0,
			accountUUID:   '',
			// Column widths -- should add up to 100
			nameColumn:    {
				'width': '30%',
			},
			salesforceColumn: {
				'width': '15%',
			},
			macAddress: {
				'width': '15%',
			},
			systemGroupNameColumn: {
				'width': '30%',
			},
			systemsColumn: {
				'width': '10%',
			},
			nameFilter:            '',
			salesforceFilter:      '',
			macAddressFilter:      '',
			systemGroupNameFilter: '',
			tableData:             [],
			showFeatureWindow:     false,
			filtersInUse:          false,
			featuresSet:           false,
			showLoader:            false,
			retryBanner:           false,
			retryBannerContent:    <></>,
			networkError:          false,
			networkErrorMessage:   false,
			filters:               {
				animatedScroller: false,
				brood:            false,
				dynamicMessage:   false,
				events:           false,
				finance:          false,
				infoBox:          false,
				listing:          false,
				monarch:          false,
				news:             false,
				pelican:          false,
				slideshow:        false,
				weather:          false,
			},
		};
	}

	componentDidMount() {
		const {
			app,
			accountList,
		} = this.props;

		if (accountList.length === 0) {
			return app.apiFetchUserAccounts.call(app);
		}

		this.makeDefaultAccountList();
	}

	componentDidUpdate(prevProps) {
		const {
			accountList,
		} = this.props;

		if (accountList.length === 0) {
			return;
		}

		if (prevProps.accountList !== accountList) {
			this.makeDefaultAccountList();
		}
	}

	makeDefaultAccountList(update = true) {
		const {
			accountList,
		} = this.props;

		let accountUUIDs = accountList.map((a) => {
			return Object.keys(a)[0];
		});

		if (update) {
			this.updateSearch(null, true, accountUUIDs);
		}
	}

	setFeatureInformation() {
		let {
			value,
		} = this.props;
		let entityKeys = Object.keys(value);
		let accountObject = this.state.accountObject;
		let accountEntityKeys = Object.keys(accountObject);
		let condorRenderNameSettingID = this.props.form.state.vulture.condorRenderNameSettingID;

		if (condorRenderNameSettingID === undefined) {
			entityKeys.forEach((entityKey) => {
				let {
					m1, m1ID, col,
				} = this.props.form.parseEntityName(entityKey);
				if (m1 === 'setting' && col === 'name' && value[entityKey].value === 'condor_render_name') {
					condorRenderNameSettingID = m1ID;
				}
			});
		}

		accountEntityKeys.forEach((entityKey) => {
			let key = entityKey.split('.');
			let m1 = key[0];
			let m1ID = key[1];
			accountObject[entityKey].features = this.getFeaturesForSystemGroup([ m1, ], [ m1ID, ], value, condorRenderNameSettingID);
		});

		this.props.form.props.showLoader(false);

		this.setState({
			accountObject,
			featuresSet:       true,
			showFeatureWindow: true,
		});
	}

	addNewAccountToAccountObject(accountName, uuid, salesforce_id_val) {
		let accountObject = this.state.accountObject;
		let accountsCount = this.state.accountsCount;

		accountObject[`account.${uuid}`] = {
			id:               uuid,
			m1:               'account',
			name:             accountName,
			systemCount:      0,
			systemGroupNames: '',
			features:         [],
			salesforceID:     salesforce_id_val,
		};
		accountsCount++;

		this.setState({
			accountObject,
			accountsCount,
		});
	}

	getFeaturesForSystemGroup(parentM1List, parentM1IDList, value, condorRenderNameSettingID, features = []) {
		const newParentM1List = [];
		const newParentM1IDList = [];
		const {
			fd,
		} = this.props;

		parentM1List.forEach((pm1, i) => {
			const m1ToM2Keys = [];

			for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
				if (m1Key === `${pm1}.${parentM1IDList[i]}`) {
					for (const m2Key of Object.keys(m1Data)) {
						const sections = m2Key.split('.').length;
						const entityKey = `${m1Key}.${m2Key}`;
						if (sections === 2) {
							m1ToM2Keys.push(entityKey);
						}
					}
				}
			}

			m1ToM2Keys.forEach((entityKey) => {
				if (entityKey.split('.').length !== 4) {
					return;
				}
				const {
					m1, m1ID, m2, m2ID,
				} = this.props.form.parseEntityName(entityKey);

				if (m1 === 'component') {
					if (value[`component.${m1ID}.setting.${condorRenderNameSettingID}.string`]) {
						let settingName = value[`component.${m1ID}.setting.${condorRenderNameSettingID}.string`].value;
						if (!features.includes(settingName.toLowerCase())) {
							features.push(settingName.toLowerCase());
						}
					}
				}

				if (m2 !== 'setting' && m2 !== 'component_type') {
					newParentM1List.push(m2);
					newParentM1IDList.push(m2ID);
				}

			});
		});

		if (newParentM1List.length > 0) {
			this.getFeaturesForSystemGroup(newParentM1List, newParentM1IDList, value, condorRenderNameSettingID, features);
		}

		return features;
	}

	getSystemCountForSystemGroup(parentM1, parentM1ID, count = 0) {
		let {
			value,
		} = this.props;
		let entityKeys = Object.keys(value);

		entityKeys.forEach((entityKey) => {
			const {
				m1, m1ID, m2, m2ID,
			} = this.props.form.parseEntityName(entityKey);

			if (m1 === parentM1 && m1ID === parentM1ID && m2 === 'system') {
				count++;
			}

			if (m1 === parentM1 && m1ID === parentM1ID && m2 === 'system_group') {
				count = this.getSystemCountForSystemGroup(m2, m2ID, count);
			}
		});

		return count;
	}

	getOwnedSystemGroupNamesForSystemGroup(accountUUID) {
		let {
			value,
		} = this.props;
		let entityKeys = Object.keys(value);
		let systemGroupNames = '';

		entityKeys.forEach((entityKey) => {
			const {
				m1, m1ID, m2, m2ID,
			} = this.props.form.parseEntityName(entityKey);

			if (m1 === 'account' && m1ID === accountUUID && m2 === 'system_group') {
				if (value[`system_group.${m2ID}.display_name`] && value[`system_group.${m2ID}.salesforce_id_val`]) {
					systemGroupNames += value[`system_group.${m2ID}.display_name`].value + ', ';
				}
			}
		});

		return systemGroupNames.substring(0, systemGroupNames.length - 2);
	}

	renderSystemGroupInformation(systemGroup) {
		let {
			router,
		} = this.props.form.props;
		router.redirect({
			to: `/systems/${systemGroup.id}`,
		});

		this.setState({
			tableData: [],
		});

		this.props.form.setState({
			vulture: {
				...this.props.form.state.vulture,
				activeComponent: {},
			},
		});
	}

	debounce = (callback, delay) => {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				return callback(...args);
			}, delay);
		};
	}

	debounceHandler = debounce((columnName, value) => {
		this.updateSearch(columnName, value);
	}, 300)

	handleFilter = (columnName, value) => {
		switch (columnName) {
		case 'nameFilter':
			this.setState({
				nameFilter: value,
			});
			break;
		case 'salesforceFilter':
			this.setState({
				salesforceFilter: value,
			});
			break;
		case 'macAddressFilter':
			this.setState({
				macAddressFilter: value,
			});
			break;
		case 'systemGroupNameFilter':
			this.setState({
				systemGroupNameFilter: value,
			});
			break;
		default:
			break;
		}
		/* this.debounceHandler(columnName, value) */
	}

	clearInputs = (currentInput) => {
		switch (currentInput) {
		case 'nameFilter':
			this.setState({
				macAddressFilter:      '',
				salesforceFilter:      '',
				systemGroupNameFilter: '',
			});
			break;
		case 'salesforceFilter':
			this.setState({
				macAddressFilter:      '',
				systemGroupNameFilter: '',
				nameFilter:            '',
			});
			break;
		case 'macAddressFilter':
			this.setState({
				salesforceFilter:      '',
				nameFilter:            '',
				systemGroupNameFilter: '',
			});
			break;
		case 'systemGroupNameFilter':
			this.setState({
				macAddressFilter: '',
				nameFilter:       '',
				salesforceFilter: '',
			});
			break;
		default:
			break;
		}
	}

	async updateSearch(columnName, value, uuids = []) {
		if (!value) {
			this.setState({
				tableData:    [],
				[columnName]: value,
			}, () => {
				this.makeDefaultAccountList();
			});

			return;
		}

		const pg = getSession().pg;
		if (columnName && value && (pg === 'ea' || pg === 'pm')) {
			return;
		}

		const data = await updateSearch(columnName, value, uuids);
		const tableData = [];

		if (data?.err) {
			this.setRetryBanner(columnName, value, uuids);
		} else if (data) {
			Object.keys(data).forEach((uuid) => {
				let account = data[uuid];

				let systemGroupNames = Object.keys(account.system_groups);

				systemGroupNames.sort(function (a, b) {
					let aName = a.toLowerCase();
					let bName = b.toLowerCase();
					if (aName > bName) {
						return 1;
					}
					if (aName < bName) {
						return -1;
					}
					return 0;
				});
				tableData.push({
					id:               uuid,
					m1:               'account',
					name:             account.display_name,
					salesforceID:     account.salesforce_id,
					systemCount:      account.system_count,
					systemGroupNames: systemGroupNames.join('|||'),
					systemGroupData:  account.system_groups,
				});
			});

			tableData.sort((a, b) => {
				let aName = a.name.toLowerCase();
				let bName = b.name.toLowerCase();

				if (aName > bName) {
					return 1;
				}

				if (aName < bName) {
					return -1;
				}

				return 0;
			});

			this.setState({
				tableData,
				filtersInUse:          true,
				nameFilter:            (columnName === 'nameFilter') ? value : '',
				salesforceFilter:      (columnName === 'salesforceFilter') ? value : '',
				systemGroupNameFilter: (columnName === 'systemGroupNameFilter') ? value : '',
			});
		} else {
			this.setState({
				tableData:             [],
				filtersInUse:          true,
				nameFilter:            (columnName === 'nameFilter') ? value : '',
				salesforceFilter:      (columnName === 'salesforceFilter') ? value : '',
				systemGroupNameFilter: (columnName === 'systemGroupNameFilter') ? value : '',
			});
		}
	}

	setRetryBanner(columnName, value, uuids) {
		this.setState({
			retryBanner:        true,
			retryBannerContent: <>
				<p>A network error has occured.</p>
				<div className="confirm-modal-buttons">
					<button className="button" onClick={(e) => {
						this.setState({
							retryBanner: false,
						});
						this.updateSearch(columnName, value, uuids);
					}}>Retry</button>
					<button className="button-outlined" onClick={(e) => {
						this.setState({
							retryBanner: false,
						});
					}}>Return</button>
				</div>
			</>,
		});
	}

	systemGroupHasFilteredFeatures(systemGroup) {
		let filters = this.state.filters;
		let systemGroupValid = true;

		Object.keys(filters).forEach((filter) => {
			if (filters[filter]) {
				if (!systemGroup.features.includes(filter.toLowerCase())) {
					systemGroupValid = false;
				}
			}
		});

		return systemGroupValid;
	}

	closeFeatureWindow() {
		this.setState({
			showFeatureWindow: false,
		});
	}

	submitAndUpdateChanges() {
		let formSubmit = document.getElementById('crow-form-submit-btn');
		formSubmit.click();
	}

	getFeatures() {
		let elms = [];
		let filters = this.state.filters;

		Object.keys(filters).forEach((filter, i) => {
			let checked = filters[filter];
			let elm = (
				<div className={style.featureElement} key={`${filter.displayName}_${i}`} onClick={(e) => {
					e.preventDefault();
					let filtersInUse = false;
					filters[filter] = !filters[filter];
					Object.values(filters).forEach((filterValue) => {
						if (filterValue) {
							filtersInUse = true;
						}
					});
					this.setState({
						filters,
						filtersInUse,
					});
					this.updateSearch();
				}}>
					<CustomCheckbox checked={checked} />
					{filter.charAt(0).toUpperCase() + filter.slice(1)}
				</div>
			);
			elms.push(elm);
		});

		return elms;
	}

	// TODO:
	// swap kali for axios
	// TODO:
	// remove page reload if possible
	async saveNewAccount(accountName, id) {
		const {
			app,
		} = this.props;

		const data = await saveNewAccount(accountName, id);

		if (data?.err) {
			this.setState({
				networkError:        true,
				networkErrorMessage:
					<>
						<p>A network error has occured</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								e.preventDefault();
								this.setState({
									networkError:        false,
									networkErrorMessage: '',
								});
							}}>Okay</button>
						</div>
					</>,
			});
		} else if (data) {
			app.setState({
				accountUUID: data.account_uuid,
				accountName,
			}, () => {
				this.closeModal();
				app.redirect({
					to: `/systems/${this.state.accountUUID}`,
				});
				this.props.app.fetchAccount(data.account_uuid);
			});
		}
	}

	closeModal(cb = () => {}) {
		this.props.app.setState({
			modalSize: 0,
			showModal: false,
			modal:     false,
			/* modalProps: {}, */
		}, () => {
			if (typeof cb === 'function') {
				return cb();
			}
		});
	}

	render() {
		const {
			app,
		} = this.props;

		let filtersApplied = this.state.nameFilter.length > 2 || this.state.salesforceFilter.length > 2 || this.state.macAddressFilter.length > 2 || this.state.systemGroupNameFilter.length > 2 || this.state.filtersInUse;
		let numberShowing = this.state.tableData.length;
		if (!filtersApplied) {
			numberShowing = 0;
		}

		const pg = getSession().pg;

		if (~window.location.href.indexOf('alias=1')) {
			return (
				<div className={`systems-page`}>
					<div className='settings-header'>
						<h2>Loading Account...</h2>
					</div>
				</div>
			);
		}
		let retryBanner = '';
		if (this.state.retryBanner === true) {
			retryBanner =
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.retryBannerContent}
					</div>
				</div>;
		}
		let networkErrorModal = '';
		if (this.state.networkError === true) {
			networkErrorModal =
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.networkErrorMessage}
					</div>
				</div>;
		}
		return (
			<React.Fragment>
				{retryBanner}
				{networkErrorModal}
				<div className={`systems-page`}>
					<div className='settings-header'>
						<h2>Accounts</h2>
					</div>
					{/* <FeatureWindow
							show={this.state.showFeatureWindow}
							close={this.closeFeatureWindow.bind(this)}
							getFeatures={this.getFeatures.bind(this)}
						/> */}
					<div className={`secondaryNav ${style.secondaryNav}`}>
						<div className={style.secondaryNavButtons}>
							{/* <button  className='button' onClick={(e) => {
									e.preventDefault();
									let showFeatureWindow = !this.state.showFeatureWindow;
									if (!this.state.featuresSet) {
										this.props.form.props.showLoader(true);
										this.setState({
											showLoader: true,
										}, () => {
											// prevent setStates from batching, this allows wait screen to display
											// there must be a better way to deal with batching?
											setTimeout(() => {
												this.setFeatureInformation();
											}, 1);
										});
										return;
									}
									this.setState({
										showFeatureWindow,
									});
								}}>
									<CustomIcon icon='search'/>Features
								</button> */}

							{(pg === 'iu' || pg === 'ia') &&
								<button className='button' data-testing-info={buildTestData('button--addNew')} onClick={(e) => {
									app.openModal({
										modalSize:  2,
										showModal:  true,
										modalProps: {
											title: 'New Account',
											jsx:   (
												<NewAccountModal
													networkError={this.state.networkError}
													saveNewAccount={this.saveNewAccount.bind(this)}
													confirmModal={this.state.confirmModal}
													confirmModalContent={this.state.confirmModalContent}
													closeModal={this.closeModal.bind(this)}
													addNewAccountToAccountObject={this.addNewAccountToAccountObject.bind(this)}
												/>
											),
										},
									});
								}}>
									<CustomIcon icon='plus-circle' />Add New
								</button>
							}
						</div>
					</div>
					<table className={style.table} data-testing-info={buildTestData('table--accounts--filters')}>
						<thead className={style.thead}>
							<tr className={style.tr}>
								<th className={style.th} style={this.state.nameColumn}>
									Name
								</th>
								<th className={style.th} style={this.state.salesforceColumn}>
									Salesforce ID
								</th>
								<th className={style.th} style={this.state.macAddress}>
									Mac Address
								</th>
								<th className={style.th} style={this.state.systemGroupNameColumn}>
									Properties
								</th>
								<th className={style.th} style={this.state.systemGroupNameColumn}>
									Systems
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className={style.accountFilter}>
								<td className={style.td} style={this.state.nameColumn}>
									<input
										className="filter"
										type="text"
										placeholder="Search..."
										data-testing-info={buildTestData('input--search--name')}
										value={this.state.nameFilter}
										onChange={(e) => {
											this.handleFilter('nameFilter', e.target.value);
											this.clearInputs('nameFilter');
										}}
										onKeyUp={(e) => {
											if (e.key === 'Enter') {
												if (e.key === 'Enter') {
													this.debounceHandler('nameFilter', this.state.nameFilter);
												}
											}
										}}
									/>
								</td>
								<td className={style.td} style={this.state.salesforceColumn}>
									<input
										className="filter"
										type="text"
										placeholder="Search..."
										data-testing-info={buildTestData('input--search--salesforceID')}
										value={this.state.salesforceFilter}
										onChange={(e) => {
											this.handleFilter('salesforceFilter', e.target.value);
											this.clearInputs('salesforceFilter');
										}}
										onKeyUp={(e) => {
											if (e.key === 'Enter') {
												if (e.key === 'Enter') {
													this.debounceHandler('salesforceFilter', this.state.salesforceFilter);
												}
											}
										}}
									/>
								</td>
								<td className={style.td} style={this.state.macAddress}>
									<input
										className="filter"
										type="text"
										placeholder="Search..."
										data-testing-info={buildTestData('input--search--macAddress')}
										value={this.state.macAddressFilter}
										onChange={(e) => {
											this.handleFilter('macAddressFilter', e.target.value);
											this.clearInputs('macAddressFilter');
										}}
										onKeyUp={(e) => {
											if (e.key === 'Enter') {
												if (e.key === 'Enter') {
													this.debounceHandler('macAddressFilter', this.state.macAddressFilter);
												}
											}
										}}
									/>
								</td>
								<td className={style.td} style={this.state.systemGroupNameColumn}>
									<input
										className="filter"
										type="text"
										placeholder="Search..."
										value={this.state.systemGroupNameFilter}
										data-testing-info={buildTestData('input--search--properties')}
										onChange={(e) => {
											this.handleFilter('systemGroupNameFilter', e.target.value);
											this.clearInputs('systemGroupNameFilter');
										}}

										onKeyUp={(e) => {
											if (e.key === 'Enter') {
												if (e.key === 'Enter') {
													this.debounceHandler('systemGroupNameFilter', this.state.systemGroupNameFilter);
												}
											}
										}}
									/>
								</td>
								<td className={style.td} style={this.state.systemsColumn}>
								</td>
							</tr>
						</tbody>
					</table>
					{filtersApplied ?
						<AccountsTable
							app={this.props.app}
							array={this.state.tableData}
							renderSystemGroupInformation={this.renderSystemGroupInformation.bind(this)}
						/> :
						<p className={style.filterInstructions} data-test-id="accountsMessage">Use the search fields to find specific accounts, then press Enter.</p>
					}
				</div>
			</React.Fragment>
		);
	}
}

class FeatureWindow extends React.Component {

	render() {
		let show = this.props.show;

		return (
			<React.Fragment>
				{show ?
					<React.Fragment>
						<div className={style.featuresWindow}
							onMouseLeave={(e) => {
								e.preventDefault();
								this.props.close();
							}}>
							<div className={style.featuresHeader}>
								<div>
									<CustomIcon icon="eye" /> Filter by Feature
								</div>
								<button onClick={(e) => {
									e.preventDefault();
									this.props.close();
								}}>
									<CustomIcon icon="times-circle" />
								</button>
							</div>
							<div className={style.featureElements}>
								{this.props.getFeatures()}
							</div>
						</div>
					</React.Fragment> :
					null
				}
			</React.Fragment>
		);
	}
}

class NewAccountModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			confirmModal:        false,
			confirmModalContent: '',
			inputsAreValid:      false,
			accountName:         '',
			salesId:             '',
			inputs:              [
				{
					name:  'Account name',
					value: '',
					error: true,
				},
				{
					name:  'Salesforce ID',
					value: '',
					error: true,
				 },
			],
		};
	}

	handleInputChange = (idx, event) => {
		const {
			inputs,
		} = this.state;
		const target = event.target;
		let error = false;
		if (!target.value) {
			error = true;
		}
		inputs[idx] = {
			...inputs[idx],
			value: target.value,
			error,
		};

		this.setState({
			inputs:         [ ...inputs, ],
			inputsAreValid: (inputs[0].error || inputs[1].error) ? false : true,
		});
	}

	render() {
		let confirmModal = '';
		let saveButtonClass = 'button button-disabled no-click';
		if (this.state.inputsAreValid) {
			saveButtonClass = 'button';
		}
		if (this.props.confirmModal === true) {
			confirmModal = (
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>
			);
		}

		return (
			<>
				{confirmModal}
				<div className={`text-small ${style.modalContent} ${style.normalizePadding}`}>
					<div className={style.sectionLabel}>Details</div>
					{this.state.inputs.map((input, idx) => {
						return (
							<div key={idx}>
								<label htmlFor="">{input.name}</label>
								<input type="text" value={input.value} data-testing-info={buildTestData(`table--${input.name.toLowerCase().split(' ').join('--')}`)} onChange={(e) => {
									return this.handleInputChange(idx, e);
								}} />
							</div>
						);
					})}
				</div>
				<div className={style.modalButtons}>
					<button
						className={saveButtonClass}
						data-testing-info={buildTestData('button--save')}
						onClick={(e) => {
							e.preventDefault();
							let accountName = this.state.inputs[0].value;
							let id = this.state.inputs[1].value;
							this.props.saveNewAccount(accountName, id);
						}}
					>
						<CustomIcon icon='save' />Save
					</button>
				</div>
			</>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.accounts'] = AccountsView;
export default AccountsView;
