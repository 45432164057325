import {
	getSession,
} from 'Vulture/Session.js';

const PGP_VIEW_INTERNAL = 'PGP_VI';
const PGP_VIEW_EXTERNAL = 'PGP_VE';

const hasPermission = function (permission) {
	let pg = (getSession() || {}).pg || false;
	if (!pg) {
		return false;
	}

	if (pg === 'ia') {
		return true;
	}

	switch (permission) {
		case PGP_VIEW_INTERNAL:
			if (pg !== 'iu') {
				return false;
			}
			break;

		case PGP_VIEW_EXTERNAL:
			if (pg !== 'iu' && pg !== 'ea') {
				return false;
			}
			break;
	}

	return true;
};

export {
	hasPermission,
	PGP_VIEW_INTERNAL,
	PGP_VIEW_EXTERNAL,
};