import React, {
	createRef,
} from 'react';
import QRCode from 'qrcode.react';
import {
	clearSecret,
	getEmail,
	getSecret,
	verify2FA,
} from 'Vulture/Session';
import CustomIcon from 'Crow/Form/Field/Custom/Common/CustomIcon/CustomIcon.js';

import style from 'Vulture/View/MFA.module.css';
import {
	setSession,
} from '../Session';

class MFAView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};

		this.number1Ref = createRef();
		this.number2Ref = createRef();
		this.number3Ref = createRef();
		this.number4Ref = createRef();
		this.number5Ref = createRef();
		this.number6Ref = createRef();
		this.submitButton = createRef();
	}

	componentDidMount() {
		if (this.number1Ref.current) {
			this.number1Ref.current.focus();
		}
	}

	async verify2FA(code) {
		const {
			app,
		} = this.props;

		app.load(async () => {
			const email = getEmail();

			let {
				err,
				res,
				data,
			} = await verify2FA(email, code);

			if (err) {
				if (res.statusText) {
					alert(`An error occurred in your authentication process:\n${res.statusText}`);
				} else {
					alert('An unexpected error occurred in your authentication process. Please try again.\nIf the problem persists, please contact support.');
				}
				return;
			}

			if (data.is_validated) {
				data.email = email;
				setSession(data);
				clearSecret();

				app.apiFetchUserAccounts(() => {
					app.redirect({
						to: `/accounts`,
					});
				});
			} else {
				alert('We\'re sorry, but the code you entered is incorrect.\nPlease double-check the code on your authenticator app and try again.');
			}
		});
	}

	build2FASection(URI, secret) {
		let mfaSection = '';

		if (secret)  {
			mfaSection = (
				<>
					<div className={style.mfaLeftSection}>
						<h1>Two-factor<br/>Authentication</h1>
						<p>You have enabled two-factor authentication for your account. Follow the instructions to complete the setup.</p>
						<QRCode
							bgColor={'#fff'} // Color of the bright squares
							fgColor={'#000'} // Color of the dark squares
							level='H' // QR Error correction level: L, M, Q, H
							style={{
								width:  '100%',
								height: '100%',
								border: `10px solid #fff`,
							}} // Size in pixels
							value={URI} // The url
						/>
					</div>
					<div className={style.mfaRightSection}>
						<div className={style.qrCodeInputContainer}>
							<ol>
								<li>Download an authenticator app. (We recommend <a target='_blank' rel='noreferrer' href='https://apps.apple.com/br/app/google-authenticator/id388497605'>Google Authenticator</a>)</li>
								<li>Scan this QR Code.</li>
								<li>Enter verification code.</li>
							</ol>
							{this.buildCodeInput()}
						</div>
						<div className={style.submitButtonContainer}>
							<button
								className={style.submitButton}
								ref={this.submitButton}
								onClick={() => {
									const code = `${this.number1Ref.current.value}${this.number2Ref.current.value}${this.number3Ref.current.value}${this.number4Ref.current.value}${this.number5Ref.current.value}${this.number6Ref.current.value}`;
									this.verify2FA(code);
								}}
							>
								<CustomIcon icon='sign-in-alt' /> Verify
							</button>
							<a href=''>&larr; Go back</a>
							<div className={style.mfaFooter}>
								<p>Having trouble logging in?</p>
								<p>Secure Login subject to our <a target='_blank' rel='noreferrer' href='https://ts-public-documents.s3.amazonaws.com/Terms2020.pdf'>Terms</a> and <a target="_blank" rel='noreferrer' href='https://ts-public-documents.s3.amazonaws.com/TouchSource+Privacy+Policy_v1.pdf'>Privacy Policy.</a></p>
							</div>
						</div>
					</div>
				</>
			);
		} else {
			mfaSection =
				<div className={style.mfaSection}>
					<div className={style.qrCodeInputContainer}>
						<img className={style.mfaIcon} src='https://portal.touchsource.com/assets/images/two-factor.png' alt='2fa icon' />
						<p>Enter your verification code below.</p>
						{this.buildCodeInput()}
					</div>
					<div className={style.submitButtonContainer}>
						<button
							className={style.submitButton}
							ref={this.submitButton}
							onClick={() => {
								const code = `${this.number1Ref.current.value}${this.number2Ref.current.value}${this.number3Ref.current.value}${this.number4Ref.current.value}${this.number5Ref.current.value}${this.number6Ref.current.value}`;
								this.verify2FA(code);
							}}
						>
							<CustomIcon icon='sign-in-alt' /> Verify
						</button>
						<a href=''>&larr; Go back</a>
						<div className={style.mfaFooter}>
							<p>Having trouble logging in?</p>
							<p>Secure Login subject to our <a target='_blank' rel='noreferrer' href='https://ts-public-documents.s3.amazonaws.com/Terms2020.pdf'>Terms</a> and <a target='_blank' rel='noreferrer' href='https://ts-public-documents.s3.amazonaws.com/TouchSource+Privacy+Policy_v1.pdf'>Privacy Policy.</a></p>
						</div>
					</div>
				</div>;
		}

		return mfaSection;
	}

	buildCodeInput() {
		let codeInput = (
			<>
				<div className={style.codeContainer}>
					<input
						className={style.contentFilter}
						type="number"
						ref={this.number1Ref}
						onFocus={() => {
							this.number1Ref.current.value = '';
						}}
						onChange={() => {
							this.number2Ref.current.focus();
						}}
					/>
					<input
						className={style.contentFilter}
						type="number"
						ref={this.number2Ref}
						onFocus={() => {
							this.number2Ref.current.value = '';
						}}
						onChange={() => {
							this.number3Ref.current.focus();
						}}
					/>
					<input
						className={style.contentFilter}
						type="number"
						ref={this.number3Ref}
						onFocus={() => {
							this.number3Ref.current.value = '';
						}}
						onChange={(e) => {
							this.number4Ref.current.focus();
							if ('0' > e.target.value || '9' < e.target.value) {
								this.number3Ref.current.value = '';
							}
						}}
					/>
					<input
						className={style.contentFilter}
						type="number"
						ref={this.number4Ref}
						onFocus={() => {
							this.number4Ref.current.value = '';
						}}
						onChange={() => {
							this.number5Ref.current.focus();
						}}
					/>
					<input
						className={style.contentFilter}
						type="number"
						ref={this.number5Ref}
						onFocus={() => {
							this.number5Ref.current.value = '';
						}}
						onChange={() => {
							this.number6Ref.current.focus();
						}}
					/>
					<input
						className={style.contentFilter}
						type="text"
						ref={this.number6Ref}
						onFocus={() => {
							this.number6Ref.current.value = '';
						}}
						onChange={() => {
							this.submitButton.current.focus();
						}}
					/>
				</div>
			</>
		);

		return codeInput;
	}

	render() {
		const email = getEmail();
		const secret = getSecret();
		let uri = '';

		if (secret) {
			uri = `otpauth://totp/TouchSource:${email}?secret=${secret}&issuer=TouchSource`;
		}

		return (
			<div className={style.mfaContainer}>
				<div className={style.header}>
					<div className={style.left}>
						<div className={style.logo}>
							<img src='https://ts-public-images.s3.amazonaws.com/TouchSourceSPARK_logo_FINAL_TS_whiteblue.png' alt='The Touchsource Cloud Beta logo' />
						</div>
					</div>
				</div>

				<div className={`${style.defaultContainer}`}>
					{this.build2FASection(uri, secret)}
				</div>
			</div>
		);
	}
}

export default MFAView;