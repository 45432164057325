import React, {
	useState,
} from 'react';

function CrowFieldFloat(props) {
	let {
		entity,
		value,
		app,
	} = props;
	let {
		attr = {},
	} = entity || {};
	let {
		htmlattr,
	} = attr || {};
	const [ fieldValue, setFieldValue, ] = useState(value);

	return (
		<div className={`float ${(props.classNameList || []).join(' ')}`}>
			<div className="label">
				<label>{attr.label}</label>
			</div>
			<input
				type='number'
				placeholder={0}
				value={fieldValue}
				step="any"
				onChange={(e) => {
					setFieldValue(e.target.value);
					app.setValue('todo', entity.name, Number(e.target.value));
				}}
				onBlur={(e) => {
					setFieldValue(e.target.value);
					app.setValue('todo', entity.name, Number(e.target.value));
				}}
				{...htmlattr}
				data-test-id={attr['data-test-id'] || attr.name}
			/>
		</div>
	);
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['custom.vulture.elements.float'] = CrowFieldFloat;
export default CrowFieldFloat;