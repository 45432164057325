export const phoneOptions = [
	{
		label: '🇺🇸 United States (+1)',
		value: '1',
		name:  'United States',
		flag:  '🇺🇸',
	},
	{
		label: '🇨🇦 Canada (+1)',
		value: '1',
		name:  'Canada',
		flag:  '🇨🇦',
	},
];