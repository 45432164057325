import React, { useEffect, useState } from "react";
import CrowFormEntity from '../../../../../Crow/Form/Entity';
import { getFDValue } from 'Vulture/Helpers';
import CustomIcon from '../Common/CustomIcon/CustomIcon';

import style from './DynamicMessage.module.css';

class DynamicMessageComponent extends React.Component {
	constructor(props) {
		super(props);
		const inputArr = [
			{
				type: "text",
				value: ""
			}
		];
		this.state = {
			activeComponentID: null,
			parentIDSet: new Set(),
			settingNameToUUIDMap: {},
			flatData: [],
			inputArr: [],
			messageList: JSON.parse(props.message_list),
		};
		this.handleChange = this.handleChange.bind(this);
		this.addInput = this.addInput.bind(this);
	}

	componentDidMount() {
		console.log('MOUNT: dynamic_message', this.props.value);
		const newArr = this.state.inputArr;
		this.state.messageList.forEach((message) => {
			newArr.push({type: "text",value: message})
		});
		this.setState({
			inputArr: newArr
		})
	}

	componentDidUpdate() {
		let activeComponentID;
		let renderName;

		if (activeComponentID) {
			if (this.state.activeComponentID !== activeComponentID && renderName === 'DynamicMessage') {
				this.setState({
					activeComponentID,
				});
			}
		}
	}

	addInput() {
		const newArr = this.state.inputArr.slice();
		newArr.push({
			type: "text",
			value: ""
		})
		this.renderChange(newArr);
	};

	deleteInput(i) {
		let newArr = this.state.inputArr;
		newArr.splice(i, 1);
		this.renderChange(newArr);
	};

	handleChange(e) {
		e.preventDefault();
		const index = e.target.id;
		const newArr = this.state.inputArr.slice();
		newArr[index].value = e.target.value;
		this.renderChange(newArr);
	};

	renderChange(newArr){
		this.setState({
			inputArr: newArr
		})
		let stringArr = [];
		newArr.map((item, i) => {
			stringArr.push(item.value);
		}); //json stringify this ersult
		this.props.setValue(JSON.stringify(stringArr));
	}

	renderMessage() {
		const { form, app } = this.props;
		let addButton = null;
		let deleteButton = null;
		return (
			<div className={style.inputsContainer}>

				{this.state.inputArr.map((item, i) => {
					if (this.state.inputArr.length - 1 === i && i < 9) {
						addButton = (
							<button onClick={this.addInput} className={`button button-notext ${style.smallButton}`}>
								<CustomIcon icon='plus' />
							</button>
						)
					}
					if (this.state.inputArr.length > 1) {
						deleteButton = (
							<button onClick={(e) => {
								e.preventDefault();
								this.deleteInput(i);
							}}
								className={`button button-notext ${style.smallButton}`}>
								<CustomIcon icon='trash' />
							</button>
						)
					}
					return (
						<div className={style.inputRow}>
							<div className={`text-small ${style.dynamicInput}`}>
								<input
									className={style.smallInput}
									onChange={this.handleChange}
									value={item.value}
									id={i}
									type={item.type}
									size="40"
									maxLength="60"
								/>
								{addButton}
								{deleteButton}
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	render() {
		return (
				<div className={style.rowContainer}>
					{this.renderMessage()}
				</div>
		);
	}
}


export default DynamicMessageComponent;