import React from 'react';

import CustomIcon from 'Crow/Form/Field/Custom/Common/CustomIcon/CustomIcon.js';

import {
	getSession,
	logout,
} from 'Vulture/Session.js';

import {
	dlAccountSelectPush,
	dlLogoutPush,
	dlNavigationPush,
	dlToolsMenu,
	dlUserMenu,
} from 'Vulture/DataLayer.js';

import style from './Header.module.css';
class HeaderView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			leftDropdownClass:  style.leftHidden,
			rightDropdownClass: style.rightHidden,
			toolsDropdownClass: style.toolsHidden,
			encryptedKey:       null,
		};
	}

	async logout() {
		const {
			app,
		} = this.props;

		try {
			await logout();
		} catch (err) {
			console.error(err);
		}

		app.logout();
	}

	componentDidUpdate(prevProps) {
		const {
			accountUUID,
		} = this.props;

		if (prevProps.accountUUID === accountUUID) {
			return;
		}

		const {
			session = '',
		} = getSession();

		let key = accountUUID.substring(0, 32);

		const env = window._getEnv('COMMITHASH');

		let iv = env;
		while (iv.length < 12) {
			iv += env;
		}
		iv = iv.substring(0, 12);

		const encoder = new TextEncoder();
		const keyBuffer = encoder.encode(key);
		const ivBuffer = encoder.encode(iv);
		const tokenBuffer = encoder.encode(session);

		// Import the key
		crypto.subtle.importKey('raw', keyBuffer, {
			name: 'AES-GCM',
		}, false, [ 'encrypt', 'decrypt', ])
			.then((cryptoKey) => {
				return crypto.subtle.encrypt({
					name: 'AES-GCM',
					iv:   ivBuffer,
				}, cryptoKey, tokenBuffer);
			})
			.then((encryptedKey) => {
				let bin = '';
				const bytes = new Uint8Array(encryptedKey);
				for (const byte of bytes) {
					bin += String.fromCharCode(byte);
				}

				this.setState({
					encryptedKey: window.btoa(bin),
				});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	renderLeftDropdown() {
		const {
			app,
			accountList,
		} = this.props;

		return (
			<div className={`${style.leftMenu} ${this.state.leftDropdownClass} ${accountList.length === 0 && style.buttonNoAccounts}`}>
				<ul className={style.leftList}>
					{accountList.map((accountObj, i) => {
						let m1UUID = Object.keys(accountObj)[0];
						let m1Name = Object.values(accountObj)[0];

						return (
							<li key={`account_${i}`}>
								<a
									onClick={(e) => {
										e.preventDefault();

										dlAccountSelectPush({
											accountName: m1Name,
										});

										this.setState({
											leftDropdownClass: style.leftHidden,
										}, () => {
											app.setAccountUUID(m1UUID);
										});
									}}
								>{m1Name}</a>
							</li>
						);
					})}
				</ul>

				<button className={style.button}
					onClick={(e) => {
						dlNavigationPush({
							destination: 'All Accounts',
						});

						this.setState({
							leftDropdownClass: style.leftHidden,
						}, () => {
							app.redirect({
								to: `/accounts`,
							});
						});
					}}
				>All Accounts</button>
			</div>
		);
	}

	renderRightDropdown() {
		return (
			<div className={`${style.rightMenu} ${this.state.rightDropdownClass}`} data-test-id="rightDropdown">
				<button
					data-test-id="logoutButton"
					className={style.button}
					onClick={async (e) => {
						dlLogoutPush();
						await this.logout();
					}}
				>Logout</button>
			</div>
		);
	}

	renderSupport() {
		const {
			app,
			accountUUID,
		} = this.props;

		// TODO: fix SF Web2Case form
		return '';

		return (
			<a href='' onClick={(e) => {
				e.preventDefault();

				let to = '/support';
				if (accountUUID) {
					to = `/support/${accountUUID}`;
				}

				app.redirect({
					to,
				});
			}}
			className={`${style.link} ${style.rightSupport}`}
			>
				<CustomIcon icon='headset' />&nbsp;
				<div className={`${style.pullDownName} ${style.pullDownAccountName}`}>Need Assistance?</div>
			</a>
		);
	}

	renderToolsDropdown() {
		const {
			app,
			accountUUID,
		} = this.props;

		const {
			encryptedKey,
		} = this.state;

		const {
			pg = '',
		} = getSession();

		let themeMenu = (
			<li>
				<a href='' onClick={(e) => {
					e.preventDefault();

					dlNavigationPush({
						destination: 'Theme Editor',
					});

					let to = '/theme_editor';
					if (accountUUID) {
						to = `/theme_editor/${accountUUID}`;
					}

					app.redirect({
						to,
					});

					this.setState({
						toolsDropdownClass: style.toolsHidden,
					});
				}}><CustomIcon icon="swatchbook"/>Theme Editor</a>
			</li>
		);

		let screenshotView = (
			<li>
				<a href='' onClick={(e) => {
					e.preventDefault();

					dlNavigationPush({
						destination: 'Screenshot',
					});

					let to = '/screenshots';
					if (accountUUID) {
						to = `/screenshots/${accountUUID}`;
					}

					app.redirect({
						to,
					});

					this.setState({
						toolsDropdownClass: style.toolsHidden,
					});
				}}><CustomIcon icon="book"/>Screenshots</a>
			</li>
		);

		const systemAssignment = (
			<li>
				<a href='' onClick={(e) => {
					e.preventDefault();

					dlNavigationPush({
						destination: 'System Assignment',
					});

					let to = '/system_assignment';

					app.redirect({
						to,
					});

					this.setState({
						toolsDropdownClass: style.toolsHidden,
					});
				}}><CustomIcon icon="book"/>System Assignment</a>
			</li>
		);

		const dataPresetsView = (
			<li>
				<a href='' onClick={(e) => {
					e.preventDefault();

					dlNavigationPush({
						destination: 'DataPresets',
					});

					let to = '/data_presets';
					if (accountUUID) {
						to = `/data_presets/${accountUUID}`;
					}

					app.redirect({
						to,
					});

					this.setState({
						toolsDropdownClass: style.toolsHidden,
					});
				}}><CustomIcon icon="laptop-code"/>Data Presets</a>
			</li>
		);

		const cardinalHost = window._getEnv('CARDINAL_URL');

		const cardinalLink = (
			<li>
				<a
					href={`${cardinalHost}/?cardinal_account=${accountUUID}&s=${encodeURIComponent(encryptedKey)}`}
					target='_blank'
					rel='noreferrer'
					onClick={() => {
						dlNavigationPush({
							destination: 'Cardinal',
						});
					}}
				>
					<CustomIcon icon="fa-location-arrow"/>Wayfinding
				</a>
			</li>
		);

		if (pg === 'iu' || pg === 'ia') {
			return (
				<div className={`${style.toolsMenu} ${this.state.toolsDropdownClass}`}>
					<ul className={style.toolsList}>
						<li>
							<a href='' onClick={(e) => {
								e.preventDefault();

								dlNavigationPush({
									destination: 'Admin Users',
								});

								let to = '/users';
								if (accountUUID) {
									to = `/users/${accountUUID}`;
								}

								app.redirect({
									to,
								});

								this.setState({
									toolsDropdownClass: style.toolsHidden,
								});
							}}><CustomIcon icon="users"/>Admin Users</a>
						</li>
						{pg === 'ia' ? themeMenu : ''}
						{pg === 'ia' ? dataPresetsView : ''}
						{pg === 'ia' ? screenshotView : ''}
						{accountUUID && (pg === 'ia' || pg === 'iu') ? cardinalLink : ''}
						{pg === 'ia' ? systemAssignment : ''}
					</ul>
				</div>
			);
		}
	}

	render() {
		const {
			accountList,
			accountName,
		} = this.props;

		const {
			email = '',
			pg = '',
		} = getSession();

		const selectedAccountName = accountName || 'Search Accounts';

		let toolsDropdown = null;
		if (pg === 'iu' || pg === 'ia') {
			toolsDropdown = (
				<div
					className={`${style.link} ${this.state.toolsDropdownClass}`}
					onClick={(e) => {
						e.preventDefault();

						dlToolsMenu();

						if (this.state.toolsDropdownClass === style.toolsHidden) {
							this.setState({
								toolsDropdownClass: style.toolsVisible,
							});
							return;
						}
						this.setState({
							toolsDropdownClass: style.toolsHidden,
						});
					}}
					data-onclick="Tools Navigation"
				>
					<div className={`${style.pullDownName} ${style.toolsTextBox}`}>TOOLS</div>
				</div>
			);
		}

		return (
			<React.Fragment>
				<div className={`${style.popoverBackground} ${this.state.leftDropdownClass} ${this.state.rightDropdownClass} ${this.state.toolsDropdownClass}`}
					onMouseOver={(e) => {
						e.preventDefault();
						this.setState({
							leftDropdownClass:  style.leftHidden,
							rightDropdownClass: style.rightHidden,
							toolsDropdownClass: style.toolsHidden,
						});
					}}/>
				<nav
					className={`${style.header}`}
				>
					<div className={style.left}>
						<div className={style.logo} data-onclick="The Touchsource Logo in header">
							<a href='https://touchsource.com/' target='_blank' rel='noopener'>
								<img src='https://ts-public-images.s3.amazonaws.com/TouchSourceSPARK_logo_FINAL_TS_whiteblue.png' alt="The Touchsource Spark PX logo" />
							</a>
						</div>
						<div className={style.leftDropdown}>
							<div className={style.vertLine}></div>
							<div
								className={`${style.link} ${this.state.leftDropdownClass}`}
								onClick={(e) => {
									e.preventDefault();
									if (this.state.leftDropdownClass === style.leftHidden) {
										this.setState({
											leftDropdownClass: style.leftVisible,
										});

										return;
									}

									this.setState({
										leftDropdownClass: style.leftHidden,
									});
								}}
								data-onclick="Project Navigation"
							>
								<div className={style.pullDownName}>{selectedAccountName}</div>
								{(pg === 'iu' || pg === 'ia') || accountList.length > 1 ? <CustomIcon icon='caret-down'/> : null}
							</div>
						</div>
					</div>
					<div className={style.right}>
						<div className={style.rightDropdown}>
							{this.renderSupport()}
							{toolsDropdown}
							<div
								className={`${style.link} ${this.state.rightDropdownClass}`}
								onClick={(e) => {
									e.preventDefault();
									dlUserMenu();
									if (this.state.rightDropdownClass === style.rightHidden) {
										this.setState({
											rightDropdownClass: style.rightVisible,
										});

										return;
									}
									this.setState({
										rightDropdownClass: style.rightHidden,
									});
								}}
								data-onclick="User Navigation"
								data-test-id="userNavigation"
							>
								<CustomIcon icon='user'/>&nbsp;
								<div className={`${style.pullDownName} ${style.pullDownAccountName}`}>{email}</div> <CustomIcon className='caret' icon='caret-down'/>
							</div>
						</div>
					</div>
				</nav>
				{(pg === 'iu' || pg === 'ia') || (accountList || []).length > 1 ? this.renderLeftDropdown() : null}
				{this.renderRightDropdown()}
				{(pg === 'iu' || pg === 'ia') ? this.renderToolsDropdown() : null}
			</React.Fragment>
		);
	}
}

export default HeaderView;