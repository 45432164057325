import React from 'react';
import axios from 'axios';

import Select from 'react-select';

import style from './Support.module.css';

class SupportView extends React.Component {
	constructor(props) {
		super(props);

        this.state = {
            systemsTableData: [],
            formData: {
                name: '',
                phone: '',
                mail: '',
                address: '',
                description: '',
            },
            selectedProperty: {},
            selectedIssue: {},
            showAddressField: false,
            supportIssues: [
                {'value': 'My TouchSource system is not updating', 'label': 'My TouchSource system is not updating'},
                {'value': 'The screen on my TouchSource system is not functioning properly', 'label': 'The screen on my TouchSource system is not functioning properly'},
                {'value': 'I would like to change the graphics, design or functionality on my TouchSource program', 'label': 'I would like to change the graphics, design or functionality on my TouchSource program'},
                {'value': 'I am having issues connecting to the portal', 'label': 'I am having issues connecting to the portal'},
                {'value': 'I have a question about billing', 'label': 'I have a question about billing'},
                {'value': 'My support issue is not listed', 'label': 'My support issue is not listed'},
            ],
            showSuccessMessage: ~window.location.hash.indexOf('success=1'),
            showErrorMessage: false,
        }
	}

    componentDidMount() {
        const {
			accountUUID,
		} = this.props;

        let systemsTableData = this.findInitialSystemGroupsForTable(accountUUID);
        let sortedTableData = Object.values(systemsTableData).sort(this.sortByName);
        let systemNameArray = []
        
        sortedTableData.map((system) => {
            systemNameArray.push({'value': system.searchValues.name, 'label': system.searchValues.name})
        })

		this.setState({
            systemsTableData: [...systemNameArray, {'value': 'Other property', 'label': 'Other property'}],
            selectedProperty: systemNameArray.length === 1 ? systemNameArray[0] : {}
		});
    }

    findInitialSystemGroupsForTable() {
		const {
			fd,
			accountUUID,
		} = this.props;

		let systemGroupObj = {};

		for (let [m1Key, m1Data] of Object.entries(fd)) {
			if (m1Key === `account.${accountUUID}`) {
				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('system_group.')) {
						let m2Data = fd[m2Key];
						let m2UUID = m2Key.split('.')[1];
						let m2Name = m2Data['display_name'];

						systemGroupObj[m2Key] = {
							id: m2UUID,
							name: 'name',
							m1: 'system_group',
							status: '',
							subRows: [],
							components: [],
							path: '',
							searchValues: {
								name: m2Name,
								resolution: '',
								status: '',
							},
						};
					}
				}
			}
		}

		return systemGroupObj;
	}    

    renderOptions() {
        this.systemsTableData?.forEach(element => {
            console.log(element);
        });
    }

    handleInputChange(e) {
        const newData = {...this.state.formData}
        newData[e.target.id] = e.target.value
        this.setState({
            formData: newData
        })
    }

    handleFormSubmit(e) {
        e.preventDefault()
        
        this.setState({
            showErrorMessage: false,
            showSuccessMessage: false,
        });

        let formElm = document.getElementById('support-form');
        let formData = new FormData(formElm);
        formData.append('selectedProperty', this.state.selectedProperty);
        formData.append('selectedIssue', this.state.selectedIssue);

        axios({
            method: 'post',
            url: 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8',
            withCredentials: false,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: formData,
        })
        .then((res) => {
            console.log(res);
            this.setState({
                showSuccessMessage: true,
                showErrorMessage: false,
            })
        })
        .catch((err) => {
            console.log(err);
            this.setState({
                showSuccessMessage: false,
                showErrorMessage: true,
            })
        });
    }

    render() {
        const returnURL = `${window.location.href}?success=1`;

        return (
            <div className={style.supportContainer}>
                <p className={style.successMessage} style={{ display: this.state.showSuccessMessage ? 'block' : 'none' }}>Support message sent successfully</p>
                <h2 className={style.supportTitle}>SUBMIT A SERVICE REQUEST</h2>
                <p className={style.supportRequired}>* Required Fields</p>
                <i>Please enter the following information, being as specific as possible on your request. One of our team members will respond before the end of the day.</i>
                <br />
                <form className={style.supportForm} action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
                    <input type='hidden' name="orgid" value="00pDj0000001rkAe" />
                    <input type='hidden' name="retURL" value={returnURL} />
                    <label>
                        <div className={style.supportFormLabel}><span>*</span> Name</div>
                        <input onChange={(e) => this.handleInputChange(e)} id="name" name="name" type="text" required/>
                    </label>
                    <label>
                        <div className={style.supportFormLabel}><span>*</span> Email</div>
                        <input onChange={(e) => this.handleInputChange(e)} id="mail" name="mail" type="text" required/>
                    </label>
                    <label>
                        <div className={style.supportFormLabel}><span>*</span> Phone</div>
                        <input onChange={(e) => this.handleInputChange(e)} id="phone" name="phone" type="text" required/>
                    </label>
                    <label>
                        <div className={style.supportFormLabel}>Please select your property</div>
                        <Select 
                            name="property" 
                            id="property"
                            className={style.customSelect}
                            options={this.state.systemsTableData} 
                            value={this.state.selectedProperty}
                            onChange={(e) => {
                                if (this.selectedProperty && this.selectedProperty['value']  === e.value) {
                                    return;
                                }
        
                                this.setState({
                                    selectedProperty: {'value': e.value, 'label': e.value},
                                    showAddressField: e.value === 'Other property' ? true : false
                                });
                            }}
                        />
                    </label>
                    {this.state.showAddressField &&
                        <label>
                            <div className={style.supportFormLabel}><span>*</span> What is the address of your property</div>
                            <textarea onChange={(e) => this.handleInputChange(e)} id="address" name="address" rows="5" required/>
                        </label>
                    }
                    <label>
                        <div className={style.supportFormLabel}>Select your support issue</div>
                        <Select 
                            name="issue" 
                            id="issue"
                            className={style.customSelect}
                            options={this.state.supportIssues}
                            onChange={(e) => {
                                if (this.selectedIssue && this.selectedIssue['value']  === e.value) {
                                    return;
                                }
        
                                this.setState({
                                    selectedIssue: {'value': e.value, 'label': e.value},
                                });
                            }}
                        />
                    </label>
                    <label>
                        <div className={style.supportFormLabel}>Additional information</div>
                        <textarea onChange={(e) => this.handleInputChange(e)} name="description" id='description' rows="5"></textarea>
                    </label>
                    <input className={style.supportSubmitBtn} type="submit" name="submit" />
                </form>
                <p className={style.errorMessage} style={{ display: this.state.showErrorMessage ? 'block': 'none'}}>An error has occured, please try again.</p>
                <p className={style.successMessage} style={{ display: this.state.showSuccessMessage ? 'block': 'none'}}>Support message sent successfully</p>
            </div>
        );
    }
}

window.CrowFormField['custom.vulture.support'] = SupportView;
export default SupportView;
