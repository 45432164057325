import React from 'react';
import Select from 'react-select';
import countryCodes from './countryCodes.json';
import {
	phoneOptions,
} from 'Vulture/Phone';
import * as cl from 'countries-list';

class CrowFieldCustomVultureElementsPhoneNumber extends React.Component {
	constructor(props) {
		super(props);
		let phoneJSON = {};
		if (!props.value) {
			phoneJSON = {
				cc:           '1',
				country_name: 'United States',
				area:         '',
				n:            '',
				label:		  '',
			};
		} else {
			phoneJSON = JSON.parse(props.value);
		}

		this.state = {
			value:         	 (props.value ? props.value : phoneJSON),
			dropdownValue: 	 (phoneJSON ? phoneJSON.country_name : ''),
			phoneValue:   	 (phoneJSON.area !== '' && phoneJSON.n !== '' ? phoneJSON.area + '-' + phoneJSON.n : ''),
			phoneJSON,
			dropdownOptions: phoneOptions,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange({
		target: {
			value,
		},
	}) {
		let newJSON = this.state.phoneJSON;
		newJSON.area = (value.length < 13 ? value : value.slice(0, -1)).substring(0,3);
		newJSON.n = (value.length < 13 ? value : value.slice(0, -1)).substring(4, value.length);
		newJSON.label = (this.props.attr.label)
		this.setState((prevState) => {
			return {
				phoneJSON:  newJSON,
				phoneValue: this.normalizeInput(value, prevState.phoneValue),
			};
		});
	}

	normalizeInput = (value, previousValue) => {
		if (!value) return value;
		const currentValue = value.replace(/[^\d]/g, '');
		const cvLength = currentValue.length;
		if (!previousValue || value.length > previousValue.length) {
			if (cvLength < 4) return currentValue;
			if (cvLength < 7) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
			return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
		}
	};

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	handleBlur = () => {
		const { app, entity } = this.props;
		const { phoneJSON } = this.state;
		if (!phoneJSON.n) {
			app.setValue('todo', entity.name, null);
		} else if (phoneJSON.n.length >= 7) {
			app.setValue('todo', entity.name, JSON.stringify(phoneJSON));
		}
	}

	setDropdownValue() {
		//since US and Canada share the same value, we need to compare by country name
		let newValue;
		this.state.dropdownOptions.forEach((country) => {
			if (country.name === this.state.dropdownValue) {
				newValue = {
					label: country.label,
					value: country.value,
				};
			} else if (country.dial_code === '') {
				newValue = {
					label: country.name,
					value: '',
				};
			}
		});
		return newValue;
	}

	render() {
		const {
			app,
			entity,
		} = this.props;
		let newOptions = [];
		newOptions.push({
			label: 'Select...',
			value: '',
		});
		countryCodes.forEach((country) => {
			let tz = {
				'label': `${country.name} (${country.dial_code})`,
				'value': country.dial_code,
			};
			newOptions.push(tz);
		});
		let {
			attr = {},
		} = entity || {};

		let value = this.state.value;
		if (typeof value === 'undefined') {
			value = '';
		}

		if (value === false) {
			value = 'false';
		}

		return (
			<div className={`text-small ${(this.props.classNameList || []).join(' ')}`}>
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<div className={`phoneContainer`}>
					<Select
						className="phoneSelect"
						disabled
						options={this.state.dropdownOptions} //change to newOptions when all country codes are available
						defaultValue={this.setDropdownValue()}
						isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
						onChange={(e) => {
							let newJSON = this.state.phoneJSON;
							newJSON.cc = e.value;
							newJSON.country_name = e.name;
							this.setState({
								phoneJSON: newJSON,
							});
						}}
						onBlur={this.handleBlur}
					/>
					<input
						type='text'
						className='number'
						value={this.state.phoneValue}
						placeholder="xxx-xxx-xxxx"
						onChange={this.handleChange} //this logic only works for US formatting!
						onBlur={this.handleBlur}
						{...attr.htmlattr || {}}
					/>
				</div>
			</div>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['custom.vulture.elements.phone.number'] = CrowFieldCustomVultureElementsPhoneNumber;
export default CrowFieldCustomVultureElementsPhoneNumber;
