import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';

import LayoutGroupComponent from 'Crow/Form/Field/Custom/Vulture/LayoutGroup.js';

import {
	buildTestData,
	removeWhitespace,
} from 'Vulture/TestData';

import {
	getSession,
} from 'Vulture/Session.js';

import {
	fetchContentSidebar,
	fetchListingCollectionData,
	fetchPelicanData,
} from 'Vulture/Content';

import {
	dlContentSelectPush, dlSubcontentSelectPush,
} from 'Vulture/DataLayer';

import ListingsView from './Listings';
import SlideshowView from './Slideshow';
import style from './Content.module.css';

const addLayoutGroups = (componentMap, layoutGroupComponents, parentComponentUUID) => {
	let component = componentMap[parentComponentUUID];
	let childComponentUUIDs = component.components.filter((childComponentUUID) => {
		if (componentMap[childComponentUUID].type === 'layout_group') {
			return true;
		}

		return false;
	});

	for (let childComponentUUID of childComponentUUIDs) {
		layoutGroupComponents.push(childComponentUUID);
		addLayoutGroups(componentMap, layoutGroupComponents, childComponentUUID);
	}
};

class ContentView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			sidebar:          {},
			activeMenu:       '',
			activeSystem:     '',
			activeSystemUUID: '',

			componentUUID:				null,
			componentMap:        		{},
			componentTypeMap:    		{},
			componentSettingMap: 		{},
			settingUUIDMap:         	{},
			listingConfigsToDisplay:	[],

			retryBanner:        false,
			retryBannerContent: <></>,

			pelicanData: {},

			listingCollectionFD: {},
		};
		this.slideshowComponent = React.createRef();
	}

	async componentDidMount() {
		const {
			app,
		} = this.props;

		this.fetchContentSidebar(() => {
			if ((sessionStorage.content_component_uuid || '').length === 36) {
				window.setTimeout(() => {
					let menuItem = document.getElementById(`content_${sessionStorage.content_component_uuid}`);
					if (menuItem) {
						menuItem.click();
					}
				}, 1000);
			}
		});

		let script = document.createElement('script');
		let subscript = document.createElement('script');
		script.text = 'window.STONLY_WID = "fabebc3c-71ad-11ec-82f1-064cee365e5a"';
		subscript.text = '!function(e,t,n,s,o,a,i,p){e.StonlyWidget||((i=e.StonlyWidget=function(){i._api?i._api.apply(i,arguments):i.queue.push(arguments)}).scriptPath=s,i.queue=[],(a=t.createElement(n)).async=!0,(p=new XMLHttpRequest).open(\'GET\',s+\'version?v=\'+Date.now(),!0),p.onreadystatechange=function(){4===p.readyState&&(a.src=s+\'stonly-widget.js?v=\'+(200===p.status?p.responseText:Date.now()),(o=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,o))},p.send())}(window,document,\'script\',\'https://stonly.com/js/widget/v2/\');';
		document.head.appendChild(script);
		document.head.appendChild(subscript);

		app.getStockAndDynamicPhotos();
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			app,
			accountUUID,
			slideshow,
		} = this.props;

		const {
			activeMenu
		} = this.state;

		if (prevProps.accountUUID !== accountUUID) {
			app.setState({
				sidebar:          {},
				activeMenu:       '',
				activeSystem:     '',
				activeSystemUUID: '',
				componentUUID:    null,
			}, () => {
				app.redirect({
					to: `/systems/${accountUUID}`,
				});
			});

			return;
		}

		if (JSON.stringify(prevProps.slideshow) !== JSON.stringify(slideshow)) {
			this.buildPelicanData();

			return;
		}

		if (prevState.activeMenu !== activeMenu) {
			if (activeMenu === 'Slideshow') {
				this.buildPelicanData();
			}
		}
	}

	buildPelicanData() {
		const {
			accountUUID,
			app,
			slideshow,
		} = this.props;

		if (!slideshow.slideshow_uuids) {
			return;
		}

		fetchPelicanData(accountUUID, slideshow.slideshow_uuids)
			.then((pelicanData) => {
				this.setState({
					pelicanData,
				});
			})
			.catch((err) => {
				console.error(err);
				app.setState({
					confirmModal:        true,
					confirmModalContent: <>
						<p>A network error has occured.</p>
						<div className="confirm-modal-buttons">
							<button className="button-outlined" onClick={(e) => {
								app.setState({
									confirmModal: false,
								});
								this.buildPelicanData();
							}}>Retry</button>
							<button className="button" onClick={(e) => {
								app.setState({
									confirmModal: false,
								});
							}}>Return</button>
						</div>
					</>,
				});
			});
	}

	getPelicanUUIDs(m1Key, slideshowLabel) {
		const {
			fd,
		} = this.props;

		const pelicanUUIDs = [];

		Object.keys(fd[m1Key]).forEach((m2Key) => {
			if (m2Key.startsWith('component.') && fd[m2Key]) {
				const m2Val = fd[m2Key];

				if (m2Val.description === 'pelican' && m2Val._bundle === undefined) {
					const m2UUID = m2Key.split('.')[1];
					if (!pelicanUUIDs.includes(m2UUID)) {
						pelicanUUIDs.push(m2UUID);
					}
				}

				const childrenPelicanUUIDs = this.getPelicanUUIDs(m2Key, slideshowLabel);

				childrenPelicanUUIDs.forEach((childPelicanUUID) => {
					if (!pelicanUUIDs.includes(childPelicanUUID)) {
						pelicanUUIDs.push(childPelicanUUID);
					}
				});
			}
		});

		return pelicanUUIDs;
	}

	fetchContentSidebar(cb = () => {}) {
		const {
			app,
			accountUUID,
		} = this.props;

		app.load(async () => {
			let contentSidebarRes = await fetchContentSidebar(accountUUID);
			if (app.handleOutdatedCommithashValidation(contentSidebarRes)) {
				return;
			}

			if (contentSidebarRes.error) {
				this.setRetryBanner();
			}

			let listingCollectionDataRes = await fetchListingCollectionData(accountUUID);

			if (listingCollectionDataRes.error) {
				this.setRetryBanner();
			}
			// Remove systems if there is Data
			listingCollectionDataRes.data && Object.entries(listingCollectionDataRes.data).forEach(([ m1Key, m1Val, ]) => {
				if (m1Key.startsWith('system.')) {
					delete listingCollectionDataRes.data[m1Key];
				}
			});

			this.setState({
				sidebar:             contentSidebarRes,
				listingCollectionFD: listingCollectionDataRes.data,
			}, cb);
		});
	}

	setListingConfigsToDisplay = (listingConfigsToDisplay) => {
		this.setState({
		  listingConfigsToDisplay,
		});
	  };

	setRetryBanner() {
		this.setState({
			retryBanner:        true,
			retryBannerContent: <>
				<p>A network error has occured.</p>
				<div className="confirm-modal-buttons">
					<button className="button" onClick={(e) => {
						this.setState({
							retryBanner: false,
						});
						this.fetchContentSidebar();
					}}>Retry</button>
					{/* <button className="button" onClick={(e) => {
						this.setState({ retryBanner: false });
					}}>Return</button> */}
				</div>
			</>,
		});
	}

	renderListings() {
		const {
			app,
			accountUUID,
			settingMap,
			listingConfigUUID,
			listingConfigMap,
			pendingChanges,
		} = this.props;

		const fd = {
			...this.props.fd,
			...this.state.listingCollectionFD,
		};

		const listingConfigHasScheduling = !!fd?.[`listing_config.${this.props?.listingConfigUUID}`]?.[`setting.${this.props?.settingMap?.listing_scheduling_toggle?.uuid}`]?.bool;

		const listingConfigIsIntegration = !!fd?.[`listing_config.${this.props?.listingConfigUUID}`]?.[`setting.${this.props?.settingMap?.listing_integration_provider?.uuid}`];

		const isFlatDataInGoodFormat = (typeof fd === 'object' && !Array.isArray(fd) && fd !== null);

		if (isFlatDataInGoodFormat && Object.keys(fd).length !== 0) {
			return (
				<ListingsView
					app={app}
					accountUUID={accountUUID}
					fd={fd}
					settingMap={settingMap}
					listingConfigUUID={listingConfigUUID}
					listingConfigMap={this.state.sidebar.Listings || listingConfigMap}
					listingConfigHasScheduling={listingConfigHasScheduling}
					listingConfigIsIntegration={listingConfigIsIntegration}
					setListingConfigsToDisplay={this.setListingConfigsToDisplay}
					pendingChanges={pendingChanges}
					activeMenu={this.state.activeMenu}
					activeSystem={this.state.activeSystem}
				/>
			);
		}
	}

	renderSlideshow() {
		const {
			app,
			accountUUID,
			fd,
			settingMap,
			pendingSlideshowChanges,
			slideshow,
		} = this.props;

		const {
			pelicanData,
		} = this.state;

		if (slideshow.system_slideshow_map) {
			return (
				<SlideshowView
					app={app}
					accountUUID={accountUUID}
					fd={fd}
					settingMap={settingMap}
					pelicanData={pelicanData}
					slideshow={slideshow}
					pendingSlideshowChanges={pendingSlideshowChanges}
					ref={this.slideshowComponent}
				/>
			);
		}
	}

	renderComponent(componentUUID) {
		const {
			app,
			componentMap = {},
			componentSettingMap = {},
			listingConfigUUID,
			slideshow,
		} = this.props;
		if (listingConfigUUID) {
			return this.renderListings();
		}

		if (Object.keys(slideshow).length > 0) {
			return this.renderSlideshow();
		}

		if (!componentUUID) {
			return '';
		}
		let component = componentMap[componentUUID];
		component.uuid = componentUUID;

		let layoutGroupComponents = [
			'layout_group',
			'info_box_group',
			'info_box',
		];
		if (~layoutGroupComponents.indexOf(component.type)) {
			let layoutGroupComponents = [
				componentUUID,
			];
			addLayoutGroups(componentMap, layoutGroupComponents, componentUUID);

			layoutGroupComponents.sort((a, b) => {
				let aComponent = componentMap[a];
				let bComponent = componentMap[b];

				let aOrder = aComponent.settings['layout_order'];
				let bOrder = bComponent.settings['layout_order'];

				if (aOrder > bOrder) {
					return 1;
				}

				if (aOrder < bOrder) {
					return -1;
				}

				return 0;
			});

			return layoutGroupComponents.map((layoutGroupComponentUUID) => {
				let component = componentMap[layoutGroupComponentUUID];
				component.uuid = layoutGroupComponentUUID;

				return (
					<LayoutGroupComponent
						key={layoutGroupComponentUUID}
						app={app}
						component={component}
						componentMap={componentMap}
						componentSettingMap={componentSettingMap}
					/>
				);
			});
		}
		let layoutGroupComponent = {};
		layoutGroupComponent.components = [ componentUUID, ];

		return (
			<LayoutGroupComponent
				key={`${componentUUID}_layout_group_parent`}
				app={app}
				component={layoutGroupComponent}
				componentMap={componentMap}
				componentSettingMap={componentSettingMap}
				renderFragment={true}
			/>
		);
	}

	render() {
		const {
			app,
			accountUUID,
			componentUUID,
		} = this.props;

		const {
			sidebar,
			activeMenu,
			activeSystem,
			activeSystemUUID,
			listingConfigsToDisplay,
		} = this.state;

		let hasPendingChanges = app.hasPendingChanges('content');

		if (app.hasPendingSlideshowChanges()) {
			hasPendingChanges = true;
		}

		let retryBanner = '';
		if (this.state.retryBanner === true) {
			retryBanner = (
				<div className="confirm-modal-content">
					{this.state.retryBannerContent}
				</div>
			);
		}

		return (
			<React.Fragment>
				{retryBanner}
				<ContentSidebarView
					app={app}
					accountUUID={accountUUID}
					content={this}
					sidebar={sidebar}
					activeMenu={activeMenu}
					activeSystem={activeSystem}
					activeSystemUUID={activeSystemUUID}
					listingConfigsToDisplay={listingConfigsToDisplay}
				/>

				{activeSystem &&
					<div className={`settings-page fade-in`}>
						<div className='settings-header'>
							<h2>
								{activeMenu}
							</h2>

							<div className={`sub-header-buttons`}>
								<button
									onClick={(e) => {
										if (app.hasPendingChanges('content')) {
											app.saveData();
										}
										if (app.hasPendingSlideshowChanges()) {
											app.saveSlideshowData(() => {
												this.buildPelicanData();
											});
										}
									}}
									data-testing-info={buildTestData('button--save')}
									className={`button button-save-lg ${hasPendingChanges ? '' : 'button-disabled'}`}
								>
									<CustomIcon icon='save' /><div className='bumpDown'>Save</div>
								</button>
							</div>
						</div>

						<div className='settings-content'>
							{/* TODO: <ContentComponentView
								app={app}
								content={this}
								componentUUID={componentUUID}
								componentTypeMap={componentTypeMap}
								componentMap={componentMap}
								componentSettingMap={componentSettingMap}
							/> */}

							{this.renderComponent(componentUUID)}
						</div>
					</div>
				}
			</React.Fragment>
		);
	}
}

function MenuItem(props) {
	const {
		app,
		accountUUID,
		content,
		menuLabel,
		menuItems,
		menuItem,
		activeMenu,
		activeSystem,
		activeSystemUUID,
	} = props;

	let activeClass = (activeSystemUUID === menuItem.componentUUID && activeMenu === menuLabel) ? style.active : '';

	let hasPendingChanges = app.hasPendingChanges('content');
	let hasPendingSlideshowChanges = app.hasPendingSlideshowChanges();

	const handleClick = (e) => {
		e.preventDefault();

		sessionStorage.content_account_uuid = accountUUID;
		sessionStorage.content_component_uuid = menuItem.componentUUID;

		dlSubcontentSelectPush({
			label:       menuItem.label,
			tier:        menuItem.tier + 1,
			isListings:  menuLabel === 'Listings',
			isSlideshow: menuLabel === 'Slideshow',
		});

		const flowCallBack = () => {
			content.setState({
				activeMenu:       menuLabel,
				activeSystem:     menuItem.label,
				activeSystemUUID: menuItem.componentUUID,
			});

			if (menuItem.slideshow) {
				app.fetchSlideshow(menuItem);
			} else if (menuItem.listing) {
				app.setState({
					listingConfigUUID:   menuItem.componentUUID,
					componentUUID:       false,
					componentMap:        {},
					componentTypeMap:    {},
					componentSettingMap: {},
					settingUUIDMap:      {},
				});
			} else {
				app.fetchComponent(menuItem.componentUUID);
			}
		};

		if (hasPendingChanges || hasPendingSlideshowChanges) {
			app.openPendingChangesModal('content', flowCallBack, flowCallBack);
		} else {
			flowCallBack();
		}
	};

	return (
		<>
			<li className={`${style.component} ${activeClass}`}
				id={`content_${menuItem.componentUUID}`}
				onClick={handleClick}
				data-testing-info={buildTestData(`listItem--${removeWhitespace(menuLabel)}--${removeWhitespace(menuItem.label)}`)}
			>
				{menuItem.tier === 0 ? (
					menuItem.label
				) : (
					<div className={style.componentText} style={{
						marginLeft: `${menuItem.tier}em`,
					}}>
						<CustomIcon icon="chevron-down" /> {menuItem.label}
					</div>
				)}
			</li>

			{menuItem.listing && menuItem?.childTiers?.length > 0 && menuItem.childTiers.map((childListingConfigUUID) => {
				let childMenuItem = menuItems.find((item) => {
					return item.componentUUID === childListingConfigUUID;
				});
				if (!childMenuItem) return null;

				childMenuItem.tier = menuItem.tier + 1;

				return (
					<MenuItem
						key={childMenuItem.componentUUID}
						app={app}
						accountUUID={accountUUID}
						content={content}
						menuLabel={menuLabel}
						menuItems={menuItems}
						menuItem={childMenuItem}
						activeMenu={activeMenu}
						activeSystem={activeSystem}
						activeSystemUUID={activeSystemUUID}
					/>
				);
			})}
		</>
	);
}
class ContentSidebarView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			menuItems: this.buildMenuItems(props.sidebar, props.listingConfigsToDisplay),
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.sidebar !== this.props.sidebar || prevProps.listingConfigsToDisplay !== this.props.listingConfigsToDisplay) {
			this.setState({
				menuItems: this.buildMenuItems(this.props.sidebar, this.props.listingConfigsToDisplay),
			});
		}
	}

	buildMenuItems(sidebar, listingConfigsToDisplay) {
		let menuItemsByLabel = {};

		Object.keys(sidebar).forEach((menuLabel) => {
			const components = sidebar[menuLabel];
			let menuItems = Object.keys(components).reduce((items, componentUUID) => {
				const component = components[componentUUID];

				let label = component.cg_label || component.label || '';

				let isLocked = component.is_locked;
				let pg = getSession().pg;
				if (isLocked && pg !== 'iu' && pg !== 'ia') {
					return items;
				}

				// Check against listingConfigsToDisplay
				if (component.listing && pg !== 'iu' && pg !== 'ia' && pg !== 'ea') {
					if (listingConfigsToDisplay && listingConfigsToDisplay.length > 0 && !listingConfigsToDisplay.includes(componentUUID)) {
						return items;
					}
				}

				// Construct the menuItem object
				let menuItem = {
					...component,
					label,
					componentUUID,
					key: `${menuLabel}-${componentUUID}`, // Unique key for each item
				};

				items.push(menuItem);
				return items;
			}, []);

			// Sort the menuItems if necessary
			menuItems.sort((a, b) => {
				return (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1;
			});

			if (menuItems.length > 0) {
				menuItemsByLabel[menuLabel] = menuItems;
			}
		});

		return menuItemsByLabel;
	}

	toggleTree(target) {
		if (target.classList.contains(style.hide)) {
			target.classList.remove(style.hide);
			target.classList.add(style.show);
		} else {
			target.classList.remove(style.show);
			target.classList.add(style.hide);
		}

		let sibling = target.nextSibling;
		if (sibling.classList.contains(style.hide)) {
			sibling.classList.remove(style.hide);
			sibling.classList.add(style.show);
		} else {
			sibling.classList.remove(style.show);
			sibling.classList.add(style.hide);
		}
	}

	render() {
		const {
			app,
			accountUUID,
			content,
			sidebar,
			activeMenu,
			activeSystem,
			activeSystemUUID,
		} = this.props;

		const {
			menuItems,
		} = this.state;

		if (Object.keys(sidebar).length === 0 || sidebar.error === true) {
			return '';
		}

		let menuLabels = Object.keys(sidebar);
		menuLabels.sort((a, b) => {
			return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
		});

		return (
			<div className={`${style.content} ${(this.props.classNameList || []).join(' ')}`}>
				<div className={style.topHeader}>
					<h2>DATA TYPES</h2>
					<div className={style.description}>Select one of this projects data types</div>
				</div>
				<div className={style.componentLine}></div>
				{menuLabels.length > 0 && (
					<React.Fragment>
						{menuLabels.map((menuLabel, i) => {
							const itemsForLabel = menuItems[menuLabel] || [];

							return (
								<div className={style.menu} key={`${menuLabel}_${i}`}
									data-testing-info={buildTestData(`listItem--${removeWhitespace(menuLabel)}`)}
								>
									<div className={`${style.header} ${style.show}`}
										onClick={(e) => {
											dlContentSelectPush({
												label: menuLabel,
											});
											this.toggleTree(e.currentTarget);
										}}
									>
										{menuLabel}
										<CustomIcon icon='caret-down' />
									</div>
									<ul className={`${style.list} ${style.hide}`}>
										{itemsForLabel.map((menuItem) => {
											if (menuItem.listing && !menuItem.root) {
												return null;
											}
											menuItem.tier = 0;

											return (
												<MenuItem
													key={menuItem.key}
													app={app}
													accountUUID={accountUUID}
													content={content}
													menuLabel={menuLabel}
													menuItems={itemsForLabel}
													menuItem={menuItem}
													activeMenu={activeMenu}
													activeSystem={activeSystem}
													activeSystemUUID={activeSystemUUID}
												/>
											);
										})}
									</ul>
									<div className={style.componentLine}></div>
								</div>
							);
						})}
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default ContentView;