import React, {
	useState,
} from 'react';
import style from './Screenshot.module.css';

export const SystemAssignmentConsentModal = (props) => {
	const {
		unassignedListingsLength,
		confirmOperation,
		systemsData,
		closeModal,
	} = props;

	const [ confirmPrompt, setConfirmPrompt, ] = useState('');

	return (
		<>
			<div className={`text-small ${style.modalContent} ${style.normalizePadding}`}>
				<div className={style.sectionLabel}>Confirmation</div>
				<p>
					These systems have <b>{unassignedListingsLength}</b> assigned listings.
				</p>
				<p>
					Make sure to export those listings before you reassign them system, so they can be properly reassigned.
				</p>
				<p>
					{'Type in "I confirm" to proceed:'}
				</p>
				<div>
					<input type='text' onChange={(e) => {
						setConfirmPrompt(e.target.value);
					}} value={confirmPrompt}/>
				</div>
			</div>
			<div className={style.modalButtons}>
				<button
					className={'button' + (confirmPrompt === 'I confirm' ? '' : ' button-disabled')}
					onClick={() => {
						if (confirmPrompt === 'I confirm') {
							closeModal();
							confirmOperation(systemsData);
						}
					}}
				>
					Confirm
				</button>
				<button
					className='button'
					onClick={() => {
						closeModal();
					}}
				>Cancel</button>
			</div>
		</>
	);
};

export const SystemAssignmentConfirmationModal = (props) => {
	const {
		closeModal,
		message,
	} = props;

	return (
		<>
			<div className={`text-small ${style.modalContent} ${style.normalizePadding}`}>
				<div className={style.sectionLabel}>Confirmation</div>
				<p>
					{message}
				</p>
			</div>
			<div className={style.modalButtons}>
				<button
					className='button'
					onClick={() => {
						closeModal();
					}}
				>OK</button>
			</div>
		</>
	);
};