import React from 'react';
import CustomIcon from 'Crow/Form/Field/Custom/Common/CustomIcon/CustomIcon';

import style from './PendingChanges.module.css';

function PendingChangesModalView(props) {
	const {
		app,
		name,
		noSaveCB,
		saveCB,
	} = props;

	return (
		<React.Fragment>
			<div className={style.buttonContainer}>
				<button
					className='button button-outlined'
					onClick={(e) => {
						app.closeModal();
					}}
				><CustomIcon icon='undo' />Go Back</button>

				<button
					className='button'
					onClick={(e) => {
						app.clearPendingChangesAndCloseModal(name, noSaveCB);
					}}
				><CustomIcon icon='times' />{`Don't Save`}</button>

				<button
					className='button'
					onClick={(e) => {
						app.saveDataAndCloseModal(name, saveCB);
					}}
				><CustomIcon icon='save' />Save</button>
			</div>
		</React.Fragment>
	);
}

export default PendingChangesModalView;