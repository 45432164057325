import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { getFDValue } from 'Vulture/Helpers';

class CrowFieldCustomVultureAdvancedCheckbox extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	render() {
		const {
			fd,
			entity,
			app
		} = this.props;

		let {
			attr = {},
		} = entity || {};

		let {
			value,
		} = this.state;

		return (
			<div className={`advanced-checkbox ${(this.props.classNameList || []).join(' ')}`}>
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<Checkbox
					color='primary'
					checked={value}
					onChange={() => {
						this.setState({
							value: !value,
						});
					}}
					onBlur={() => {
						if (this.state.value === getFDValue(fd, entity.name)) {
							return;
						}
						app.setValue('todo', entity.name, this.state.value);
					}}
				/>
			</div>
		);
	}

}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.elements.advanced.checkbox'] = CrowFieldCustomVultureAdvancedCheckbox;
export default CrowFieldCustomVultureAdvancedCheckbox;