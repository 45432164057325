import React, {
	useRef,
} from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import DatePicker from 'react-datepicker';
import {
	DndProvider, useDrop, useDrag,
} from 'react-dnd';
import {
	HTML5Backend,
} from 'react-dnd-html5-backend';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/theme-monokai'; // Consider dynamically importing themes if needed

import kali from 'kali';
import {
	toCapitalCase, toSnakeCase,
} from '../../../../../Crow/Common/Crow';
import {
	v4 as uuidv4,
} from 'uuid';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';
import Select from 'react-select';
import AWS from 'aws-sdk';
import {
	arrayNames,
	jsonTypes,
	exclusions,
	componentTypeExclusions,
	dropdownSettings,
	dropdownValues,
	listingGroupDropdownOptions,
	listingGroupDropdownValues,
	unusedSettingsSpecific,
	unusedSettingsGeneric,
	readOnlySettingsSpecific,
	numberRanges,
	multipleSelectionValues,
	readOnlySystemGroupSettingsSpecific,
} from './systemRules.js';
import {
	mergeFD,
} from 'Crow/Common/Crow.js';

import style from './TemplateSkeleton.module.css';
import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
	vultureENV,
} from 'Vulture/ENV';

import {
	downloadCSS,
} from 'Vulture/Template';
import {
	buildTestData,
} from 'Vulture/TestData';

const dropdownStyles = {
	control: (provided, state) => {
		return {
			...provided,
			background:  '#fff',
			borderColor: '#9e9e9e',
			minHeight:   '30px',
			height:      '30px',
			boxShadow:   state.isFocused ? null : null,
		};
	},

	valueContainer: (provided) => {
		return {
			...provided,
			height:  '30px',
			padding: '0 6px',
		};
	},

	input: (provided, _) => {
		return {
			...provided,
			margin: '0px',
		};
	},
	indicatorsContainer: (provided, _) => {
		return {
			...provided,
			height: '30px',
		};
	},
};

class TemplateSkeleton extends React.Component {
	constructor(props) {
		super(props);

		AWS.config.update({
			region:      'us-east-1',
			credentials: new AWS.CognitoIdentityCredentials({
				IdentityPoolId: 'us-east-1:dd01a076-d5c9-474e-a031-cb3bcc6f105c',
			}),
		});

		let expandedOrCollapsedEntities = JSON.parse(sessionStorage.expandedOrCollapsedEntities || '{}');

		this.state = {
			s3: new AWS.S3({
				apiVersion: '2006-03-01',
				params:     {
					Bucket: 'ts-condor-custom-css',
				},
			}),
			activeEntity:                {},
			activeEntityKey:             '',
			componentTypeToSettingMap:   {},
			flatData:                    {},
			isCustom:                    false,
			rules:                       {},
			showSettings:                true,
			bucketName:                  'ts-condor-custom-css',
			confirmModal:                false,
			confirmModalContent:         {},
			componentTypeFilter:         '',
			clipboard:                   false,
			customCSSOptions:            [],
			expandedOrCollapsedEntities,
		};
	}

	componentDidMount() {
		this.fetchComponentTypeToSettingMap();
		this.getCustomCSSFiles();

		let clipboard = JSON.parse(sessionStorage.template_skeleton_clipboard || '{}');
		if (Array.isArray(clipboard.entries) && clipboard.entries.length > 0) {
			this.setState({
				clipboard: true,
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevState.flatData) !== JSON.stringify(this.state.flatData)) {
			this.props.updateSkeletonData(this.state.flatData);
		}

		// Clear the active entity when receiving new flatData. This can happen via SystemProgramming.
		if (!this.areObjectsEqual(prevProps.existingFlatData, this.props.existingFlatData)) {
			let isCustom = this.findIsCustom(this.props.existingFlatData);
			this.getCustomCSSFiles();
			this.setState({
				activeEntity:    {},
				activeEntityKey: '',
				flatData:        this.props.existingFlatData,
				isCustom,
			});
		}

		// Pass the activeEntityKey back up to SystemProgramming
		if (prevState.activeEntityKey !== this.state.activeEntityKey && this.props.updateActiveEntityKey) {
			this.props.updateActiveEntityKey('activeEntityKey', this.state.activeEntityKey);
		}

		if (sessionStorage.expandedOrCollapsedEntities !== JSON.stringify(this.state.expandedOrCollapsedEntities)) {
			sessionStorage.expandedOrCollapsedEntities = JSON.stringify(this.state.expandedOrCollapsedEntities);
		}
	}

	getCustomCSSFiles() {
		const prefix = `${vultureENV}`;

		const {
			app,
			accountUUID,
		} = this.props;

		app.state.s3.listObjects(
			{
				Bucket:    this.state.bucketName,
				Delimiter: '/',
				Prefix:    `${prefix}/${accountUUID}/`,
			},
			(err, data) => {
				if (err) {
					console.error(err);
					return;
				}

				if (data && data.Contents) {
					if (data.Contents.length === 0) {
						this.setState({
							customCSSOptions: [],
						});

						return;
					}

					const customCSSOptions = data.Contents.map((fileData) => {
						return {
							label: fileData.Key.split('/')[2],
							value: fileData.Key.split('/')[2],
						};
					});

					this.setState({
						customCSSOptions,
					});
				}
			}
		);
	}

	// Main function to recursively compare two JSON-like objects
	areObjectsEqual(obj1, obj2) {
		// Check if both objects are empty or null, in which case they are considered equal
		if (this.isEmptyOrNull(obj1) && this.isEmptyOrNull(obj2)) {
			return true;
		}

		// Check for null in either of the objects, in which case they are considered not equal
		if (obj1 === null || obj2 === null) {
			return false;
		}

		// Check if both objects are of the same type
		if (typeof obj1 !== typeof obj2) {
			return false;
		}

		// Short-cirtuit: check if the number of properties is different
		if (Object.keys(obj1).length !== Object.keys(obj2).length) {
			return false; // Different number of properties, so not equal
		}

		// Recursively compare all properties of obj1 with obj2
		for (const key in obj1) {
			if (!(key in obj2)) {
				return false; // Property in obj1 is missing in obj2
			}
			if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
				// Both properties are objects, so we need to recursively compare them
				if (!this.areObjectsEqual(obj1[key], obj2[key])) {
					return false; // Recursive comparison found a difference
				}
			} else if (obj1[key] !== obj2[key]) {
				return false; // Direct comparison found a difference
			}
		}

		// Check for extra properties in obj2 that are not in obj1
		for (const key in obj2) {
			if (!(key in obj1)) {
				return false; // Property in obj2 is missing in obj1
			}
		}

		return true; // No differences found, objects are equal
	}

	// Helper function to check if an object is either empty or has all properties set to null
	isEmptyOrNull(obj) {
		if (obj === null) {
			return true; // The object is null
		}
		for (const key in obj) {
			if (obj[key] !== null) {
				return false; // Found a property that is not null
			}
		}
		return true; // No properties or all properties are null
	}

	stateCallback(key, val) {
		if (typeof key !== undefined && typeof val !== undefined) {
			this.setState({
				[key]: val,
			});
		}
	}

	handleDuplicateName(file) {
		let systemOrAccountName = (this.props.activeSystemName ? this.props.activeSystemName + ' for this account' : 'this account');
		this.setState({
			confirmModal:        true,
			confirmModalContent:
				<>
					<p>{file.name + ' already exists on ' + systemOrAccountName + '. Please rename the .css file to a unique name and re-upload.'}</p>
					<div className='confirmModalButtonContainer'>
						<div className='confirm-modal-buttons'>
							<button className='button' onClick={(e) => {
								this.setState({
									confirmModal: false,
								});
							}}>Okay</button>
						</div>
					</div>
				</>,
		});
	}

	uploadCSS(file, settingKey, type) {
		const {
			app,
			accountUUID,
		} = this.props;

		app.setState({
			loading: true,
		});

		let objKey = `${vultureENV}/${accountUUID}/${file.name}`;
		let params = {
			Bucket: this.state.bucketName,
			Key:    objKey,
		};
		// Using callbacks

		this.state.s3.headObject(params, function (err) {
			console.error('err', err);
			if (err && err.name === 'NotFound') {
				// Handle no object on cloud here
				this.state.s3.upload(
					{
						Body:        file,
						Bucket:      this.state.bucketName,
						Key:         objKey,
						ACL:         'public-read',
						ContentType: 'text/css',
					},
					(err, data) => {
						if (err) {
							console.error(err, data);
							alert(`There was an error uploading your file: ${err.message}`);
							app.setState({
								loading: false,
							});
							return;
						}
						app.setState({
							loading: false,
						});

						this.updateSettingValue(settingKey, objKey, type, false);
					}
				);
			} else if (err) {
				// Handle other errors here....
			} else {
				this.state.s3.getSignedUrl('getObject', params, this.handleDuplicateName(file));
				app.setState({
					loading: false,
				});
			}
		}.bind(this));
	}

	handleCustomCSSUpload(e, settingKey, type) {
		e.preventDefault();
		if (e.target && e.target.files) {
			const file = e.target.files[0];
			this.uploadCSS(file, settingKey, type);
		}
	}

	deleteCSS(settingKey, type) {
		this.updateSettingValue(settingKey, '', type, false);
	}

	fetchComponentTypeToSettingMap() {
		const url = `${broncoURL}/template_skeleton`;
		const sessionKey = getSession().session;

		new kali({
			headers: {
				'content_type':   'application/json',
				'X-Auth-Session': sessionKey,
			},
			method: 'POST',
		}).post(url, {
			success: (_kali, res, contents) => {
				if (contents) {
					let flatData = {};
					let componentTypeListForRules = [];
					// Using invalid UUIDs so /w/edit will create new UUIDs each time a TLC is generated from a skeleton.
					const uuid = `_${uuidv4()}`;
					const tlcKey = `component.${uuid}`;

					// Create TLC in flatData.
					Object.entries(contents).forEach(([ componentTypeKey, componentTypeVal, ]) => {
						if (componentTypeVal.type === 'top_level_condor') {
							const flatObj = this.setDefaultValues(componentTypeKey, componentTypeVal);
							flatData[tlcKey] = flatObj;

							return;
						}

						// List of component_types that cannot be owned by a TLC directly.
						const componentTypeIgnoreList = [
							'listing_group',
							'monarch',
							'peacock',
							'pelican',
							'broadsign_feed',
						];

						if (!~componentTypeIgnoreList.indexOf(componentTypeVal.type)) {
							componentTypeListForRules.push(componentTypeVal.type);
						}
					});

					this.initRules(componentTypeListForRules);

					// Sort component_types by type and reduce into new object
					const sortedData = Object.entries(contents)
						.sort(([ aKey, aVal, ], [ bKey, bVal, ]) => {
							if (aVal.type > bVal.type) {
								return 1;
							}
							if (aVal.type < bVal.type) {
								return -1;
							}
							return 0;
						}).reduce((obj, [ key, ]) => {
							obj[key] = contents[key];
							return obj;
						}, {});

					let isCustom = false;

					if (this.props.existingFlatData) {
						flatData = this.props.existingFlatData;
						isCustom = this.findIsCustom(flatData);
					}

					this.setState({
						// If this is a new skeleton, make the TLC active.
						...!this.props.existingFlatData && {
							activeEntity:    flatData[tlcKey],
							activeEntityKey: tlcKey,
						},
						componentTypeToSettingMap: sortedData,
						flatData,
						isCustom,
					});
				}
			},

			failure: (_kali, err) => {
				console.error(err);
			},
		});
	}

	initRules(componentTypeList) {
		const filteredComponentTypeList = componentTypeList.filter((componentType) => {
			if (componentType.startsWith('monarch_feed_')) {
				return false;
			}

			if (componentType === 'presence_detection') {
				return false;
			}

			if (componentType === 'info_box') {
				return false;
			}

			if (componentType === 'info_box_header') {
				return false;
			}

			if (componentType === 'info_box_header_block') {
				return false;
			}

			if (componentType === 'info_box_img') {
				return false;
			}

			if (componentType === 'info_box_text') {
				return false;
			}

			return true;
		});

		const rules = {
			'animated_scroller':  [ 'clock', 'date_display', 'dynamic_message', 'image_component', 'info_box_group', 'layout_group', 'news', 'qr_code', 'stocks', 'text_box', 'text_list', 'weather', ],
			'brood':              [ 'monarch', 'peacock', 'pelican', 'broadsign_feed', ],
			'monarch':            [ ],
			'info_box_group':     [ 'info_box', ],
			'info_box':           [ 'info_box_header', 'info_box_header_block', 'info_box_img', 'info_box_text', ],
			'layout_group':       filteredComponentTypeList,
			'listings_scroller':  [ 'listing_collection', ],
			'listing_collection': [ 'listing_group', ],
			'top_level_condor':   [ ...filteredComponentTypeList, 'presence_detection', ],
			'navigation':         [ 'navigation_button', ],
			'navigation_button':  [ 'PDF Component', ],
			'mobile':             [ 'mobile_url', ],
		};

		rules['monarch'] = componentTypeList.filter((componentType) => {
			return componentType.startsWith('monarch_feed_');
		});

		this.setState({
			rules,
		});
	}

	findIsCustom(flatData = {}) {
		const {
			customConfig,
		} = this.props;

		if (customConfig) {
			return true;
		}

		let isCustomUUID = '';

		window.form.getMatchingKeys(`setting.*.name`).forEach((entityKey) => {
			const {
				m1ID,
			} = window.form.parseEntityName(entityKey);
			const settingName = window.form.getValue(`setting.${m1ID}.name`);

			if (settingName === 'is_custom') {
				isCustomUUID = m1ID;
			}
		});

		for (const data of Object.values(flatData)) {
			if (data.type === 'top_level_condor' && data[`setting.${isCustomUUID}`] && data[`setting.${isCustomUUID}`].bool) {
				return data[`setting.${isCustomUUID}`].bool;
			}
		}

		return false;
	}

	setDefaultValues(componentTypeEntityKey, componentTypeData) {
		let condorRenderNameKey = '';
		let flatObj = {};
		let type = '';

		Object.entries(componentTypeData).forEach(([ componentTypeChildKey, componentTypeChildVal, ]) => {
			if (componentTypeChildKey.startsWith('setting.')) {
				let defaultVal = componentTypeChildVal.default_value;
				const {
					type: attr, name, required,
				} = componentTypeChildVal;

				// If the setting is required and there's no default value in the DB, set a zero value.
				if (required && defaultVal === null) {
					switch (attr) {
					case 'bool':
						defaultVal = false;
						break;
					case 'date':
					case 'datetime':
					case 'timestamp':
						defaultVal = '2006-01-02T15:04:05Z';
						break;
					case 'file':
					case 'string':
					case 'text':
						defaultVal = '';
						break;
					case 'float':
					case 'int':
						defaultVal = 0;
						break;
					case 'json':
						defaultVal = {};
						break;
					case 'time':
						defaultVal = '00:00:00';
						break;
					default:
						break;
					}
				}

				// Set the condor_render_name key
				if (name === 'condor_render_name') {
					condorRenderNameKey = componentTypeChildKey;
				}

				const [ m2, m2ID, ] = componentTypeChildKey.split('.');

				flatObj = {
					...flatObj,
					[`${m2}.${m2ID}`]: {
						[attr]: defaultVal,
					},
				};
			}

			if (componentTypeChildKey === 'type') {
				type = componentTypeChildVal;
			}
		});

		const friendlyType = toCapitalCase(type);

		// Set the condor_render_name default setting
		if (type !== 'top_level_condor') {
			flatObj = {
				...flatObj,
				[condorRenderNameKey]: {
					string: friendlyType.replace(/\s/g, ''),
				},
			};
		}

		flatObj = {
			...flatObj,
			[componentTypeEntityKey]: {},
			'display_name':           friendlyType,
			'description':            type,
			type,
		};

		return flatObj;
	}

	addEntity(componentTypeKey) {
		const {
			activeEntityKey,
			componentTypeToSettingMap,
			flatData,
			expandedOrCollapsedEntities,
		} = this.state;

		const {
			activeTLCUUID,
			settingMap,
			fd,
		} = this.props;

		const uuid = `_${uuidv4()}`;

		// Avoid pass by reference mutation!
		let data = JSON.parse(JSON.stringify(flatData));

		data[activeEntityKey] = {
			...data[activeEntityKey],
			[`component.${uuid}`]: {},
		};

		const flatObj = this.setDefaultValues(componentTypeKey, componentTypeToSettingMap[componentTypeKey]);
		data[`component.${uuid}`] = {
			...flatObj,
			parent: activeEntityKey,
		};

		// Create a component_to_listing_collection relation.
		if (componentTypeToSettingMap[componentTypeKey].type === 'listing_collection') {
			const listingCollectionUUID = `_${uuidv4()}`;
			data[`component.${uuid}`][`listing_collection.${listingCollectionUUID}`] = {};
			data[`listing_collection.${listingCollectionUUID}`] = {
				parent: `component.${uuid}`,
			};
		}

		// Create component_to_listing_group relation.
		if (componentTypeToSettingMap[componentTypeKey].type === 'listing_group') {
			const isTouch = !!flatData?.[activeTLCUUID]?.[`setting.${settingMap?.is_touch?.uuid}`]?.bool;
			const listingGroupUUID = `_${uuidv4()}`;
			// Add listing_group entity to flatData.
			data[`component.${uuid}`][`listing_group.${listingGroupUUID}`] = {};
			data[`listing_group.${listingGroupUUID}`] = {
				parent: `component.${uuid}`,
			};

			for (const m1Key of Object.keys(data[activeEntityKey])) {
				if (m1Key.startsWith('listing_collection.') && fd[m1Key]) {
					for (const m2Key of Object.keys(fd[m1Key])) {
						if (m2Key.startsWith('listing_config.')) {
							let isParentConfig = true;
							let currentConfig = m2Key;
							while (isParentConfig) {
								data[`listing_group.${listingGroupUUID}`][currentConfig] = {
									show_sub_nav_button: isTouch,
									mobile_display:      true,
								};
								isParentConfig = false;

								Object.keys(fd[currentConfig]).forEach((key) => {
									if (key.startsWith('listing_config.')) {
										isParentConfig = true;
										currentConfig = key;
									}
								});
							}
						}
					}
				}
			}
		}

		expandedOrCollapsedEntities[activeEntityKey] = true;

		this.setState({
			expandedOrCollapsedEntities,
			flatData: data,
		});
	}

	cloneChildren(orgEntityKey, parentKey, flatData) {
		const {
			m1,
		} = window.form.parseEntityName(orgEntityKey);

		let orgEntity = Object.assign({}, flatData[orgEntityKey]);

		// Clone the orignal entity into a new key.
		const newEntityKey = `${m1}._${uuidv4()}`;
		flatData[newEntityKey] = JSON.parse(JSON.stringify(orgEntity));

		// Update the parent key on the entity.
		flatData[newEntityKey].parent = parentKey;

		// Assign cloned entity to the parent.
		flatData[parentKey][newEntityKey] = {};

		const entityCloneList = [
			'component',
			'listing_collection',
			'listing_group',
		];

		// Remove any orignal child components from the cloned entity and replace them with cloned children.
		Object.keys(flatData[newEntityKey]).forEach((childKey) => {
			const {
				m1: childM1,
			} = window.form.parseEntityName(childKey);

			if (~entityCloneList.indexOf(childM1)) {
				delete flatData[newEntityKey][childKey];
				this.cloneChildren(childKey, newEntityKey, flatData);
			}
		});

		return flatData;
	}

	cloneEntity() {
		const {
			activeEntity, activeEntityKey, flatData,
		} = this.state;

		// Avoid pass by reference mutation!
		let data = JSON.parse(JSON.stringify(flatData));

		let updatedData = this.cloneChildren(activeEntityKey, activeEntity.parent, data);
		this.setState({
			activeEntity:    {},
			activeEntityKey: '',
			flatData:        updatedData,
		});
	}

	deleteEntityFromFlatData(entityKey, flatData) {
		if (!flatData[entityKey]) {
			return flatData;
		}

		const {
			m1ID,
		} = window.form.parseEntityName(entityKey);

		// Remove entity from parent.
		const parentEntityKey = flatData[entityKey].parent;
		if (parentEntityKey) {
			// Delete mock data from flatData.
			if (m1ID.length !== 36) {
				delete flatData[parentEntityKey][entityKey];
				if (parentEntityKey in window.form.newVals && entityKey in window.form.newVals[parentEntityKey]) {
					delete window.form.newVals[parentEntityKey][entityKey];
				}
			} else {
				// Set real data relations to false.
				if (flatData[parentEntityKey]) {
					flatData[parentEntityKey][entityKey] = false;
				}
				if (parentEntityKey in window.form.newVals) {
					window.form.newVals[parentEntityKey][entityKey] = false;
				}
			}
		}

		// Recursively delete the children.
		Object.keys(flatData[entityKey]).forEach((childKey) => {
			const {
				m1,
				m1ID,
			} = window.form.parseEntityName(childKey);

			if (m1 && m1ID) {
				// Delete mock data from flatData.
				if (m1ID.length !== 36) {
					delete flatData[entityKey][childKey];
					if (entityKey in window.form.newVals && childKey in window.form.newVals[entityKey]) {
						delete window.form.newVals[entityKey][childKey];
					}
				} else {
					// Set real data relations to false.
					flatData[entityKey][childKey] = false;
					if (entityKey in window.form.newVals) {
						window.form.newVals[entityKey][childKey] = false;
					}
				}

				// if component is a pelican one, we should not delete its children (the slides)
				const isPelican = flatData[entityKey].description === 'pelican';

				if (m1 === 'component' && !isPelican || m1.startsWith('listing_')) {
					this.deleteEntityFromFlatData(childKey, flatData);
				}
			}
		});

		if (m1ID.length !== 36) {
			delete flatData[entityKey];
		}

		return flatData;
	}

	deleteEntity() {
		const {
			activeEntity,
			activeEntityKey,
			flatData,
		} = this.state;

		// Avoid pass by reference mutation!
		let data = JSON.parse(JSON.stringify(flatData));

		if (window.confirm(`Are you sure you want to delete ${activeEntity.display_name} and all its children?`)) {
			let updatedData = this.deleteEntityFromFlatData(activeEntityKey, data);
			this.setState({
				activeEntity:    {},
				activeEntityKey: '',
				flatData:        updatedData,
			});
		}
	}

	renderComponentTypes() {
		const {
			activeEntity, componentTypeToSettingMap, componentTypeFilter, rules,
		} = this.state;

		const acceptedComponentTypeList = rules[activeEntity.type];

		return (
			<div className={style.dataContainer}>
				<div className={style.skeletonContainerHeader}>
					<div className={style.headerLabel}>ADD COMPONENT</div>
					<input
						type='text'
						placeholder='filter'
						onChange={(e) => {
							this.setState({
								componentTypeFilter: e.target.value,
							});
						}}
					/>
				</div>
				<div className={style.scrollingWrapper}>
					{Object.entries(componentTypeToSettingMap)
						.filter(([ componentTypeKey, componentTypeVal, ]) => {
							if (componentTypeFilter === '') {
								return true;
							}

							if (~toSnakeCase(componentTypeVal.type).indexOf(toSnakeCase(componentTypeFilter))) {
								return true;
							}

							return false;
						})
						.map(([ componentTypeKey, componentTypeVal, ], i) => {
							if (componentTypeVal.type && componentTypeVal.type !== 'top_level_condor') {
								if (!~acceptedComponentTypeList.indexOf(componentTypeVal.type)) {
									return '';
								}

								let typeWithoutMonarchFeedPrefix = componentTypeVal.type;

								// remove 'monarch_feed_' from the component name before displaying it
								if (typeWithoutMonarchFeedPrefix.startsWith('monarch_feed_')) {
									typeWithoutMonarchFeedPrefix = typeWithoutMonarchFeedPrefix.slice(13);
								}

								const friendlyType = toCapitalCase(typeWithoutMonarchFeedPrefix);

								return (
									<div
										className={style.componentType}
										key={i}
										onClick={(e) => {
											e.preventDefault();
											this.addEntity(componentTypeKey);
										}}
									>
										{friendlyType}
										<CustomIcon icon='plus-circle' />
									</div>
								);
							}
						}
						)}
				</div>
			</div>
		);
	}

	updateSettingValue(settingKey, settingVal, attr, updateBundle = false) {
		const {
			activeEntityKey, flatData,
		} = this.state;

		const {
			app,
			accountUUID,
			activeSystemUUID,
		} = this.props;

		// Avoid pass by reference mutation!
		let data = JSON.parse(JSON.stringify(flatData));

		if (!data?.[activeEntityKey]?.[settingKey]) {
			if (attr !== 'filter') {
				app.fetchAccountSystem(accountUUID, activeSystemUUID);
				return;
			}
		}

		if (settingKey.includes('column_group')) {
			let column_group = settingKey.split(',');
			data[column_group[0]][column_group[1]][attr] = settingVal;

			if (updateBundle) {
				data = this.updateBundle(settingVal, data);
			}
			this.setState({
				flatData: data,
			});
			return;
		}

		data[activeEntityKey][settingKey][attr] = settingVal;

		if (updateBundle) {
			data = this.updateBundle(settingVal, data);
		}
		this.setState({
			flatData: data,
		});
	}

	// If the custom_content_sidebar_label has been changed, update the _bundle.component if there is one.
	updateBundle(newVal, data) {
		const {
			activeEntityKey,
		} = this.state;

		const {
			m1, m1ID,
		} = window.form.parseEntityName(activeEntityKey);
		if (m1ID.length !== 36) {
			return data;
		}

		window.form.getMatchingKeys(`${m1}.${m1ID}._bundle`).forEach((entityKey) => {
			const bundleList = window.form.getValue(entityKey);
			if (bundleList) {
				let bundleListCopy = JSON.parse(JSON.stringify(bundleList));

				bundleListCopy.forEach((bundle) => {
					if (bundle.attr?.component) {
						bundle.attr.component = newVal;
					}
				});

				data[activeEntityKey]['_bundle'] = bundleListCopy;
			}
		});

		return data;
	}

	renderSystemGroupSettings(settingName) {
		const {
			settingMap, activeSystemGroup, settingsFlatData, activeSystemFlatData,
		} = this.props;

		if (!settingMap || !settingsFlatData || !activeSystemGroup || !activeSystemFlatData) {
			return null;

		}

		const settingUUID = settingMap[settingName].uuid;

		if (!settingsFlatData[`setting.${settingUUID}`]) {
			return null;
		}

		const type = settingMap[settingName].type;
		const setting = activeSystemGroup[`setting.${settingUUID}`];
		const {
			name, description, display_name,
		} = settingsFlatData[`setting.${settingUUID}`];

		let value = null;

		if (setting) {
			if (name === 'project_number') {
				value = `${setting[type]}-${activeSystemFlatData.directory_number}`;
			} else {
				value = setting[type];
			}
		}

		let helpIcon = null;
		if (description) {
			helpIcon = (
				<div className={style.settingHelpIcon}>
					<CustomIcon icon='question-circle' />
					<div className={style.settingHelpToolTip} style={{
						zIndex: 1,
					}}>{description}</div>
				</div>
			);
		}

		let innerElm = null;

		const _numVal = value || 0;
		const numStr = (_numVal || '').toString().replace(/^0+/, '');
		const numVal = numStr || _numVal;

		switch (type) {
		case 'file':
		case 'string':
		case 'text':
			innerElm = (
				<input
					value={value}
					disabled
				/>
			);

			break;
		case 'float':
		case 'int':
			innerElm = (
				<input
					type='number'
					value={numVal}
					disabled
				/>
			);

			break;
		default:
			console.error(`Setting type: ${type} won't display for System Group settings on setting: ${name}`);
			return '';
		}

		return (
			<div className={style.settingContainer} key={settingUUID}>
				<div className={style.settingLabel} title={name}>
					{display_name || name}
					{helpIcon}
				</div>
				{innerElm}
			</div>
		);
	}

	getIconTypeValue(optionsList, value) {
		if (optionsList.length > 0) {
			for (let item of optionsList) {
				if (item.value === value) {
					return item;
				}
			}
		}
		return 0;
	}

	renderSetting(componentType, settingKey, settingData, settingsDescription) {
		let {
			activeEntityKey, flatData, activeEntity, componentTypeToSettingMap,
		} = this.state;
		const {
			display_name, description, name,
		} = settingData;
		let {
			type,
		} = settingData;

		const key = `${activeEntityKey}.${settingKey}`;

		//this block gets the value for the icon_family dropdown so that icon_type has access to it
		let componentTypeKey = '';
		Object.keys(activeEntity).forEach((key) => {
			if (key.startsWith('component_type.')) {
				componentTypeKey = key;
			}
		});
		const componentTypeData = componentTypeToSettingMap[componentTypeKey];
		let familyValue = 0;
		for (let key in componentTypeData) {
			if (componentTypeData[key].name === 'icon_family') {
				familyValue = flatData[activeEntityKey]?.[key]?.[type] || 0;
				break;
			}
		}

		let value = flatData[activeEntityKey]?.[settingKey]?.[type];
		let helpIcon = null;
		if (description) {
			helpIcon = (
				<div className={style.settingHelpIcon}>
					<CustomIcon icon='question-circle' />
					<div className={style.settingHelpToolTip} style={{
						zIndex: 1,
					}}>{description}</div>
				</div>
			);
		}

		if (exclusions.includes(name)) {
			return null;
		}

		if (Object.keys(componentTypeExclusions).includes(componentType) && componentTypeExclusions[componentType].includes(name)) {
			return null;
		}

		// If no value was found, add the settingKey to flatData and assign the default value. This allows new settings to come through.
		if (typeof value === 'undefined') {
			// Intentionally mutate flatData to avoid infinite re-render loop.
			flatData[activeEntityKey] = {
				...flatData[activeEntityKey],
				[settingKey]: {
					[type]: null,
				},
			};
		}

		// Removed values
		if (unusedSettingsSpecific[settingsDescription] && unusedSettingsSpecific[settingsDescription].includes(name)) {
			return null;
		}
		if (unusedSettingsGeneric.includes(name)) {
			return null;
		}

		// Array values
		if (arrayNames.includes(name)) {
			let tmpVal = null;
			try {
				if (value) {
					tmpVal = JSON.parse(value);
				}
			} catch (err) {
				console.error(err);
			}

			if (!tmpVal) {
				tmpVal = [];
			}

			const stringVal = tmpVal.join(', ');
			return (
				<div className={style.settingContainer} key={key}>
					<div className={style.settingLabel} title={name}>
						{display_name || name}
						{helpIcon}
					</div>
					<input
						onChange={(e) => {
							e.preventDefault();
							let arrayVal = [];
							if (e.target.value.length > 0) {
								arrayVal = e.target.value.split(', ');
							}
							this.updateSettingValue(settingKey, JSON.stringify(arrayVal), type);
						}}
						value={stringVal}
					/>
				</div>
			);
		}

		// Multiple selection values
		if (multipleSelectionValues[settingsDescription] && multipleSelectionValues[settingsDescription][name]) {
			return (
				<div className={style.settingContainer} key={key}>
					<div className={style.settingLabel} title={name}>
						{display_name || name}
						{helpIcon}
					</div>
					<label className={style.container}>
						<Select
							isMulti={true}
							allowSelectAll={true}
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							className={style.buttonsDropdown + ' ' + style.autoWidthSelect}
							styles={dropdownStyles}
							options={multipleSelectionValues[settingsDescription][name]}
							onChange={(e) => {
								const newValue = e.map((item) => {
									return item.value;
								}).join(',');
								this.updateSettingValue(settingKey, newValue, type);
							}}
							value={multipleSelectionValues[settingsDescription][name].filter((option) => {
								return (value || '').split(',').includes(option.value);
							})}
						/>
					</label>
				</div>
			);
		}

		if (dropdownSettings[settingsDescription] && dropdownSettings[settingsDescription].includes(name) && name === 'icon_family') {
			return (
				<div className={style.settingContainer} key={key}>
					<div className={style.settingLabel} title={name}>
						{display_name || name}
						{helpIcon}
					</div>
					<label className={style.container}>
						<Select
							className={style.buttonsDropdown + ' ' + style.autoWidthSelect}
							styles={dropdownStyles}
							options={dropdownValues[settingsDescription][name]}
							onChange={(e) => {
								familyValue = e.value;
								this.updateSettingValue(settingKey, e.value, type);
							}}
							value={dropdownValues[settingsDescription][name].find((option) => {
								return option.value === value;
							})}
						/>
					</label>
				</div>
			);
		}
		if (dropdownSettings[settingsDescription] && dropdownSettings[settingsDescription].includes(name) && name === 'icon_type') {
			return (
				<div className={style.settingContainer} key={key}>
					<div className={style.settingLabel} title={name}>
						{display_name || name}
						{helpIcon}
					</div>
					<label className={style.container}>
						<Select
							className={style.buttonsDropdown + ' ' + style.autoWidthSelect}
							styles={dropdownStyles}
							options={dropdownValues[settingsDescription]['icon_family'][familyValue][name]}
							onChange={(e) => {
								this.updateSettingValue(settingKey, e.value, type);
							}}
							/* value={dropdownValues[settingsDescription]['icon_family'][familyValue][name][value]} */
							value={this.getIconTypeValue(dropdownValues[settingsDescription]['icon_family'][familyValue][name], value)}
						/* value={dropdownValues[settingsDescription] ? dropdownValues[settingsDescription]['icon_family'][familyValue][name][value] : 0} */
						/>
					</label>
				</div>
			);
		}
		// standard Dropdown values
		if (dropdownSettings[settingsDescription] && dropdownSettings[settingsDescription].includes(name)) {
			return (
				<div className={style.settingContainer} key={key}>
					<div className={style.settingLabel} title={name}>
						{display_name || name}
						{helpIcon}
					</div>
					<label className={style.container}>
						<Select
							className={style.buttonsDropdown + ' ' + style.autoWidthSelect}
							styles={dropdownStyles}
							options={dropdownValues[settingsDescription][name]}
							onChange={(e) => {
								this.updateSettingValue(settingKey, e.value, type);
							}}
							value={dropdownValues[settingsDescription][name].find((option) => {
								return option.value === value;
							})}
						/>
					</label>
				</div>
			);
		}


		// Custom toggle
		if (name === 'combined') {
			let nestedIsChecked = false;
			if (value === false) {
				nestedIsChecked = true;
			}

			let combinedIsChecked = false;
			if (value === true) {
				combinedIsChecked = true;
			}

			return (
				<div className={style.customSwitch} key={key}>
					<div>
						<input
							type='radio'
							label='Nested'
							onChange={(e) => {
								this.updateSettingValue(settingKey, false, type);
							}}
							checked={nestedIsChecked}
						/>

						<label
							onClick={(e) => {
								this.updateSettingValue(settingKey, false, type);
							}}
						>
							<span>Nested</span>
						</label>
					</div>
					<div>
						<input
							type='radio'
							label='Combined'
							onChange={(e) => {
								this.updateSettingValue(settingKey, true, type);
							}}
							checked={combinedIsChecked}
						/>

						<label
							onClick={(e) => {
								this.updateSettingValue(settingKey, true, type);
							}}
						>
							<span>Combined</span>
						</label>
					</div>
				</div>
			);
		}

		if (readOnlySettingsSpecific[settingsDescription] && readOnlySettingsSpecific[settingsDescription].includes(name) && this.props.category) {
			let inputValue = '';
			if (name === 'condor_configuration') {
				inputValue = this.props?.config?.display_name || '';
			} else if (name === 'condor_theme') {
				inputValue = this.props?.theme?.display_name || '';
			} else {
				inputValue = this.props?.category?.display_name || '';
			}
			return (
				<div className={style.settingContainer} key={key}>
					<div className={style.settingLabel} title={name}>
						{display_name || name}
						{helpIcon}
					</div>
					<input
						disabled={readOnlySettingsSpecific[settingsDescription] && readOnlySettingsSpecific[settingsDescription].includes(name)}
						value={inputValue}
					/>
				</div>

			);
		}
		let innerElm = null;

		let textMaxLenght;
		if (this.state.activeEntity.description === 'emergency_message' && display_name === 'Title') {
			textMaxLenght = 20;
		} else if (this.state.activeEntity.description === 'emergency_message' && display_name === 'Message') {
			textMaxLenght = 140;
		}


		let _numVal = value || 0;
		const extraNumProps = {};
		if (numberRanges[settingsDescription] && numberRanges[settingsDescription][name]) {
			extraNumProps.max = numberRanges[settingsDescription][name].max;
			extraNumProps.min = numberRanges[settingsDescription][name].min;
			if (_numVal > extraNumProps.max) {
				_numVal = extraNumProps.max;
			}
			if (_numVal < extraNumProps.min) {
				_numVal = extraNumProps.min;
			}
		}

		const numStr = (_numVal || '').toString().replace(/^0+/, '');

		const numVal = numStr || _numVal || '';

		switch (type) {
		case 'bool':
			innerElm = (
				<label className={style.container}>
					<input
						type='checkbox'
						onChange={(e) => {
							this.updateSettingValue(settingKey, !value, type);
						}}
						checked={value}
					/>
					<span className={style.checkmark}></span>
				</label>
			);

			break;
		case 'date':
		case 'datetime':
		case 'timestamp':
			if (value) {
				value = new Date(value);
				// Set value to null if invalid date object
				if (isNaN(Date.parse(value))) {
					value = null;
				}
			}

			innerElm = (
				<DatePicker
					selected={value}
					onChange={(date) => {
						let formattedDate = null;
						if (date) {
							formattedDate = date.toISOString();
						}
						this.updateSettingValue(settingKey, formattedDate, type);
					}}
					{...type === 'date' ? null : {
						showTimeSelect: true,
					}}
					// showTimeSelect
					timeFormat='h:mm aa'
					timeIntervals={15}
					dateFormat='MMMM d, yyyy h:mm aa'
					dropdownMode='select'
					showMonthDropdown
					showYearDropdown
					isClearable
				/>
			);

			break;
		case 'file':
		case 'string':
		case 'text':
			let inputErrorMessage = '';

			if (name === 'source' && !value.toLowerCase().startsWith('https://') && value) {
				inputErrorMessage = 'URL must start with https://';
			}

			innerElm = (
				<>
					<input
						disabled={readOnlySettingsSpecific[settingsDescription] && readOnlySettingsSpecific[settingsDescription].includes(name)}
						onChange={(e) => {
							e.preventDefault();

							let updateBundle = false;
							// Check for _bundle records to update when a custom_content_sidebar_label is changed.
							if (name === 'custom_content_sidebar_label') {
								updateBundle = true;
							}
							if (name === 'source') {
								if (e.target.value.toLowerCase().startsWith('https://') || e.target.value === '') {
									e.target.classList.remove('invalidValue');
									inputErrorMessage = '';
								} else {
									e.target.classList.add('invalidValue');
									inputErrorMessage = 'URL must start with https://';
								}
							}
							this.updateSettingValue(settingKey, e.target.value, type, updateBundle);
						}}
						value={value}
						maxLength={textMaxLenght}
					/>
					{inputErrorMessage && <div className={style.inputErrorMessage}>{inputErrorMessage}</div>}
				</>
			);

			break;
		case 'float':
		case 'int':
			innerElm = (
				<>
					<input
						type='number'
						placeholder={0}
						min='-Infinity' max='Infinity'
						value={numVal}
						step='any'
						onChange={(e) => {
							let val = Number(e.target.value);
							this.updateSettingValue(settingKey, val, type);
						}}
						onBlur={(e) => {
							let val = Number(e.target.value);
							this.updateSettingValue(settingKey, val, type);
						}}
						{...extraNumProps}
					/>
				</>
			);

			break;
		case 'json':
			innerElm = (
				<JSONField
					settingKey={settingKey}
					type={type}
					defaultValue={jsonTypes[name]}
					updateSettingValue={this.updateSettingValue.bind(this)}
					value={value}
				/>
			);

			break;
		case 'time':
			if (value) {
				// Create date object for react-datepicker
				let newDate = new Date();

				let time = value.split(':');
				let hour = time[0];
				let minutes = time[1];
				let seconds = time[2];

				newDate.setHours(hour);
				newDate.setMinutes(minutes);
				newDate.setSeconds(seconds);
				value = newDate;

				// Set value to empty if invalid date object
				if (isNaN(Date.parse(value))) {
					console.error('invalid date format');
					value = null;
				}
			}

			innerElm = (
				<DatePicker
					selected={value}
					onChange={(time) => {
						let formattedTime = null;
						if (time) {
							formattedTime = time.toLocaleTimeString('it-IT');
						}
						this.updateSettingValue(settingKey, formattedTime, type);
					}}
					showTimeSelect
					showTimeSelectOnly
					timeFormat="h:mm aa"
					timeIntervals={15}
					dateFormat="h:mm aa"
					isClearable
				/>
			);

			break;
		default:
			console.error(`BAD SETTING_TYPE: ${type} FOR SETTING: ${name}`);
			return '';
		}

		let customCSSUploader = null;
		if (name === 'custom_css') {
			const {
				customCSSOptions,
			} = this.state;

			let fileName = value;
			let downloadButton = 'button button-disabled no-click';
			let uploadButton = 'button';
			if (value) {
				let filePath = value.split('/');
				if (filePath[2]) {
					fileName = filePath[2];
				}
				downloadButton = 'button';
			}

			customCSSUploader = (
				<div className={`uploader-buttons ${style.uploader}`}>
					<Select
						options={customCSSOptions}
						className={style.uploaderDropdown}
						onChange={(e) => {
							const {
								accountUUID,
							} = this.props;
							const newVal = `${vultureENV}/${accountUUID}/${e.value}`;
							this.updateSettingValue(settingKey, newVal, type);
						}}
						value={{
							value: fileName,
							label: fileName,
						}}
					/>
					<div className={style.uploaderButtons}>
						<button
							onClick={(e) => {
								e.preventDefault();
								document.getElementById('customCSSUploadButton').click();
							}}
							className={uploadButton}
							data-testing-info={buildTestData('button--uploadCSS')}
						>
							<CustomIcon icon='cloud-upload-alt' />
						</button>
						<button
							onClick={(e) => {
								e.preventDefault();
								downloadCSS(this.state.bucketName, value);
							}}
							className={downloadButton}
						>
							<CustomIcon icon='cloud-download-alt' />
						</button>
						<button
							onClick={(e) => {
								e.preventDefault();
								this.setState({
									confirmModal:        true,
									confirmModalContent:
										<>
											<p>{'This .css will not be added to this system anymore. Do you want to continue?'}</p>
											<div className='confirmModalButtonContainer'>
												<div className='confirm-modal-buttons'>
													<button className='button' onClick={(e) => {
														this.setState({
															confirmModal: false,
														});
														this.deleteCSS(settingKey, type);
													}}>Okay</button>
												</div>
												<div className='confirm-modal-buttons'>
													<button className={`button ${style.cancelButton}`} onClick={(e) => {
														this.setState({
															confirmModal: false,
														});
													}}>Cancel</button>
												</div>
											</div>
										</>,
								});
							}}
							className={downloadButton}
						>
							<CustomIcon icon='trash' />
						</button>
					</div>
					<input
						id='customCSSUploadButton'
						onChange={(e) => {
							if (e.target.files[0].name === fileName) {
								let fileRef = e;
								this.setState({
									confirmModal:        true,
									confirmModalContent:
										<>
											<p>{`${fileName} already exists, are you sure you want to overwrite it?  It may affect other systems in this account.`}</p>
											<div className='confirmModalButtonContainer'>
												<div className='confirm-modal-buttons'>
													<button className='button' onClick={(e) => {
														this.setState({
															confirmModal: false,
														});
														this.handleCustomCSSUpload(fileRef, settingKey, type);
														fileRef.target.value = '';
													}}>Okay</button>
												</div>
												<div className='confirm-modal-buttons'>
													<button className={`button ${style.cancelButton}`} onClick={(e) => {
														this.setState({
															confirmModal: false,
														});
														fileRef.target.value = '';
														fileName = '';
													}}>Cancel</button>
												</div>
											</div>
										</>,
								});
							} else {
								this.handleCustomCSSUpload(e, settingKey, type);
								e.target.value = '';
							}
						}}
						accept='.css'
						type='file'
					/>
				</div>
			);
		}

		if (name === 'custom_inline_css') {
			innerElm = (
				<AceEditor
					mode='css'
					theme='monokai'
					onChange={(val) => {
						this.updateSettingValue(settingKey, val, type);
					}}
					name='custom_inline_css'
					value={value || ''}
					editorProps={{
						$blockScrolling: true,
					}}
					setOptions={{
						enableBasicAutocompletion: true,
						enableLiveAutocompletion:  true,
						enableSnippets:            true,
					}}
					maxLines={20}
					minLines={20}
					width='100%'
					showPrintMargin={false}
					fontSize='0.875em'
				/>
			);
		}

		return (
			<div className={style.settingContainer} key={key}>
				<div className={style.settingLabel} title={name}>
					{display_name || name}
					{helpIcon}
				</div>
				{customCSSUploader || innerElm}
			</div>
		);
	}

	handleUUIDLink(e) {
		e.preventDefault();

		let entityKeys = window.form.getMatchingKeys(`system.${this.props.activeSystemUUID}.mac_address`);

		if (entityKeys.length > 0) {
			alert('This system may have shipped, please be aware that any changes you make to CONTENT will display on the shipped system and its display');
		}

		const uuid = this.state.activeEntityKey.split('.')[1];
		const previewHost = window._getEnv('PREVIEW_URL');
		window.open(`${previewHost}/?condor_component=${uuid}`, '_blank');
	}

	getInfoValues() {
		let prettifiedOrientation = '';

		for (const [ m1Key, m1Val, ] of Object.entries(this.props.activeSystemFlatData)) {
			if (m1Key.startsWith('setting.')) {
				const orientation = m1Val.string;
				if ([ 'portraitleft', 'portraitright', 'landscape', 'landscapeflipped', ].includes(orientation)) {
					switch (orientation) {
					case 'portraitleft':
						prettifiedOrientation = 'Portrait (left)';
						break;
					case 'portraitright':
						prettifiedOrientation = 'Portrait (right)';
						break;
					case 'landscape':
						prettifiedOrientation = 'Landscape';
						break;
					case 'landscapeflipped':
						prettifiedOrientation = 'Landscape (flipped)';
						break;
					default:
						prettifiedOrientation = orientation;
						break;
					}
					break;
				}
			}
		}

		const infoValues = {
			orientation: prettifiedOrientation ? prettifiedOrientation : '',
			resolution:  this.props.activeSystemFlatData.resolution ? this.props.activeSystemFlatData.resolution : '',
		};

		return infoValues;
	}

	renderTLCUUID() {
		const {
			activeEntity, activeEntityKey,
		} = this.state;

		if (activeEntity.type === 'top_level_condor') {
			const uuid = activeEntityKey.split('.')[1];

			if (uuid && uuid.length === 36) {
				const infoValues = this.getInfoValues();

				return (
					<div>
						<div className={style.settingContainer}>
							<div className={style.settingLabel}>UUID (click the link to see a LiveView):</div>
							<a href='#' onClick={(e) => {
								this.handleUUIDLink(e);
							}} className={style.settingLabel}>{uuid}</a>
							{infoValues.orientation && (
								<div className={style.systemInfoContainer}>
									<div className={style.settingLabel}>Orientation:</div>
									<div className={style.settingValue}>{infoValues.orientation}</div>
								</div>
							)}
							{infoValues.resolution && (
								<div className={style.systemInfoContainer}>
									<div className={style.settingLabel}>Resolution:</div>
									<div className={style.settingValue}>{infoValues.resolution}</div>
								</div>
							)}
						</div>
					</div>
				);
			}
		}

		return;
	}

	renderSettings() {
		const {
			activeEntity,
			componentTypeToSettingMap,
			flatData,
		} = this.state;
		const {
			display_name, type,
		} = activeEntity || {};

		let componentTypeKey = '';
		Object.keys(activeEntity).forEach((key) => {
			if (key.startsWith('component_type.')) {
				componentTypeKey = key;
			}
		});

		const componentTypeData = componentTypeToSettingMap[componentTypeKey];
		const hiddenSettings = [ 'raven_provider_token', 'raven_provider', 'raven_theme', 'raven_ts_branding', 'raven_client_branding', 'raven_ts_branding_color', 'time_zone', ];

		// Sort settings by name and reduce into new object.
		const sortedData = Object.entries(componentTypeData)
			.sort(([ aKey, aVal, ], [ bKey, bVal, ]) => {
				if (aVal.name > bVal.name) {
					return 1;
				}
				if (aVal.name < bVal.name) {
					return -1;
				}
				return 0;
			}).reduce((obj, [ key, ]) => {
				if (key.startsWith('setting.')) {
					obj[key] = componentTypeData[key];
				}
				return obj;
			}, {});

		// Build custom field for listing_collection
		let listingCustomField = null;
		if (activeEntity.type === 'listing_collection') {
			listingCustomField = this.buildListingCollectionCustomField();
		}

		if (activeEntity.type === 'listings_scroller') {
			listingCustomField = this.buildListingsScrollerCustomField();
		}

		// Build custom field for listing_group
		if (activeEntity.type === 'listing_group') {
			let listingGroupEntityKey = '';
			for (const entityKey of Object.keys(activeEntity)) {
				if (entityKey.startsWith('listing_group.')) {
					listingGroupEntityKey = entityKey;
					break;
				}
			}

			listingCustomField = (
				<ListingGroupCustomField
					app={this.props.app}
					accountUUID={this.props.accountUUID}
					activeEntity={activeEntity}
					deleteEntityFromFlatData={this.deleteEntityFromFlatData.bind(this)}
					flatData={JSON.parse(JSON.stringify(flatData))}
					listingConfigMap={this.props.listingConfigMap}
					listingGroupEntityKey={listingGroupEntityKey}
					stateCallback={this.stateCallback.bind(this)}
					updateSettingValue={this.updateSettingValue.bind(this)}
				/>
			);
		}

		return (
			<div className={style.dataContainer}>
				<div className={style.skeletonContainerHeader}>
					<div className={style.headerLabel}>{display_name || type}</div>
				</div>
				<div className={style.scrollingWrapper}>
					{this.renderTLCUUID()}
					{listingCustomField}
					{readOnlySystemGroupSettingsSpecific[activeEntity.description] &&
						readOnlySystemGroupSettingsSpecific[activeEntity.description].map((setting) => {
							return this.renderSystemGroupSettings(setting);
						})
					}

					{Object.entries(sortedData).map(([ key, val, ]) => {
						if (hiddenSettings.includes(val.name)) {
							return null;
						}

						if (activeEntity.type === 'brood') {
							if (val.name === 'brood_enable_transition') {
								if (!activeEntity[key]) {
									activeEntity[key] = {
										[val.type]: val.default_value,
									};
								}
							}
						}

						return this.renderSetting(activeEntity.type, key, val, activeEntity.description);
					})}
				</div>
			</div>
		);
	}

	buildListingCollectionCustomField() {
		const {
			activeEntityKey,
			flatData,
		} = this.state;

		const {
			listingConfigMap = {},
		} = this.props;

		const listingConfigMapEntries = Object.entries(listingConfigMap);
		let disabled = false;
		// If the account has no listings_config components associated to it, set disabled to true.
		if (!listingConfigMapEntries.length) {
			disabled = true;
		}

		let options = [];
		let listingsConfigValue = null;
		let listingCollectionEntityKey = '';
		let hasChild = false;

		if (!disabled) {
			// Find the Tier 1 listings_config components to build options array.
			listingConfigMapEntries.forEach(([ entityKey, m2Vals, ]) => {
				const listingConfigUUID = entityKey;

				if (m2Vals.root) {
					const listingConfigDisplayName = window.form.getValue(`listing_config.${listingConfigUUID}.display_name`);
					options.push({
						label: listingConfigDisplayName,
						value: listingConfigUUID,
					});
				}
			});

			// Find the component_to_listing_collection_map.
			for (const entityKey of Object.keys(flatData[activeEntityKey])) {
				if (entityKey.startsWith('component.')) {
					hasChild = true;
				}

				if (entityKey.startsWith('listing_collection.')) {
					const {
						m1ID: listingCollectionUUID,
					} = window.form.parseEntityName(entityKey);
					listingCollectionEntityKey = `listing_collection.${listingCollectionUUID}`;

					// Find the listing_collection_to_listing_config_map
					for (const [ m2EntityKey, m2Value, ] of Object.entries(flatData[listingCollectionEntityKey])) {
						// Skip pending deletions
						if (!m2Value) {
							continue;
						}
						if (m2EntityKey.startsWith('listing_config.')) {
							const {
								m1ID: listingConfigUUID,
							} = window.form.parseEntityName(m2EntityKey);

							// Set the value for react-select
							for (const option of options) {
								if (option.value === listingConfigUUID) {
									listingsConfigValue = option;
									break;
								}
							}

							break;
						}
					}
				}
			}
		}

		options.sort((a, b) => {
			const labelA = a.label.toLowerCase();
			const labelB = b.label.toLowerCase();

			if (labelA > labelB) {
				return 1;
			}

			if (labelA < labelB) {
				return -1;
			}

			return 0;
		});

		return (
			<div className={style.settingContainer}>
				<div className={style.settingLabel}>
					Listing Config
				</div>
				<Select
					isDisabled={disabled}
					options={options}
					onChange={(e) => {
						if (hasChild) {
							this.updateListingConfigRelation(listingCollectionEntityKey, e.value, listingsConfigValue?.value);
						} else {
							this.props.app.openModal({
								modalSize:  1,
								showModal:  true,
								modalProps: {
									omitClose: true,
									title:     'Warning',
									jsx:       (
										<ListingCollectionWarning
										/>
									),
								},
							});
						}
					}}
					value={listingsConfigValue}
				/>
			</div>
		);
	}

	buildDefaultListShownOptions(listingConfigUUID, accum, tier = 1) {
		const {
			fd,
		} = this.props;

		let displayText = '';

		if (tier > 1) {
			for (let tierOffset = 1; tierOffset < tier; tierOffset++) {
				displayText = '-' + displayText;
			}
			displayText += ' ';
		}

		displayText += fd[listingConfigUUID].display_name;

		accum.push({
			label: displayText,
			value: listingConfigUUID,
		});

		for (const m2Key of Object.keys(fd[listingConfigUUID])) {
			if (m2Key.startsWith('listing_config.')) {
				accum = this.buildDefaultListShownOptions(m2Key, accum, tier + 1);
			}
		}

		return accum;
	}

	buildListingsScrollerCustomField() {
		const {
			activeEntityKey, flatData,
		} = this.state;
		const {
			listingConfigMap = {}, settingMap,
		} = this.props;

		const listingConfigMapEntries = Object.entries(listingConfigMap);
		let disabled = false;
		// If the account has no listings_config components associated to it, set disabled to true.
		if (!listingConfigMapEntries.length) {
			disabled = true;
		}

		let options = [];
		let listingsConfigValue = null;
		let scrollerDefault = window.form.getValue(`${activeEntityKey}.setting.${settingMap['default_list_shown'].uuid}.string`);

		if (flatData[activeEntityKey]?.[`setting.${settingMap['default_list_shown']?.uuid}`]) {
			scrollerDefault = flatData[activeEntityKey][`setting.${settingMap['default_list_shown'].uuid}`].string;
		}

		let listingConfigDisplayName = window.form.getValue(`${scrollerDefault}.display_name`);
		if (scrollerDefault) {
			listingsConfigValue = {
				label: listingConfigDisplayName,
				value: scrollerDefault,
			};
		}

		if (!disabled) {
			const {
				accountUUID,
				fd,
			} = this.props;


			const tier1ListingConfigs = Object.keys(fd[`account.${accountUUID}`]).filter((m1Key) => {
				return m1Key.startsWith('listing_config.');
			});

			const allTieredListingConfigs = tier1ListingConfigs.map((m1Key) => {
				const tieredListingConfigs = this.buildDefaultListShownOptions(m1Key, []);
				return tieredListingConfigs;
			})
				.sort((a, b) => {
					const labelA = a[0].label;
					const labelB = b[0].label;

					if (labelA > labelB) {
						return 1;
					}

					return -1;
				});

			const allListingConfigs = allTieredListingConfigs.reduce((accum, curr) => {
				return [ ...accum, ...curr, ];
			}, []);

			options = allListingConfigs;

		}

		return (
			<div className={style.settingContainer}>
				<div className={style.settingLabel}>
					Default List Shown
				</div>
				<Select
					isDisabled={disabled}
					options={options}
					onChange={(e) => {
						this.updateSettingValue(`setting.${settingMap['default_list_shown'].uuid}`, e.value, 'string');
					}}
					value={listingsConfigValue}
				/>
			</div>
		);
	}

	updateListingConfigRelation(listingCollectionEntityKey, newListingsConfigUUID, oldListingsConfigUUID) {
		if (newListingsConfigUUID === oldListingsConfigUUID) {
			return;
		}

		const {
			fd,
			activeSystemFlatData,
			activeTLCUUID,
			settingMap,
		} = this.props;

		const {
			activeEntity,
			activeEntityKey,
			flatData,
		} = this.state;


		const isTouch = !!flatData?.[activeTLCUUID]?.[`setting.${settingMap?.is_touch?.uuid}`]?.bool;

		// const hasMobile = Object.entries(flatData).findIndex(([ key, val, ]) => {
		// 	if (key.startsWith('component.') && val.description === 'mobile' && flatData?.[activeTLCUUID]?.[key]) {
		// 		return true;
		// 	}
		// }) !== -1;

		// Avoid pass by reference mutation!
		let data = JSON.parse(JSON.stringify(flatData));

		// Remove the former relation of listings_group to listings_config
		if (oldListingsConfigUUID) {
			let blockDelete = false;

			if (fd !== undefined) {
				const listingsFromConfig = [];
				const listingColumns = {};

				Object.keys(fd[`listing_config.${oldListingsConfigUUID}`]).forEach((listingConfigKey) => {
					if (listingConfigKey.startsWith('listing_column.')) {
						listingColumns[listingConfigKey] = true;
					}
				});

				Object.entries(fd).forEach(([ key, value, ]) => {
					if (key.startsWith('listing.')) {
						Object.keys(value).forEach((key2) => {
							if (listingColumns[key2]) {
								listingsFromConfig.push(key);
							}
						});
					}
				});

				listingsFromConfig.forEach((listing) => {
					const listingsCollectionsFromListing = [];

					Object.entries(fd).forEach(([ key, value, ]) => {
						if (key.startsWith('listing_collection.')) {
							if (value[listing]) {
								listingsCollectionsFromListing.push(key);
							}
						}
					});

					if (listingsCollectionsFromListing.includes(listingCollectionEntityKey)) {
						blockDelete = true;
					}
				});
			}

			if (blockDelete) {
				window.alert(`Cannot complete this listing_config change.\nContinuing with this change will leave existing listings orphaned and they will disappear from view.\nPlease assign any listings that are only assigned to [${activeSystemFlatData?.display_name}] to another system before continuing.`);
				return;
			}

			data[listingCollectionEntityKey][`listing_config.${oldListingsConfigUUID}`] = false;
		}

		if (!listingCollectionEntityKey) {
			listingCollectionEntityKey = 'listing_collection._' + uuidv4();
			data[listingCollectionEntityKey] = {};
			data[activeEntityKey] = data[activeEntityKey] || {};
			data[activeEntityKey][listingCollectionEntityKey] = true;
		}

		// Add the listings_config as a child to the active listings_group
		data[listingCollectionEntityKey][`listing_config.${newListingsConfigUUID}`] = {};

		// Find the listing_group entities and delete all their children.
		// Changing the listing_collection_to_listing_config_map will affect all the listing_group_to_listing_column_group_map relations.
		Object.keys(activeEntity).forEach((entityKey) => {
			// Find the component of type listing_group.
			if (flatData[entityKey] && flatData[entityKey].type === 'listing_group') {
				const listingGroupComponent = flatData[entityKey];

				// Find the listing_group entity.
				Object.keys(listingGroupComponent).forEach((childKey) => {
					if (childKey.startsWith('listing_group.')) {
						const listingGroupEntity = flatData[childKey];

						// Delete all the children.
						Object.keys(listingGroupEntity).forEach((key) => {
							if (key.startsWith('listing_config.') && key !== `listing_config.${newListingsConfigUUID}`) {
								data[childKey][key] = false;
							}
						});

						let isParentConfig = true;
						let currentConfig = `listing_config.${newListingsConfigUUID}`;
						while (isParentConfig) {
							data[childKey][currentConfig] = {
								show_sub_nav_button: isTouch,
								mobile_display:      true,
							};
							isParentConfig = false;

							Object.keys(fd[currentConfig]).forEach((key) => {
								if (key.startsWith('listing_config.')) {
									isParentConfig = true;
									currentConfig = key;
								}
							});
						}
						// Delete all the children.
						Object.keys(listingGroupEntity).forEach((key) => {
							data = this.deleteEntityFromFlatData(key, data);
						});
					}
				});
			}
		});

		this.setState({
			flatData: data,
		});
	}

	renderData() {
		const {
			activeEntityKey,
		} = this.state;

		if (!activeEntityKey) {
			return '';
		}

		if (this.state.showSettings) {
			return this.renderSettings();
		}

		return this.renderComponentTypes();
	}

	getChildEntities(entity, entityKey, parentTreeList = []) {
		const {
			app,
		} = this.props;

		const {
			flatData,
			rules,
		} = this.state;

		const {
			settingMap,
		} = app.state;

		let childEntities = [];

		Object.entries(entity).forEach(([ childKey, childVal, ]) => {
			if (~childKey.startsWith('component.')) {
				// Skip components that have a false relation.
				if (childVal === false) {
					return;
				}

				if (flatData[childKey]) {
					// Skip the child entity if it doesn't have a type.
					if (!flatData[childKey].type) {
						return;
					}

					childEntities.push({
						parentTreeList: [ ...parentTreeList, entityKey, ],
						accepted:       rules[flatData[childKey].type] || [],
						entity:         flatData[childKey],
						entityKey:      childKey,
					});
				}
			}
		});

		let tlcLayoutOrderSetting = settingMap['tlc_layout_order'];
		let tlcLayoutOrderSettingM1Key = `setting.${tlcLayoutOrderSetting.uuid}`;

		let customTLCBuilderNameSetting = settingMap['custom_tlc_builder_name'];
		let customTLCBuilderNameSettingM1Key = `setting.${customTLCBuilderNameSetting.uuid}`;

		// sort by one of:
		// - order
		// - custom label
		// - component type
		childEntities.sort((a, b) => {
			let aOrder = (a.entity[tlcLayoutOrderSettingM1Key] || {})[tlcLayoutOrderSetting.type];
			let bOrder = (b.entity[tlcLayoutOrderSettingM1Key] || {})[tlcLayoutOrderSetting.type];

			if (aOrder < bOrder) {
				return -1;
			}

			if (aOrder > bOrder) {
				return 1;
			}

			let aCustomName = (a.entity[customTLCBuilderNameSettingM1Key] || {})[customTLCBuilderNameSetting.type];
			let bCustomName = (b.entity[customTLCBuilderNameSettingM1Key] || {})[customTLCBuilderNameSetting.type];

			if (aCustomName && bCustomName) {
				if ((aCustomName < bCustomName)) {
					return -1;
				}

				if (aCustomName > bCustomName) {
					return 1;
				}
			}

			if (aCustomName && !bCustomName) {
				return -1;
			}

			if (bCustomName && !aCustomName) {
				return 1;
			}

			let aType = a.entity.type;
			let bType = b.entity.type;

			if (aType < bType) {
				return -1;
			}

			if (aType > bType) {
				return 1;
			}

			return 0;
		});

		return childEntities;
	}

	setActiveEntity(entity, entityKey) {
		this.setState({
			activeEntity:    entity,
			activeEntityKey: entityKey,
			showSettings:    true,	// Show settings when a new entity is selected
		});
	}

	updateDnDRelations(childKey, oldParentKey, newParentKey) {
		const {
			flatData,
		} = this.state;

		// Avoid pass by reference mutation!
		let data = JSON.parse(JSON.stringify(flatData));

		// Delete the child from the old parent.
		delete data[oldParentKey][childKey];
		// Add the child to the new parent.
		data[newParentKey][childKey] = {};
		// Update the parent key for the child.
		data[childKey].parent = newParentKey;

		this.setState({
			flatData: data,
		});
	}

	expandOrCollapseEntity(entityKey) {
		const {
			expandedOrCollapsedEntities,
			flatData,
		} = this.state;

		const {
			fd,
		} = this.props;

		const expandRecursive = (entityKey) => {
			expandedOrCollapsedEntities[entityKey] = true;
			for (const [ m1Key, m1Data, ] of Object.entries(flatData[entityKey])) {
				if (m1Key.startsWith('component.')) {
					expandRecursive(m1Key);
				}
			}
		};

		if (!expandedOrCollapsedEntities[entityKey]) {
			expandRecursive(entityKey);
		} else {
			expandedOrCollapsedEntities[entityKey] = false;
		}

		this.setState({
			expandedOrCollapsedEntities,
		});
	}

	renderTree() {
		const {
			flatData,
			rules,
		} = this.state;

		let accepted = [];
		let entity = {};
		let entityKey = '';

		// Find the TLC and start the tree.
		Object.entries(flatData).forEach(([ key, val, ]) => {
			if (val.type === 'top_level_condor') {
				accepted = rules[val.type];
				entity = val;
				entityKey = key;
			}
		});

		return (
			<DndProvider backend={HTML5Backend}>
				<TreeEntity
					app={this.props.app}
					accountUUID={this.props.accountUUID}
					accepted={accepted}
					stateFlatData={flatData}
					activeEntityKey={this.state.activeEntityKey}
					componentTypeToSettingMap={this.state.componentTypeToSettingMap}
					entity={entity}
					entityKey={entityKey}
					fd={this.props.fd}
					getChildEntities={this.getChildEntities.bind(this)}
					expandOrCollapseEntity={this.expandOrCollapseEntity.bind(this)}
					expandedOrCollapsedEntities={this.state.expandedOrCollapsedEntities}
					level={0}
					setActiveEntity={this.setActiveEntity.bind(this)}
					updateDnDRelations={this.updateDnDRelations.bind(this)}
					templateSkeleton={this}
				/>
			</DndProvider>
		);
	}

	renderSkeleton() {
		const {
			activeEntity,
			isCustom,
			rules,
			flatData
		} = this.state;

		const {
			fd,
			activeTLCUUID,
		} = this.props;

		let addBtnClassName = 'button button-disabled';
		let cloneBtnClassName = 'button button-disabled';
		let deleteBtnClassName = 'button button-disabled';
		let expandOrCollapseBtnClassName = 'button button-disabled';
		let addBtnDisabled = true;
		let cloneBtnDisabled = true;
		let deleteBtnDisabled = true;
		let expandOrCollapseBtnDisabled = true;

		// If the activeEntity has a rule, enable the add button.
		if (activeEntity) {
			const {
				type,
			} = activeEntity;
			if (rules[type]) {
				addBtnClassName = 'button';
				addBtnDisabled = false;
			}

			if (activeEntity.type && activeEntity.type !== 'top_level_condor') {
				cloneBtnClassName = 'button';
				deleteBtnClassName = 'button';
				cloneBtnDisabled = false;
				deleteBtnDisabled = false;
			}

			expandOrCollapseBtnDisabled = false;
			expandOrCollapseBtnClassName = 'button';
		}

		let isCustomElm = null;
		if (isCustom) {
			isCustomElm = (
				<span className={style.headerCustomLabel}>CUSTOM</span>
			);
		}

		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal =
				<div className='confirm-modal-container'>
					<div className='confirm-modal-content'>
						{this.state.confirmModalContent}
					</div>
				</div>;
		}
		return (
			<React.Fragment>
				{confirmModal}
				<div className={style.mainContainer}>
					<div className={style.skeletonContainer}>
						<div className={style.skeletonContainerHeader}>
							<div className={style.headerLabel}>COMPONENTS {isCustomElm}</div>
							<div className={style.headerBtnContainer}>
								<button
									className={addBtnClassName}
									onClick={(e) => {
										this.setState({
											showSettings: false,
										});
									}}
									disabled={addBtnDisabled}
									alt='Add'
									data-ts-id='addComponentButton'
								>
									<CustomIcon icon='plus' />
								</button>
								<button
									className={cloneBtnClassName}
									onClick={(e) => {
										e.preventDefault();
										this.cloneEntity();
									}}
									disabled={cloneBtnDisabled}
									alt='Clone'
									data-ts-id='cloneComponentButton'
								>
									<CustomIcon icon='copy' />
								</button>
								<button
									className={deleteBtnClassName}
									onClick={(e) => {
										e.preventDefault();
										this.deleteEntity();
									}}
									disabled={deleteBtnDisabled}
									alt='Delete'
									data-ts-id='deleteComponentButton'
								>
									<CustomIcon icon='trash' />
								</button>
								<button
									className={expandOrCollapseBtnClassName}
									onClick={(e) => {
										const {
											expandedOrCollapsedEntities
										} = this.state;
										e.preventDefault();

										let tlcUUID = activeTLCUUID;
										let flatDataSource = flatData ? flatData : fd;

										const collapseRecursive = (componentUUID) => {
											expandedOrCollapsedEntities[componentUUID] = true;
											for (const [ m1Key, m1Data, ] of Object.entries(flatDataSource[componentUUID])) {
												if (m1Key.startsWith('component.')) {
													collapseRecursive(m1Key);
												}
											}
										};

										if (!tlcUUID) {
											flatDataSource = this.props.existingFlatData;

											for (const [ m1Key, m1Data, ] of Object.entries(flatDataSource)) {
												if (m1Data.type === 'top_level_condor') {
													tlcUUID = m1Key;
													break;
												}
											}
										}

										collapseRecursive(tlcUUID);

										this.setState({
											expandedOrCollapsedEntities,
										});
									}}
									disabled={expandOrCollapseBtnDisabled}
									alt='Expand All'
									data-ts-id='expandAllButton'
								>
									<div className={style.expandCollapseAllIcon} >
										<CustomIcon icon='angle-down' />
									</div>
								</button>
								<button
									className={expandOrCollapseBtnClassName}
									onClick={(e) => {
										e.preventDefault();
										this.setState({
											expandedOrCollapsedEntities: {},
										});
									}}
									disabled={expandOrCollapseBtnDisabled}
									alt='Collapse All'
									data-ts-id='collapseAllButton'
								>
									<div className={style.expandCollapseAllIcon} >
										<CustomIcon icon='angle-right' />
									</div>
								</button>
							</div>
						</div>
						<div className={style.skeletonScroller}>
							{this.renderTree()}
						</div>
					</div>
					{this.renderData()}
				</div>
			</React.Fragment>
		);
	}

	render() {
		if (Object.keys(this.state.componentTypeToSettingMap).length === 0) {
			return '';
		}

		return (
			<React.Fragment>
				{this.renderSkeleton()}
			</React.Fragment>
		);
	}
}

class Clipboard extends React.Component {
	constructor(props) {
		super(props);
	}

	filterFD(unfilteredFD) {
		let filteredFD = {};
		for (let [ m1Key, m1Data, ] of Object.entries(unfilteredFD)) {
			if (m1Key === 'clone_parent_id') {
				continue;
			}

			if (m1Key.startsWith('component.')) {
				let s = JSON.stringify(m1Data);
				if (!~s.indexOf('component_type.')) {
					continue;
				}
			}

			filteredFD[m1Key] = m1Data;
		}

		return filteredFD;
	}

	_copy(fd, copiedFD, m1Key) {
		if (fd[m1Key].type === 'pelican_slide') {
			return;
		}

		copiedFD[m1Key] = this.filterFD(fd[m1Key]);
		for (let [ m2Key, m2Data, ] of Object.entries(fd[m1Key])) {
			copiedFD[m1Key][m2Key] = m2Data;

			if (~m2Key.indexOf('.')) {
				if (!fd[m2Key]) {
					continue;
				}

				this._copy(fd, copiedFD, m2Key);
			}
		}
	}

	copy(templateSkeletonM1Key, entityType) {
		const {
			accountUUID,
			templateSkeleton,
		} = this.props;

		const {
			activeEntity,
			flatData,
		} = templateSkeleton.state;

		if (entityType === 'top_level_condor') {
			let childM1Keys = Object.keys(activeEntity).filter((m1Key) => {
				return m1Key.startsWith('component.');
			});

			for (let m1Key of childM1Keys) {
				this.copy(m1Key, activeEntity[m1Key].type || '');
			}

			return;
		}

		let clipboard = JSON.parse(sessionStorage.template_skeleton_clipboard || '{}');
		if (!Array.isArray(clipboard.entries)) {
			clipboard = {
				accountUUID,
				listings: false,
				entries:  [],
			};
		}

		let copiedFD = {};
		this._copy(flatData, copiedFD, templateSkeletonM1Key);
		clipboard.entries.push({
			root: templateSkeletonM1Key,
			fd:   copiedFD,
		});

		for (let entry of clipboard.entries) {
			entry.fd[entry.root].parent = null;

			for (let [ childM1Key, m1Data, ] of Object.entries(entry.fd)) {
				let parentM1Key = m1Data.parent;
				if (parentM1Key) {
					if (!entry.fd[parentM1Key]) {
						entry.fd[parentM1Key] = {};
					}
					entry.fd[parentM1Key][childM1Key] = {};
				}
			}

			if (~(JSON.stringify(entry.fd).indexOf('listing_collection.'))) {
				clipboard.listings = true;
			}
		}

		this.clearSlideEntries(clipboard);

		sessionStorage.template_skeleton_clipboard = JSON.stringify(clipboard);

		templateSkeleton.setState({
			clipboard: true,
		});
	}

	clearSlideEntries(clipboard) {
		clipboard.entries.forEach((entry) => {
			const fd = {
				...entry.fd,
			};

			Object.entries(entry.fd).forEach(([ m1Key, m1Val, ]) => {
				if (m1Val.description && (m1Val.description === 'brood' ||
					m1Val.description.startsWith('pelican') ||
					m1Val.description.startsWith('monarch') ||
					m1Val.description.startsWith('peacock') ||
					m1Val.description === 'broadsign_feed')) {
					Reflect.deleteProperty(entry.fd, m1Key);
				}
			});

			Object.keys(entry.fd[entry.root]).forEach((m1Key) => {
				if (m1Key.startsWith('component.')) {
					if (fd[m1Key]?.description === 'brood') {
						Reflect.deleteProperty(entry.fd[entry.root], m1Key);
					}
				}
			});
		});
	}

	removeListings(entry) {
		for (let [ m1Key, m1Data, ] of Object.entries(entry.fd)) {
			for (let m2Key of Object.keys(m1Data)) {
				if (m2Key.startsWith('listing_column_group.')) {
					Reflect.deleteProperty(entry.fd[m1Key], m2Key);
				}

				if (m2Key.startsWith('listing_config.')) {
					Reflect.deleteProperty(entry.fd[m1Key], m2Key);
				}

				if (m2Key.startsWith('listing.')) {
					Reflect.deleteProperty(entry.fd[m1Key], m2Key);
				}
			}

			if (m1Key.startsWith('listing_column_group.')) {
				Reflect.deleteProperty(entry.fd, m1Key);
			}

			if (m1Key.startsWith('listing_config.')) {
				Reflect.deleteProperty(entry.fd, m1Key);
			}
		}
	}

	paste(removeListings = true) {
		const {
			accountUUID,
			templateSkeletonM1Key,
			templateSkeleton,
		} = this.props;

		const {
			flatData,
		} = templateSkeleton.state;

		let clipboard = JSON.parse(sessionStorage.template_skeleton_clipboard || '{}');
		if (!Array.isArray(clipboard.entries)) {
			return;
		}

		let accountChanged = false;
		if (clipboard.accountUUID !== accountUUID) {
			accountChanged = true;
		}

		if (accountChanged || removeListings) {
			for (let entry of clipboard.entries) {
				this.removeListings(entry);
			}
		}

		for (let entry of clipboard.entries) {
			let uuids = {};
			for (let m1Key of Object.keys(entry.fd)) {
				if (m1Key.startsWith('component.')) {
					uuids[m1Key.split('.')[1]] = `_${uuidv4()}`;
				}

				if (m1Key.startsWith('listing_collection.')) {
					uuids[m1Key.split('.')[1]] = `_${uuidv4()}`;
				}

				if (m1Key.startsWith('listing_group.')) {
					uuids[m1Key.split('.')[1]] = `_${uuidv4()}`;
				}

				if (m1Key.startsWith('listing_column_group.')) {
					uuids[m1Key.split('.')[1]] = `_${uuidv4()}`;
				}
			}

			let s = JSON.stringify(entry.fd);
			for (let [ oldUUID, newUUID, ] of Object.entries(uuids)) {
				s = s.replace(new RegExp(oldUUID, 'g'), newUUID);
			}
			entry.fd = JSON.parse(s);

			entry.root = `component.${uuids[entry.root.split('.')[1]]}`;
		}

		let pasteFD = JSON.parse(JSON.stringify(flatData));
		for (let entry of clipboard.entries) {
			pasteFD[templateSkeletonM1Key][entry.root] = {};
			pasteFD = mergeFD(pasteFD, entry.fd);
			pasteFD[entry.root].parent = templateSkeletonM1Key;
		}

		templateSkeleton.setState({
			flatData: pasteFD,
		}, () => {
			// this.clear();
		});
	}

	clear() {
		const {
			templateSkeleton,
		} = this.props;

		sessionStorage.template_skeleton_clipboard = '{}';

		templateSkeleton.setState({
			clipboard: false,
		});
	}

	copied(m1Key) {
		let clipboard = JSON.parse(sessionStorage.template_skeleton_clipboard || '{}');

		return Boolean(~clipboard.entries.map((entry) => {
			return entry.root;
		}).indexOf(m1Key));
	}

	render() {
		const {
			app,
			templateSkeletonM1Key,
			entity,
			templateSkeleton,
		} = this.props;

		const {
			clipboard: clipboardHasEntries,
		} = templateSkeleton.state;

		let clipboard = JSON.parse(sessionStorage.template_skeleton_clipboard || '{}');

		let copyEnabled = false;
		if (entity.type === 'top_level_condor' || entity.type === 'layout_group') {
			copyEnabled = true;
		}

		let copyEnabledComponents = [
			// 'animated_scroller',
			'emergency_message',
			// 'info_box_group',
			// 'info_box',
			// 'listing_collection',
			// 'listing_scroller',
			'mobile',
			// 'navigation_button',
			'navigation',
		];

		if (~copyEnabledComponents.indexOf(entity.type)) {
			copyEnabled = true;
		}

		let pasteEnabled = false;
		if (clipboardHasEntries && (entity.type === 'top_level_condor' || entity.type === 'layout_group')) {
			pasteEnabled = true;
		}

		if (!copyEnabled && !pasteEnabled) {
			return '';
		}

		let hasListings = false;
		if (clipboardHasEntries) {
			if (this.copied(templateSkeletonM1Key)) {
				return (
					<strong>copied</strong>
				);
			}

			if (clipboard.listings) {
				hasListings = true;
			}
		}

		let copyButtonLabel = 'copy';
		if (clipboardHasEntries) {
			copyButtonLabel = `copy(${Object.keys(clipboard.entries).length})`;
		}
		if (entity.type === 'top_level_condor') {
			copyButtonLabel = 'copy children';
		}

		let pasteButton1Label = 'paste';
		let pasteButton2Label = 'paste';
		if (clipboardHasEntries) {
			pasteButton1Label = `paste(${Object.keys(clipboard.entries).length})`;
			pasteButton2Label = `paste(${Object.keys(clipboard.entries).length})`;
		}
		if (hasListings) {
			pasteButton1Label = `${pasteButton1Label} -L`;
			pasteButton2Label = `${pasteButton2Label} +L`;
		}

		let setting = app.state.settingMap['custom_tlc_builder_name'];
		let pasteHoverText = (clipboard.entries || []).map((entry) => {
			try {
				return entry.fd[entry.root][`setting.${setting.uuid}`][setting.type];
			} catch (err) {
				return entry.fd[entry.root].display_name;
			}
		}).join('\n');

		return (
			<div
				style={{
					marginTop: '7px',
					display:   'flex',
				}}
			>
				{pasteEnabled &&
					<>
						<button
							className={`button`}
							onClick={this.clear.bind(this)}
						>
							X
						</button>
						&nbsp;
					</>
				}

				{copyEnabled &&
					<button
						className={`button`}
						onClick={this.copy.bind(this, templateSkeletonM1Key, entity.type)}
					>
						{copyButtonLabel}
					</button>
				}

				{pasteEnabled &&
					<>
						&nbsp;
						<button
							className={`button`}
							onClick={this.paste.bind(this, true)}
							title={pasteHoverText}
						>
							{pasteButton1Label}
						</button>

						{hasListings &&
							<>
								&nbsp;
								<button
									className={`button`}
									onClick={this.paste.bind(this, false)}
									title={pasteHoverText}
								>
									{pasteButton2Label}
								</button>
							</>
						}
					</>
				}
			</div>
		);
	}
}

function TreeEntity(props) {
	let {
		app,
		accountUUID,
		accepted,
		activeEntityKey,
		entity,
		entityKey,
		fd,
		getChildEntities,
		level,
		parentTreeList = [],
		setActiveEntity,
		templateSkeleton,
		expandOrCollapseEntity,
		expandedOrCollapsedEntities,
		stateFlatData,
	} = props;

	const ref = useRef(null);

	const [{
		isDragging,
	}, drag, preview, ] = useDrag({
		item: {
			entity,
			entityKey,
		},

		type: entity.type,

		collect: (monitor) => {
			return {
				isDragging: !!monitor.isDragging(),
			};
		},
	});

	const [{
		isOver, isOverCurrent, canDrop,
	}, drop, ] = useDrop({
		accept: accepted,

		collect: (monitor) => {
			return {
				isOver:        !!monitor.isOver(),
				isOverCurrent: !!monitor.isOver({
					shallow: true,
				}),
				canDrop: monitor.canDrop(),
			};
		},

		drop: (item) => {

			// Don't drop an entity into itself
			if (item.entityKey === entityKey) {
				return;
			}

			// Don't drop an entity lower into its own tree.
			if (~parentTreeList.indexOf(item.entityKey)) {
				return;
			}

			let oldParentKey = item.entity.parent;
			let newParentKey = entityKey;
			props.updateDnDRelations(item.entityKey, oldParentKey, newParentKey);
		},
	});

	// TODO: A better way?
	let componentTypesToIgnore = [
		'listing',
		'shared_ui_table_config',
	];

	// Don't render certain component_types.
	if (~componentTypesToIgnore.indexOf(entity.type)) {
		return '';
	}

	preview(drop(ref));

	let selected = '';
	let canDropComponent = '';

	// If activeEntityKey matches the entityKey, it's been clicked by the user. Make it lightblue.
	if (activeEntityKey === entityKey) {
		selected = style.selected;
	}

	// If an entity is currently hovering and can be dropped, change the background color to lightgreen.
	if (isOverCurrent && isOver && canDrop) {
		canDropComponent = style.canDropComponent;
	}

	// Set the opacity to 0 if we're dragging an entity.
	const opacity = isDragging ? 0 : 1;

	let dragHandle = (
		<div className={style.dragHandle} ref={drag}>
			<CustomIcon icon='bars' />
		</div>
	);

	const blockedEntityTypesForDrag = [
		'top_level_condor',
		'listing_group',
	];

	// Don't allow certain entities to drag.
	if (~blockedEntityTypesForDrag.indexOf(entity.type)) {
		dragHandle = null;
	}

	const settingNameToInfo = props.app.state.settingMap || {};

	let displayName = toCapitalCase(entity.type);
	const customTLCBuilderNameUUID = settingNameToInfo['custom_tlc_builder_name']?.uuid;

	if (entity[`setting.${customTLCBuilderNameUUID}`]?.string) {
		displayName = entity[`setting.${customTLCBuilderNameUUID}`].string;
	}

	if (displayName.startsWith('Monarch Feed ')) {
		// if it starts with 'Monarch Feed ', remove the first thirteen letters
		displayName = displayName.slice(13);
	}

	let hasChildComponents = false;

	if (stateFlatData[entityKey]) {
		for (const [ m1Key, m1Data, ] of Object.entries(stateFlatData[entityKey])) {
			if (m1Key.startsWith('component.') && stateFlatData[entityKey].type !== 'pelican') {
				hasChildComponents = true;
				break;
			}
		}
	}

	let isTopLevelComponent = stateFlatData[entityKey]?.description === 'top_level_condor';
	let showCaret = hasChildComponents && !isTopLevelComponent;

	let canDropIcon = <div></div>;
	if (accepted && Array.isArray(accepted) && accepted.length > 0) {
		canDropIcon = (
			<div className={style.iconsContainer} >
				{showCaret > 0 &&
				<div
					className={`${style.angleContainer} ${!expandedOrCollapsedEntities[entityKey] ? style.rotatedIcon : ''}`}
					onClick={(e) => {
						e.stopPropagation();
						if (level === 0) {
							return;
						}
						expandOrCollapseEntity(entityKey);
					}}
					data-ts-id={`expandCollapseButton-${displayName.replace(/\s+/g, '')}`}
				>
					<CustomIcon icon='angle-down' />	
				</div>}
			
				<CustomIcon icon='object-group' color='#EFAF00' />
			</div>
		);
	}

	return (
		<React.Fragment>
			<div
				className={`${style.treeEntity} ${selected} ${canDropComponent}`}
				onClick={(e) => {
					e.preventDefault();
					setActiveEntity(entity, entityKey);
				}}
				ref={ref}
				style={{
					opacity,
				}}
			>
				<div
					className={style.treeEntityInner}
					style={{
						marginLeft: `${(level > 0 ? level - 1 : level) * 1.2 + (!showCaret && 1.8) - (level < 2 && !showCaret ? 0.3 : 0)}em`,
					}}
				>
					{canDropIcon}
					<div>
						{displayName}
						<br />
						{selected &&
							<Clipboard
								app={app}
								accountUUID={accountUUID}
								entity={entity}
								templateSkeletonM1Key={activeEntityKey}
								templateSkeleton={templateSkeleton}
							/>
						}
					</div>
				</div>
				{dragHandle}
			</div>

			{/* Recursively render the tree! */}
			{getChildEntities(entity, entityKey, parentTreeList)
				.filter((childEntity) => {
					if (childEntity.entity.type === 'pelican_slide') {
						return false;
					}

					if (accepted && level !== 0 && !expandedOrCollapsedEntities[entityKey]) {
						return false;
					}

					return true;
				})
				.map((childEntity) => {
					return (
						<TreeEntity
							{...props}
							app={props.app}
							accountUUID={props.accountUUID}
							accepted={childEntity.accepted}
							entity={childEntity.entity}
							entityKey={childEntity.entityKey}
							key={childEntity.entityKey}
							level={level + 1}
							parentTreeList={childEntity.parentTreeList}
							stateFlatData={stateFlatData}
						/>
					);
				})}
		</React.Fragment>
	);
}

class JSONField extends React.Component {
	componentDidMount() {
		const {
			settingKey,
			type,
			defaultValue,
			updateSettingValue,
			value,
		} = this.props;

		if (!value && defaultValue) {
			updateSettingValue(settingKey, defaultValue, type);
		}
		let changed = false;
		const options = {
			mode:  'code',
			modes: [
				'code',
				'view',
				'form',
			],
			onValidate: () => {
				if (!changed) {
					return;
				}

				let json = JSON.stringify(this.jsoneditor.get());
				if (defaultValue === '[]') {
					(/^\[.*\]$/).test(json) && updateSettingValue(settingKey, json, type);
					return;
				}
				updateSettingValue(settingKey, json, type);
			},

			onChange: () => {
				changed = true;
				// Set value to null if a user highlights and deletes everything.
				if (this.jsoneditor) {
					let text = this.jsoneditor.getText();
					if (text === '[]') {
						updateSettingValue(settingKey, defaultValue || null, type);
					}
				}
			},
		};

		let parsedValue = null;
		try {
			parsedValue = JSON.parse(value);
		} catch (err) {

		}

		this.jsoneditor = new JSONEditor(this.container, options);

		// Display a blank value in the editor.
		if (parsedValue === null) {
			this.jsoneditor.setText('');
			return;
		}

		this.jsoneditor.set(parsedValue);
	}

	componentWillUnmount() {
		if (this.jsoneditor) {
			this.jsoneditor.destroy();
		}
	}

	render() {
		return (
			<div
				className='jsoneditor-react-container'
				ref={(elm) => {
					this.container = elm;
				}}
			/>
		);
	}
}

class ListingGroupCustomField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			listingColumnOptionsMap:              {},
			listingConfigList:                    [],
			listingConfigToListingColumnGroupMap: {},
		};
	}

	componentDidMount() {
		this.buildListingConfigList();
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.activeEntity) !== JSON.stringify(this.props.activeEntity)) {
			this.buildListingConfigList();
		}
	}

	buildListingConfigToListingColumnGroupMap(listingConfigList) {
		const {
			flatData, listingGroupEntityKey,
		} = this.props;
		let listingConfigToListingColumnGroupMap = {};

		// Create keys for each avaiable listing_config
		listingConfigList.forEach((listingConfig) => {
			listingConfigToListingColumnGroupMap[`listing_config.${listingConfig.uuid}`] = [];
		});

		// Find the listing_column_group entities and sort by tier
		Object.entries(flatData[listingGroupEntityKey] || []).forEach(([ listingColumnGroupKey, listingColumnGroupVal, ]) => {
			if (listingColumnGroupKey.startsWith('listing_column_group.') && listingColumnGroupVal) {
				for (const childKey of Object.keys(flatData[listingColumnGroupKey])) {
					if (childKey.startsWith('listing_config.') && listingConfigToListingColumnGroupMap[childKey]) {
						listingConfigToListingColumnGroupMap[childKey] = [
							...listingConfigToListingColumnGroupMap[childKey],
							{
								entityKey: listingColumnGroupKey,
								order:     listingColumnGroupVal.order,
							},
						];
					}
				}
			}
		});

		// Sort column_groups by order and then filter down to only the entityKey.
		Object.values(listingConfigToListingColumnGroupMap).forEach((listingColumnGroupList) => {
			listingColumnGroupList.sort((a, b) => {
				if (a.order > b.order) {
					return 1;
				}
				if (a.order < b.order) {
					return -1;
				}
				return 0;
			}).filter((obj, i, arr) => {
				arr[i] = obj.entityKey;
				return true;
			});
		});

		this.setState({
			listingConfigToListingColumnGroupMap,
		});
	}

	buildListingConfigList() {
		const {
			activeEntity, flatData,
		} = this.props;

		let listingColumnOptionsMap = {};
		let listingConfigList = [];

		// Find the parent component that owns the listing_collection
		const listingCollectionComponent = flatData[activeEntity.parent] || {};

		// Find the listing_collection entity
		for (const entityKey of Object.keys(listingCollectionComponent)) {
			if (entityKey.startsWith('listing_collection')) {
				// Find the listing_config owned by the listing_collection
				for (const childKey of Object.keys(flatData[entityKey])) {
					if (childKey.startsWith('listing_config.')) {
						// If the listing_collection_to_listing_collection isn't pending deletion, create the listingConfigList list.
						if (flatData[entityKey][childKey]) {
							// Build the listingColumnOptionsMap and listingConfigList
							const obj = this.loopListingConfigTree(childKey);
							listingColumnOptionsMap = obj.listingColumnOptionsMap;
							listingConfigList = obj.listingConfigList;
						}
					}
				}
			}
		}

		this.buildListingConfigToListingColumnGroupMap(listingConfigList);

		this.setState({
			listingColumnOptionsMap,
			listingConfigList,
		});
	}

	loopListingConfigTree(listingConfigKey, listingConfigList = [], listingColumnOptionsMap = {}, tier = 0) {
		const {
			listingConfigMap = {},
		} = this.props;
		tier++;
		const {
			m1ID: listingConfigUUID,
		} = window.form.parseEntityName(listingConfigKey);

		if (listingConfigMap[listingConfigUUID]) {
			listingConfigList.push({
				tier,
				display_name: listingConfigMap[listingConfigUUID].display_name,
				uuid:         listingConfigUUID,
				offset:       0,
			});

			let displayName = listingConfigMap[listingConfigUUID].display_name;
			// Loop over children to see if there are child tiers and recursively build listingConfigList if so.
			Object.entries(listingConfigMap[listingConfigUUID]).forEach(([ entityKey, entityVal, ]) => {
				const {
					m1: childM1, m1ID: childM1UUID,
				} = window.form.parseEntityName(entityKey);
				if (childM1 === 'listing_column') {
					listingColumnOptionsMap[listingConfigKey] = [
						...listingColumnOptionsMap[listingConfigKey] || [],
						{
							label: entityVal.display_name,
							value: {
								tier,
								uuid: childM1UUID,
							},
							displayName,
						},
					];
				}
				if (entityKey === 'childTiers' && entityVal.length) {
					const nestedChild = `listing_config.${entityVal[0]}`;
					this.loopListingConfigTree(nestedChild, listingConfigList, listingColumnOptionsMap, tier);
				}
			});
		}

		return {
			listingColumnOptionsMap,
			listingConfigList,
		};
	}

	toggleMobileDisplay(listing) {
		listing.mobile_display = !listing.mobile_display;
	}

	toggleMobileWayfinding(listing) {
		listing.mobile_wayfinding = !listing.mobile_wayfinding;
	}

	toggleMobileDialing(listing) {
		listing.mobile_dialing = !listing.mobile_dialing;
	}

	toggleShowSubNav(listing) {
		listing.show_sub_nav_button = !listing.show_sub_nav_button;
	}

	buildNestedColumnOptions(listingColumnOptionsMap, currentConfigKey) {
		const {
			listingConfigMap,
		} = this.props;
		let optionShowList = [];

		let isRoot = false;
		let parentUUID = currentConfigKey;
		while (!isRoot) {
			if (!listingConfigMap[parentUUID]) {
				break;
			}

			if (listingConfigMap[parentUUID]['root']) {
				optionShowList.push(parentUUID);
				isRoot = true;
				break;
			}

			optionShowList.push(parentUUID);
			parentUUID = listingConfigMap[parentUUID]['parentUUID'];
		}

		let optionsList = [];
		Object.entries(listingColumnOptionsMap).forEach(([ listingConfigKey, listingConfigVal, ]) => {
			let listingConfigUUID = listingConfigKey.replace('listing_config.', '');
			if (optionShowList.includes(listingConfigUUID)) {
				let labelOptions = {};
				labelOptions['label'] = listingConfigVal[0]['displayName'];
				labelOptions['options'] = [];

				listingConfigVal.forEach((option) => {
					let tierOption = {
						label: option.label.split('\n')[0],
						value: option.value,
					};
					labelOptions['options'].push(tierOption);
				});

				optionsList.push(labelOptions);
			}

		});
		return optionsList;
	}

	addListingColumnGroup(listingConfigUUID) {
		if (!listingConfigUUID) {
			return;
		}

		const {
			listingConfigToListingColumnGroupMap,
		} = this.state;

		const {
			flatData,
			listingGroupEntityKey,
			stateCallback,
		} = this.props;
		const newListingColumnGroupUUID = `_${uuidv4()}`;

		if (!flatData[listingGroupEntityKey][`listing_config.${listingConfigUUID}`]) {
			flatData[listingGroupEntityKey][`listing_config.${listingConfigUUID}`] = {
				show_sub_nav_button: false,
				mobile_display:      true,
			};
		}

		const order = listingConfigToListingColumnGroupMap[`listing_config.${listingConfigUUID}`].length + 1;

		// Add listing_group_to_listing_column_group relation.
		flatData[listingGroupEntityKey][`listing_column_group.${newListingColumnGroupUUID}`] = {
			order,
		};

		// Add the listing_column_group to the flatData.
		flatData[`listing_column_group.${newListingColumnGroupUUID}`] = {
			parent: listingGroupEntityKey,

			// Map the listing_column_group to the listing_config that was selected.
			[`listing_config.${listingConfigUUID}`]: {},
		};

		// Add temp listing_column_group_to_listing_column relation.
		// listing_column.uuid needs to line up with m2UUID of listing_config.*.listing_column.*
		// Not adding UUID so it is ignored in /w/edit if the user never selects a listing_column for the listing_group.
		// NOTE: If a user saves a column_group without selecting a column in Step 5, the column_group needs to be deleted with this approach.
		flatData[`listing_column_group.${newListingColumnGroupUUID}`][`listing_column.`] = {};

		stateCallback('flatData', flatData);
		this.buildListingConfigToListingColumnGroupMap(this.state.listingConfigList);
	}

	renderTierSection(listingConfigKey, listingColumnGroupList, shouldDisable = false, index) {
		const {
			deleteEntityFromFlatData,
			flatData,
			listingGroupEntityKey,
			stateCallback,
			updateSettingValue,
			listingConfigMap = {},
		} = this.props;

		const {
			listingColumnOptionsMap, listingConfigList,
		} = this.state;
		// Disable tier select and buttons if there are no listing_configs to choose from.
		let isDisabled = false;
		if (!listingConfigList.length) {
			isDisabled = true;
		}

		// Any column group from a specific config can only appear on a single listing group
		Object.entries(flatData).forEach(([ m1Key, m1Val, ]) => {
			if (m1Key.startsWith('listing_group.') && m1Key !== listingGroupEntityKey) {
				Object.keys(m1Val).forEach((m2Key) => {
					if (m2Key.startsWith('listing_column_group.')) {
						if (flatData?.[m2Key]?.[listingConfigKey]) {
							// isDisabled = true;
							console.warn('This is a potentially risky column addition. Any column group from a specific config should only appear on a single listing group.');
						}
					}
				});
			}
		});

		// Any listing group that belongs to a listing collection with more than a single listing group should only have one tier
		const listingGroupComponent = flatData[listingGroupEntityKey]?.parent;
		const listingCollectionComponent = flatData[listingGroupComponent]?.parent;

		let listingGroupCount = 0;
		let otherTierHasColumns = false;
		if (listingColumnGroupList.length === 0) {
			Object.keys(flatData[listingCollectionComponent]).forEach((m1Key) => {
				if (m1Key.startsWith('component.')) {
					Object.entries(flatData[m1Key]).forEach(([ m2Key, m2Val, ]) => {
						if (m2Key === 'description' && m2Val === 'listing_group') {
							listingGroupCount++;

							if (!flatData[m1Key]?.[listingGroupEntityKey]) {
								Object.keys(flatData[listingGroupEntityKey]).forEach((m3Key) => {
									if (m3Key.startsWith('listing_column_group.')) {
										otherTierHasColumns = true;
									}
								});
							}
						}
					});
				}
			});

			if (listingGroupCount > 1 && otherTierHasColumns) {
				// isDisabled = true;
				console.warn('This is a potentially risky column addition. Any listing group that belongs to a listing collection with more than a single listing group should only have one tier.');
			}
		}

		let hasRelation = flatData[listingGroupEntityKey][listingConfigKey];

		const {
			m1ID: listingConfigUUID,
		} = window.form.parseEntityName(listingConfigKey);
		const customStyles = {
			control: (provided, state) => {
				return {
					...provided,
					background:  '#fff',
					borderColor: '#9e9e9e',
					minHeight:   '30px',
					height:      '30px',
					boxShadow:   state.isFocused ? null : null,
				};
			},

			valueContainer: (provided) => {
				return {
					...provided,
					height:  '30px',
					padding: '0 6px',
				};
			},

			input: (provided) => {
				return {
					...provided,
					margin: '0px',
				};
			},
			indicatorsContainer: (provided) => {
				return {
					...provided,
					height: '30px',
				};
			},
		};
		let addColumnBtn = (
			<button
				className='button'
				onClick={(e) => {
					e.preventDefault();
					this.addListingColumnGroup(listingConfigUUID);
				}}
			>
				<CustomIcon icon='plus' /> Column
			</button>
		);

		let showSubNavToggle = false;
		if (hasRelation) {
			showSubNavToggle = flatData[listingGroupEntityKey][listingConfigKey].show_sub_nav_button;
		}
		let showSubNavBtn = (
			<button
				className={`${style.mobileDisplay} ${showSubNavToggle ? style.active : ''}`}
				onClick={(e) => {
					e.preventDefault();
					flatData[listingGroupEntityKey][listingConfigKey].show_sub_nav_button = !showSubNavToggle;
					stateCallback('flatData', flatData);
				}}
			>
				<CustomIcon icon='list' />
			</button>
		);

		let mobileDisplayToggle = false;
		if (hasRelation) {
			mobileDisplayToggle = flatData[listingGroupEntityKey][listingConfigKey].mobile_display;
		}
		let mobileDisplayBtn = (
			<button
				className={`${style.mobileDisplay} ${mobileDisplayToggle ? style.active : ''}`}
				onClick={(e) => {
					e.preventDefault();
					flatData[listingGroupEntityKey][listingConfigKey].mobile_display = !mobileDisplayToggle;
					stateCallback('flatData', flatData);
				}}
			>
				<CustomIcon icon='mobile-alt' />
			</button>
		);

		let mobileCallingToggle = false;
		if (hasRelation) {
			mobileCallingToggle = flatData[listingGroupEntityKey][listingConfigKey].mobile_dialing;
		}
		let mobileCallingBtn = (
			<button
				className={`${style.mobileDisplay} ${mobileCallingToggle ? style.active : ''}`}
				onClick={(e) => {
					e.preventDefault();
					flatData[listingGroupEntityKey][listingConfigKey].mobile_dialing = !mobileCallingToggle;
					stateCallback('flatData', flatData);
				}}
			>
				<CustomIcon icon='phone' />
			</button>
		);

		let mobileWayfindingToggle = false;
		if (hasRelation) {
			mobileWayfindingToggle = flatData[listingGroupEntityKey][listingConfigKey].mobile_wayfinding;
		}

		let mobileWayfindingBtn = (
			<button
				className={`${style.mobileDisplay} ${mobileWayfindingToggle ? style.active : ''}`}
				onClick={(e) => {
					e.preventDefault();
					flatData[listingGroupEntityKey][listingConfigKey].mobile_wayfinding = !mobileWayfindingToggle;
					stateCallback('flatData', flatData);
				}}
			>
				<CustomIcon icon='route' />
			</button>
		);

		let boxStyleToggle = false;
		if (hasRelation) {
			boxStyleToggle = flatData[listingGroupEntityKey][listingConfigKey].boxstyle_display;
		}
		let boxStyleListingsBtn = (
			<button
				className={`${style.mobileDisplay} ${boxStyleToggle ? style.active : ''}`}
				onClick={(e) => {
					e.preventDefault();
					flatData[listingGroupEntityKey][listingConfigKey].boxstyle_display = !boxStyleToggle;
					stateCallback('flatData', flatData);
				}}
			>
				<CustomIcon icon='box' />
			</button>
		);

		let showQRCodeToggle = false;
		if (hasRelation) {
			showQRCodeToggle = flatData[listingGroupEntityKey][listingConfigKey].show_qrcode;
		}

		let	showQRCodeBtn = (
			<button
				className={`${style.mobileDisplay} ${showQRCodeToggle ? style.active : ''}`}
				onClick={(e) => {
					e.preventDefault();
					flatData[listingGroupEntityKey][listingConfigKey].show_qrcode = !showQRCodeToggle;
					stateCallback('flatData', flatData);
				}}
			>
				<CustomIcon icon='qrcode' />
			</button>
		);

		let mobileOptions = [
			{
				label: 'Buttons',
				value: 0,
			},
			{
				label: 'Direct Call',
				value: 1,
			},
			{
				label: 'Direct Wayfinding',
				value: 2,
			},
			{
				label: 'Details',
				value: 3,
			},
		];

		let mobileOptionsValue = mobileOptions[0];
		if (hasRelation) {
			mobileOptionsValue = mobileOptions[flatData[listingGroupEntityKey][listingConfigKey].mobile_click_event];
		}

		let touchActionOptions = [
			{
				label: 'None',
				value: 0,
			},
			{
				label: 'Tray A',
				value: 1,
			},
			{
				label: 'Tray B',
				value: 2,
			},
			{
				label: 'Tray C',
				value: 3,
			},
			{
				label: 'Details Page A',
				value: 4,
			},
			{
				label: 'Details Page B',
				value: 5,
			},
			{
				label: 'Details Page C',
				value: 6,
			},
			{
				label: 'Wayfinding A',
				value: 7,
			},
			{
				label: 'Wayfinding B',
				value: 8,
			},
			{
				label: 'Wayfinding C',
				value: 9,
			},
			{
				label:    'Tier+1',
				value:    10,
				disabled: shouldDisable,
			},
			{
				label: 'Iframe URL',
				value: 11,
			},
			{
				label: 'PDF',
				value: 12,
			},
			{
				label: 'Video',
				value: 13,
			},
		];

		let boxLayoutOptions = [
			{
				label: 'Layout 1',
				value: 0,
			},
			{
				label: 'Layout 2',
				value: 1,
			},
		];

		let boxColumnsOptions = [
			{
				label: '1',
				value: 0,
			},
			{
				label: '2',
				value: 1,
			},
			{
				label: '3',
				value: 2,
			},
			{
				label: '4',
				value: 3,
			},
			{
				label: '5',
				value: 4,
			},
		];

		let touchActionOptionsValue = touchActionOptions[0];

		if (hasRelation) {
			touchActionOptionsValue = touchActionOptions[flatData[listingGroupEntityKey][listingConfigKey].touch_action] || touchActionOptions[0];
			if (touchActionOptionsValue.value === 10 && shouldDisable) {
				touchActionOptionsValue = touchActionOptions[0];
			}
		}

		let mobileButtonValue = '';
		if (hasRelation) {
			mobileButtonValue = flatData[listingGroupEntityKey][listingConfigKey].mobile_button_label;
			if (!mobileButtonValue) {
				mobileButtonValue = '';
			}
		}

		let mobileOrderValue = 0;
		if (hasRelation) {
			mobileOrderValue = flatData[listingGroupEntityKey][listingConfigKey].mobile_button_order;
		}
		let boxColumns;
		if (hasRelation) {
			boxColumns = boxColumnsOptions[flatData[listingGroupEntityKey][listingConfigKey].number_of_columns];
		}
		let boxLayout;
		if (hasRelation) {
			boxLayout = boxLayoutOptions[flatData[listingGroupEntityKey][listingConfigKey].active_layout];
		}

		let pdfURLOptions = [{
			label: 'None',
			value: 0,
		}, ];
		let iframeURLOptions = [{
			label: 'None',
			value: 0,
		}, ];
		let videoURLOptions = [{
			label: 'None',
			value: 0,
		}, ];
		Object.keys(listingConfigMap[listingConfigUUID]).forEach((childKey) => {
			if (childKey.startsWith('listing_column')) {
				Object.keys(listingConfigMap[listingConfigUUID][childKey]).forEach((key) => {
					if (listingConfigMap[listingConfigUUID][childKey][key].display_name && listingConfigMap[listingConfigUUID][childKey][key].display_name === 'PDF') {
						pdfURLOptions.push({
							label: listingConfigMap[listingConfigUUID][childKey].display_name,
							value: pdfURLOptions.length,
						});
					}
					if (listingConfigMap[listingConfigUUID][childKey][key].display_name && listingConfigMap[listingConfigUUID][childKey][key].display_name === 'URL') {
						iframeURLOptions.push({
							label: listingConfigMap[listingConfigUUID][childKey].display_name,
							value: iframeURLOptions.length,
						});
					}
					if (listingConfigMap[listingConfigUUID][childKey][key].display_name && listingConfigMap[listingConfigUUID][childKey][key].display_name === 'Video') {
						videoURLOptions.push({
							label: listingConfigMap[listingConfigUUID][childKey].display_name,
							value: videoURLOptions.length,
						});
					}
				});
			}
		});
		let iframeURLValue, pdfURLValue, videoURLValue;
		let iframeURLLabel, pdfURLLabel, videoURLLabel;
		let pdfURLOptionsValue = pdfURLOptions[0];
		let iframeURLOptionsValue = iframeURLOptions[0];
		let videoURLOptionsValue = videoURLOptions[0];


		if (hasRelation) {
			pdfURLValue = flatData[listingGroupEntityKey][listingConfigKey]?.pdf_url_value || '';
			pdfURLLabel = flatData[listingGroupEntityKey][listingConfigKey]?.pdf_url_label || undefined;
			iframeURLValue = flatData[listingGroupEntityKey][listingConfigKey]?.iframe_url_value || '';
			iframeURLLabel = flatData[listingGroupEntityKey][listingConfigKey]?.iframe_url_label || undefined;
			videoURLValue = flatData[listingGroupEntityKey][listingConfigKey]?.video_url_value || '';
			videoURLLabel = flatData[listingGroupEntityKey][listingConfigKey]?.video_url_label || undefined;
			for (let i = 0; i < pdfURLOptions.length; i++) {
				if (pdfURLOptions[i].value === pdfURLValue) {
					pdfURLOptionsValue = {
						'label': pdfURLLabel,
						'value': pdfURLValue,
					};
				}
			}
			for (let i = 0; i < iframeURLOptions.length; i++) {
				if (iframeURLOptions[i].value === iframeURLValue) {
					iframeURLOptionsValue = {
						'label': iframeURLLabel,
						'value': iframeURLValue,
					};
				}
			}
			for (let i = 0; i < videoURLOptions.length; i++) {
				if (videoURLOptions[i].value === videoURLValue) {
					videoURLOptionsValue = {
						'label': videoURLLabel,
						'value': videoURLValue,
					};
				}
			}
		}

		// Enforce limit of 3 column_groups. Remove/modify this when condor can support more columns.
		// commented conditional may be used in the future for a more complex limiter for columns.
		// if (this.state.listingColumnOptionsMap[`listing_config.${listingConfigUUID}`] && listingColumnGroupList.length >= this.state.listingColumnOptionsMap[`listing_config.${listingConfigUUID}`].length || isDisabled) {

		if (listingColumnGroupList.length === 7 || isDisabled) {
			addColumnBtn = null;
		}

		let columnGroups = [];

		// Create listing_column_group elements.
		listingColumnGroupList.forEach((listingColumnGroupKey, i) => {
			if (!flatData[listingGroupEntityKey][listingColumnGroupKey]) {
				return;
			}

			// Set the order for the listing_column_group. Check if the value needs to be updated due to a column_group deletion.
			if (flatData[listingGroupEntityKey][listingColumnGroupKey].order !== i + 1) {
				flatData[listingGroupEntityKey][listingColumnGroupKey].order = i + 1;
				stateCallback('flatData', flatData);
			}

			let columns = [];

			// Find listing_columns that the listing_column_group owns.
			Object.entries(flatData[listingColumnGroupKey]).forEach(([ listingColumnKey, listingColumnVal, ]) => {
				const {
					m1, m1ID: listingColumnUUID,
				} = window.form.parseEntityName(listingColumnKey);

				let columnOrder = 0;
				let nestedOptions = this.buildNestedColumnOptions(listingColumnOptionsMap, listingConfigUUID, listingConfigMap);
				// Build listing column fields if the relation isn't false.
				if (m1 === 'listing_column' && listingColumnVal) {
					let columnSelectValue = null;
					columnOrder++;
					if (typeof listingColumnOptionsMap[listingConfigKey] !== 'undefined') {
						Object.entries(listingColumnOptionsMap).forEach(([ listingConfigKey, listingConfigVal, ]) => {
							for (const option of listingConfigVal) {
								if (option.value.uuid === listingColumnUUID) {
									const mappedOption = {
										label: option.label.split('\n')[0],
										value: option.value,
									};
									columnSelectValue = mappedOption;
									break;
								}
							}
						});
					}
					let checkedHeader;
					if (hasRelation) {
						checkedHeader = flatData[listingColumnGroupKey][listingColumnKey].header_as_label;
					}
					columns[0] = (
						<React.Fragment key={listingColumnUUID}>
							{listingColumnUUID &&
								<ListingColumnInput
									app={this.props.app}
									accountUUID={this.props.accountUUID}
									attr={'display_name'}
									flatData={flatData}
									label={'Header'}
									listingColumnGroupKey={listingColumnGroupKey}
									stateCallback={stateCallback}
									type={'text'}
									value={flatData[listingColumnGroupKey].display_name}
								/>
							}
							{flatData[listingGroupEntityKey][listingConfigKey]?.boxstyle_display &&
								<div className={style.settingContainer}>
									<div className={style.settingLabel}>Use label in Condor:</div>
									<label className={style.container}>
										<input
											type='checkbox'
											onChange={(e) => {
												flatData[listingColumnGroupKey][listingColumnKey].header_as_label = !flatData[listingColumnGroupKey][listingColumnKey].header_as_label;
												stateCallback('flatData', flatData);
											}}
											checked={checkedHeader}
										/>
										<span className={`${style.checkmark} ${style.boxStyleCheckmark}`}></span>
									</label>
								</div>
							}

							<div className={style.settingContainer}>
								<div className={style.settingLabel}>Data</div>
								<Select
									// Disable the selection of the same data options selected on previous columns
									options={nestedOptions}
									onChange={(e) => {
										// Delete the temp listing_column_group_to_listing_column relation if there is one.
										delete flatData[listingColumnGroupKey]['listing_column.'];

										// If there was a previous listing_column_group_to_listing_column, carry the attrs over to the new listing_column.
										// Delete the old relation.
										flatData[listingColumnGroupKey].display_name = flatData[listingColumnGroupKey].display_name || e.label;
										if (columnSelectValue?.value) {
											if (e.value.uuid === columnSelectValue.value.uuid) {
												return;
											}

											flatData[listingColumnGroupKey][`listing_column.${e.value.uuid}`] = flatData[listingColumnGroupKey][`listing_column.${columnSelectValue.value.uuid}`];
											delete flatData[listingColumnGroupKey][`listing_column.${columnSelectValue.value.uuid}`];
										} else {
											// Else set default attributes.
											let defaultValue = {
												order:             columnOrder,
												width:             70,
												sort:              1,
												text_align:        0,
												mobile_width:      70,
												mobile_text_align: 0,
												filter:            '',
											};

											if (i === 1) {
												defaultValue = {
													order:             columnOrder,
													width:             30,
													sort:              2,
													text_align:        1,
													mobile_width:      30,
													mobile_text_align: 1,
													filter:            '',
												};
											} else if (i > 1) {
												defaultValue = {
													order:             columnOrder,
													width:             0,
													sort:              i + 1,
													text_align:        1,
													mobile_width:      0,
													mobile_text_align: 1,
													filter:            '',
												};
											}

											flatData[listingColumnGroupKey][`listing_column.${e.value.uuid}`] = defaultValue;
										}

										stateCallback('flatData', flatData);
									}}
									value={columnSelectValue}
								/>
							</div>

							{/* Only show width, sort, and filter inputs if there is a listingColumnUUID */}
							{listingColumnUUID &&
								<React.Fragment>
									<ListingColumnInput
										app={this.props.app}
										accountUUID={this.props.accountUUID}
										attr={'width'}
										flatData={flatData}
										listingColumnGroupKey={listingColumnGroupKey}
										listingColumnKey={listingColumnKey}
										stateCallback={stateCallback}
										type={'number'}
										value={flatData[listingColumnGroupKey][listingColumnKey].width}
									/>

									<ListingColumnInput
										app={this.props.app}
										accountUUID={this.props.accountUUID}
										attr={'sort'}
										flatData={flatData}
										listingColumnGroupKey={listingColumnGroupKey}
										listingColumnKey={listingColumnKey}
										stateCallback={stateCallback}
										type={'number'}
										value={flatData[listingColumnGroupKey][listingColumnKey].sort}
									/>

									<ListingColumnInput
										app={this.props.app}
										accountUUID={this.props.accountUUID}
										attr={'filter'}
										flatData={flatData}
										listingColumnGroupKey={listingColumnGroupKey}
										listingColumnKey={listingColumnKey}
										stateCallback={stateCallback}
										updateSettingValue={updateSettingValue}
										type={'text'}
										value={flatData[listingColumnGroupKey][listingColumnKey].filter}
									/>

									<div className={style.settingContainer}>
										<div className={style.settingLabel}>Text Align</div>
										<Select
											options={listingGroupDropdownOptions['text_align']}
											onChange={(e) => {
												flatData[listingColumnGroupKey][listingColumnKey].text_align = e.value;
												stateCallback('flatData', flatData);
											}}
											value={listingGroupDropdownValues['text_align'][flatData[listingColumnGroupKey][listingColumnKey].text_align]}
										/>
									</div>

									<ListingColumnInput
										app={this.props.app}
										accountUUID={this.props.accountUUID}
										attr={'mobile_width'}
										flatData={flatData}
										listingColumnGroupKey={listingColumnGroupKey}
										listingColumnKey={listingColumnKey}
										stateCallback={stateCallback}
										type={'number'}
										value={flatData[listingColumnGroupKey][listingColumnKey].mobile_width}
									/>

									<div className={style.settingContainer}>
										<div className={style.settingLabel}>Mobile Text Align</div>
										<Select
											options={listingGroupDropdownOptions['mobile_text_align']}
											onChange={(e) => {
												flatData[listingColumnGroupKey][listingColumnKey].mobile_text_align = e.value;
												stateCallback('flatData', flatData);
											}}
											value={listingGroupDropdownValues['mobile_text_align'][flatData[listingColumnGroupKey][listingColumnKey].mobile_text_align]}
										/>
									</div>
								</React.Fragment>
							}
						</React.Fragment>
					);
				}
			});

			columnGroups.push(
				<div
					className={style.listingColumnGroup}
					key={listingColumnGroupKey}
				>
					<div className={style.headerLabel}>
						Column {i + 1}
					</div>

					{columns}

					<div
						className={style.listingColumnGroupDelete}
						onClick={(e) => {
							e.preventDefault();
							if (window.confirm(`Are you sure you want to delete ${flatData[listingColumnGroupKey].display_name}?`)) {
								const updatedData = deleteEntityFromFlatData(listingColumnGroupKey, flatData);
								stateCallback('flatData', updatedData);
								this.buildListingConfigToListingColumnGroupMap(this.state.listingConfigList);
								const newListingConfigList = this.state.listingConfigList.map((element) => {
									if (element.uuid === listingConfigKey.split('.')[1] && element.offset >= 1) {
										element.offset -= 1;
									}
									return element;
								});

								this.setState({
									listingConfigList: newListingConfigList,
								});
							}
						}}
					>
						<CustomIcon icon='minus-circle' color='darkred' />
					</div>
				</div>
			);
		});

		const listingConfigDisplayName = listingConfigMap[listingConfigUUID].display_name;
		const listingConfigTier = listingConfigMap[listingConfigUUID].tier;

		let matchingKeys = [];

		if (listingConfigMap[listingConfigUUID].childTiers.length === 0 || shouldDisable) {

			const tierPlusOneOption = touchActionOptions.find((option) => {
				return option.value === 10;
			});
			if (tierPlusOneOption) {
				tierPlusOneOption.isDisabled = true;
			}
		} else {
			Object.entries(listingConfigMap).forEach(([ key, val, ]) => {
				if (listingConfigMap[listingConfigUUID]?.childTiers.includes(key)) {
					matchingKeys.push(key);
				}
			});
		}

		const offset = this.state.listingConfigList.find((element) => {
			if (element.uuid === listingConfigKey.split('.')[1]) {
				return true;
			}
			return false;
		}).offset;

		return (
			<div key={listingConfigKey}>
				<div className={style.listingGroupTierLabel}>
					Tier {listingConfigTier} Data
				</div>
				<div className={style.listingGroupSelectTierContainer}>
					<div className={style.listingGroupLabel}>
						{listingConfigDisplayName}
					</div>
					{addColumnBtn}
					{showSubNavBtn}
					{mobileDisplayBtn}
					{showQRCodeBtn}
					{mobileCallingBtn}
					{mobileWayfindingBtn}
					{boxStyleListingsBtn}
					<Select
						className={style.buttonsDropdown}
						styles={customStyles}
						options={mobileOptions}
						onChange={(e) => {
							flatData[listingGroupEntityKey][listingConfigKey].mobile_click_event = e.value;
							stateCallback('flatData', flatData);
						}}
						value={mobileOptionsValue}
					/>
				</div>
				<div className={style.listingGroupSelectTierContainer}>
					<div className={`${style.settingContainer} ${style.settingContainerShort} ${style.listingGroupSettings}`}>
						<label className={style.settingLabel}>Mobile / Sub Nav Label</label>
						<input
							type='text'
							onChange={(e) => {
								e.preventDefault();
								flatData[listingGroupEntityKey][listingConfigKey].mobile_button_label = e.target.value;
								console.log('flatData[listingGroupEntityKey][listingConfigKey]', flatData[listingGroupEntityKey][listingConfigKey]);
								stateCallback('flatData', flatData);
							}}
							value={mobileButtonValue}
						/>
					</div>
					<div className={`${style.settingContainer} ${style.settingContainerShort} ${style.listingGroupSettings}`}>
						<label className={style.settingLabel}>Mobile / Sub Nav Order</label>
						<input
							type='number'
							onChange={(e) => {
								e.preventDefault();
								flatData[listingGroupEntityKey][listingConfigKey].mobile_button_order = parseInt(e.target.value);
								stateCallback('flatData', flatData);
							}}
							value={mobileOrderValue}
						/>
					</div>
					<div className={`${style.settingContainer} ${style.settingContainerShort} ${style.listingGroupSettings}`}>
						<label className={style.settingLabel}>Touch Action</label>
						<Select
							className={style.buttonsDropdown}
							styles={customStyles}
							options={touchActionOptions}
							onChange={(e) => {
								flatData[listingGroupEntityKey][listingConfigKey].touch_action = e.value;
								if (e.value === 11) {
									flatData[listingGroupEntityKey][listingConfigKey].iframe_url_value =  0;
									flatData[listingGroupEntityKey][listingConfigKey].iframe_url_label = iframeURLOptions[0]?.label;
								}
								if (e.value === 12) {
									flatData[listingGroupEntityKey][listingConfigKey].pdf_url_value =  0;
									flatData[listingGroupEntityKey][listingConfigKey].pdf_url_label = pdfURLOptions[0]?.label;
								}
								stateCallback('flatData', flatData);
							}}
							value={touchActionOptionsValue}
						/>
					</div>

					<div className={style.boxRow}>
						{boxStyleToggle && (
							<>
								<div className={`${style.settingContainer} ${style.listingGroupSettings}`}>
									<label className={style.settingLabel}>Columns Per Row</label>
									<Select
										className={style.buttonsDropdown}
										styles={customStyles}
										options={boxColumnsOptions}
										onChange={(e) => {
											flatData[listingGroupEntityKey][listingConfigKey].number_of_columns = e.value;
											stateCallback('flatData', flatData);
										}}
										value={boxColumns}
									/>
								</div>
								<div className={`${style.settingContainer} ${style.listingGroupSettings}`}>
									<label className={style.settingLabel}>Box Layout Type</label>
									<Select
										className={style.buttonsDropdown}
										styles={customStyles}
										options={boxLayoutOptions}
										onChange={(e) => {
											flatData[listingGroupEntityKey][listingConfigKey].active_layout = e.value;
											stateCallback('flatData', flatData);
										}}
										value={boxLayout}
									/>
								</div>
							</>
						)}
						{touchActionOptionsValue !== undefined && touchActionOptionsValue.value === 11 && (
							<div className={style.settingContainer}>
								<label className={style.settingLabel}>iFrame URL</label>
								<Select
									className={style.buttonsDropdown}
									styles={customStyles}
									options={iframeURLOptions}
									onChange={(e) => {
										flatData[listingGroupEntityKey][listingConfigKey].iframe_url_value = e.value;
										flatData[listingGroupEntityKey][listingConfigKey].iframe_url_label = e.label;
										stateCallback('flatData', flatData);
									}}
									value={iframeURLOptionsValue}
								/>
							</div>
						)}
						{touchActionOptionsValue !== undefined && touchActionOptionsValue.value === 12 && (
							<div className={style.settingContainer}>
								<label className={style.settingLabel}>PDF URL</label>
								<Select
									className={style.buttonsDropdown}
									styles={customStyles}
									options={pdfURLOptions}
									onChange={(e) => {
										flatData[listingGroupEntityKey][listingConfigKey].pdf_url_value = e.value;
										flatData[listingGroupEntityKey][listingConfigKey].pdf_url_label = e.label;
										stateCallback('flatData', flatData);
									}}
									value={pdfURLOptionsValue}
								/>
							</div>
						)}
						{touchActionOptionsValue !== undefined && touchActionOptionsValue.value === 13 && (
							<div className={style.settingContainer}>
								<label className={style.settingLabel}>Video URL</label>
								<Select
									className={style.buttonsDropdown}
									styles={customStyles}
									options={videoURLOptions}
									onChange={(e) => {
										flatData[listingGroupEntityKey][listingConfigKey].video_url_value = e.value;
										flatData[listingGroupEntityKey][listingConfigKey].video_url_label = e.label;
										stateCallback('flatData', flatData);
									}}
									value={videoURLOptionsValue}
								/>
							</div>
						)}
					</div>


				</div>

				{(columnGroups.length > 3) ?
					<div className={style.listingColumnGroupContainer} style={{
						display:        'flex',
						justifyContent: 'flex-end',
					}}>
						<div className={style.settingContainer}>
							<button
								className={`${style.arrowButton} button ${offset < 1 ? 'button-disabled' : ''}`}
								disabled={offset < 1}
								onClick={(e) => {
									e.preventDefault();

									const newListingConfigList = this.state.listingConfigList.map((element) => {
										if (element.uuid === listingConfigKey.split('.')[1] && element.offset >= 1) {
											element.offset -= 1;
										}
										return element;
									});

									this.setState({
										listingConfigList: newListingConfigList,
									});
								}
								}>
								<CustomIcon icon='arrow-circle-left' />
							</button>
						</div>
						<div className={style.settingContainer}>
							<button
								className={`${style.arrowButton} button ${offset + 3 >= columnGroups.length ? 'button-disabled' : ''}`}
								disabled={offset + 3 >= columnGroups.length}
								onClick={(e) => {
									e.preventDefault();

									const newListingConfigList = this.state.listingConfigList.map((element) => {
										if (element.uuid === listingConfigKey.split('.')[1] && element.offset + 3 < columnGroups.length) {
											element.offset += 1;
										}
										return element;
									});

									this.setState({
										listingConfigList: newListingConfigList,
									});
								}
								}>
								<CustomIcon icon='arrow-circle-right' />
							</button>
						</div>
					</div> :
					''}
				<div className={style.listingColumnGroupContainer}>
					{columnGroups.slice(0 + offset, 3 + offset)}
				</div>
			</div>
		);
	}


	render() {
		const {
			listingConfigToListingColumnGroupMap,
		} = this.state;

		let innerElm = (
			<div>Please Select A Listing Config in the Listing Collection.</div>
		);

		if (Object.keys(listingConfigToListingColumnGroupMap).length > 0) {
			const listingConfigKeys = Object.keys(listingConfigToListingColumnGroupMap);
			innerElm = listingConfigKeys.map((listingConfigKey, index) => {
				const listingColumnGroupList = listingConfigToListingColumnGroupMap[listingConfigKey];
				const nextListingConfigKey = listingConfigKeys[index + 1];
				const shouldDisable = !nextListingConfigKey || Object.keys(listingConfigToListingColumnGroupMap[nextListingConfigKey]).length === 0;
				return this.renderTierSection(listingConfigKey, listingColumnGroupList, shouldDisable, index);
			});
		}
		return (
			<div className={style.listingGroupDataContainer}>
				<div className={style.listingGroupSettingSectionHeader}>DATA</div>
				{innerElm}
			</div>
		);
	}
}

class ListingColumnInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}

	updateFlatData(value) {
		const {
			attr,
			flatData,
			listingColumnGroupKey,
			listingColumnKey,
			stateCallback,
			type,
		} = this.props;

		if (this.pendingUpdatesTimeout) {
			clearTimeout(this.pendingUpdatesTimeout);
		}

		// Add a delay to wait for the user to finsish typing before we do a deep compare.
		this.pendingUpdatesTimeout = setTimeout(() => {
			if (type === 'number') {
				value = parseInt(value, 10);
			}

			if (!listingColumnKey) {
				flatData[listingColumnGroupKey][attr] = value;
				stateCallback('flatData', flatData);

				return;
			}

			flatData[listingColumnGroupKey][listingColumnKey][attr] = value;
			stateCallback('flatData', flatData);
		}, 500);
	}

	render() {
		const {
			attr,
			label,
			type,
			flatData,
			updateSettingValue,
			listingColumnGroupKey,
			listingColumnKey,
		} = this.props;

		if (arrayNames.includes(attr)) {
			let key = listingColumnGroupKey + ',' + listingColumnKey;
			let value = flatData[listingColumnGroupKey][listingColumnKey][attr] || '[]';
			let tmpVal = [];

			try {
				tmpVal = JSON.parse(value);
			} catch (err) {
				console.error(err);
			}

			const stringVal = tmpVal.join(', ');
			return (
				<div className={style.settingContainer} key={key}>
					<div className={style.settingLabel} title={attr}>
						{attr}
					</div>
					<input
						onChange={(e) => {
							e.preventDefault();
							let arrayVal = [];
							if (e.target.value.length > 0) {
								arrayVal = e.target.value.split(', ');
							}

							updateSettingValue(key, JSON.stringify(arrayVal), attr);
						}}
						value={stringVal}
					/>
				</div>
			);
		}

		return (
			<div className={style.settingContainer}>
				<div className={style.settingLabel}>{label || toCapitalCase(attr)}</div>
				<input
					type={type}
					onChange={(e) => {
						e.preventDefault();
						this.setState({
							value: e.target.value,
						});

						this.updateFlatData(e.target.value);
					}}
					value={this.state.value}
				/>
			</div>
		);
	}
}

class ListingCollectionWarning extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}
	render() {
		return (
			<React.Fragment>
				<div className={style.listingWarningModalContainer}>
					<p>You must create a Listing Group for this Listing Collection before you are able to choose a Listing Config.</p>
					<div className={style.warningModalButton}>
						<button className='button' onClick={(e) => {
							e.preventDefault();
							this.props.app.closeModal();
						}}><CustomIcon icon='undo' />Go Back</button>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default TemplateSkeleton;