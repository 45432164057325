import React from 'react';

import CustomCheckbox from './Elements/Checkbox';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import DatePicker from 'react-datepicker';
import md5 from 'md5';

// Styles
import style from './Slideshow.module.css';
import modalStyle from './Library.module.css';
import 'react-datepicker/dist/react-datepicker.css';

import {
	vultureENV,
} from 'Vulture/ENV.js';
import {
	getPhotosFromBucket,
} from 'Vulture/Content';
import {
	buildTestData,
} from 'Vulture/TestData';

class SlideshowModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			description:  '',
			order:        1,
			fileMetadata: {
				duration:        '15',
				description:     '',
				resolution:      '',
				scheduling:      false,
				use_dates:       false,
				start_date:      '',
				end_date:        '',
				use_times:       false,
				start_time:      '',
				end_time:        '',
				use_days:        false,
				key:             '',
				days:            [],
				file:            null,
				fileType:        '',
				formData:        {},
				pelicanUpdates:  {},
				previewSrc:      'https://ts-pelican-resized.s3.amazonaws.com/y9DpT.jpg',
				component_uuids: [],
				order:           1,
			},
			file:                 null,
			modalState:           'slide',
			originalFileMetadata: {},
			allowedSizes:         {
				'image/*': {
					size: 10,
					unit: 'mb',
				},
				'video/*': {
					size: 400,
					unit: 'mb',
				},
			},
			searchValue:          '',
			assignContentData:    [],
			selectedContentCount: 0,
			durationButtonClass:  'button',
			libraryKey:           '',
			isLibrarySelection:   false,
			confirmModal:         false,
			confirmModalContent:  '',
		};
	}

	componentDidMount() {
		let fileMetadata = this.state.fileMetadata;

		if (this.props.file) {
			fileMetadata = JSON.parse(JSON.stringify(this.props.file));
		}

		if (this.props.isEdit) {
			fileMetadata.previewSrc = this.props.file.imgURL;

			this.setState({
				description:          fileMetadata.description,
				order:                fileMetadata.order,
				fileMetadata,
				originalFileMetadata: JSON.parse(JSON.stringify(fileMetadata)),
			});
		}

		let assignContentData = [];
		let selectedContentCount = 0;
		let uniqueListOfDisplayNames = [];

		this.props.systemToSystemGroupObjects.forEach((systemToSystemGroupObject) => {
			let componentUUIDs = systemToSystemGroupObject.componentUUIDs;
			console.log(systemToSystemGroupObject);
			let checked = false;

			Object.keys(componentUUIDs).forEach((componentUUID) => {
				if (fileMetadata.component_uuids.includes(componentUUID)) {
					checked = true;
				}
			});

			let data = {
				checked,
				componentUUIDs,
				displayName: systemToSystemGroupObject.systemDisplayName,
			};

			if (systemToSystemGroupObject.isBundled) {
				data.displayName = systemToSystemGroupObject.bundleName;
			}

			if (!uniqueListOfDisplayNames.includes(data.displayName)) {
				uniqueListOfDisplayNames.push(data.displayName);
				assignContentData.push(data);
				if (data.checked) {
					selectedContentCount++;
				}
			}

		});

		let durationButtonClass = 'button';

		if (fileMetadata.kind && fileMetadata.kind.split('/')[0] === 'video') {
			durationButtonClass = 'button-disabled no-click';
		}

		this.setState({
			assignContentData,
			durationButtonClass,
			selectedContentCount,
		});
	}


	closeModal() {
		const {
			app,
		} = this.props;

		app.closeModal();
	}

	handleSelectImageFromLibrary(image) {
		const oldFileMetaData = this.state.fileMetadata;
		let fileMetadata = oldFileMetaData;
		fileMetadata.previewSrc = image.imgURL;

		let img = new Image();
		img.addEventListener('load', function () {
			fileMetadata.resolution = `${this.naturalWidth}px x ${this.naturalHeight}px`;
		});
		img.src = image.imgURL;
		let splitName = image.name.split('.');
		let extension = splitName[splitName.length - 1];
		fileMetadata.fileType = `image/${extension}`;
		fileMetadata.description = image.name;

		this.setState({
			description:        image.name,
			fileMetadata,
			libraryKey:         image.imgKey,
			isLibrarySelection: true,
		});
	}

	handleImageUpload(e) {
		const {
			accountUUID,
		} = this.props;

		e.preventDefault();
		if (e.target && e.target.files) {
			let file = e.target.files[0];
			let fileSize = file.size;
			let fileType = file.type;

			let goodSize = true;
			Object.keys(this.state.allowedSizes).forEach((key) => {
				let typeSegments = fileType.split('/');
				let sizeSegments = key.split('/');
				if (typeSegments[0] !== sizeSegments[0]) {
					return;
				}
				if (typeSegments[1] === sizeSegments[1] || sizeSegments[1] === '*') {
					let ruleUnit = this.state.allowedSizes[key].unit;
					let ruleSize = Number(this.state.allowedSizes[key].size + 1);
					if (ruleUnit === 'mb') {
						fileSize = fileSize / 1024 / 1024;
					}
					if (ruleUnit === 'kb') {
						fileSize = fileSize / 1024;
					}

					if (fileSize > ruleSize) {
						this.setState({
							confirmModal:        true,
							confirmModalContent:
							<>
								<p>{'File size: ' + Math.floor(fileSize) + '(' + ruleUnit + ') is too large for file type.'}</p>
								<div className="confirm-modal-buttons">
									<button className="button" onClick={(e) => {
										this.setState({
											confirmModal: false,
										});
									}}>Okay</button>
								</div>
							</>,
						});
						goodSize = false;
					}
				}
			});

			if (!goodSize) {
				return;
			}

			let fileArray = fileType.split('/');
			let type = fileArray[0];

			let previewSrc = 'https://ts-pelican-resized.s3.amazonaws.com/y9DpT.jpg';
			if (type === 'image') {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (e) => {
					let image = new Image();
					image.src = reader.result;
					image.onload = () => {

						previewSrc = e.target.result;
						let fileMetadata = this.state.fileMetadata;
						fileMetadata.previewSrc = previewSrc;
						fileMetadata.file = `${window._getEnv('ENV')}/${accountUUID}/${file.name}`;
						fileMetadata.description = file.name;
						fileMetadata.fileType = fileType;
						fileMetadata.resolution = `${image.width}px x ${image.height}px`;

						this.setState({
							description:         file.name,
							file,
							libraryKey:          '',
							isLibrarySelection:  false,
							durationButtonClass: 'button',
							fileMetadata,
						});
					};
				};
				return;
			}

			previewSrc = 'https://ts-pelican-resized.s3.amazonaws.com/video_placeholder.jpeg';
			let fileMetadata = this.state.fileMetadata;
			fileMetadata.previewSrc = previewSrc;
			fileMetadata.file = `${window._getEnv('ENV')}/${accountUUID}/${file.name}`;
			fileMetadata.description = file.name;
			fileMetadata.fileType = fileType;
			fileMetadata.duration = '';

			this.setState({
				description:         file.name,
				file,
				libraryKey:          '',
				isLibrarySelection:  false,
				durationButtonClass: 'button-disabled no-click',
				fileMetadata,
			});
		}
	}

	formatDateForJSON(date) {
		if (date) {
			let iso = date.toISOString();
			return iso;
		}
		return null;
	}

	formatTimeForJSON(date) {
		let selectedDate = new Date(date);
		return `${selectedDate.getHours()}:${(selectedDate.getMinutes() < 10 ? '0' : '') + selectedDate.getMinutes()}:00`;
	}

	formateDateForDatePicker(date) {
		let formattedDate = new Date(date);
		return formattedDate;
	}

	updateDays(day) {
		let days = this.state.fileMetadata.days;
		if (days.includes(day)) {
			days.map((obj, i) => {
				if (obj === day) {
					days.splice(i, 1);
				}
			});
		} else {
			days.push(day);
		}
		let fileMetadata = this.state.fileMetadata;
		fileMetadata.days = days.sort((a, b) => {
			return a - b;
		});
		this.setState({
			fileMetadata,
		});
	}

	filterContent() {
		let i, li, name, txtValue;
		li = document.querySelectorAll('li[data-selector="content-item"]');
		for (i = 0; i < li.length; i++) {
			name = li[i].querySelectorAll('div[data-selector="content-name"]')[0];
			txtValue = name.textContent || name.innerText;
			if (txtValue.toUpperCase().indexOf(this.state.searchValue.toUpperCase()) > -1) {
				li[i].style.display = '';
			} else {
				li[i].style.display = 'none';
			}
		}
	}

	getAssignContentData() {
		let elms = [];

		let sortedAssignContentData = this.state.assignContentData.sort((a, b) => {
			return a.displayName.localeCompare(b.displayName);
		});

		sortedAssignContentData.map((data, i) => {
			let uploadedImgUnitVal;
			let uploadedImgXResolutionVal;
			let uploadedImgYResolutionVal;

			let resolutionText = null;
			Object.values(data.componentUUIDs).forEach((settings) => {
				uploadedImgUnitVal = settings['uploaded_img_unit'] || 'px';
				uploadedImgXResolutionVal = settings['uploaded_img_x_resolution'];
				uploadedImgYResolutionVal = settings['uploaded_img_y_resolution'];
			});

			if (uploadedImgUnitVal && uploadedImgXResolutionVal && uploadedImgYResolutionVal) {
				resolutionText = (
					<div className={style.uploaderFileName} style={{
						width: '100%',
					}}>
						{`Max Resolution: ${uploadedImgXResolutionVal}${uploadedImgUnitVal} x ${uploadedImgYResolutionVal}${uploadedImgUnitVal}`}
					</div>
				);
			}

			elms.push(
				<li data-selector="content-item"
					key={`slideshow-li-${i}`}
					onClick={(e) => {
						let assignContentData = this.state.assignContentData;
						let checked = assignContentData[i].checked;
						assignContentData[i].checked = !checked;
						const componentUUIDs = Object.keys(assignContentData[i].componentUUIDs);

						let selectedContentCount = this.state.selectedContentCount;

						let fileMetadata = this.state.fileMetadata;
						if (assignContentData[i].checked) {
							componentUUIDs.forEach((uuid) => {
								if (!fileMetadata.component_uuids.includes(uuid)) {
									fileMetadata.component_uuids.push(uuid);
									selectedContentCount++;
								}
							});
						}
						if (!assignContentData[i].checked) {
							componentUUIDs.forEach((uuid) => {
								if (fileMetadata.component_uuids.includes(uuid)) {
									let indexToRemove = fileMetadata.component_uuids.indexOf(uuid);
									fileMetadata.component_uuids.splice(indexToRemove, 1);
									selectedContentCount--;
								}
							});
						}

						this.setState({
							selectedContentCount,
							assignContentData,
							fileMetadata,
						});
					}}
				>
					<CustomCheckbox
						checked={data.checked}
					/>
					<div data-selector="content-name" className={style.contentBucket}>
						<div>{data.displayName}</div>
						{resolutionText}
					</div>
				</li>
			);
		});

		return elms;
	}

	checkAll() {
		let { assignContentData, fileMetadata } = this.state;
		let assignContentLength = assignContentData.length;

		const updatedData = assignContentData.map(item => {
			return { 
				...item, 
				checked: true 
			};
		});

		const allUUIDs = assignContentData.flatMap(obj =>
			Object.keys(obj.componentUUIDs)
		);

		let updatedFileMetadata = {
			...fileMetadata, 
			component_uuids: allUUIDs
		};

		this.setState({ 
			assignContentData: updatedData,
			selectedContentCount: assignContentLength,
			fileMetadata: updatedFileMetadata
		})
	}

	checkNone() {
		let { assignContentData, fileMetadata } = this.state;
		let updatedFileMetadata = {
			...fileMetadata, 
			component_uuids: []
		};
		
		const updatedData = assignContentData.map(item => {
			return { 
				...item, 
				checked: false 
			};
		});

		this.setState({ 
			assignContentData: updatedData,
			selectedContentCount: 0,
			fileMetadata: updatedFileMetadata
		})
	}

	render() {
		const {
			fileMetadata,
		} = this.state;

		let disabledForEdit = 'button';
		// let disabledForAdd = "button";

		if (this.props.isEdit) {
			disabledForEdit = 'button button-disabled no-click button-hide';
		}
		// if (!this.props.isEdit) {
		//     disabledForAdd = "button-disabled no-click";
		// }

		let startDate = null;
		if (this.state.fileMetadata.start_date) {
			startDate = this.formateDateForDatePicker(this.state.fileMetadata.start_date);
		}

		let endDate = null;
		if (this.state.fileMetadata.end_date) {
			endDate = this.formateDateForDatePicker(this.state.fileMetadata.end_date);
		}

		// DatePicker component does not accept just a time.
		// This code block instantiates a new Date and then assigns only the relavent hour and minute
		let startTime = null;
		if (this.state.fileMetadata.start_time) {
			let hoursMinutesSeconds = this.state.fileMetadata.start_time.split(':');
			let stubDate = new Date();
			stubDate.setHours(hoursMinutesSeconds[0]);
			stubDate.setMinutes(hoursMinutesSeconds[1]);
			stubDate.setSeconds('00');
			startTime = stubDate;
		}

		// DatePicker component does not accept just a time.
		// This code block instantiates a new Date and then assigns only the relavent hour and minute
		let endTime = null;
		if (this.state.fileMetadata.end_time) {
			let hoursMinutesSeconds = this.state.fileMetadata.end_time.split(':');
			let stubDate = new Date();
			stubDate.setHours(hoursMinutesSeconds[0]);
			stubDate.setMinutes(hoursMinutesSeconds[1]);
			stubDate.setSeconds('00');
			endTime = stubDate;
		}

		const maxTime = new Date();
		maxTime.setHours(23);
		maxTime.setMinutes(45);
		maxTime.setSeconds(0);

		let bottomButton =
		<button
			onClick={(e) => {
				this.setState({
					confirmModal:        true,
					confirmModalContent:
					<>
						<p>By cancelling you will lose your unsaved changes. Are you sure you want to continue?</p>
						<div className="confirm-modal-buttons">
							<button className="button-outlined" onClick={(e) => {
								this.setState({
									confirmModal: false,
								});
								this.closeModal();
							}}>Yes</button>
							<button className="button" onClick={(e) => {
								this.setState({
									confirmModal: false,
								});
							}}>No</button>
						</div>
					</>,
				});
			}}
			className="button button-outlined"
		>
			Cancel
		</button>;

		if (this.props.isEdit) {
			bottomButton =
				<button
					onClick={(e) => {
						this.setState({
							confirmModal:        true,
							confirmModalContent:
							<>
								<p>Are you sure you want to delete this slide?</p>
								<div className="confirm-modal-buttons">
									<button className="button-outlined" onClick={(e) => {
										const keys = [];
										const file = fileMetadata.file;
										const uuid = fileMetadata.key;

										keys.push({
											Key: `${file}`,
										});
										keys.push({
											Key: `${file}.json`,
										});
										// keys.push({ Key: `${window._getEnv('ENV')}/${accountUUID}/${file}` });
										// keys.push({ Key: `${window._getEnv('ENV')}/${accountUUID}/${file}.json` });
										this.props.deleteObjects(keys, uuid, fileMetadata, this.closeModal.bind(this));
									}}>Yes</button>
									<button className="button" onClick={(e) => {
										this.setState({
											confirmModal: false,
										});
									}}>No</button>
								</div>
							</>,
						});
					}}
					className="button button-outlined"
				>
					<CustomIcon
						icon='trash'
					/>Delete
				</button>;
		}

		if (this.state.modalState === 'library') {
			return (
				<div className={style.slideshowModal}>
					<div className={style.libraryModalContainer}>
						<div className={style.top}>
							<div className={style.breadcrumbs}>
								<strong><span onClick={(e) => {
									this.setState({
										modalState: 'slide',
									});
								}}>Slide Details</span> {'>'} Library</strong>
							</div>
						</div>
						<LibraryModal
							accountUUID={this.props.accountUUID}
							slideshowState={this}
							handleSelectImageFromLibrary={this.handleSelectImageFromLibrary.bind(this)}
						/>
					</div>
				</div>
			);
		}

		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal =
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>;
		}

		return (
			<React.Fragment>
				<div className={style.slideshowModal}>
					{confirmModal}
					<div className={style.slideshowModalContainer}>
						<div className={style.instructions}><strong>Image Format:</strong> .JPEG, .JPG and .PNG (Max: 10mb) <strong>  Movie Format:</strong>  .MP4 / .MOV as H.264, WebM. (Max file size: 400 MB file size, Max Resolution: HD, and Max Framerate: 30fps)</div>
						<div className={style.column1}>
							<div className={style.sectionLabel}>Details</div>
							<div className={`uploader-buttons ${style.uploader}`}>
								<div className={style.uploaderImage}>
									<img id="modalImage" src={this.state.fileMetadata.previewSrc} alt="test" />
								</div>
								<div className={style.uploaderFileName}>
									{this.state.fileMetadata.resolution}
								</div>
								<div className={style.uploaderButtons}>
									<button
										onClick={(e) => {
											this.setState({
												modalState: 'library',
											});
										}}
										className={disabledForEdit}
										data-testing-info={buildTestData('button--library')}
									>
										<CustomIcon icon='book' />Library
									</button>
									<button
										onClick={(e) => {
											document.getElementById('slideshowUploadButton').click();
										}}
										className={disabledForEdit}
										data-testing-info={buildTestData('button--uploadFile')}
									>
										<CustomIcon icon='cloud-upload-alt' />Upload File
									</button>
								</div>
								<input
									id="slideshowUploadButton"
									onChange={(e) => {
										this.handleImageUpload(e);
										e.target.value = '';
									}}
									accept='.jpg, .jpeg, .png, .mp4, .mov, .webm'
									type='file'
								/>
							</div>
							{/* Description */}
							<div className={`text-small ${style.description}`}>
								<div className={`label ${style.label}`}>
									<label>Description</label>
								</div>
								<input
									value={this.state.description}
									id='modalDescription'
									type='text'
									placeholder='Placeholder'
									onChange={(e) => {
										this.setState({
											description: e.target.value.trim(),
										});
									}}
									onBlur={(e) => {
										let fileMetadata = this.state.fileMetadata;
										fileMetadata.description = e.target.value.trim();
										this.setState({
											fileMetadata,
										});
									}}
									data-testing-info={buildTestData('input--description')}
								/>
							</div>
							{/* Order */}
							<div className={`text-small ${style.order}`}>
								<div className={`label ${style.label}`}>
									<label>Order</label>
								</div>
								<input
									value={this.state.order}
									id='modalOrder'
									type='number'
									placeholder='1'
									onChange={(e) => {
										this.setState({
											order: e.target.value.trim(),
										});
									}}
									onBlur={(e) => {
										let fileMetadata = this.state.fileMetadata;
										fileMetadata.order = e.target.value.trim();
										this.setState({
											fileMetadata,
										});
									}}
									data-testing-info={buildTestData('input--order')}
								/>
							</div>
							{/* Display Duration */}
							<div className={`numberPicker ${style.numberPicker}`}>
								<div className={`label ${style.label}`}>
									<label>Display Duration (seconds)</label>
								</div>
								<div className="numberPickerContainer">
									<button
										onClick={(e) => {
											e.preventDefault();
											let fileMetadata = this.state.fileMetadata;
											if (fileMetadata.duration === '0') {
												return;
											}
											let duration = parseInt(fileMetadata.duration);
											fileMetadata.duration = `${duration - 1}`;
											this.setState({
												fileMetadata,
											});
										}}
										className={this.state.durationButtonClass}
									>-</button>
									<span>
										<input
											value={this.state.fileMetadata.duration}
											onChange={(e) => {
												if (!isNaN(Number(e.target.value))) {
													let fileMetadata = this.state.fileMetadata;
													fileMetadata.duration = e.target.value;
													this.setState({
														fileMetadata,
													});
												} else {
													console.warn('Invalid input', e);
												}
											}}
											data-testing-info={buildTestData('input--duration')}
										/>
									</span>
									<button
										onClick={(e) => {
											e.preventDefault();
											let fileMetadata = this.state.fileMetadata;
											let duration = parseInt(fileMetadata.duration);
											fileMetadata.duration = `${duration + 1}`;
											this.setState({
												fileMetadata,
											});
										}}
										className={this.state.durationButtonClass}
									>+</button>
								</div>
							</div>
						</div>
						<div className={style.column2}>
							<div className={style.sectionLabel}>Assign Content</div>
							<input
								className={style.contentFilter}
								type="text"
								data-selector="filter"
								value={this.state.searchValue}
								onChange={(e) => {
									return this.setState({
										searchValue: e.target.value,
									});
								}}
								onKeyUp={this.filterContent.bind(this)}
								placeholder="Search list.."
								data-testing-info={buildTestData('input--filter--assignContent')}
							/>
							{/* <div className={style.contentControls}>
								<div><span>Selected: </span>{this.state.selectedContentCount} of {this.state.assignContentData.length}</div>
								<div>
									<div className={style.pagination}><CustomIcon icon="chevron-left" /></div>
									<div className={style.pagination}><CustomIcon icon="chevron-right" /></div>
								</div>
							</div> */}
							<ul data-selector="content-list" className={style.contentAssign}>
								{this.getAssignContentData()}
							</ul>
							<button className={style.multiselectButton} onClick={() => {this.checkAll()}}>Select all</button>
							<button className={style.multiselectButton} onClick={() => {this.checkNone()}}>Select none</button>
							<div className={style.sectionLabel}>Scheduling</div>
							<div className={style.scheduling}>
								<div className={style.contentToggle}>
									<input
										className='on-off on-off-label'
										type='checkbox'
										checked={this.state.fileMetadata.scheduling}
										onChange={(e) => {
											let fileMetadata = this.state.fileMetadata;
											fileMetadata.scheduling = !fileMetadata.scheduling;
											if (!fileMetadata.scheduling) {
												fileMetadata.use_dates = false;
												fileMetadata.start_date = '';
												fileMetadata.end_date = '';
												fileMetadata.use_times = false;
												fileMetadata.start_time = '';
												fileMetadata.end_time = '';
												fileMetadata.use_days = false;
												fileMetadata.days = [];
											}
											this.setState({
												fileMetadata,
											});
										}}
										data-testing-info={buildTestData('input--checkbox--scheduling')}
									/> Use Scheduling
								</div>
								<div className={`${style.contentToggle} ${this.state.fileMetadata.scheduling ? style.available : style.notAvailable}`}>
									<input
										className='on-off on-off-label'
										type='checkbox'
										checked={this.state.fileMetadata.use_dates}
										onChange={(e) => {
											let fileMetadata = this.state.fileMetadata;
											fileMetadata.use_dates = !fileMetadata.use_dates;
											if (!fileMetadata.use_dates) {
												fileMetadata.start_date = '';
												fileMetadata.end_date = '';
											}
											this.setState({
												fileMetadata,
											});
										}}
										data-testing-info={buildTestData('input--checkbox--useDates')}
									/>
									Use Dates
									<button className={style.clearButton}
										onClick={() => {
											let fileMetadata = this.state.fileMetadata;
											fileMetadata.start_date = '';
											fileMetadata.end_date = '';
											this.setState({
												fileMetadata,
											});
										}}
										data-testing-info={buildTestData('input--checkbox--clearDates')}
									>Clear Dates</button>
									<div className={`${style.datePicker} ${this.state.fileMetadata.use_dates ? style.show : style.hide}`}>
										<div className={style.startDate}>
											<div className={`label ${style.label}`}>
												<label>Start Date:</label>
											</div>
											<DatePicker
												selected={startDate}
												minDate={new Date()}
												maxDate={this.state.fileMetadata.end_date ? Date.parse(this.state.fileMetadata.end_date) : null}
												onChange={(e) => {
													let formattedDate = this.formatDateForJSON(e);
													let fileMetadata = this.state.fileMetadata;
													fileMetadata.start_date = formattedDate;
													this.setState({
														fileMetadata,
													});
												}}
												data-testing-info={buildTestData('input--startDate')}
											/>
										</div>
										<div className={style.endDate}>
											<div className={`label ${style.label}`}>
												<label>End Date:</label>
											</div>
											<DatePicker
												selected={endDate}
												minDate={this.state.fileMetadata.start_date ? Date.parse(this.state.fileMetadata.start_date) : new Date()}
												onChange={(e) => {
													let formattedDate = this.formatDateForJSON(e);
													let fileMetadata = this.state.fileMetadata;
													fileMetadata.end_date = formattedDate;
													this.setState({
														fileMetadata,
													});
												}}
												data-testing-info={buildTestData('input--endDate')}
											/>
										</div>
									</div>
								</div>
								<div className={`${style.contentToggle} ${this.state.fileMetadata.scheduling ? style.available : style.notAvailable}`}>
									<input
										className='on-off on-off-label'
										type='checkbox'
										checked={this.state.fileMetadata.use_times}
										onChange={(e) => {
											let fileMetadata = this.state.fileMetadata;
											fileMetadata.use_times = !fileMetadata.use_times;
											if (!fileMetadata.use_times) {
												fileMetadata.start_time = '';
												fileMetadata.end_time = '';
											}
											this.setState({
												fileMetadata,
											});
										}}
										data-testing-info={buildTestData('input--checkbox--useTimes')}
									/> Use Times
									<button className={style.clearButton}
										onClick={() => {
											let fileMetadata = this.state.fileMetadata;
											fileMetadata.start_time = '';
											fileMetadata.end_time = '';
											this.setState({
												fileMetadata,
											});
										}}
										data-testing-info={buildTestData('input--checkbox--clearTimes')}
									>Clear Times</button>
									<div className={`${style.timePicker} ${this.state.fileMetadata.use_times ? style.show : style.hide}`}>
										<div className={style.startDate}>
											<div className={`label ${style.label}`}>
												<label>Start Time:</label>
											</div>
											<DatePicker
												selected={startTime}
												showTimeSelect
												showTimeSelectOnly
												timeFormat="hh:mm:aa"
												timeIntervals={15}
												dateFormat="hh:mm:aa"
												onChange={(e) => {
													let fileMetadata = this.state.fileMetadata;
													fileMetadata.start_time = this.formatTimeForJSON(e);
													this.setState({
														fileMetadata,
													});
												}}
												data-testing-info={buildTestData('input--startTime')}
											/>
										</div>
										<div className={style.endDate}>
											<div className={`label ${style.label}`}>
												<label>End Time:</label>
											</div>
											<DatePicker
												selected={endTime}
												/* minTime={startTime}
												maxTime={maxTime} */
												showTimeSelect
												showTimeSelectOnly
												timeFormat="hh:mm:aa"
												timeIntervals={15}
												dateFormat="hh:mm:aa"
												onChange={(e) => {
													let fileMetadata = this.state.fileMetadata;
													fileMetadata.end_time = this.formatTimeForJSON(e);
													this.setState({
														fileMetadata,
													});
												}}
												data-testing-info={buildTestData('input--endTime')}
											/>
										</div>
									</div>
								</div>
								<div className={`${style.contentToggle} ${style.noLine} ${this.state.fileMetadata.scheduling ? style.available : style.notAvailable}`}>
									<input
										className='on-off on-off-label'
										type='checkbox'
										checked={this.state.fileMetadata.use_days}
										onChange={(e) => {
											let fileMetadata = this.state.fileMetadata;
											fileMetadata.use_days = !fileMetadata.use_days;
											if (!fileMetadata.use_days) {
												fileMetadata.days = [];
											}
											this.setState({
												fileMetadata,
											});
										}}
										data-testing-info={buildTestData('input--checkbox--useDays')}
									/> Use Days
									<div className={`${style.dayPicker} ${this.state.fileMetadata.use_days ? style.show : style.hide}`}>
										<div className="weekday-selector">
											<input
												type="checkbox"
												id="weekday-sun"
												className="weekday"
												checked={this.state.fileMetadata.days.includes(0)} onChange={(e) => {
													this.updateDays(0);
												}}
												data-testing-info={buildTestData('input--checkbox--sunday')}
											/>
											<label htmlFor="weekday-sun">Sun</label>
											<input
												type="checkbox"
												id="weekday-mon"
												className="weekday"
												checked={this.state.fileMetadata.days.includes(1)} onChange={(e) => {
													this.updateDays(1);
												}}
												data-testing-info={buildTestData('input--checkbox--monday')}
											/>
											<label htmlFor="weekday-mon">Mon</label>
											<input
												type="checkbox"
												id="weekday-tue"
												className="weekday"
												checked={this.state.fileMetadata.days.includes(2)} onChange={(e) => {
													this.updateDays(2);
												}}
												data-testing-info={buildTestData('input--checkbox--tuesday')}
											/>
											<label htmlFor="weekday-tue">Tue</label>
											<input
												type="checkbox"
												id="weekday-wed"
												className="weekday"
												checked={this.state.fileMetadata.days.includes(3)} onChange={(e) => {
													this.updateDays(3);
												}}
												data-testing-info={buildTestData('input--checkbox--wednesday')}
											/>
											<label htmlFor="weekday-wed">Wed</label>
											<input
												type="checkbox"
												id="weekday-thu"
												className="weekday"
												checked={this.state.fileMetadata.days.includes(4)} onChange={(e) => {
													this.updateDays(4);
												}}
												data-testing-info={buildTestData('input--checkbox--thursday')}
											/>
											<label htmlFor="weekday-thu">Thu</label>
											<input
												type="checkbox"
												id="weekday-fri"
												className="weekday"
												checked={this.state.fileMetadata.days.includes(5)} onChange={(e) => {
													this.updateDays(5);
												}}
												data-testing-info={buildTestData('input--checkbox--friday')}
											/>
											<label htmlFor="weekday-fri">Fri</label>
											<input
												type="checkbox"
												id="weekday-sat"
												className="weekday"
												checked={this.state.fileMetadata.days.includes(6)} onChange={(e) => {
													this.updateDays(6);
												}}
												data-testing-info={buildTestData('input--checkbox--saturday')}
											/>
											<label htmlFor="weekday-sat">Sat</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={style.library}>

					</div>

					<div className={style.bottom}>
						{bottomButton}
						<button
							onClick={(e) => {
								const {
									isLibrarySelection,
									fileMetadata,
									originalFileMetadata,
									file,
									libraryKey,
								} = this.state;

								e.preventDefault();
								if (!this.props.isEdit) {
									if (isLibrarySelection && fileMetadata.component_uuids.length > 0) {
										this.props.copyImageFromLibrary(libraryKey, fileMetadata, this.closeModal.bind(this));
										return;
									}

									if (file === null || fileMetadata.component_uuids.length === 0) {
										this.setState({
											confirmModal:        true,
											confirmModalContent:
											<>
												<p>{'You must upload a file and assign it to a system in order to continue saving.'}</p>
												<div className="confirm-modal-buttons">
													<button className="button" onClick={(e) => {
														this.setState({
															confirmModal: false,
														});
													}}>Okay</button>
												</div>
											</>,
										});
										return;
									}

									this.props.addObject(file, fileMetadata, this.closeModal.bind(this));
								}
								if (this.props.isEdit) {
									if (this.props.file === null || fileMetadata.component_uuids.length === 0) {
										this.setState({
											confirmModal:        true,
											confirmModalContent:
											<>
												<p>{'You must upload a file and assign it to a system in order to continue saving.'}</p>
												<div className="confirm-modal-buttons">
													<button className="button" onClick={(e) => {
														this.setState({
															confirmModal: false,
														});
													}}>Okay</button>
												</div>
											</>,
										});
										return;
									}
									if (fileMetadata.use_days && fileMetadata.days.length === 7) {
										fileMetadata.days = [];
										fileMetadata.use_days = false;
									}
									if (fileMetadata.use_times && fileMetadata.start_time === fileMetadata.end_time) {
										fileMetadata.start_time = '';
										fileMetadata.end_time = '';
										fileMetadata.use_times = false;
									}
									if (!fileMetadata.start_date && !fileMetadata.end_date) {
										fileMetadata.use_dates = false;
									}
									console.log(fileMetadata);
									if (!fileMetadata.use_times && !fileMetadata.use_days && !fileMetadata.use_dates) {
										fileMetadata.scheduling = false;
									}
									if (fileMetadata.days && fileMetadata.days.length === 7) {
										fileMetadata.use_days = false;
									}
									this.props.editObject(fileMetadata, originalFileMetadata, this.closeModal.bind(this));
								}
							}}
							className="button"
						>
							<CustomIcon icon='save' /><div>Save</div>
						</button>
					</div>
				</div>

			</React.Fragment>
		);
	}
}

class LibraryModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			photos:      [],
			stockPhotos: [],
			activeTab:   'My Images',
		};
		this.getStockAndDynamicPhotos();
	}

	getStockAndDynamicPhotos() {
		this.getPhotosFromBucket(`${vultureENV}/ts-library/${this.getHashedActiveAccountName()}`);
		this.getPhotosFromBucket(`${vultureENV}/ts-library/stock`);
	}


	getHashedActiveAccountName() {
		const {
			accountUUID,
		} = this.props;

		return md5(accountUUID);
	}

	getPhotosFromBucket(prefix) {
		getPhotosFromBucket(prefix)
			.then((newState) => {
				this.setState(newState);
			})
			.catch((err) => {
				console.error(err);
			});
	}

	render() {
		let tabContent = (
			<React.Fragment>
				{this.state.photos.map((photo, i) => {
					return (
						<div className={modalStyle.card} key={i}>
							<div className={modalStyle.cardContainer}>
								<div className={modalStyle.image} onClick={(e) => {
									this.props.handleSelectImageFromLibrary(photo);
									this.props.slideshowState.setState({
										...this.props.slideshowState,
										modalState: 'slide',
									});
								}}>
									{photo.imgElm}
								</div>
								<div className={modalStyle.dimensions}>{photo.size}</div>
								<div className={modalStyle.dimensions}>{photo.resolution}</div>
							</div>
						</div>
					);
				})}
			</React.Fragment>
		);
		if (this.state.activeTab === 'Stock Images') {
			tabContent = (
				<React.Fragment>
					{this.state.stockPhotos.map((stockPhoto, i) => {
						return (
							<div className={modalStyle.card} key={i}>
								<div className={modalStyle.cardContainer}>
									<div className={modalStyle.image} onClick={(e) => {
										this.props.handleSelectImageFromLibrary(stockPhoto);
										this.props.slideshowState.setState({
											...this.props.slideshowState,
											modalState: 'slide',
										});
									}}>
										{stockPhoto.imgElm}
									</div>
									<div className={modalStyle.dimensions}>{stockPhoto.size}</div>
									<div className={modalStyle.dimensions}>{stockPhoto.resolution}</div>
								</div>
							</div>
						);
					})}
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<ul className={modalStyle.modalTabs}>
					<li className={this.state.activeTab === 'My Images' ? modalStyle.active : ''} onClick={(e) => {
						this.setState({
							activeTab: 'My Images',
						});
					}}>My Images</li>
					<li className={this.state.activeTab === 'Stock Images' ? modalStyle.active : ''} onClick={(e) => {
						this.setState({
							activeTab: 'Stock Images',
						});
					}}>Stock Images</li>
				</ul>
				<div className={modalStyle.modalGallery}>
					<p>Your collection of reusable images for logos and property info.</p>
					{tabContent}
				</div>
			</React.Fragment>
		);
	}
}

export default SlideshowModal;
