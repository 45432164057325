import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import CustomIcon from '../CustomIcon/CustomIcon';

import 'react-datepicker/dist/react-datepicker.css';

function CrowFieldCustomCommonDateTime(props) {
	let { field, value } = props;
	let { attr = {} } = field || {};
	let { htmlattr } = attr || {};
	const [time, setTime] = useState(null);

	const label = props.attr.label

	if (!value) {
		value = '';
	} else {
		// Create date object for react-datepicker
		let newDate = new Date();

		let newTime = value.split(':');
		let hour = newTime[0];
		let minutes = newTime[1];
		let seconds = newTime[2];

		newDate.setHours(hour);
		newDate.setMinutes(minutes);
		newDate.setSeconds(seconds);
		value = newDate;

		// Set value to empty if invalid date object
		let isValid = Date.parse(value);
		if (isNaN(isValid)) {
			console.error('invalid date format');
			value = '';
		}
	}

	const handleTimeChange = (date) => {
		setTime(date);
		let time = date.toLocaleTimeString('it-IT');
		props.setValue(time, true);
	};

	const handleClearButtonClick = () => {
		setTime(null);
		props.setValue(null, true);
	};

	return (
		<div className={`timefield-wrapper`}>
			<div className="label">
				<label>{label}</label>
			</div>
			
			<DatePicker
				selected={value}
				onChange={handleTimeChange}
				showTimeSelect
				showTimeSelectOnly
				timeIntervals={15}
				timeCaption="Time"
				dateFormat="h:mm aa"
			/>
			{value && (
				<button className={`button`} type="button" onClick={handleClearButtonClick}>
					<CustomIcon icon="times"></CustomIcon>
				</button>
			)}
		</div>
	);
};

const _exports = {
	name: 'custom.common.date.time',
	desc: 'Standard Time Field',
	scope: {},
	field: CrowFieldCustomCommonDateTime,
};

export default _exports;