import React from 'react';
import { getFDValue } from 'Vulture/Helpers';

const crowFormHost = window._getEnv('CROW_FORM_ORIGIN');

function renderLabel(entity) {
	let label = (entity.attr || {}).label || entity.name;
	return label;
}

function renderEntity(props, entity) {
	const {
		form,
		getValue,
		setValue,
	} = props;

	if (!entity.isCustomField) {
		if (entity.type === 'FIELD' && !entity.orgType) {
			let field = form.getField(entity);
			if (field.custom) {
				entity.fieldType = field.fieldType;
				entity.customField = field;
				entity.isCustomField = true;
			}
		}
	}

	if (!entity.form) {
		entity.form = () => {
			return form;
		};
	}

	if (entity.children) {
		let children = entity.children.map((childEntity) => {
			childEntity.parent = () => {
				return entity;
			};

			return childEntity;
		});

		entity.children = children;
	}

	let m1Key = `${form.props.m1}_${form.props.id}`;
	let reactKey = `${m1Key}_${entity.type}_${entity.name}`;
	let entityProps = {
		key: reactKey,
		form,
		entity,
		getValue,
		setValue,
	};

	if (!entity.type) {
		// console.warn('SKIP:', entity);
		return '';
	}

	let elm;
	if (entity.type !== 'FIELD') {
		elm = (
			<CrowFormEntity {...entityProps} />
		);
	}
	if (entity.type === 'FIELD') {
		elm = (
			<CrowFormEntityField {...entityProps} field={entity} />
		);
	}

	entity.fieldType = entity.fieldType || '';
	if (typeof form.props.renderEntity === 'function') {
		entityProps.elm = elm;

		return (
			<React.Fragment
				key={reactKey}
			>
				{form.props.renderEntity(entityProps)}
			</React.Fragment>
		);
	}

	// console.log('TYPE:', entity.type);
	return (
		<div key={reactKey} className={`entity ${entity.type}-entity`}>
			{elm}
		</div>
	);
}

function CrowFormEntity(props) {
	const {
		entity,
	} = props;

	if (entity.skip) {
		return '';
	}

	if (entity.type !== 'FIELD') {
		if (entity.fieldType) {
			// WARN: DO NOT MODIFY THE TREE_FORM!!!
			let entityClone = Object.assign({}, entity, {
				type: 'FIELD',
				orgType: entity.type,
			});

			return renderEntity(props, entityClone);
		}

		let form = props.form;
		return (
			<React.Fragment
				key={`${form.props.m1}_${form.props.id}_${entity.name}`}
			>
				{entity.children.map((childEntity) => {
					return renderEntity(props, childEntity);
				})}
			</React.Fragment>
		);
	}

	return renderEntity(props, entity);
}

function CrowFormEntityField(props) {
	const {
		form,
		field,
		fd
	} = props;

	const getValue = () => {
		// console.log('WC_FIELDS:', field.wcFields);
		if (typeof field.wcFields !== 'undefined') {
			// console.time('form.getValue - field.wcFields');
			let values = form.getValue(...field.wcFields) || {};
			// console.timeEnd('form.getValue - field.wcFields');

			let data = {};
			Object.entries(values).forEach(([key, val]) => {
				data[key] = {
					// maybe required for vulture?
					// name: key,
					value: val,
				};
			});

			return data;
		}

		return getFDValue(fd, field.name);
	}

	if (!field.attr) {
		field.attr = {};
	}

	if (field.fieldType === '') {
		field.fieldType = 'text.small';
	}
	let dynamicField = window.CrowFormField[field.fieldType];
	if (!dynamicField) {
		return (
			<div>
				<strong>BAD_FIELD:</strong><br />
				{field.name} - {field.fieldType}
			</div>
		);
	}
	let Field = dynamicField;

	if (typeof form.props.shouldFieldRender === 'function') {
		if (!form.props.shouldFieldRender(field)) {
			return '';
		}
	}

	let fieldValue = getValue();
	let fieldElm = (
		<Field
			key={`DYNAMIC_FIELD_${field.name}`}
			classNameList={['dynamic-field']}

			form={form}
			field={field}
			// entity={this}
			value={fieldValue} // singleton(string, bool, etc...), arr[{field, value}]
			// setGroupValue={this.setGroupValue}
		/>
	);

	let isNull = (typeof fieldValue === 'undefined');
	if (field.nullable) {
		let nullClassName = '';
		if (isNull) {
			nullClassName = 'is-null';
		}

		return (
			<div className={`nullable-wrapper ${nullClassName}`}
				title='SHIFT+CLICK to set me to null'
				onClick={(e) => {
					if (e.shiftKey) {
						this.clearValue();
					}
				}}
				style={{
					background: '#efefef',
					border: '1px solid #333',
					padding: `5px`,
				}}
			>
				{(field.readOnly && isNull) &&
					<span>NULL</span>
				}

				{(field.readOnly && !isNull) &&
					<React.Fragment>
						{fieldElm}
					</React.Fragment>
				}

				{!field.readOnly &&
					<React.Fragment>
						{fieldElm}
					</React.Fragment>
				}
			</div>
		);
	}

	return fieldElm;
}

export default CrowFormEntity;
