import React from 'react';
import axios from 'axios';

import style from './DataPresets.module.css';
import DataPresetEntity from './DataPresetEntity';
import CustomIcon from '../Common/CustomIcon/CustomIcon.js';
import TextSmall from 'Vulture/Field/TextSmall.js';

import {
	v4 as uuidv4,
} from 'uuid';

import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
} from 'Vulture/ENV.js';

class DataPresetsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dataSets:                 {},
			activeDataSet:            null,
			listingColumnTypeOptions: [ null, ],
		};
	}

	componentDidMount() {
		this.fetchDataSets();
		this.fetchListingColumnTypes();
	}

	fetchDataSets() {
		const session = getSession();

		let url = `${broncoURL}/data_sets`;

		let body = {
			user: session.user,
		};

		let headers = {
			'X-Auth-Session': session.session,
		};

		axios
			.post(url, body, {
				headers,
			})
			.then((res) => {
				this.setState({
					dataSets: res.data.dataSets,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	fetchListingColumnTypes() {
		const url = `${window._getEnv('BRONCO_URL')}/r/list/listing_column_type`;

		const sessionKey = getSession().session;

		const body = {
			skip_auth_filter: true,
		};

		const headers = {
			'content_type':   'application/json',
			'X-Auth-Session': sessionKey,
		};

		axios
			.post(url, body, {
				headers,
			})
			.then((res) => {
				if (res?.data?.data) {
					let listingColumnTypeOptions = [];
					res.data.data
						.filter((listing_column_type) => {
							return listing_column_type.display_name !== 'Array';
						})
						.forEach((listing_column_type) => {
							const {
								display_name,
								uuid,
							} = listing_column_type;

							listingColumnTypeOptions.push({
								label: display_name,
								value: uuid,
							});
						});
					this.setState({
						listingColumnTypeOptions,
					});
				}

			})
			.catch((err) => {
				alert(err);
			});
	}

	addList() {
		const {
			dataSets,
		} = this.state;

		const {
			app,
		} = this.props;

		const newUUID = '_' + uuidv4();

		const newDisplayName = 'New Dataset';

		const newJSON = JSON.stringify({
			tiers: [
				{
					listName: '',
					data:     [],
				},
			],
		});

		const newDataSets = {
			...dataSets,
			[newUUID]: {
				json:         newJSON,
				display_name: newDisplayName,
			},
		};

		this.setState({
			dataSets:      newDataSets,
			activeDataSet: newUUID,
		});

		const keys = [
			`data_set.${newUUID}.display_name`,
			`data_set.${newUUID}.json`,
		];

		const vals = [
			newDisplayName,
			newJSON,
		];

		app.setValue('dataPresets', keys, vals);
	}

	addData(tierIndex) {
		const {
			activeDataSet,
			dataSets,
			listingColumnTypeOptions,
		} = this.state;

		const {
			app,
		} = this.props;

		const newParsedJSON = JSON.parse(dataSets[activeDataSet].json);

		const newData = {
			type:  listingColumnTypeOptions[0],
			label: '',
		};

		newParsedJSON.tiers[tierIndex].data.push(newData);

		const newJSON = JSON.stringify(newParsedJSON);

		const newActiveDataSetObj = {
			...dataSets[activeDataSet],
			json: newJSON,
		};

		const newDataSets = {
			...dataSets,
			[activeDataSet]: newActiveDataSetObj,
		};

		this.setState({
			dataSets: newDataSets,
		});

		app.setValue('dataPresets', `data_set.${activeDataSet}.json`, newJSON);
	}

	deleteData(tierIndex, dataIndex) {
		const {
			activeDataSet,
			dataSets,
		} = this.state;

		const {
			app,
		} = this.props;

		const newParsedJSON = JSON.parse(dataSets[activeDataSet].json);

		newParsedJSON.tiers[tierIndex].data = newParsedJSON.tiers[tierIndex].data.filter((_, index) => {
			return index !== dataIndex;
		});

		const newJSON = JSON.stringify(newParsedJSON);

		const newActiveDataSetObj = {
			...dataSets[activeDataSet],
			json: newJSON,
		};

		const newDataSets = {
			...dataSets,
			[activeDataSet]: newActiveDataSetObj,
		};

		this.setState({
			dataSets: newDataSets,
		});

		app.setValue('dataPresets', `data_set.${activeDataSet}.json`, newJSON);
	}

	addTier() {
		const {
			activeDataSet,
			dataSets,
		} = this.state;

		const {
			app,
		} = this.props;

		const newTier = {
			listName: '',
			data:     [],
		};

		const newParsedJSON = JSON.parse(dataSets[activeDataSet].json);

		newParsedJSON.tiers.push(newTier);

		const newJSON = JSON.stringify(newParsedJSON);

		const newActiveDataSetObj = {
			...dataSets[activeDataSet],
			json: newJSON,
		};

		const newDataSets = {
			...dataSets,
			[activeDataSet]: newActiveDataSetObj,
		};

		this.setState({
			dataSets: newDataSets,
		});

		app.setValue('dataPresets', `data_set.${activeDataSet}.json`, newJSON);
	}

	deleteTier(tierIndex) {
		const {
			activeDataSet,
			dataSets,
		} = this.state;

		const {
			app,
		} = this.props;

		const newParsedJSON = JSON.parse(dataSets[activeDataSet].json);

		newParsedJSON.tiers = newParsedJSON.tiers.filter((_, index) => {
			return index !== tierIndex;
		});

		const newJSON = JSON.stringify(newParsedJSON);

		const newActiveDataSetObj = {
			...dataSets[activeDataSet],
			json: newJSON,
		};

		const newDataSets = {
			...dataSets,
			[activeDataSet]: newActiveDataSetObj,
		};

		this.setState({
			dataSets: newDataSets,
		});

		app.setValue('dataPresets', `data_set.${activeDataSet}.json`, newJSON);
	}

	updateDataLabel(newDataLabel, tierIndex, dataIndex) {
		const {
			activeDataSet,
			dataSets,
		} = this.state;

		const {
			app,
		} = this.props;

		const newParsedJSON = JSON.parse(dataSets[activeDataSet].json);

		newParsedJSON.tiers[tierIndex].data[dataIndex].label = newDataLabel;

		const newJSON = JSON.stringify(newParsedJSON);

		const newActiveDataSetObj = {
			...dataSets[activeDataSet],
			json: newJSON,
		};

		const newDataSets = {
			...dataSets,
			[activeDataSet]: newActiveDataSetObj,
		};

		this.setState({
			dataSets: newDataSets,
		});

		app.setValue('dataPresets', `data_set.${activeDataSet}.json`, newJSON);
	}

	updateDataType(newDataType, tierIndex, dataIndex) {
		const {
			activeDataSet,
			dataSets,
		} = this.state;

		const {
			app,
		} = this.props;

		const newParsedJSON = JSON.parse(dataSets[activeDataSet].json);

		newParsedJSON.tiers[tierIndex].data[dataIndex].type = newDataType;

		const newJSON = JSON.stringify(newParsedJSON);

		const newActiveDataSetObj = {
			...dataSets[activeDataSet],
			json: newJSON,
		};

		const newDataSets = {
			...dataSets,
			[activeDataSet]: newActiveDataSetObj,
		};

		this.setState({
			dataSets: newDataSets,
		});

		app.setValue('dataPresets', `data_set.${activeDataSet}.json`, newJSON);
	}

	updateTier(tierName, tierIndex) {
		const {
			activeDataSet,
			dataSets,
		} = this.state;

		const {
			app,
		} = this.props;

		const newParsedJSON = JSON.parse(dataSets[activeDataSet].json);

		newParsedJSON.tiers[tierIndex].listName = tierName;

		const newJSON = JSON.stringify(newParsedJSON);

		const newActiveDataSetObj = {
			...dataSets[activeDataSet],
			json: newJSON,
		};

		const newDataSets = {
			...dataSets,
			[activeDataSet]: newActiveDataSetObj,
		};

		this.setState({
			dataSets: newDataSets,
		});

		app.setValue('dataPresets', `data_set.${activeDataSet}.json`, newJSON);
	}

	updateDisplayName(newDisplayName) {
		const {
			dataSets,
			activeDataSet,
		} = this.state;
		const {
			app,
		} = this.props;

		const newActiveDataSetObj = {
			...dataSets[activeDataSet],
			display_name: newDisplayName,
		};

		const newDataSets = {
			...dataSets,
			[activeDataSet]: newActiveDataSetObj,
		};

		this.setState({
			dataSets: newDataSets,
		});

		app.setValue('dataPresets', `data_set.${activeDataSet}.display_name`, newDisplayName);
	}

	render() {
		const {
			dataSets,
			activeDataSet,
			listingColumnTypeOptions,
		} = this.state;

		const {
			app,
		} = this.props;

		let saveButtonClass = 'button button-disabled button-save-lg no-click';

		if (app.hasPendingChanges('account_settings')) {
			saveButtonClass = 'button button-save-lg';
		}

		return (
			<div className={`systems-page fade-in`}>
				<div className={style.pageHeader}>
					<h2>Data Presets Editor</h2>
					<div className={style.headerDetails}>Create and manage data presets.</div  >
					<div className={`sub-header-buttons`}>
						<button
							onClick={() => {
								app.saveData();
							}}
							className={saveButtonClass}
						>
							<CustomIcon icon='save' /> Save
						</button>
					</div>
				</div>
				<div className={style.content}>
					<div className={style.dataSetSelector}>
						<h2>Data Sets</h2>
						{Object.entries(dataSets).map(([ uuid, dataSet, ]) => {
							return (
								<button
								className={`button ${style.button}`}
								key={uuid}
								onClick={() => {
									if (uuid !== activeDataSet) {
										this.setState({
											activeDataSet: uuid,
										});
									}
								}}
								style={{
									width: 'fit-content',
								}}
							>
								{dataSet.display_name}
							</button>
							);
						})}
						
						<button
							className={`button ${style.button}`}
							onClick={() => {
								this.addList();
							}}
							style={{
								width: 'fit-content',
							}}
						>
							<CustomIcon icon='plus' /> Set
						</button>

						{activeDataSet &&
						<div className={style.textInputContainer}>
							<TextSmall
								setValue={(e) => {
									this.updateDisplayName(e);
								}}
								value={dataSets[activeDataSet]?.display_name}
							/>
						</div>
						}
					</div>
					<div className={style.dataSetEditor}>
						{activeDataSet && dataSets[activeDataSet] &&
							<DataPresetEntity
								app={app}
								dataSet={JSON.parse(dataSets[activeDataSet].json)}
								listingColumnTypeOptions={listingColumnTypeOptions}
								addData={this.addData.bind(this)}
								updateDataLabel={this.updateDataLabel.bind(this)}
								updateDataType={this.updateDataType.bind(this)}
								deleteData={this.deleteData.bind(this)}
								addTier={this.addTier.bind(this)}
								updateTier={this.updateTier.bind(this)}
								deleteTier={this.deleteTier.bind(this)}
							/>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default DataPresetsView;