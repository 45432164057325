import React, {
	useEffect,
	useState,
} from 'react';
import ListingConfig from './ListingConfig.js';

import style from './AccountSettings.module.css';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import TextSmall from 'Vulture/Field/TextSmall.js';
import {
	getFDValue,
} from 'Vulture/Helpers.js';
import {
	buildTestData,
} from 'Vulture/TestData.js';
import ArrayOptions from './ArrayOptions.js';
import MessagingOptions from './MessagingOptions.js';
import {
	deleteAccount, fetchDataSets,
} from 'Vulture/Account.js';


const DeleteInputField = (props) => {
	const {
		setConfirmModalContent,
	} = props;

	const [ deleteField, setDeleteField, ] = useState('');
	const [ deleteConfirmed, setDeleteConfirmed, ] = useState(false);

	return (
		<>
			<input
				value={deleteField}
				onChange={(e) => {
					if (e.target.value === 'DELETE') {
						setDeleteConfirmed(true);
					} else {
						setDeleteConfirmed(false);
					}
					setDeleteField(e.target.value);
				}}

			/>
			<button
				className='button'
				onClick={(e) => {
					e.preventDefault();
					setConfirmModalContent('');
				}}
			>
				Cancel
			</button>
			<button
				type='submit'
				className={`button ${deleteConfirmed ? '' : 'button-disabled no-click'}`}
			>
				DELETE
			</button>
		</>
	);
};

const AccountSettingsView = (props) => {
	const {
		app,
		accountUUID,
		fd,
		listingConfigMap,
		pendingChanges,
		settingMap,
	} = props;

	const [ confirmModalContent, setConfirmModalContent, ] = useState('');
	const [ dataSets, setDataSets, ] = useState({});

	useEffect(() => {
		fetchDataSets(setDataSets);
	}, []);

	if (Object.keys(fd).length === 0) {
		return '';
	}

	if (!fd[`account.${accountUUID}`]) {
		return '';
	}

	const displayName = getFDValue(fd, `account.${accountUUID}.display_name`);
	const salesforceIDVal = getFDValue(fd, `account.${accountUUID}.salesforce_id_val`);

	const handleDeleteAccount = async () => {
		const data = await deleteAccount(fd, accountUUID);

		if (data?.err) {
			alert('Please make sure the accountUUID is not associated to any other users.');
		} else if (data) {
			app.redirect({
				to:     '/accounts',
				reload: true,
			});
		}
	};

	const deleteAccountPreflightCheck = () => {
		const children = [];
		const childrenTexts = [];

		for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key === `account.${accountUUID}`) {
				for (const m2Key of Object.keys(m1Data)) {
					if (m2Key.split('.').length === 2) {
						children.push(m2Key);
					}
				}
			}
		}

		for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (children.includes(m1Key)) {
				for (const [ m2Key, m2Data, ] of Object.entries(m1Data)) {
					if (m2Key === 'display_name') {
						childrenTexts.push(`${m1Key}: ${m2Data}`);
					}
				}
			}
		}

		showCannotDeleteModal(childrenTexts);
	};

	const showCannotDeleteModal = (children) => {
		setConfirmModalContent(
			<div className="confirm-modal-container">
				<div className="confirm-modal-content">
					<div>
						<p>By hitting DELETE you will be deleting all of the following child components.  These cannot be recovered.  Enter the word “DELETE” below to continue or Cancel to exit.</p>
						{children.map((child, i) => {
							return <p key={i}>{child.split(': ')[1]}</p>;
						})}
						<form onSubmit={(e) => {
							e.preventDefault();
							handleDeleteAccount();
						}}>
							<div className="confirm-modal-buttons">
								<DeleteInputField setConfirmModalContent={setConfirmModalContent}/>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	};

	const hasSaveBlocks = () => {
		const saveBlocks = [];

		const pendingArrayOptionsChanges = pendingChanges?.[`account.${accountUUID}.setting.${settingMap?.array_options?.uuid}.json`];

		if (pendingArrayOptionsChanges) {
			const parsedJson = JSON.parse(pendingArrayOptionsChanges);

			Object.entries(parsedJson).forEach(([ dataName, arrayOptionsSet, ]) => {
				if (!Array.isArray(arrayOptionsSet?.options)) {
					saveBlocks.push(`- ${dataName}'s options must be an array`);
					return;
				}

				arrayOptionsSet.options.forEach((option) => {
					if (!(typeof option === 'object' && !Array.isArray(option) && option !== null)) {
						saveBlocks.push(`- ${dataName}'s options must be objects`);
						return;
					}

					if (typeof option.label !== 'string') {
						saveBlocks.push(`- ${dataName}'s labels must be strings`);
						return;
					}

					switch (arrayOptionsSet.type) {
					case 'string':
					case 'text':
						if (!(typeof option.value === 'string')) {
							saveBlocks.push(`- ${dataName}'s values must be strings`);
							return;
						}
						break;
					case 'float':
						if (!(typeof option.value === 'number')) {
							saveBlocks.push(`- ${dataName}'s values must be floats`);
							return;
						}
						break;
					case 'int':
						if (!(typeof option.value === 'number') || option.value !== Math.floor(option.value)) {
							saveBlocks.push(`- ${dataName}'s values must be integers`);
							return;
						}
						break;
					case 'bool':
						if (!(typeof option.value === 'boolean')) {
							saveBlocks.push(`- ${dataName}'s values must be booleans`);
							return;
						}
						break;

					default:
						console.log(`The type ${arrayOptionsSet.type} has no validation logic implemented.`);
					}
				});
			});
		}

		return saveBlocks;
	};

	let saveButtonClass = 'button button-disabled button-save-lg no-click';
	if (app.hasPendingChanges('account_settings')) {
		saveButtonClass = 'button button-save-lg';
	}

	if (salesforceIDVal === '' || displayName === '') {
		saveButtonClass = 'button button-disabled button-save-lg no-click';
	}

	return (
		<>
			{confirmModalContent}
			<div className={`systems-page`}>
				<div className={style.pageHeader}>
					<h2>Account Settings</h2>
					<div className={`sub-header-buttons`}>
						<button
							className='button button-save-lg alert-button mar-rt-0'
							onClick={(e) => {
								deleteAccountPreflightCheck();
								// TODO: /w/delete/m1/m1ID
								// TODO: handle error(has_children)
								// TODO: handle success(no_children)
								debugger;
							}}
							data-testing-info={buildTestData('button--delete')}
						>
							<CustomIcon icon='trash' />Delete
						</button>
						<button
							onClick={(e) => {
								const saveBlocks = hasSaveBlocks();

								if (saveBlocks.length === 0) {
									app.saveData();
								} else {
									const saveBlockMessage = saveBlocks.join('\n');
									alert(saveBlockMessage);
								}

							}}
							className={saveButtonClass}
							data-testing-info={buildTestData('button--save')}
						>
							<CustomIcon icon='save' /> Save
						</button>
					</div>
				</div>
				<div className={style.settingsForm}>
					<div className={style.column1}>
						<div className={style.sectionLabel}
							onClick={(e) => {
								e.preventDefault();
								let url = `https://touchsource.lightning.force.com/lightning/r/Account/${salesforceIDVal}/view`;
								window.open(url, '_blank').focus();
							}}
						>
							Salesforce <CustomIcon icon='external-link-alt' color='#00BAFF' />
						</div>

						<TextSmall
							value={salesforceIDVal}
							setValue={app.setValue.bind(app, 'account_settings', `account.${accountUUID}.salesforce_id_val`)}
							dataTestingInfo={buildTestData('input--salesforce')}
						/>
					</div>
					<div className={style.column2}>
						<div className={style.sectionLabel}>Details</div>
						<div className={style.settingsField}>
							<TextSmall
								value={displayName}
								setValue={app.setValue.bind(app, 'account_settings', `account.${accountUUID}.display_name`)}
								dataTestingInfo={buildTestData('input--details')}
							/>
						</div>
						<div className={style.settingsField}>
							<>
								<a>UUID</a>
								<input disabled={true} value={accountUUID} data-testing-info={buildTestData('input--uuid')}></input>
							</>
						</div>
					</div>
					<div className={style.column2}>
						<div className={style.sectionLabel}>TWO FACTOR AUTHENTICATION</div>
						<div className={style.checkbox}>
							<div
								className={getFDValue(fd, `account.${accountUUID}.require_2fa`) ? style.activeCheckbox : style.disabledSquare}
								onClick={(e) => {
									e.preventDefault();
									const nextVal = !getFDValue(fd, `account.${accountUUID}.require_2fa`);
									const keys = [
										`account.${accountUUID}.require_2fa`,
									];

									const vals = [
										nextVal,
									];
									app.setValue('account_settings', keys, vals);
								}}>
								<div className={style.checkboxIcon}>
									{getFDValue(fd, `account.${accountUUID}.require_2fa`) ?
										<CustomIcon icon="check"/> :
										<CustomIcon icon="check"/>
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				<ArrayOptions
					app={app}
					accountUUID={accountUUID}
					fd={fd}
					pendingChanges={pendingChanges}
					settingMap={settingMap}
				/>

				<MessagingOptions
					app={app}
					accountUUID={accountUUID}
					fd={fd}
					pendingChanges={pendingChanges}
					settingMap={settingMap}
				/>

				<ListingConfig
					app={app}
					accountUUID={accountUUID}
					fd={fd}
					dataSets={dataSets}
					listingConfigMap={listingConfigMap}
					pendingChanges={pendingChanges}
					settingMap={settingMap}
				/>
			</div>
		</>
	);
};

export default AccountSettingsView;