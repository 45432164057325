import axios from 'axios';

import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
} from 'Vulture/ENV.js';

const saveData = async function (body = {}) {
	const session = getSession();

	let url = `${broncoURL}/w/edit`;
	let headers = {
		'X-Auth-Session': session.session,
	};

	try {
		let res = await axios.post(url, body, { headers });
		if (res.status !== 200) {
			// debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		debugger;
		// error handling to be done on a per-page basis to more easily add Retry buttons
	}
};

const deleteM1 = async function (m1, m1UUID) {
	const session = getSession();

	let url = `${broncoURL}/w/delete/${m1}/${m1UUID}`;
	let headers = {
		'X-Auth-Session': session.session,
	};

	try {
		let res = await axios.post(url, {}, { headers });
		if (res.status !== 200) {
			// debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
		// error handled in App.js
	}
}

const deleteMM = async function(m1, m1UUID, m2, m2UUID) {
	
}

const sanitizeData = (input) => {
	// Convert the input to a string for validation
	const inputAsString = String(input);

	// Trim the input
	const trimmedInput = inputAsString.trim();

	// Define an allowlist of special characters
	const allowedSpecialChars = '@.\\-_: ';

	// Use a regex to match only allowed characters
	const regex = new RegExp(`^[a-zA-Z0-9${allowedSpecialChars}]*$`);

	// Test the input against the regex
	if (regex.test(trimmedInput)) {
	  // Return the original input value if it passes the test
	  return input;
	}

	// Return an empty string if input is null or empty
	return null;
};

export {
	saveData,
	deleteM1,
	deleteMM,
	sanitizeData,
};