import React from 'react';
import Cookie from 'js-cookie';
import CustomIcon from '../Common/CustomIcon/CustomIcon.js';
import kali from 'kali';

import ContentGroups from './ContentGroups.js';
import TemplateSkeleton from './TemplateSkeleton.js';
import TemplateBuilder from './TemplateBuilder.js';
import {
	updatesAllowed,
} from './systemRules.js';

import style from './SystemProgramming.module.css';
import {
	getSession,
} from 'Vulture/Session.js';

const broncoURL = window._getEnv('BRONCO_URL');

class SystemProgramming extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeAccountUUID:        '',
			activeEntityKey:          '',
			activeSystemUUID:         '',
			activeTLCUUID:            '',
			cb:                       null,
			assignmentMap:            {},
			contentGroupsSubmitForm:  () => { },
			globalTemplateData:       [],
			hasFetchedData:           false,
			pendingUpdates:           window.form.updateBackup || {},
			skeletonHasUpdated:       false,
			systemMap:                {},
			systemGroupMap:           {},
			step:                     1,
			showLoader:               false,
			blockUpdatesEmptyColumn:  false,
			blockUpdatesDataConflict: false,
			shouldRebuildBundleMap:   false,
		};
	}

	componentDidMount() {
		const {
			accountUUID,
		} = this.props;

		this.fetchAccountTemplates(accountUUID);
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			accountUUID,
			fd,
			app,
		} = this.props;

		const {
			activeSystemUUID,
		} = this.state;

		if (prevProps.accountUUID !== accountUUID) {
			this.setState({
				step: 1,
			}, () => {
				this.fetchAccountTemplates(accountUUID);
			});

			return;
		}

		if ((JSON.stringify(prevProps.fd) !== JSON.stringify(fd))) {
			this.initSystemProgramming();
		}

		if (activeSystemUUID !== prevState.activeSystemUUID && activeSystemUUID !== '') {
			app.fetchAccountSystem(accountUUID, activeSystemUUID);
		}

		if (false && this.state.skeletonHasUpdated) {
			if (Object.keys(fd).length > 0) {
				window.form.rawKeys = window.form._makeRawKeys(window.form._rawData());

				this.setState({
					activeAccountUUID:  accountUUID,
					skeletonHasUpdated: false,
				}, () => {
					// return;

					// this.buildSystemMap();
					this.makeMaps();
					this.fetchGlobalTemplateData();
					// this.findSettings();
				});
			}
		}
		/* this.refreshAccount(); */ //this causes a Maximum Update Depth Exceeded error
	}

	initSystemProgramming() {
		const {
			accountUUID,
			fd,
		} = this.props;

		if (Object.keys(fd).length > 0) {
			window.form.orgVals = fd;
			window.form.rawKeys = window.form._makeRawKeys(window.form._rawData());

			this.setState({
				activeAccountUUID: accountUUID,
				assignmentMap:     {},
			}, () => {
				this.makeMaps();
				this.fetchGlobalTemplateData();
			});
		}
	}

	refreshAccount() {
		const {
			app,
			accountUUID,
		} = this.props;

		this.setState({
			pendingUpdates: {},
		}, () => {
			app.fetchAccountTemplates(accountUUID);
			app.fetchAccountSystems(accountUUID);
		});
	}

	fetchAccountTemplates() {
		const {
			app,
			accountUUID,
		} = this.props;

		app.fetchAccountTemplates(accountUUID);
	}

	fetchAccountSystems() {
		const {
			app,
			accountUUID,
		} = this.props;

		app.fetchAccountSystems(accountUUID);
	}

	fetchAccountSystem(systemUUID) {
		const {
			app,
			accountUUID,
		} = this.props;

		app.fetchAccountSystem(accountUUID, systemUUID);
	}

	setSkeletonHasUpdated() {
		this.setState({
			skeletonHasUpdated: true,
		});
	}

	makeSystemGroupMap() {
		const {
			fd,
		} = this.props;

		let systemGroupMap = {};
		for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key.startsWith('system_group.')) {
				if (!systemGroupMap[m1Key]) {
					systemGroupMap[m1Key] = {};
				}

				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('system.')) {
						systemGroupMap[m1Key][m2Key] = {};
					}
				}
			}
		}

		return systemGroupMap;
	}

	makeSystemMap() {
		const {
			fd,
		} = this.props;

		let systemMap = {};
		for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key.startsWith('system.')) {
				systemMap[m1Key] = {};
				this.makeComponentMap(fd, systemMap[m1Key], m1Key);
			}
		}

		return systemMap;
	}

	makeComponentMap(formData, systemMap, parentM1Key) {
		let componentMap = {};
		for (let m1Key of Object.keys(formData[parentM1Key])) {
			if (m1Key.startsWith('component.') && formData[m1Key]) {
				systemMap[m1Key] = formData[m1Key];
				systemMap[m1Key]['parent'] = parentM1Key;

				for (let m2Key of Object.keys(formData[m1Key])) {
					if (m2Key.startsWith('component_type.')) {
						systemMap[m1Key]['type'] = formData[m2Key].type;
					}
				}

				this.makeComponentMap(formData, systemMap, m1Key);
			}

			let isListing = false;
			if (m1Key.startsWith('listing_collection.')) {
				isListing = true;
			}
			if (m1Key.startsWith('listing_group.')) {
				isListing = true;

				for (let [ m2Key, m2Data, ] of Object.entries(formData[m1Key])) {
					if (m2Key.startsWith('listing_column_group.')) {
						systemMap[m2Key] = {
							...formData[m2Key],
							...m2Data,
						};
					}
				}
			}

			if (isListing) {
				systemMap[m1Key] = formData[m1Key];
				systemMap[m1Key]['parent'] = parentM1Key;
			}
		}
	}

	makeMaps(cb = () => { }) {
		let systemGroupMap = this.makeSystemGroupMap();
		let systemMap = this.makeSystemMap();

		this.setState({
			systemGroupMap,
			systemMap,
		}, cb);
	}

	fetchGlobalTemplateData() {
		const url = `${broncoURL}/template_category`;
		const sessionKey = getSession().session;

		new kali({
			headers: {
				'content_type':   'application/json',
				'X-Auth-Session': sessionKey,
			},
			method: 'POST',
		}).post(url, {
			success: (_kali, res, data) => {
				if (data) {
					this.setState({
						globalTemplateData: data,
					});
				}
			},

			failure: (_kali, res, err) => {
				console.error(err);

				if (res.status === 503) {
					// window.form.props.on503(true);
				}
			},
		});
	}

	handleSubmit(modalCallback = () => { }, cbKey, cbVal) {
		const {
			app,
			accountUUID,
		} = this.props;

		const {
			activeSystemUUID,
			cb,
			contentGroupsSubmitForm,
			pendingUpdates,
			step,
			assignmentMap,
		} = this.state;

		const [ updatesAreAllowed, errorMsg, ] = updatesAllowed(pendingUpdates);

		// Should not allow saving if there are forbidden data inputted on the settings
		if (!updatesAreAllowed) {
			alert(errorMsg);
			return;
		}

		// Step 2 can require cloning configs
		if (step === 2 && cb) {
			return cb(pendingUpdates)
				.then(() => {
					// Rebuild the systemMap after a successful network response.
					window.form.postFormData(null, this.makeMaps.bind(this));

					this.setState({
						cb:             null,
						pendingUpdates: {},
					}, () => {
						modalCallback(cbKey, cbVal);
						window.form.updateBackup = {};
						this.refreshAccount();
					});
				})
				.catch((err) => {
					console.error(err);
					alert('Error saving.');
				});
		}

		if (step === 1 || step === 2) {
			window.form.postFormData(null, () => {
				this.setState({
					pendingUpdates: {},
				}, () => {
					this.fetchAccountTemplates();
				});
			});

			return;
		}

		if (step === 3) {
			app.setState({
				loading: true,
			});
			app.applySystemTemplateAssignment(accountUUID, assignmentMap)
				.then(() => {
					app.setState({
						loading: false,
					});
				});
			return;
		}

		if (step === 5) {
			app.setState({
				loading: true,
			});
			window.form.postFormData(null, () => {
				this.setState({
					pendingUpdates: {},
				}, () => {
					this.fetchAccountSystem(activeSystemUUID);
					app.setState({
						loading: false,
					});
				});
			});

			return;
		}

		// Step 4 uses Save functionality from Content Groups.
		if (step === 4) {
			contentGroupsSubmitForm(cbKey, cbVal);
			return;
		}

		window.form.setValue(Object.keys(pendingUpdates), Object.values(pendingUpdates));

		// Rebuild the systemMap after a successful network response.
		app.setState({
			loading: true,
			// forceLoading: true,
		});

		window.form.postFormData(null, () => {
			this.fetchAccountTemplates();
		});
	}

	stateCallback(key, val) {
		if (typeof key !== undefined && typeof val !== undefined) {
			this.setState({
				[key]: val,
			}, () => {
				window.form.updateBackup = this.state.pendingUpdates;
			});
		}
	}

	handleDontSave(modalCallback = () => { }, cbKey, cbVal) {
		window.form.newVals = {};

		this.setState({
			pendingUpdates: {},
			assignmentMap:  {},
		}, () => {
			modalCallback(cbKey, cbVal);
			window.form.updateBackup = {};
		});
	}

	closeModal() {
		this.props.app.closeModal();
		this.setSkeletonHasUpdated();
	}

	openModal(stateKey, stateVal, templateBuilderStateCallback) {
		let stateCallBack = this.stateCallback.bind(this);
		if (templateBuilderStateCallback) {
			stateCallBack = templateBuilderStateCallback;
		}

		this.props.app.openModal({
			modalSize:  1,
			showModal:  true,
			modalProps: {
				omitClose: true,
				title:     'Save Pending Changes?',
				jsx:       (
					<PendingChangesModal
						closeModal={this.closeModal.bind(this)}
						currentStep={this.state.step}
						handleDontSave={this.handleDontSave.bind(this)}
						handleSubmit={this.handleSubmit.bind(this)}
						stateCallback={stateCallBack}
						stateKey={stateKey}
						stateVal={stateVal}
					/>
				),
			},
		});
	}

	changeStep(newStep) {
		const {
			pendingUpdates,
		} = this.state;

		if (Object.keys(pendingUpdates).length > 0) {
			this.openModal('step', newStep);
			return;
		}

		if (newStep <= 2) {
			this.fetchAccountTemplates();
		}

		if (newStep === 3 || newStep === 5) {
			this.fetchAccountSystems();
		}

		this.setState({
			activeEntityKey:  '',
			activeSystemUUID: '',
			activeTLCUUID:    '',
			step:             newStep,
		});
	}

	handleStepFivePendingUpdates(newData) {
		const {
			settingMap,
		} = this.props;

		const {
			activeSystemUUID,
			systemMap,
		} = this.state;

		if (this.pendingUpdatesTimeout) {
			clearTimeout(this.pendingUpdatesTimeout);
		}

		// Add a delay to wait for the user to finsish typing before we do a deep compare.
		this.pendingUpdatesTimeout = setTimeout(() => {
			const orgData = systemMap[`system.${activeSystemUUID}`];

			let pendingUpdates = {};
			let blockUpdatesEmptyColumn = false;
			let blockUpdatesDataConflict = false;
			let skeletonHasUpdated = true;

			// If orgData doesn't match what the newData that TemplateSkeleton has sent back, loop over newData to compare.
			if (orgData && JSON.stringify(orgData) !== JSON.stringify(newData)) {
				// Array to check if there are repeated data columns on a column group
				let column_group_columns = [];

				Object.entries(newData).forEach(([ m1Key, newDataM2Maps, ]) => {
					// If orgData doesn't have the m1Key, we're adding a new component to the tree.
					if (typeof orgData[m1Key] === 'undefined') {
						// Loop over the new components children and add to pendingUpdates.

						Object.entries(newData[m1Key]).forEach(([ m2Key, m2Val, ]) => {
							// Skip type key and placeholder listing_column key
							if (m2Key === 'type') {
								return;
							}

							if (m2Key === 'listing_column.') {
								blockUpdatesEmptyColumn = true;
								return;
							}

							// If multiple column on a column group share the sama data, it shouldn't be possible to save.
							if (m1Key.includes('listing_column_group.') && m2Key.includes('listing_column.')) {
								// if (column_group_columns.includes(m2Key)) {
								// 	blockUpdatesDataConflict = true;
								// }
								column_group_columns.push(m2Key);
							}

							// Add the parent to child relation.
							if (m2Key === 'parent') {
								pendingUpdates[`${m2Val}.${m1Key}`] = true;
								return;
							}

							// COL
							if (typeof m2Val !== 'object') {
								pendingUpdates[`${m1Key}.${m2Key}`] = m2Val;
								return;
							}

							// REL
							if (Object.keys(m2Val).length === 0) {
								pendingUpdates[`${m1Key}.${m2Key}`] = true;
								return;
							}

							// ATTR
							Object.entries(m2Val).forEach(([ attrKey, attrVal, ]) => {
								pendingUpdates[`${m1Key}.${m2Key}.${attrKey}`] = attrVal;
							});
						});


						return;
					}

					// Check for COL and REL changes.
					if (JSON.stringify(orgData[m1Key]) !== JSON.stringify(newData[m1Key])) {
						Object.keys(orgData[m1Key]).forEach((m2Key) => {
							// If there was orgData[m1Key][m2Key], compare the value to newData[m1Key][m2Key] and update.
							if (typeof orgData[m1Key][m2Key] !== 'undefined' && JSON.stringify(orgData[m1Key][m2Key]) !== JSON.stringify(newData[m1Key][m2Key])) {
								pendingUpdates[`${m1Key}.${m2Key}`] = newData[m1Key][m2Key];
							}

							// If newData[m1Key] doesn't have the m2Key from orgData[m1Key], we're deleting a component from the tree.
							if (typeof newData[m1Key][m2Key] === 'undefined') {
								pendingUpdates[`${m1Key}.${m2Key}`] = false;
							}
						});

						Object.keys(newData[m1Key]).forEach((m2Key) => {
							// If orgData[m1Key] doesn't have the m2Key from newData[m1Key], add the new relation and check for attrs.
							if (typeof orgData[m1Key][m2Key] === 'undefined') {
								pendingUpdates[`${m1Key}.${m2Key}`] = true;

								if (typeof newData[m1Key][m2Key] === 'object') {
									Object.entries(newData[m1Key][m2Key]).forEach(([ attr, val, ]) => {
										pendingUpdates[`${m1Key}.${m2Key}.${attr}`] = val;
									});
								}
							}
						});
					}

					// Check for setting changes.
					Object.entries(newDataM2Maps).forEach(([ m2Key, m2Val, ]) => {
						// If multiple column on a column group share the sama data, it shouldn't be possible to save.
						if (m1Key.includes('listing_column_group.') && m2Key.includes('listing_column.')) {
							// if (column_group_columns.includes(m2Key)) {
							// 	blockUpdatesDataConflict = true;
							// }
							column_group_columns.push(m2Key);
						}

						const {
							attr,
						} = window.form.parseEntityName(`${m1Key}.${m2Key}`);
						if (typeof attr === 'undefined') {
							return;
						}

						if (JSON.stringify(orgData[m1Key][m2Key]) !== JSON.stringify(m2Val)) {
							// Only 1 key/val in m2Val.
							Object.entries(m2Val).forEach(([ attr, val, ]) => {
								pendingUpdates[`${m1Key}.${m2Key}.${attr}`] = val;
							});
						}

						// Find TLC and assign is_custom to true.
						if (m2Key === 'type' && m2Val === 'top_level_condor') {
							pendingUpdates[`${m1Key}.setting.${settingMap['is_custom'].uuid}.bool`] = true;
						}
					});
				});
			}

			window.form.newVals = {};

			window.form.setValue(Object.keys(pendingUpdates), Object.values(pendingUpdates));

			// Trigger Save button.
			this.setState({
				pendingUpdates,
				blockUpdatesEmptyColumn,
				blockUpdatesDataConflict,
				skeletonHasUpdated,
			});
			window.form.updateBackup = pendingUpdates;
		}, 500);
	}

	setActiveSystem(systemUUID) {
		const {
			systemMap,
		} = this.state;

		if (Object.keys(this.state.pendingUpdates).length) {
			this.openModal('activeSystemUUID', systemUUID);
			return;
		}

		let tlcUUID = '';

		Object.entries(systemMap[`system.${systemUUID}`]).forEach(([ key, val, ]) => {
			if (key.startsWith('component.') && val.description === 'top_level_condor') {
				tlcUUID = key;
			}
		});

		this.setSkeletonHasUpdated();
		this.setState({
			activeSystemUUID: systemUUID,
			activeTLCUUID:    tlcUUID,
		});
	}

	turnOffRebuildBundleMap() {
		this.setState({
			shouldRebuildBundleMap: false,
		});
	}

	turnOnRebuildBundleMap() {
		this.setState({
			shouldRebuildBundleMap: true,
		});
	}

	renderSystems() {
		const {
			fd,
		} = this.props;

		const {
			activeEntityKey,
			activeSystemUUID,
			systemGroupMap,
			systemMap,
		} = this.state;

		let projectNumber = '';
		let projectNumberSettingUUID = '';

		for (const [ m1Key, m1Val, ] of Object.entries(this.props.fd)) {
			if (m1Key.startsWith('setting.') && m1Val.name === 'project_number') {
				projectNumberSettingUUID = m1Key;
				break;
			}
		}

		return (
			Object.keys(systemGroupMap).map((entityKey) => {
				const {
					m1ID: systemGroupUUID,
				} = window.form.parseEntityName(entityKey);
				const systemGroupName = window.form.getValue(`system_group.${systemGroupUUID}.display_name`);
				return {
					systemGroupUUID,
					systemGroupName,
				};
			})
				.sort((a, b) => {
					if (!a.systemGroupName || !b.systemGroupName) {
						return 0;
					}

					if (a.systemGroupName.toLowerCase() > b.systemGroupName.toLowerCase()) {
						return 1;
					}
					return -1;
				})
				.map(({
					systemGroupUUID,
					systemGroupName,
				}) => {
					Object.entries(this.props.fd).forEach(([ m1Key, m1Val, ]) => {
						if (m1Key === `system_group.${systemGroupUUID}`) {
							projectNumber = m1Val[projectNumberSettingUUID];
						}
					});

					return (
						<div
							className={style.systemGroupLabel}
							key={systemGroupUUID}
						>
							<div className={style.systemGroupText}>{systemGroupName} <span className={style.projectNumber}>{projectNumber && `- ${projectNumber.string}`}</span></div>
							{window.form.getMatchingKeys(`system_group.${systemGroupUUID}.system.*`).map((nestedEntityKey) => {
								const {
									m2ID: systemUUID,
								} = window.form.parseEntityName(nestedEntityKey);
								const systemName = window.form.getValue(`system.${systemUUID}.display_name`);
								const directoryNumber = window.form.getValue(`system.${systemUUID}.directory_number`);

								return {
									systemUUID,
									systemName,
									directoryNumber,
								};
							})
								.sort((a, b) => {
									if (a.systemName.toLowerCase() > b.systemName.toLowerCase()) {
										return 1;
									}
									return -1;
								})
								.map(({
									systemUUID, systemName, directoryNumber,
								}) => {
									let activeSystemLabel = '';
									if (systemUUID === activeSystemUUID) {
										activeSystemLabel = style.activeSystemLabel;
									}

									let contentGroupIcon = '';
									if (systemMap[`system.${systemUUID}`]?.[activeEntityKey]) {
										contentGroupIcon = <CustomIcon icon='object-group' color='green' />;
									}

									let disabled = '';
									const systemToComponentList = window.form.getMatchingKeys(`system.${systemUUID}.component.*`, true);

									// Disable system if it doesn't have a child TLC. system.*.component.* can be false from Step 3.
									let systemToComponentRel = false;
									systemToComponentList.forEach((systemToComponentKey) => {
										let val = window.form.getValue(systemToComponentKey);
										if (val) {
											systemToComponentRel = true;
										}
									});

									if (!systemToComponentRel) {
										disabled = style.disabledSystemLabel;
									}
												
									let tlcUUID = '';
							
									Object.entries(systemMap[`system.${systemUUID}`]).forEach(([ key, val, ]) => {
										if (key.startsWith('component.') && val.description === 'top_level_condor') {
											tlcUUID = key.replace('component.', '');
										}
									});

									let hasMobile = false;

									if (fd?.['component.' + tlcUUID]) {
										hasMobile = Object.keys(fd['component.' + tlcUUID]).findIndex((m2Key) => {
											if (fd[m2Key]?.description === 'mobile') {
												return true;
											}
										}) !== -1;
									}

									return (
										<div
											className={`${style.systemLabel} ${activeSystemLabel} ${disabled}`}
											key={systemUUID}
											onClick={(e) => {
												e.preventDefault();
												if (!disabled) {
													this.setActiveSystem(systemUUID);
												}
											}}
										>
											<div
												className={style.systemLabelContainer}
											>
												<span className={style.systemIcon}>{contentGroupIcon}</span>
												<span>{systemName} <span className={style.directoryNumber}>{directoryNumber && `- ${directoryNumber}`}</span></span>
											</div>
											<div
												className={style.condorIconsContainer}
											>
												<div
													alt='Open Preview'
													className={style.condorPreviewIcon}
													onClick={(e) => {
														e.stopPropagation();

														if (!tlcUUID) {
															return;
														}

														const previewHost = window._getEnv('PREVIEW_URL');
														window.open(`${previewHost}/?condor_component=${tlcUUID}`, '_blank');
													}}
												>
													<CustomIcon icon='eye' color={tlcUUID ? '#4BA046' : '#4B4B4B'} />
												</div>
												<div
													alt='Open Mobile App'
													className={style.condorMobileIcon}
													onClick={(e) => {
														e.stopPropagation();

														if (!tlcUUID || !hasMobile) {
															return;
														}

														const mobileHost = window._getEnv('CONDOR_MOBILE_URL');
														window.open(`${mobileHost}/?id=${tlcUUID}`, '_blank');
													}}
												>
													<CustomIcon icon='mobile-alt' color={(tlcUUID && hasMobile) ? '#4BA046' : '#aeaeae'} />
												</div>
											</div>
										</div>
									);
								})}
						</div>
					);
				})
		);
	}

	renderStepFive() {
		const {
			app,
			accountUUID,
			settingMap,
			fd,
		} = this.props;

		const {
			activeSystemUUID,
			activeTLCUUID,
			systemMap,
			systemGroupMap,
		} = this.state;
		let existingFlatData = null;
		let settingsFlatData = null;
		let activeSystemFlatData = null;

		if (systemMap[`system.${activeSystemUUID}`]) {
			existingFlatData = systemMap[`system.${activeSystemUUID}`];
			settingsFlatData = Object.entries(fd).filter(([ key, ]) => {
				return key.startsWith('setting.');
			})
				.reduce((accum, [ key, val, ]) => {
					accum[key] = val;
					return accum;
				}, {});
			activeSystemFlatData = fd[`system.${activeSystemUUID}`];
		}

		if (activeSystemUUID && !existingFlatData) {
			return '';
		}

		let activeSystemGroup;
		Object.entries(systemGroupMap).forEach(([ m1Key, m1Val, ]) => {
			Object.keys(m1Val).forEach((m2Key) => {
				if (m2Key === `system.${activeSystemUUID}`) {
					const activeSystemGroupUUID = m1Key.split('.')[1];
					activeSystemGroup = fd[`system_group.${activeSystemGroupUUID}`];
				}
			});
		});

		return (
			<div className={style.stepFiveContainer}>
				<div className={style.stepFiveSystems}>
					<div className={style.systemsContainerHeader}>
						<div className={style.headerLabel}>SYSTEMS</div>
					</div>
					<div className={style.systemsScroller}>
						{this.renderSystems()}
					</div>
				</div>
				{activeSystemUUID &&
					<TemplateSkeleton
						app={app}
						listingConfigMap={this.props.listingConfigMap}
						accountUUID={accountUUID}
						activeSystemUUID={activeSystemUUID}
						activeTLCUUID={activeTLCUUID}
						existingFlatData={existingFlatData}
						settingsFlatData={settingsFlatData}
						activeSystemFlatData={activeSystemFlatData}
						pendingUpdates={this.state.pendingUpdates}
						stateCallback={this.stateCallback.bind(this)}
						updateActiveEntityKey={this.stateCallback.bind(this)}
						updateSkeletonData={this.handleStepFivePendingUpdates.bind(this)}
						activeSystemGroup={activeSystemGroup}
						settingMap={settingMap}
						fd={this.props.fd}
					/>
				}
			</div>
		);
	}

	renderHeaderButtons() {
		const {
			step,
		} = this.state;

		const btns = [
			'Import',
			'Setup',
			'Assign',
			'Group',
			'Systems',
		];

		return (
			<div className={style.headerBtnContainer}>
				{btns.map((btnText, i) => {
					let active = '';
					if (step === i + 1) {
						active = style.headerBtnActive;
					}

					return (
						<div
							key={i}
							className={`${style.headerBtn} ${active}`}
							onClick={(e) => {
								e.preventDefault();
								this.changeStep(i + 1);
							}}
						>
							<span className={style.headerBtnNum}>{i + 1} </span>{btnText}
						</div>
					);
				})}
			</div>
		);
	}

	renderBody() {
		const {
			step,
		} = this.state;

		const {
			value,
			app,
			accountUUID,
			fd,
			settingMap,
			pendingChanges,
		} = this.props;

		switch (step) {
		case 1:
		case 2:
		case 3:
			return (
				<TemplateBuilder
					app={app}
					settingMap={settingMap}
					fd={fd}
					listingConfigMap={this.props.listingConfigMap}
					accountUUID={accountUUID}
					assignmentMap={this.state.assignmentMap}
					globalTemplateData={this.state.globalTemplateData}
					openModal={this.openModal.bind(this)}
					pendingUpdates={this.state.pendingUpdates}
					stateCallback={this.stateCallback.bind(this)}
					makeMaps={this.makeMaps.bind(this)}
					step={this.state.step}
					value={value}
					setSkeletonHasUpdated={this.setSkeletonHasUpdated.bind(this)}
				/>
			);
		case 4:
			return (
				<ContentGroups
					app={app}
					fd={fd}
					pendingChanges={pendingChanges}
					settingMap={settingMap}
					systemProgramming={this}

					listingConfigMap={this.props.listingConfigMap}
					accountUUID={accountUUID}
					buildSystemMap={this.makeMaps.bind(this)}
					systemProgrammingPendingUpdates={this.state.pendingUpdates}
					stateCallback={this.stateCallback.bind(this)}
					step={step}
					value={value}
					shouldRebuildBundleMap={this.state.shouldRebuildBundleMap}
					turnOffRebuildBundleMap={this.turnOffRebuildBundleMap.bind(this)}
					turnOnRebuildBundleMap={this.turnOnRebuildBundleMap.bind(this)}
					refreshAccount={this.refreshAccount.bind(this)}
				/>
			);
		case 5:
			return this.renderStepFive();
		default:
			return;
		}
	}

	renderSystemProgramming() {
		const {
			pendingUpdates,
			step,
			assignmentMap,
		} = this.state;

		let title = '';
		switch (step) {
		case 1:
			title = 'IMPORT';
			break;
		case 2:
			title = 'CONFIGURATION';
			break;
		case 3:
			title = 'SYSTEM PROGRAMMING';
			break;
		case 4:
			title = 'CONTENT GROUPS';
			break;
		case 5:
			title = 'SYSTEM COMPONENTS';
			break;
		default:
			return;
		}

		let disabled = true;
		let saveButtonClassName = 'button button-save button-disabled';
		if (Object.keys(pendingUpdates).length > 0 || window.form.hasNewVals()) {
			disabled = false;
			saveButtonClassName = 'button button-save';
		}

		// for step 3
		if (Object.keys(assignmentMap).length > 0) {
			disabled = false;
			saveButtonClassName = 'button button-save';
		}

		return (
			<div className='systems-page'>
				<div className={style.title}>
					<h2>{title}</h2>
					<button
						className={saveButtonClassName}
						disabled={disabled}
						onClick={(e) => {
							e.preventDefault();
							if (!this.state.blockUpdatesEmptyColumn && !this.state.blockUpdatesDataConflict) {
								this.handleSubmit();
								this.setSkeletonHasUpdated();
							} else {
								alert((this.state.blockUpdatesEmptyColumn ? '\nThere is an empty column!' : '') +
									(this.state.blockUpdatesDataConflict ? '\nThere are columns with the same data selected!' : ''));
							}
						}}
					>
						<CustomIcon icon='save' />Save
					</button>
				</div>

				{this.renderHeaderButtons()}

				{this.renderBody()}
			</div>
		);
	}

	render() {
		return (
			<React.Fragment>
				{this.renderSystemProgramming()}
			</React.Fragment>
		);
	}
}

function PendingChangesModal(props) {
	return (
		<React.Fragment>
			<div className={style.buttonContainer}>
				<button
					onClick={(e) => {
						e.preventDefault();
						props.closeModal();
					}}
					className='button button-outlined'
				><CustomIcon icon='undo' />Go Back</button>

				<button
					onClick={(e) => {
						e.preventDefault();
						props.handleDontSave(props.stateCallback, props.stateKey, props.stateVal);
						props.closeModal();
					}}
					className='button'
				><CustomIcon icon='times' />{`Don't Save`}</button>

				<button
					onClick={(e) => {
						e.preventDefault();
						props.handleSubmit(props.stateCallback, props.stateKey, props.stateVal);

						// Step 4 has a custom save modal. Don't close the modal.
						if (props.currentStep !== 4) {
							props.closeModal();
						}
					}}
					className='button'
				><CustomIcon icon='save' />Save</button>
			</div>
		</React.Fragment>
	);
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['custom.vulture.system.programming'] = SystemProgramming;
export default SystemProgramming;