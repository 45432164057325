import React from 'react';
import style from './ListingConfig.module.css';
import CustomIcon from '../Common/CustomIcon/CustomIcon.js';
import TextSmall from 'Vulture/Field/TextSmall.js';
import Select from 'react-select';

import {
	v4 as uuidv4,
} from 'uuid';

import {
	buildTestData,
	removeWhitespace,
} from 'Vulture/TestData.js';

import {
	getSession,
} from 'Vulture/Session.js';

import ListingIntegrationModal from './ListingIntegrationModal.js';

import {
	providerListToModalContent,
} from './listingIntegrationModels.js';

import {
	crawlListingIntegration,
} from 'Vulture/Listing.js';

const reallocateWidths = (originalColumns, hasParent) => {
	const reallocatedColumns = [ ...originalColumns, ];
	let denominator = reallocatedColumns.length;
	if (hasParent) {
		denominator++;
	}

	if (originalColumns.length === 0) {
		return reallocatedColumns;
	}

	if (denominator <= 2) {
		for (let i = 0; i < reallocatedColumns.length; i++) {
			reallocatedColumns[i].width = Math.floor(100 / denominator);
		}
	} else {
		reallocatedColumns[0].width = 40;
		denominator--;

		for (let i = 1; i < reallocatedColumns.length; i++) {
			reallocatedColumns[i].width = Math.floor(60 / denominator);
		}
	}

	return reallocatedColumns;
};

const getM1Relation = (fd, parentM1Key, m2) => {
	for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
		if (m1Key === parentM1Key) {
			for (let [ m2Key, m2Data, ] of Object.entries(m1Data)) {
				if (m2Key.startsWith(`${m2}.`) && m2Data) {
					// skip pending rel deletions

					return {
						m1Data: fd[m2Key],
						m2UUID: m2Key.split('.')[1],
						m2Data,
					};
				}
			}
		}
	}

	return {
		m1Data: null,
		m2UUID: null,
		m2Data: null,
	};
};

export const ListingConfigTier = (props) => {
	const {
		findChildEntitiesForRender,
		app,
		fd,
		accountUUID,
		settingMap,
		openNewListingModal,
		listingConfigUUID,
		listingConfigMap,
		listNumber,
		listingColumnTypeOptions,
		pendingChanges,
		parentM1,
		parentM1UUID,
		tier = 1,
	} = props;

	const providerUUID = settingMap['listing_integration_provider'].uuid;
	const providerListUUID = settingMap['listing_integration_list'].uuid;
	const isIntegration = !!fd[`listing_config.${listingConfigUUID}`]?.[`setting.${providerUUID}`];

	const provider = fd?.[`listing_config.${listingConfigUUID}`]?.[`setting.${providerUUID}`]?.string;
	const providerList = fd?.[`listing_config.${listingConfigUUID}`]?.[`setting.${providerListUUID}`]?.string;

	const listingConfigName = fd[`listing_config.${listingConfigUUID}`].display_name;

	const listingIntegrationUsernameSettingUUID = settingMap['listing_integration_username'].uuid;
	const listingIntegrationPasswordSettingUUID = settingMap['listing_integration_password'].uuid;

	const hasSetCredentials = (
		!!fd[`listing_config.${listingConfigUUID}`]?.[`setting.${listingIntegrationUsernameSettingUUID}`]?.string ||
		!!fd[`listing_config.${listingConfigUUID}`]?.[`setting.${listingIntegrationPasswordSettingUUID}`]?.string
	);

	let listingColumns = [];
	for (const [ m1Key, m1Data, ] of Object.entries(fd[`listing_config.${listingConfigUUID}`])) {
		// skip pending relation deletions
		if (m1Key.startsWith('listing_column.') && m1Data) {
			listingColumns.push({
				uuid: m1Key.split('.')[1],
				...m1Data,
				...fd[m1Key],
			});
		}
	}

	listingColumns.sort((a, b) => {
		return a.order > b.order ? 1 : -1;
	});

	let blockTierDelete = false;

	// Find the existing listing_columns
	let listingColumnCount = listingColumns.length;
	let columnElms = [];

	const listingConfigData = listingConfigMap[listingConfigUUID];
	const hasParent = !!listingConfigMap[listingConfigData.parentUUID];

	listingColumns.forEach((listingColumn, i) => {
		let listingColumnUUID = listingColumn.uuid;

		let {
			m2UUID: listingColumnTypeUUID,
		} = getM1Relation(fd, `listing_column.${listingColumnUUID}`, 'listing_column_type');
		if (!listingColumnTypeUUID) {
			return '';
		}

		// Find the value for react-select.
		let listingColumnTypeValue = null;
		for (let option of listingColumnTypeOptions) {
			if (option.value === listingColumnTypeUUID) {
				listingColumnTypeValue = option;
			}
		}

		let deleteColumnButton = (
			<div
				className={style.deleteIcon}
				onClick={(e) => {
					e.preventDefault();
					console.log('DELETE');
					const widthColumns = [];

					Object.entries(fd[`listing_config.${listingConfigUUID}`])
						.forEach(([ m1Key, m1Val, ]) => {
							if (m1Key.startsWith('listing_column.') &&
								m1Key !== `listing_column.${listingColumnUUID}` &&
								m1Val.width !== 0
							) {
								widthColumns.push({
									column: `listing_config.${listingConfigUUID}.${m1Key}`,
									order:  m1Val.order,
									width:  m1Val.width,
								});
							}
						});

					const reallocatedWidths = reallocateWidths(widthColumns, hasParent);
					const allNewKeyVals = reallocatedWidths.reduce((accum, curr) => {
						accum[`${curr.column}.width`] = curr.width;
						accum[`${curr.column}.order`] = curr.order;
						return accum;
					}, {});

					app.delValue('account_settings', [
						`listing_column.${listingColumnUUID}`,
						`listing_config.${listingConfigUUID}.listing_column.${listingColumnUUID}`,
					], () => {
						app.setValue('account_settings', Object.keys(allNewKeyVals), Object.values(allNewKeyVals));
					});

				}}
			>
				<CustomIcon icon='minus-circle' />
			</div>
		);

		let isTypeLocked = false;

		// Block listing_column deletion if the listing_column is owned by a listing or a listing_column_group.
		// Lock the listing_column type if the listing_column is owned by a listing or a listing_column_group.
		let isChild = false;
		for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (isChild) {
				break;
			}

			if (m1Key.startsWith('listing.') || m1Key.startsWith('listing_column_group.')) {
				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key === `listing_column.${listingColumnUUID}`) {
						isChild = true;
						break;
					}
				}
			}
		}

		if (isChild) {
			deleteColumnButton = null;
			isTypeLocked = true;
			blockTierDelete = true;
		}

		const styles = {
			control: (base) => {
				return {
					...base,
					maxHeight: 30,
				};
			},
			dropdownIndicator: (base) => {
				return {
					...base,
					paddingTop:    0,
					paddingBottom: 0,
				};
			},
			clearIndicator: (base) => {
				return {
					...base,
					paddingTop:    0,
					paddingBottom: 0,
				};
			},
			menu: (base) => {
				return {
					...base,
					top:      20,
					position: 'absolute',
				};
			},
		};

		let disableInputs = false;

		if (isIntegration) {
			disableInputs = true;
			deleteColumnButton = null;
		}

		const listingColumnName = fd[`listing_column.${listingColumnUUID}`]?.display_name;

		let dataField = (
			<TextSmall
				value={listingColumnName}
				setValue={app.setValue.bind(app, 'account_settings', `listing_column.${listingColumnUUID}.display_name`)}
				dataTestingInfo={buildTestData(`input--Data${i + 1}`)}
			/>
		);

		const listingColumnTypeLabel = listingColumnTypeValue?.label || '';

		if (listingColumnTypeLabel === 'Array') {
			let options = [
				{
					label: '',
					value: '',
				},
			];

			const arrayOptions = fd[`account.${accountUUID}`][`setting.${settingMap['array_options'].uuid}`];

			if (arrayOptions) {
				options = Object.keys(JSON.parse(arrayOptions.json)).map((option) => {
					return {
						label: option,
						value: option,
					};
				});
			}

			dataField = (
				<Select
					className={style.listingConfigSelectLabel}
					options={options}
					styles={styles}
					onChange={(e) => {
						app.setValue('account_settings', `listing_column.${listingColumnUUID}.display_name`, e.value);
					}}
					value={options.find((option) => {
						return option.value === listingColumnName;
					})}
				/>
			);
		}

		if (listingColumnTypeLabel === 'SMS Message' || listingColumnTypeLabel === 'Email Message') {
			let options = [
				{
					label: '',
					value: '',
				},
			];

			const messagingOptions = fd[`account.${accountUUID}`][`setting.${settingMap['messaging_options'].uuid}`];

			if (messagingOptions) {
				const parsedData = JSON.parse(messagingOptions.json);
				const messaging = parsedData.messaging;
				options = messaging.options.map((option) => {
					return {
						label: option.label,
						value: option.label,
					};
				});
			}

			dataField = (
				<Select
					className={style.listingConfigSelectLabel}
					options={options}
					styles={styles}
					onChange={(e) => {
						app.setValue('account_settings', `listing_column.${listingColumnUUID}.display_name`, e.value);
					}}
					value={options.find((option) => {
						return option.value === listingColumnName;
					})}
				/>
			);
		}

		const nameLocked = !!listingColumnName && !deleteColumnButton;

		columnElms.push(
			<div
				className={style.listingConfigDataRow}
				key={listingColumnUUID}
			>
				<div className={style.listingConfigDataRowInput}>
					<div>Data {i + 1}</div>
					{dataField}
				</div>

				<div className={style.listingConfigDataRowInput}>
					{<div>Type:</div>}
					<div className={style.listingConfigSubcontainer}>
						<Select
							className={style.listingConfigSelectLabel}
							defaultValue={listingColumnTypeOptions[0]}
							isDisabled={nameLocked || isTypeLocked || disableInputs}
							options={listingColumnTypeOptions}
							styles={styles}
							onChange={(e) => {
								const newListingColumnTypeUUID = e.value;
								const newKeyVals = {
									[`listing_column.${listingColumnUUID}.listing_column_type.${newListingColumnTypeUUID}`]: true,
								};

								if (listingColumnTypeUUID !== newListingColumnTypeUUID) {
									newKeyVals[`listing_column.${listingColumnUUID}.listing_column_type.${listingColumnTypeUUID}`] = false;
								}

								app.setValue('account_settings', Object.keys(newKeyVals), Object.values(newKeyVals));
							}}
							value={listingColumnTypeValue}
						/>
						{deleteColumnButton}
					</div>
				</div>
			</div>
		);
	});

	const childEntityList = findChildEntitiesForRender('listing_config', listingConfigUUID);

	// If any columns are in use, block deletion of the tier.
	const addColumn = () => {
		let order = listingColumnCount + 1;

		const newListingColumnUUID = `_${uuidv4()}`;

		const widthColumns = [];

		Object.entries(fd).forEach(([ m1Key, m1Val, ]) => {
			if (m1Key === `listing_config.${listingConfigUUID}`) {
				Object.entries(m1Val).forEach(([ m2Key, m2Val, ]) => {
					if (m2Key.startsWith('listing_column.') &&
						m2Val.width !== 0
					) {
						widthColumns.push({
							column: `${m1Key}.${m2Key}`,
							order:  m2Val.order,
							width:  m2Val.width,
						});
						if (m2Val.order > order) {
							order = m2Val.order + 1;
						}
					}
				});
			}
		});

		// Set the width to 1 not to default it to unselected
		widthColumns.push({
			column: `listing_config.${listingConfigUUID}.listing_column.${newListingColumnUUID}`,
			width:  1,
			order,
		});

		const newWidthVals = widthColumns
			.filter((listingColumn) => {
				if (listingColumn.column === `listing_config.${listingConfigUUID}.listing_column.${newListingColumnUUID}`) {
					return true;
				}

				return `listing_column.${listingColumn.column.split('.')[3]}` in fd[`listing_config.${listingConfigUUID}`];
			})
			.sort((a, b) => {
				if (a.order > b.order) {
					return 1;
				}

				return -1;
			});

		const reallocatedWidths = reallocateWidths(newWidthVals, hasParent);

		const newKeyVals = {
			// Add listing_config_to_listing_column
			[`listing_config.${listingConfigUUID}.listing_column.${newListingColumnUUID}`]:       true,
			[`listing_config.${listingConfigUUID}.listing_column.${newListingColumnUUID}.order`]: order,

			// Set a default for listing_column_to_listing_column_type
			[`listing_column.${newListingColumnUUID}.listing_column_type.${listingColumnTypeOptions[0].value}`]: {},
			[`listing_column.${newListingColumnUUID}.display_name`]:                                             '',
		};


		const allNewKeyVals = reallocatedWidths.reduce((accum, curr) => {
			accum[`${curr.column}.width`] = curr.width;
			accum[`${curr.column}.order`] = curr.order;
			return accum;
		}, {
			...newKeyVals,
		});

		app.setValue('account_settings', Object.keys(allNewKeyVals), Object.values(allNewKeyVals));
	};

	const buttonElms = [];

	if (!isIntegration) {
		buttonElms.push(
			<button
				className={`button ${style.button}`}
				key={'data-btn'}
				onClick={(e) => {
					e.preventDefault();
					addColumn();
				}}
				data-testing-info={buildTestData('button--data')}
			>
				<CustomIcon icon='plus'/> Data
			</button>
		);
	}

	// Only display the add tier button if we're at the lowest tier.
	if (childEntityList.length === 0) {
		buttonElms.push(
			<button
				className={`button ${style.button}`}
				key='add-tier-btn'
				onClick={(e) => {
					e.preventDefault();
					openNewListingModal('listing_config', listingConfigUUID);
				}}
				data-testing-info={buildTestData('button--tier')}
			>
				<CustomIcon icon='plus' /> Tier
			</button>
		);
	}

	if (isIntegration) {
		const isIntegrationSaved = !pendingChanges[`${parentM1}.${parentM1UUID}.listing_config.${listingConfigUUID}`];

		buttonElms.push(
			<button
				className={`button ${style.button} ${!isIntegrationSaved && 'button-disabled'}`}
				key='set-credentials-btn'
				onClick={(e) => {
					e.preventDefault();
					const providerLabel = providerListToModalContent[provider].label;
					const providerListCapitalized = providerList[0].toUpperCase() + providerList.slice(1);

					app.openModal({
						modalSize:  1,
						showModal:  true,
						modalProps: {
							title: `Log in ${providerLabel}: ${providerListCapitalized}`,
							jsx:   (
								<ListingIntegrationModal
									app={app}
									accountUUID={accountUUID}
									hasSetCredentials={hasSetCredentials}
									listingConfigUUID={listingConfigUUID}
									provider={provider}
									providerList={providerList}
									closeModal={() => {
										app.closeModal();
									}}
								/>
							),
						},
					});
				}}
				data-testing-info={buildTestData('set-credentials-button--tier')}
				disabled={!isIntegrationSaved}
			>
				Set credentials
			</button>
		);

		const pg = getSession().pg;
		if (pg === 'ia') {
			buttonElms.push(
				<button
					className={`button ${style.button} ${!hasSetCredentials && 'button-disabled'}`}
					key='crawl-btn'
					onClick={async (e) => {
						e.preventDefault();

						app.setState({
							loading: true,
						});

						try {
							await crawlListingIntegration(accountUUID, listingConfigUUID, provider);

							alert('Crawl started successfully.');
						} catch (error) {
							alert(`Error starting crawler.\nPlease try again later or contact support.`);
							console.error(error);
						} finally {
							app.setState({
								loading: false,
							});
						}
					}}
					data-testing-info={buildTestData('crawl-button--tier')}
					disabled={!hasSetCredentials}
				>
					Crawl
				</button>
			);
		}
	}

	const isSchedulingChecked = !!fd[`listing_config.${listingConfigUUID}`]?.[`setting.${settingMap?.['listing_scheduling_toggle']?.uuid}`]?.bool;

	let schedulingPiece = null;
	if (!isIntegration) {
		schedulingPiece = (
			<div className={style.listingConfigDataRowInput}>
				<div className={style.schedulingHeader} >Scheduling</div>
				<div className={style.checkbox}>
					<div
						className={isSchedulingChecked ? style.activeCheckbox : style.disabledSquare}
						onClick={(e) => {
							e.preventDefault();
							const nextVal = !isSchedulingChecked;
							const keys = [
								`listing_config.${listingConfigUUID}.setting.${settingMap?.['listing_scheduling_toggle']?.uuid}`,
								`listing_config.${listingConfigUUID}.setting.${settingMap?.['listing_scheduling_toggle']?.uuid}.bool`,
							];

							const vals = [
								true,
								nextVal,
							];
							app.setValue('account_settings', keys, vals);
						}}>
						<div className={style.checkboxIcon}>
							{isSchedulingChecked ?
								<CustomIcon icon="check"/> :
								<CustomIcon/>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}

	let deleteTierButton = null;

	if (!blockTierDelete) {
		deleteTierButton = (
			<div
				className={style.deleteIcon}
				key='delete-btn'
				onClick={(e) => {
					e.preventDefault();

					// TODO: w/delete_all route?

					const confirmation = confirm(`Are you sure you want to delete "${providerList}" from "${provider}" (UUID ${listingConfigUUID})?`);

					if (!confirmation) {
						return;
					}

					const keys = [];
					const vals = [];

					for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
						for (const m2Key of Object.keys(m1Data)) {
							const entityKey = `${m1Key}.${m2Key}`;
							// Find the parent relation and delete it.
							if (m2Key === `listing_config.${listingConfigUUID}`) {
								keys.push(entityKey);
								vals.push(false);
							}
							// Delete any child listing_configs, listing_columns, and listing_column_types.
							if (m1Key === `listing_config.${listingConfigUUID}`) {
								if (m2Key.startsWith('listing_config') || m2Key.startsWith('listing_column') || m2Key.startsWith('listing_column_type')) {
									keys.push(entityKey);
									vals.push(false);
								}
							}
						}
					}

					app.setValue('listings', keys, vals);
					app.deleteListingConfig(listingConfigUUID);
				}}
			>
				<CustomIcon icon='minus-circle' />
			</div>
		);
	}

	return (
		<>
			<div
				className={style.listingConfigContainer}
				key={listingConfigUUID}
				data-testing-info={buildTestData(`list--list${listNumber}--tier${tier}`)}
			>
				<div className={style.listingConfigLabel}>
					<h1>List {listNumber}: <span>Tier {tier}</span> {deleteTierButton}</h1>
				</div>
				<div className={style.listingConfigCode}>
					{listingConfigUUID}
				</div>

				<div className={style.listingConfigData}>
					<div className={style.listingConfigDataRow}>
						<div className={style.listingConfigDataRowInput}>
							<div>List Name</div>
							<div
								className={style.crowForm}
							>
								<TextSmall
									value={listingConfigName}
									setValue={app.setValue.bind(app, 'account_settings', `listing_config.${listingConfigUUID}.display_name`)}
									dataTestingInfo={buildTestData(`input--${removeWhitespace(listingConfigName)}`)}
								/>
							</div>
						</div>
						{schedulingPiece}
					</div>

					{columnElms}
					<div className={style.buttonContainer}>
						{buttonElms}
					</div>
				</div>
			</div>

			{childEntityList
				.map((childListingConfig) => {
					return (
						<ListingConfigTier
							{...props}
							parentM1='listing_config'
							parentM1UUID={listingConfigUUID}
							key={childListingConfig.uuid}
							listingConfigUUID={childListingConfig.uuid}
							listingConfigMap={listingConfigMap}
							tier={tier + 1}
						/>
					);
				})}
		</>
	);
};