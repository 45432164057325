import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import CustomCheckbox from './Elements/Checkbox';
import Select from 'react-select';

import style from './Users.module.css';
import {
	getSession,
} from 'Vulture/Session';
import {
	buildTestData,
	removeWhitespace,
} from 'Vulture/TestData';
import {
	registerUser,
	rootUserDelete,
	rootUserWrite,
	sendForgotPasswordEmail,
} from 'Vulture/Users';

import API from 'Vulture/lib/API.js';

// this will use /r/list_auth/permission_group once DEV-5285 is complete
const internalUserUUID = 'cacbcbc2-e8ea-40ac-98e4-7c0f30b3f083';
const internalAdminUUID = 'bccf8794-d545-4bb4-8343-1929cc85b7f5';
const externalAdminUUID = 'd97ec83e-d489-441e-ad83-c360382f10a6';
const propertyManagerUUID = '14c4898e-7489-4893-a50c-a4a3fdbc8c7a';

class UserModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			confirmDelete: false,
			noAccounts:    false,
			newData:       {},
			orgData:       {
				...props.row.original,
			},
			associatedComponents:   [],
			unassociatedComponents: [],
			searchValue:            '',
		};
	}

	componentDidMount() {
		const {
			isAccountUsers,
			systemGroupInfo,
			row,
		} = this.props;
		// Reformat react-select data
		let accounts = this.props.row.original.accounts;
		let arr = [];
		if (accounts && Array.isArray(accounts)) {
			accounts.forEach((account) => {
				arr.push(account.value);
			});
		}

		let associatedComponents = [];
		let unassociatedComponents = [];

		if (isAccountUsers) {
			systemGroupInfo.forEach((systemGroup) => {
				if (row.original?.system_groups?.includes(systemGroup.uuid)) {
					if (!associatedComponents.includes(systemGroup.uuid)) {
						associatedComponents.push(systemGroup.uuid);
					}
				} else if (!unassociatedComponents.includes(systemGroup.uuid)) {
					unassociatedComponents.push(systemGroup.uuid);
				}
			});
		}

		this.setState({
			orgData: {
				...this.state.orgData,
				accounts: arr,
			},
			associatedComponents,
			unassociatedComponents,
		});
	}


	assignContent(allOrNone) {
		let associatedComponents = [];
		let unassociatedComponents = [];
		let currentAssociatedComponents = this.state.associatedComponents;
		let currentUnassociatedComponents = this.state.unassociatedComponents;

		// assign all
		if (allOrNone) {
			associatedComponents = currentAssociatedComponents;
			currentUnassociatedComponents.forEach((uuid) => {
				associatedComponents.push(uuid);
			});
		}
		// unassign all
		if (!allOrNone) {
			unassociatedComponents = currentUnassociatedComponents;
			currentAssociatedComponents.forEach((uuid) => {
				unassociatedComponents.push(uuid);
			});
		}

		this.setState({
			associatedComponents,
			unassociatedComponents,
		});
	}

	getSystemGroupInfo() {
		const {
			isAccountUsers,
			systemGroupInfo: systemGroupInfos,
		} = this.props;

		if (!isAccountUsers) {
			return '';
		}

		let elms = [];

		systemGroupInfos.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});

		systemGroupInfos.forEach((systemGroupInfo, i) => {
			let uuid = systemGroupInfo.uuid;
			let checked = this.state.associatedComponents.includes(uuid);
			let associatedComponents = this.state.associatedComponents;
			let unassociatedComponents = this.state.unassociatedComponents;
			let searchText = this.state.searchValue.toLowerCase();

			if (systemGroupInfo.name.length !== 0 && !systemGroupInfo.name.toLowerCase().includes(searchText)) {
				return;
			}

			elms.push(
				<li data-selector="content-item" className={style.contentItem}
					// className={childClassName}
					key={`system-group-${i}`}
					onClick={(e) => {
						e.preventDefault();
						if (!checked) {
							associatedComponents.push(uuid);
							if (unassociatedComponents.includes(uuid)) {
								let indexToRemove = unassociatedComponents.indexOf(uuid);
								unassociatedComponents.splice(indexToRemove, 1);
							}
						}
						if (checked) {
							unassociatedComponents.push(uuid);
							if (associatedComponents.includes(uuid)) {
								let indexToRemove = associatedComponents.indexOf(uuid);
								associatedComponents.splice(indexToRemove, 1);
							}
						}

						this.setState({
							associatedComponents,
							unassociatedComponents,
						});
					}}
					data-testing-info={buildTestData(`listItem--${removeWhitespace(systemGroupInfo.name)}`)}
				>
					<div className={style.nameContainer}>
						<CustomCheckbox className={style.customCheck}
							checked={checked}
						/>
						<div data-selector="content-name" className={style.contentBucket}>{systemGroupInfo.name}</div>
					</div>
				</li>
			);
		});

		return (
			<>
				<div className={style.halfcolumn}>
					<div className={style.column3}>
						<div className={style.topContainer}>
							<div className={style.sectionLabel}>Properties</div>
							<div className={style.selectButton}
								onClick={(e) => {
									e.preventDefault();
									this.assignContent(true);
								}}
								data-testing-info={buildTestData('button--all')}
							>
								All
							</div>
							<div className={style.selectButton}
								onClick={(e) => {
									e.preventDefault();
									this.assignContent(false);
								}}
								data-testing-info={buildTestData('button--none')}
							>
								None
							</div>
						</div>
						<input className={style.contentFilter} type="text" data-selector="filter" value={this.state.searchValue} onChange={(e) => {
							return this.setState({
								searchValue: e.target.value,
							});
						}} placeholder="Search list.." />

						<ul data-selector="content-list" className={style.contentList}>
							{elms}
						</ul>
					</div>
				</div>
			</>
		);
	}

	getAccountUserInfo() {
		const {
			row,
		} = this.props;

		const {
			newData,
		} = this.state;

		return (
			<>
				<div className={`text-small ${style.field}`}>
					<div className={style.label}>First Name</div>
					<input
						data-test-id='firstNameInput'
						type='text'
						value={ typeof newData.first_name === 'string' ? newData.first_name : row.original.first_name}
						placeholder="First Name"
						onChange={(e) => {
							this.compareValue('first_name', e.target.value);
						}}
					/>
				</div>
				<div className={`text-small ${style.field}`}>
					<div className={style.label}>Last Name</div>
					<input
						data-test-id='lastNameInput'
						type='text'
						value={typeof newData.last_name === 'string' ? newData.last_name : row.original.last_name}
						placeholder="Last Name"
						onChange={(e) => {
							this.compareValue('last_name', e.target.value);
						}}
					/>
				</div>
				<div className={`text-small ${style.field}`}>
					<div className={style.label}>Title</div>
					<input
						data-test-id='titleInput'
						type='text'
						value={typeof newData.title === 'string' ? newData.title : row.original.title}
						placeholder="Title"
						onChange={(e) => {
							this.compareValue('title', e.target.value);
						}}
					/>
				</div>
			</>
		);
	}

	compareValue(key, val) {
		const {
			isAccountUsers,
			fd,
			accountUUID,
		} = this.props;

		const {
			newData,
			orgData,
		} = this.state;

		if (key === 'accounts') {
			let accountObj = {};

			// If there were already accounts associated to the root user
			if (Array.isArray(orgData['accounts'])) {
				// If all accounts have been removed∂
				if (val === null || val.length === 0) {
					orgData['accounts'].forEach((uuid) => {
						accountObj = {
							...accountObj,
							[uuid]: false,
						};
					});

					this.setState({
						noAccounts: true,
						newData:    {
							...newData,
							accounts: accountObj,
						},
					});

					return;
				}

				let has2FA = newData.has_2fa;

				let newAccountList = [];
				val.forEach((account) => {
					newAccountList.push(account.value);
					if (account.require_2fa) {
						has2FA = true;
					}
				});
				newAccountList.forEach((uuid) => {
					// If the account was not in orgVaules, add it to the user
					if (!~orgData.accounts.indexOf(uuid)) {
						accountObj = {
							...accountObj,
							[uuid]: true,
						};
					}
				});

				orgData['accounts'].forEach((uuid) => {
					// If an account was in orgValues, but not in our newAccountList, remove it from the user
					if (!~newAccountList.indexOf(uuid)) {
						accountObj = {
							...accountObj,
							[uuid]: false,
						};
					}
				});

				this.setState({
					noAccounts: false,
					newData:    {
						...newData,
						accounts: accountObj,
						has_2fa:  has2FA,
					},
				});

				return;
			}

			// Return if there are no accounts to assign to a user without previous accounts
			if (val === null) {
				return;
			}

			// Add all accounts to user if the user had no previous accounts
			val.forEach((account) => {
				accountObj = {
					...accountObj,
					[account.value]: true,
				};
			});

			this.setState({
				newData: {
					...newData,
					accounts: accountObj,
				},
			});

			return;
		}

		if (key === 'active') {
			if (val === false) {
				this.setState({
					newData: {
						...newData,
						active:        false,
						has_2fa:       false,
						has_2fa_setup: false,
					},
				});
			} else {
				this.setState({
					newData: {
						...newData,
						active: true,
					},
				});
			}

			return;
		}

		if (key === 'permission_group_uuid') {
			if (val === internalUserUUID) {
				this.setState({
					newData: {
						...newData,
						permission_group_uuid: val,
						has_2fa:               true,
					},
				});
			} else {
				this.setState({
					newData: {
						...newData,
						permission_group_uuid: val,
					},
				});
			}

			return;
		}

		if (key === 'has_2fa') {
			let requires2FA = false;

			const permissionGroup = newData.permission_group_uuid || orgData.permission_group_uuid;
			const isExternal = (permissionGroup === propertyManagerUUID || permissionGroup === externalAdminUUID);

			if (isAccountUsers) {
				requires2FA = fd[`account.${accountUUID}`].require_2fa;
			} else {
				const {
					accounts,
				} = this.props;

				requires2FA = accounts.find((account) => {
					if (!account.require_2fa) {
						return false;
					}

					if (Array.isArray(newData.accounts)) {
						return newData.accounts.includes(account.value);
					} else if (newData.accounts) {
						return newData.accounts[account.value];
					}

					if (Array.isArray(orgData.accounts)) {
						return orgData.accounts.includes(account.value);
					} else if (orgData.accounts) {
						return orgData.accounts[account.value];
					}

					return false;
				});
			}

			if (isExternal && requires2FA) {
				this.setState({
					newData: {
						...newData,
						has_2fa: true,
					},
				});
			} else if (permissionGroup === internalUserUUID) {
				this.setState({
					newData: {
						...newData,
						has_2fa: true,
					},
				});
			} else {
				this.setState({
					newData: {
						...newData,
						has_2fa:       val,
						has_2fa_setup: false,
					},
				});
			}

			return;
		}

		if (orgData[key] === val) {
			Reflect.deleteProperty(newData, key);
			this.setState({
				newData,
			});
			return;
		}

		this.setState({
			newData: {
				...newData,
				[key]: val,
			},
		});
	}

	getFieldValue(fieldTitle) {
		const {
			newData,
			orgData,
		} = this.state;

		if (newData[fieldTitle]) {
			return newData[fieldTitle];
		}
		if (newData[fieldTitle] === '') {
			return '';
		}
		if (orgData[fieldTitle]) {
			return orgData[fieldTitle];
		}
		if (orgData[fieldTitle] === '') {
			return '';
		}
		return '';
	}

	async submitForm() {
		const {
			app,
		} = this.props;

		const {
			newData,
			orgData,
		} = this.state;

		let accountsBeingRemoved = 0;
		let accountsBeingAdded = 0;

		if (newData.permission_group_uuid === propertyManagerUUID) {
			if (orgData.accounts.length === 0 && !newData.accounts) {
				alert('Must assign system');
				return;
			}
			// if the edited user is a PM and on more then one account.
			if (orgData.accounts.length > 1) {
				alert('Property Managers should only belong to one account');
				return;
			}
			if (newData.accounts) {
				Object.entries(newData.accounts).map((account) => {
					if (account[1] === false) {
						accountsBeingRemoved++;
					}
				});
			}
			if (accountsBeingRemoved === orgData.accounts.length && accountsBeingRemoved !== 0) {
				alert('Must assign system');
				return;
			}
		} else if (orgData.permission_group_uuid === propertyManagerUUID && !newData.permission_group_uuid) {
			if (newData.accounts) {
				Object.entries(newData.accounts).map((account) => {
					if (account[1] === false) {
						accountsBeingRemoved++;
					}
					if (account[1] === true) {
						accountsBeingAdded++;
					}
				});
			}

			if (accountsBeingAdded > 0 && (orgData.accounts.length === accountsBeingRemoved + 1)) {
				alert('Property Managers should only belong to one account');
				return;
			}

			if (accountsBeingRemoved > accountsBeingAdded && accountsBeingRemoved === orgData.accounts.length && accountsBeingRemoved !== 0) {
				alert('Must assign system');
				return;
			}
		}

		if (!this.props.isAccountUsers && Object.keys(newData).length === 0) {
			return;
		}

		const {
			uuid: userUUID,
		} = this.props.row.original;

		try {
			app.setState({
				loading: true,
			});

			if (newData.permission_group_uuid && orgData.permission_group_uuid !== newData.permission_group_uuid) {
				try {
					const data = await API.deleteMapRecord('user', userUUID, 'permission_group', orgData.permission_group_uuid);

					console.log(data);
				} catch (err) {
					console.error(err);
					alert('Error removing user permission_group.');
					return;
				}

				try {
					const data = await API.createMapRecord('user', userUUID, 'permission_group', {
						permission_group_uuid: newData.permission_group_uuid,
					});

					console.log(data);
				} catch (err) {
					console.error(err);
					alert('Error updating user permission_group.');
					return;
				}
			}

			let orgSystemGroups = orgData.system_groups || [];
			let newSystemGroups = this.state.associatedComponents || [];

			let associationsToAdd = newSystemGroups.filter((uuid) => {
				return !orgSystemGroups.includes(uuid);
			});
			if (associationsToAdd.length > 0) {
				for (const systemGroupUUID of associationsToAdd) {
					try {
						const data = await API.createMapRecord('user', userUUID, 'system_group', {
							system_group_uuid: systemGroupUUID,
						});

						console.log(data);
					} catch (err) {
						console.error(err);
						alert('Error adding user system_group.');
						return;
					}
				}
			}

			let associationsToRemove = orgSystemGroups.filter((uuid) => {
				return !newSystemGroups.includes(uuid);
			});
			if (associationsToRemove.length > 0) {
				for (const systemGroupUUID of associationsToRemove) {
					try {
						const data = await API.deleteMapRecord('user', userUUID, 'system_group', systemGroupUUID);

						console.log(data);
					} catch (err) {
						console.error(err);
						alert('Error removing user system_group.');
						return;
					}
				}
			}

			if (newData.accounts) {
				let accountsToAdd = Object.entries(newData.accounts).filter((account) => {
					return account[1] === true && !orgData.accounts.includes(account[0]);
				});

				for (const [ accountUUID, ] of accountsToAdd) {
					try {
						const data = await API.createMapRecord('user', userUUID, 'account', {
							account_uuid: accountUUID,
						});

						console.log(data);
					} catch (err) {
						console.error(err);
						alert('Error adding user account.');
						return;
					}
				}

				let accountsToRemove = Object.entries(newData.accounts).filter((account) => {
					return account[1] === false && orgData.accounts.includes(account[0]);
				});

				for (const [ accountUUID, ] of accountsToRemove) {
					try {
						const data = await API.deleteMapRecord('user', userUUID, 'account', accountUUID);

						console.log(data);
					} catch (err) {
						console.error(err);
						alert('Error removing user account.');
						return;
					}
				}
			}

			const excludeKeys = [
				'accounts',
				'permission_group_uuid',
				'permission_group_display_name',
				'system_groups',
				'fd',
				'fd_keys',
				'_bundle',
			];

			for (const key of excludeKeys) {
				Reflect.deleteProperty(orgData, key);
				Reflect.deleteProperty(newData, key);
			}

			let orgString = JSON.stringify(orgData);
			let newString = JSON.stringify(newData);

			if (Object.keys(newData).length > 0 && orgString !== newString) {
				const body = {
					...newData,
					user_uuid: userUUID,
				};

				try {
					const data = await API.updateRecord('user', userUUID, newData);

					console.log(data);
				} catch (err) {
					console.error(err);
					alert('Error updating user.');
					return;
				}
			}

			if (this.props.parent && this.props.parent.getUsers) {
				this.props.parent.getUsers();
			}

			if (this.props.handleRootUserReadRequest) {
				this.props.handleRootUserReadRequest(this.props.filter);
			}

			app.closeModal();
		} catch (err) {
			console.error(err);
		} finally {
			app.setState({
				loading: false,
			});
		}
	}

	async deleteUser() {
		const {
			app,
		} = this.props;

		const {
			accounts,
			uuid: userUUID,
			email,
		} = this.state.orgData;

		try {
			let data = await API.updateRecord('user', userUUID, {
				email: email + '_deleted_' + new Date().getTime(),
			});
			console.log(data);

			data = await API.deleteRecord('user', userUUID);
			console.log(data);
		} catch (err) {
			console.error(err);
			alert('Error deleting user');
			return;
		}

		if (this.props.parent && this.props.parent.getUsers) {
			this.props.parent.getUsers();
		}

		if (this.props.handleRootUserReadRequest) {
			this.props.handleRootUserReadRequest(this.props.filter);
		}

		this.props.closeModal();
	}

	sendForgotPassword() {
		const {
			app,
		} = this.props;

		app.load(async () => {
			const {
				err,
				data,
			} = await sendForgotPasswordEmail(this.state.orgData.email);

			if (err) {
				if (data.message) {
					alert(data.message);
				} else {
					alert('Error sending forgot password message');
				}

				return;
			}
			alert(`Successfully sent email to: ${this.state.orgData.email}`);
		});
	}

	isEmail(candidate, checkIsTouchSource) {
		if (checkIsTouchSource) {
			let isValid = false;
			if (candidate && candidate.toLowerCase().endsWith('@touchsource.com')) {
				isValid = true;
			}
			return isValid;
		}

		let pattern = /.+@.+\..+/;
		return candidate.match(pattern);
	}

	isRequiredData(newData) {
		let isValid = false;
		if (newData.first_name && newData.last_name && newData.title) {
			isValid = true;
		}
		return isValid;
	}

	isRequiredAccountEditData(newData, orgData, emailToCheck) {
		let isValidEmail = false;

		if (newData?.permission_group_uuid === externalAdminUUID ||
			(orgData.permission_group_uuid === externalAdminUUID && !newData.permission_group_uuid)) {
			isValidEmail = this.isEmail(emailToCheck, false);
		} else if (newData?.permission_group_uuid === propertyManagerUUID ||
			(orgData.permission_group_uuid === propertyManagerUUID && !newData.permission_group_uuid)) {
			isValidEmail = this.isEmail(emailToCheck, false);
		} else {
			isValidEmail = this.isEmail(emailToCheck, true);
		}

		let isValidProperties = Object.keys(this.state.associatedComponents).length !== 0;
		let isValidData = false;
		if ((newData.first_name !== '') && (newData.last_name !== '') && (newData.title !== '')) {
			isValidData = true;
		}
		if (isValidEmail && isValidProperties & isValidData) {
			return true;
		}
	}

	isRequiredAdminEditData(emailToCheck) {
		const {
			newData,
			orgData,
		} = this.state;

		let isValidEmail = false;

		if (newData?.permission_group_uuid === externalAdminUUID ||
			(orgData.permission_group_uuid === externalAdminUUID && !newData.permission_group_uuid)) {
			isValidEmail = this.isEmail(emailToCheck, false);
		} else if (newData?.permission_group_uuid === propertyManagerUUID ||
			(orgData.permission_group_uuid === propertyManagerUUID && !newData.permission_group_uuid)) {
			isValidEmail = this.isEmail(emailToCheck, false);
		} else {
			isValidEmail = this.isEmail(emailToCheck, true);
		}

		let isValidData = false;
		if ((newData.first_name !== '') && (newData.last_name !== '') && (newData.title !== '')) {
			isValidData = true;
		}
		let isAccountsValid = false;
		//if the group has been edited
		if (newData.permission_group_uuid) {
			if (newData?.permission_group_uuid === externalAdminUUID) {
				isAccountsValid = !this.state.noAccounts;
			} else {
				isAccountsValid = true;
			}
		//if it has not
		} else if (orgData?.permission_group_uuid === externalAdminUUID) {
			isAccountsValid = !this.state.noAccounts;
		} else {
			isAccountsValid = true;
		}

		if (isValidEmail && isAccountsValid & isValidData) {
			return true;
		}
	}

	registerUser() {
		const {
			isUserModal,
			isAccountUsers,
			accountUUID,
			app,
		} = this.props;

		const {
			newData,
			orgData,
		} = this.state;
		// TODO: Send register email template

		let accounts = newData.accounts ? newData.accounts : orgData.accounts;

		if (accounts.length === 0) {
			accounts = {};
		}

		let body = {
			accounts,
			first_name:            newData.first_name ? newData.first_name : orgData.first_name,
			last_name:             newData.last_name ? newData.last_name : orgData.last_name,
			title:                 newData.title ? newData.title : orgData.title,
			email:                 newData.email ? newData.email : orgData.email,
			active:                newData.active ? newData.active : orgData.active,
			permission_group_uuid: newData.permission_group_uuid ? newData.permission_group_uuid : orgData.permission_group_uuid,
			has_2fa:               newData.has_2fa ? newData.has_2fa : orgData.has_2fa,
		};

		if (isUserModal) {
			body = {
				...body,
				permission_group_uuid: newData.permission_group_uuid ? newData.permission_group_uuid : orgData.permission_group_uuid,
			};
		}

		if (isAccountUsers) {
			body.accounts = {
				[accountUUID]: true,
			};

			body = {
				...body,
				'first_name':            newData.first_name ? newData.first_name : orgData.first_name,
				'last_name':             newData.last_name ? newData.last_name : orgData.last_name,
				'title':                 newData.title ? newData.title : orgData.title,
				'permission_group_uuid': propertyManagerUUID,
			};
		}

		for (const key in body) {
			if (typeof body[key] === 'string') {
				body[key] = body[key].trim();
			}
		}

		app.setState({
			loading: true,
		});

		app.load(async () => {
			const {
				err,
				data,
			} = await registerUser(body);

			if (err) {
				if (data.message) {
					alert(data.message);
				} else {
					alert('Error registering user');
				}

				return;
			}

			if (isAccountUsers) {
				this.props.updateUserToSystemGroupMaps(data.uuid, this.props.row.original.system_groups, this.state.associatedComponents);
			}
			alert(`Successfully sent email to: ${newData.email}`);
			this.props.closeModal();
		});
	}

	render() {
		const {
			isAccountUsers,
		} = this.props;
		const {
			newData,
			orgData,
		} = this.state;

		let activeValue = orgData.active;
		if (newData.active !== undefined) {
			activeValue = newData.active;
		}

		let has2FA = orgData.has_2fa;
		if (newData.has_2fa !== undefined) {
			has2FA = newData.has_2fa;
		}

		let has2FASetup = orgData.has_2fa_setup;
		if (newData.has_2fa_setup !== undefined) {
			has2FASetup = newData.has_2fa_setup;
		}
		const pg = getSession().pg;

		let newUser = false;
		let registerButtonClass = 'button button-disabled no-click';
		let internalUserWarningText = '';
		let isPropertyManager = newData.permission_group_uuid === propertyManagerUUID;

		if (newData?.email?.length > 0 && newData?.permission_group_uuid?.length > 0) {
			let checkIsTouchSource = false;
			if (newData.permission_group_uuid !== externalAdminUUID &&
				newData.permission_group_uuid !== propertyManagerUUID) {
				checkIsTouchSource = true;
			}
			if (this.isEmail(newData.email, checkIsTouchSource) && this.isRequiredData(newData)) {
				if (isAccountUsers) {
					if (this.state.associatedComponents && this.state.associatedComponents.length > 0) {
						registerButtonClass = 'button';
					}
				} else if (!isAccountUsers) { //if admin user
					if (checkIsTouchSource) {
						registerButtonClass = 'button';
					} else if (newData.accounts && Object.keys(newData.accounts).length !== 0) {
						registerButtonClass = 'button';
					}
				}
			}
			if (isPropertyManager && this.isEmail(newData.email, checkIsTouchSource)) {
				registerButtonClass = 'button';
			}
		}
		if (orgData.uuid === '') {
			newUser = true;
		}

		if (newUser && !newData.permission_group_uuid) {
			this.compareValue('permission_group_uuid', externalAdminUUID);
		}

		let saveButtonClass = 'button button-disabled no-click';

		let permissionGroupUUIDToCheck = newData.permission_group_uuid || '';
		if (permissionGroupUUIDToCheck.length === 0) {
			permissionGroupUUIDToCheck = orgData.permission_group_uuid;
		}

		let emailToCheck = orgData.email;

		if (!newUser && permissionGroupUUIDToCheck === internalUserUUID) {
			let checkIsTouchSource = true;

			if (newData.email) {
				emailToCheck = newData.email;
			}

			if (!this.isEmail(emailToCheck, checkIsTouchSource)) {
				saveButtonClass = 'button button-disabled no-click';
			}
		}

		if (!isAccountUsers) {
			if (this.isRequiredAdminEditData(emailToCheck)) {
				saveButtonClass = 'button';
			}
		} else if (permissionGroupUUIDToCheck === internalAdminUUID) {
			let checkIsTouchSource = true;

			let emailToCheck = orgData.email;
			if (newData.email) {
				emailToCheck = newData.email;
			}

			if (!this.isEmail(emailToCheck, checkIsTouchSource)) {
				registerButtonClass = 'button button-disabled no-click';
				saveButtonClass = 'button button-disabled no-click';
			} else {
				saveButtonClass = 'button';
			}
		} else if (this.isRequiredAccountEditData(newData, orgData, emailToCheck)) {
			saveButtonClass = 'button';
		}

		let roleList = [];

		if (!isAccountUsers) {
			roleList = [{
				label: 'External Admin',
				value: externalAdminUUID,
			},
			{
				label: 'Internal User',
				value: internalUserUUID,
			},
			];
			if (pg === 'ia') {
				roleList.push({
					label: 'Internal Admin',
					value: internalAdminUUID,
				});
			}
			if (!newUser) {
				roleList.push({
					label: 'Property Manager',
					value: propertyManagerUUID,
				});
			}
		}

		if (isAccountUsers) {
			roleList = [{
				label: 'Property Manager',
				value: propertyManagerUUID,
			},
			];
			if (orgData.permission_group_uuid === internalAdminUUID) {
				roleList = [{
					label: 'Internal Admin',
					value: internalAdminUUID,
				},
				];
			}
			if (orgData.permission_group_uuid === externalAdminUUID) {
				roleList = [{
					label: 'External Admin',
					value: externalAdminUUID,
				},
				];
			}
		}

		let selectedRoleListIndex = 0;
		for (let i = 0; i < roleList.length; i++) {
			if (this.props.row.original.permission_group_uuid === roleList[i].value) {
				selectedRoleListIndex = i;
				break;
			}
		}

		let showWarning = false;

		if (newUser) {
			if (registerButtonClass === 'button button-disabled no-click' && newData.permission_group_uuid === internalUserUUID) {
				showWarning = true;
			}

			if (registerButtonClass === 'button button-disabled no-click' && permissionGroupUUIDToCheck === internalUserUUID) {
				showWarning = true;
			}

			if (registerButtonClass === 'button button-disabled no-click' && permissionGroupUUIDToCheck === internalAdminUUID) {
				showWarning = true;
			}
		} else {
			if (saveButtonClass === 'button button-disabled no-click' && newData.permission_group_uuid === internalUserUUID) {
				showWarning = true;
			}

			if (saveButtonClass === 'button button-disabled no-click' && permissionGroupUUIDToCheck === internalUserUUID) {
				showWarning = true;
			}

			if (saveButtonClass === 'button button-disabled no-click' && permissionGroupUUIDToCheck === internalAdminUUID) {
				// showWarning = true;
			}
		}

		if (showWarning) {
			internalUserWarningText = (
				<div className={style.warningText}>
					Internal users can only be assigned by internal users to users with @touchsource emails
				</div>
			);
		}

		return (
			<div className={style.userModal}>
				{!this.state.confirmDelete ?
					<React.Fragment>
						<div className={style.halfcolumn}>
							<div className={style.column1}>
								<div className={`text-small ${style.field}`}>
									<div className={style.label}>Email</div>
									<input
										data-test-id='emailAddressInput'
										type='text'
										value={newData.email || this.props.row.original.email}
										placeholder="Email address"
										onChange={(e) => {
											this.compareValue('email', e.target.value);
										}}
									/>
								</div>
								{this.getAccountUserInfo()}
								<div className={`${style.field} ${style.mfaToggles}`}>
									<div className={style.mfaToggleContainer}>
										<div className={style.label}>Active</div>
										<div className={style.activeToggle}>
											<input
												className={`on-off on-off-label`}
												type='checkbox'
												checked={activeValue}
												onChange={() => {
													this.compareValue('active', !activeValue);
												}}
											/>
										</div>
									</div>
									<div className={style.label}>2FA Enabled</div>
									<div className={style.activeToggle}>
										<input
											className={`on-off on-off-label`}
											type='checkbox'
											checked={has2FA}
											onChange={() => {
												this.compareValue('has_2fa', !has2FA);
											}}
										/>
									</div>
									<div className={style.label}>2FA Setup Complete</div>
									<div className={style.activeToggle}>
										<input
											className={`on-off on-off-label`}
											type='checkbox'
											checked={has2FASetup}
											onChange={() => {
												this.compareValue('has_2fa_setup', false);
											}}
										/>
									</div>
								</div>
							</div>
							<div className={style.column2}>
								{/* Roles */}
								<div className={style.label}>Roles</div>
								<Select
									data-test-id='roleSelectDropdown'
									className={style.select}
									options={roleList}
									defaultValue={roleList[selectedRoleListIndex]}
									onChange={(e) => {
										this.compareValue('permission_group_uuid', e.value);
									}}
								/>
							</div>
							{internalUserWarningText}
						</div>
						{this.props.accounts ?
							<div className={style.halfcolumn}>
								<div className={style.field}>
									<div className={style.label}>Accounts</div>
									<Select
										className={style.select}
										options={this.props.accounts}
										isMulti
										defaultValue={this.props.row.original.accounts}
										onChange={(e) => {
											this.compareValue('accounts', e);
										}}
									/>
								</div>
							</div> :
							null}
						{this.getSystemGroupInfo()}
						<div className={style.bottom}>
							{newUser ?
								<button
									data-test-id='registerUserButton'
									className={registerButtonClass}
									onClick={this.registerUser.bind(this)}
								>
									<CustomIcon icon='paper-plane' />Register User
								</button> :
								<React.Fragment>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											this.sendForgotPassword();
										}}
										data-testing-info={buildTestData('button--resetPassword')}
									>
										<CustomIcon icon='key' />Reset Password
									</button>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											this.setState({
												confirmDelete: true,
											});
										}}
										data-testing-info={buildTestData('button--delete')}
									>
										<CustomIcon icon='trash' />Delete
									</button>
									<button
										className={saveButtonClass}
										onClick={this.submitForm.bind(this)}
										data-testing-info={buildTestData('button--save')}
									>
										<CustomIcon icon='save' />Save
									</button>
								</React.Fragment>
							}
						</div>
					</React.Fragment> :
					// Confirm Delete
					<React.Fragment>
						<div className={`${style.userModal} ${style.deleteModal}`}>
							Are you sure you want to delete&nbsp;<span>{orgData.email}</span>?
						</div>
						<div className={style.bottom}>
							<button
								className='button'
								onClick={(e) => {
									e.preventDefault();
									this.setState({
										confirmDelete: false,
									});
								}}
							>
								<CustomIcon icon='ban' />Cancel
							</button>
							<button
								className='button'
								onClick={this.deleteUser.bind(this)}
							>
								<CustomIcon icon='trash' />Delete
							</button>
						</div>
					</React.Fragment>
				}
			</div>
		);

	}
}

export default UserModal;