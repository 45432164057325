import React from 'react';
import style from './SystemsDetailModal.module.css';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import {
	getFDValue,
} from 'Vulture/Helpers';

class SystemsDetailModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			confirmModal:        false,
			confirmModalContent: '',
			blockMacAddressEdit: true,
		};
	}

	componentDidMount() {
		const {
			fd,
		} = this.props;

		if (!getFDValue(fd, `system.${this.props.uuid}.mac_address`)) {
			this.setState({
				blockMacAddressEdit: false,
			});
		}
	}

	checkForDuplicateMacAddress(systemUUID, macAddress) {
		const {
			fd,
		} = this.props;

		let duplicateSystemUUID;
		for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key === `system.${systemUUID}`) {
				continue;
			}

			for (let [ m2Key, m2Data, ] of Object.entries(m1Data)) {
				if (m2Key === 'mac_address' && m2Data === macAddress) {
					duplicateSystemUUID = m1Key.split('.')[1];
				}
			}
		}

		if (duplicateSystemUUID) {
			let systemGroupEntityKeys = [];
			for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
				if (m1Key.startsWith('system_group.')) {
					for (let m2Key of Object.keys(m1Data)) {
						if (m2Key === `system.${duplicateSystemUUID}`)
							systemGroupEntityKeys.push(`${m1Key}.${m2Key}`);
					}
				}
			}

			if (systemGroupEntityKeys.length === 0) {
				return false;
			}

			let systemDisplayName = getFDValue(fd, `system.${duplicateSystemUUID}.display_name`) || duplicateSystemUUID;
			let systemGroupUUID = systemGroupEntityKeys[0].split('.')[1];
			let systemGroupDisplayName = getFDValue(fd, `system_group.${systemGroupUUID}.display_name`) || systemGroupUUID;

			let cannotDuplicateMacAddress = (
				<>
					<p>Error: Duplicate MAC address found on {systemGroupDisplayName} <br/> {systemDisplayName}</p>
					<div className="confirm-modal-buttons">
						<button className="button" onClick={(e) => {
							e.preventDefault();
							this.setState({
								confirmModal: false,
							});
						}}>Okay</button>
					</div>
				</>
			);
			this.setState({
				confirmModal:        true,
				confirmModalContent: cannotDuplicateMacAddress,
			});
		}

		return Boolean(duplicateSystemUUID);
	}


	getSystemEntites(systemUUID, macAddress) {
		const {
			app,
			fd,
		} = this.props;
		let resolutionList = [
			{
				label: '1920 x 1080',
				value: '1920x1080',
			},
			{
				label: '1080 x 1920',
				value: '1080x1920',
			},
			{
				label: '3840 x 2160 4K',
				value: '3840x2160',
			},
			{
				label: '2160 x 3840 4K',
				value: '2160x3840',
			},
		];

		let networkTypeList = [
			{
				label: 'Cellular 1GB',
				value: 'cellular1GB',
			},
			{
				label: 'Cellular 3GB',
				value: 'cellular3GB',
			},
			{
				label: 'Cellular 5GB',
				value: 'cellular5GB',
			},
			{
				label: 'Cellular 10GB',
				value: 'cellular10GB',
			},
			{
				label: 'Hardwired',
				value: 'hardwired',
			},
			{
				label: 'WiFi',
				value: 'wifi',
			},
		];

		let entityArr = [
			{
				attr: {
					label: 'SalesForce ID',
				},
				name:      `system.${systemUUID}.salesforce_id_val`,
				type:      'FIELD',
				fieldType: 'custom.vulture.elements.text.small',
				style:     style.systemField,
			},
			{
				attr: {
					label: 'Name',
				},
				name:      `system.${systemUUID}.display_name`,
				type:      'FIELD',
				fieldType: 'custom.vulture.elements.text.small',
				style:     style.systemField,
			},
			{
				attr: {
					label:    'MAC Address',
					htmlattr: {},
				},
				name:      `system.${systemUUID}.mac_address`,
				type:      'FIELD',
				fieldType: 'custom.vulture.elements.text.small',
				style:     style.systemField,
			},
			{
				attr: {
					label: 'Resolution',
				},
				name:       `system.${systemUUID}.resolution`,
				altName:    `system.${systemUUID}`,
				type:       'FIELD',
				fieldType:  'custom.vulture.elements.resolution.drop.down',
				style:      style.systemField,
				options:    resolutionList,
				settingMap: this.props.settingMap,
			},
			{
				attr: {
					label: 'Hide System',
				},
				name:      `system.${systemUUID}.hidden`,
				type:      'FIELD',
				fieldType: 'custom.vulture.elements.advanced.checkbox',
				style:     style.systemField,
			},
		];
		let systemInfoArr = [
			{
				attr: {
					label: 'Network Type',
				},
				name:      `system.${systemUUID}.network_type`,
				type:      'FIELD',
				fieldType: 'custom.vulture.elements.drop.down',
				style:     style.systemField,
				options:   networkTypeList,
			},
			{
				attr: {
					label: 'Directory Number',
				},
				name:      `system.${systemUUID}.directory_number`,
				type:      'FIELD',
				fieldType: 'custom.vulture.elements.number',
				style:     style.systemField,
			},
		];
		let meshInfo = '';

		if (this.props.systemStatusInfo[macAddress]) {
			meshInfo = (
				<React.Fragment>
					<div className="text-small">
						<div className="label">
							<label>IP</label>
						</div>
						<input type="text" disabled={true} value={this.props.systemStatusInfo[macAddress].ip}></input>
					</div>
					<div className="text-small">
						<div className="label">
							<label>OS</label>
						</div>
						<input type="text" disabled={true} value={this.props.systemStatusInfo[macAddress].os}></input>
					</div>
				</React.Fragment>
			);
		}

		return (
			<div className={style.system} key={systemUUID}>
				<div className={style.detailsColumn}>
					<div className={style.sectionLabel}>Details</div>
					{entityArr.map((entity, i) => {
						if (entity.attr.label === 'MAC Address' && this.state.blockMacAddressEdit) {
							return (
								<div key={i} className={entity.style}>
									<label className={style.fakeInputLabel}>MAC Address</label>
									<p className={style.fakeInput}>{macAddress}</p>
								</div>
							);
						}
						return (
							<div key={i} className={entity.style}>
								{app.renderEntity(entity, {
									fd,
								})}
							</div>
						);
					})}
				</div>
				<div className={style.meshColumn}>
					<div className={style.sectionLabel}>System Info</div>
					<div className="text-small">
						<div className="label">
							<label>UUID</label>
						</div>
						<input type="text" disabled={true} value={systemUUID}></input>
					</div>
					{systemInfoArr.map((entity, i) => {
						return (
							<div key={i} className={entity.style}>
								{app.renderEntity(entity, {
									fd,
								})}
							</div>
						);
					})}
					{meshInfo}
				</div>
			</div>
		);
	}

	deleteSystem(systemUUID) {
		const {
			app,
			fd,
		} = this.props;

		let children = [];

		for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key === `system.${systemUUID}`) {
				for (let [ m2Key, ] of Object.entries(m1Data)) {
					if (m2Key.includes('.') && !m2Key.startsWith('setting.')) {
						if (getFDValue(fd, `${m1Key}.${m2Key}`)) {
							const [ m2, m2ID, ] = m2Key.split('.');
							const displayName = getFDValue(fd, `${m2}.${m2ID}.display_name`);
							children.push(`${m2}: ${displayName}`);
						}
					}
				}
			}
		}

		if (children.length) {
			const confirmModalContent = (
				<>
					<p>Please delete the following children before deleting the system</p>
					{children.map((child, i) => {
						return <p key={i}>{child}</p>;
					})}
					<div className="confirm-modal-buttons">
						<button className="button" onClick={(e) => {
							e.preventDefault();
							this.setState({
								confirmModal: false,
							});
						}}>Close</button>
					</div>
				</>
			);

			this.setState({
				confirmModalContent,
			});
			return;
		}

		const entityNames = [];
		const values = [];

		entityNames.push(`system.${systemUUID}.mac_address`);
		values.push(null);

		for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key.startsWith(`system_group.`)) {
				for (let [ m2Key, ] of Object.entries(m1Data)) {
					if (m2Key === `system.${systemUUID}`) {
						entityNames.push(`${m1Key}.${m2Key}`);
						values.push(false);
					}
				}
			}
		}

		app.setValue('system', entityNames, values, () => {
			this.props.save();
			app.closeModal();
		});
	}

	render() {
		let elm = this.getSystemEntites(this.props.uuid, this.props.macAddress);

		const {
			app,
			fd,
		} = this.props;

		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal = (
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>
			);
		}

		let buttonClassName = 'button button-disabled no-click';
		if (this.state.blockMacAddressEdit) {
			buttonClassName = 'button';
		}

		return (
			<React.Fragment>
				{confirmModal}
				<div className={style.systemsModalContainer}>
					{elm}
				</div>
				<div className={style.buttons}>
					<button
						className={buttonClassName}
						onClick={(e) => {
							e.preventDefault();
							let pinInput = (
								<>
									<p>Please enter your pin</p>
									<input id='pinNumber'></input>
									<button
										className='button-outlined'
										onClick={(e) => {
											e.preventDefault();
											if (document.getElementById('pinNumber').value === '1370') {
												this.setState({
													blockMacAddressEdit: false,
													confirmModal:        false,
												});
											} else {
												this.setState({
													confirmModal: false,
												});
											}
										}}>Enter
									</button>
								</>
							);
							this.setState({
								confirmModal:        true,
								confirmModalContent: pinInput,
							});
						}}
					>
						<CustomIcon icon='lock-open' /> Unlock MAC Address
					</button>
					<button
						className="button-outlined-dark"
						onClick={(e) => {
							e.preventDefault();
							let deleteAllowed = (
								<>
									<p>Are you sure you want to delete this system?</p>
									<div className="confirm-modal-buttons">
										<button className="button-outlined" onClick={(e) => {
											e.preventDefault();
											this.deleteSystem(this.props.uuid);
										}}>Yes</button>
										<button className="button" onClick={(e) => {
											e.preventDefault();
											this.setState({
												confirmModal: false,
											});
										}}>No</button>
									</div>
								</>
							);
							this.setState({
								confirmModal:        true,
								confirmModalContent: deleteAllowed,
							});
						}}
					>
						<CustomIcon icon='trash' /> Delete
					</button>
					<button
						className='button'
						onClick={(e) => {
							e.preventDefault();
							let macAddress = getFDValue(fd, `system.${this.props.uuid}.mac_address`);
							if (macAddress) {
								let macAddressForDatabase = macAddress.replaceAll(':', '');
								macAddressForDatabase = macAddressForDatabase.toLowerCase();
								if (this.checkForDuplicateMacAddress(this.props.uuid, macAddressForDatabase)) {
									app.setValue('system', `system.${this.props.uuid}.mac_address`, '');
									return;
								}

								if (macAddressForDatabase.length !== 12) {
									let invalidMacAddress = (
										<>
											<p>MAC Addresses must be 12 characters</p>
											<div className="confirm-modal-buttons">
												<button className="button" onClick={(e) => {
													e.preventDefault();
													this.setState({
														confirmModal: false,
													});
												}}>Okay</button>
											</div>
										</>
									);
									this.setState({
										confirmModal:        true,
										confirmModalContent: invalidMacAddress,
									});

									return;
								}

								app.setValue('system', `system.${this.props.uuid}.mac_address`, macAddressForDatabase, () => {
									this.props.save();
									app.closeModal();
								});
							} else {
								app.setValue('system', `system.${this.props.uuid}.mac_address`, null, () => {
									this.props.save();
									app.closeModal();
								});
							}
						}}
					>
						<CustomIcon icon='save' />Save
					</button>
				</div>
			</React.Fragment>
		);
	}
}

export default SystemsDetailModal;