import React from 'react';
import {
	dlLoginAttemptPush,
} from 'Vulture/DataLayer';

import style from 'Vulture/View/Login.module.css';

const SplitScreenView = function (props) {
	const {
		app,
	} = props;

	return (
		<React.Fragment>
			<div className={style.header}>
				<div className={style.left}>
					<div className={style.logo}>
						<img src='https://ts-public-images.s3.amazonaws.com/TouchSourceSPARK_logo_FINAL_TS_whiteblue.png' alt="The TouchSource logo" />
					</div>
				</div>
			</div>
			<div className={style.login}>
				<div className={style.splitScreen}>
					<div className={`${style.halfScreen} ${style.legacyLogin}`}>
						<div className={`${style.loginHalf} ${style.sparkLogin}`}>
							<div className={style.loginContainer}>
								<img src="https://ts-public-images.s3.amazonaws.com/TouchsourceSPARKLogo_allwhite.png" alt="Touchource Spark PX logo" />
								<p data-test-id="loginMessage">Access the new TouchSource Spark PX™ Platform.</p>
								<button
									className={`button ${style.splitScreenButton}`}
									onClick={() => {
										dlLoginAttemptPush({
											method: 'Next-Gen Platform Login',
										});

										app.redirect({
											to: `/login`,
										});
									}}
									data-onclick="Next-Gen Platform Login"
									data-test-id="loginButton"
								>Spark PX™ Login</button>
							</div>
						</div>
						<div className={style.loginHalf}>
							<div className={style.loginContainer}>
								<img src="https://ts-public-images.s3.amazonaws.com/touchsource-portal.png" alt="Touchource Legacy Portal logo" />
								<p>Learn about the TouchSource Spark PX platform and enhanced features <a className={style.loginLink} href="https://touchsource.com/spark-px-platform">here.</a></p>
								<p>Email us at info@touchsource.com to request a demo or have us migrate your account for you.</p>
								<a
									className={`button ${style.splitScreenButton}`}
									href="https://portal.touchsource.com/"
									data-onclick="Legacy Login"
									onClick={() => {
										dlLoginAttemptPush({
											method: 'Original Login',
										});
									}}
								>
									Legacy Portal
								</a>
							</div>
						</div>
					</div>
					<div className={`${style.halfScreen} ${style.newLogin}`}>
						{/* <img src="https://ts-public-images.s3.amazonaws.com/touchsource-cloud.png" alt="Touchource Cloud logo" /> */}
						<iframe className={style.iframeContainer}
							title="marketing"
							src="https://get.touchsource.com/customer-login-portal-page"
						/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SplitScreenView;