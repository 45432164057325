import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon.js';

// React Table
import {useRowSelect, useSortBy, useTable} from 'react-table';
import { dlAccountSelectPush } from 'Vulture/DataLayer';

import { buildTestData } from 'Vulture/TestData';

// Import styles
import style from './AccountsTable.module.css';

function AccountsTable(props) {

	let accountsColumns = [
		{
			Header:   'Name',
			accessor: 'name'
		},
		{
			Header:   'SalesForceID',
			accessor: 'salesforceID'
		},
		{
			Header:   'Properties',
			accessor: 'systemGroupNames'
		},
		{
			Header:   'Systems',
			accessor: 'systemCount'
		}
	];

	const columns = React.useMemo(
		() => {
			return [
				{
					Header:  'AccountsHeader',
					columns: accountsColumns
				}
			];
		},
		[
			{
				Header:  'AccountsHeader',
				columns: accountsColumns
			}
		]
	);

	const data = React.useMemo(() => {
		return props.array;
	}, [props.array]);

	function Table({columns, data}) {
		// Use the state and functions returned from useTable to build your UI
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			rows,
			prepareRow,
			state,
			visibleColumns,
			state: {selectedRowIds}
		} = useTable(
			{
				columns: columns,
				data:    data
			},
			useSortBy,
			useRowSelect
		);

		// Render the UI for your table
		return (
			<table className={style.table} {...getTableProps()} data-testing-info={buildTestData('table--accounts')}>
				{/* <thead className={style.thead}>
					{headerGroups.map((headerGroup, i) => {
						return (
							<tr key={`${i}`} className={style.tr} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, j) => {
									return (
										<th key={`${j}`} className={style.th} {...column.getHeaderProps()} {...column.getSortByToggleProps()}>
											{column.render('Header')}&nbsp;
										</th>
									);
								})}
							</tr>
						);
					})}
				</thead> */}
				<tbody className={style.tbody} {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr key={`${i}`} className={style.tr} {...row.getRowProps()} data-testing-info={buildTestData(`tableItem--${row.original.id}`)} onClick={(e) => {
								e.preventDefault();

								dlAccountSelectPush({ accountName: row.original.name });

								props.app.setAccountUUID(row.original.id);
							}}>
								{row.cells.map((cell, j) => {
									if (j == 2) {
										const systemGroupNames = row.original.systemGroupNames.split('|||');
										let firstSystemGroupRender = '';
										if (row.original.systemGroupData[systemGroupNames[0]]) {
											firstSystemGroupRender = <><CustomIcon icon='circle' /> <b>{row.original.systemGroupData[systemGroupNames[0]].project_number}</b> - {systemGroupNames[0]}</>;
										}

										const renderString = systemGroupNames.slice(1).reduce((accum, systemGroupName) => {
											return <>{accum}, <CustomIcon icon='circle' /> <b>{row.original.systemGroupData[systemGroupName].project_number}</b> - {systemGroupName}</>;
										}, firstSystemGroupRender);

										const retVal = <td key={`${j}`} className={style.td} {...cell.getCellProps()}>{renderString}</td>;
										
										return retVal;
									}

									return <td key={`${j}`} className={style.td} {...cell.getCellProps()}>{cell.render('Cell')}</td>;
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	return (
		<Table columns={columns} data={data} />
	);

}

export default AccountsTable;