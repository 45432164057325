import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import Select from 'react-select';

import style from './Users.module.css';
import {
	providerListToColumnMap,
} from './listingIntegrationModels';

class NewListingModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedDataSet:         null,
			selectedIntegration:     null,
			selectedIntegrationList: null,
			dataSetOptions:          [],
		};
	}

	componentDidMount() {
		const {
			dataSets,
		} = this.props;


		const dataSetOptions = Object.entries(dataSets).map(([ key, val, ]) => {
			return {
				label: val.display_name,
				value: key,
			};
		});

		this.setState({
			dataSetOptions,
		});
	}

	render() {
		const {
			newListing,
			importListing,
			integrateListing,
			closeModal,
		} = this.props;

		const {
			dataSetOptions,
			selectedIntegration,
			selectedIntegrationList,
		} = this.state;

		const integrationOptions = Object.keys(providerListToColumnMap)
			.map((provider) => {
				return {
					value: provider,
					label: provider[0].toUpperCase() + provider.slice(1),
				};
			});

		let integrationListOptions = [];

		if (selectedIntegration) {
			integrationListOptions = Object.keys(providerListToColumnMap[selectedIntegration])
				.map((providerList) => {
					return {
						value: providerList,
						label: providerList[0].toUpperCase() + providerList.slice(1),
					};
				});
		}

		let predefinedListButtonClass = 'button button-disabled no-click';
		if (this.state.selectedDataSet !== null) {
			predefinedListButtonClass = 'button';
		}

		let integratedListButtonClass = 'button button-disabled no-click';
		if (this.state.selectedIntegrationList !== null) {
			integratedListButtonClass = 'button';
		}

		return (
			<div className={style.userModal}>
				<div className={style.halfcolumn}>
					<div className={style.label}>New Predefined List</div>
					<Select
						className={style.select}
						options={dataSetOptions}
						onChange={(e) => {
							this.setState({
								selectedDataSet: e.value,
							});
						}}
					/>
					<button
						className={predefinedListButtonClass}
						onClick={(e) => {
							const {
								selectedDataSet,
							} = this.state;

							importListing(selectedDataSet);

							closeModal();

							e.preventDefault();
						}}
						style={{
							width: 'fit-content',
						}}
					>
						<CustomIcon icon='plus' /> New List
					</button>
				</div>
				<div className={style.halfcolumn}>
					<div className={style.label}>New Integration List</div>
					<Select
						className={style.select}
						options={integrationOptions}
						onChange={(e) => {
							this.setState({
								selectedIntegration: e.value,
							});
						}}
					/>
					{this.state.selectedIntegration !== null &&
					<>
						<div className={style.label}>List Type</div>
						<Select
							className={style.select}
							options={integrationListOptions}
							onChange={(e) => {
								this.setState({
									selectedIntegrationList: e.value,
								});
							}}
						/>
					</>}
					<button
						className={integratedListButtonClass}
						onClick={(e) => {
							integrateListing(selectedIntegration, selectedIntegrationList);

							closeModal();

							e.preventDefault();
						}}
						style={{
							width: 'fit-content',
						}}
					>
						<CustomIcon icon='plus' /> New List
					</button>
				</div>
				<div className={style.halfcolumn}>
					<div className={style.label}>New Custom List</div>
					<button
						className={`button ${style.button} ${style.marginSmall}`}
						onClick={(e) => {
							newListing();

							closeModal();

							e.preventDefault();
						}}
						style={{
							width: 'fit-content',
						}}
					>
						<CustomIcon icon='plus' /> New List
					</button>
				</div>
			</div>
		);
	}
}

export default NewListingModal;