import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import md5 from 'md5';
import {
	v4 as uuidv4,
} from 'uuid';

import style from './Screenshot.module.css';

import {
	getSession,
} from 'Vulture/Session';

import {
	broncoURL,
} from 'Vulture/ENV';

import {
	fetchAccountFD,
	fetchAccountSystem,
} from 'Vulture/Account';

import {
	copyFileFromCustomCSSBucket,
	copyFileFromPelicanBucket,
} from 'Vulture/Content';
import {
	SystemAssignmentConfirmationModal,
	SystemAssignmentConsentModal,
} from './SystemAssignmentModal';

class SystemAssignmentView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accountsFD: {},

			fetchedAccounts: [],

			sourceAccount:                 null,
			sourceAccountOptions:          [],
			sourceAccountSelectorDisabled: true,

			sourceSystemGroup:                 null,
			sourceSystemGroupOptions:          [],
			sourceSystemGroupSelectorDisabled: true,

			sourceSystems:                null,
			sourceSystemOptions:          [],
			sourceSystemSelectorDisabled: true,

			targetAccount:                 null,
			targetAccountOptions:          [],
			targetAccountSelectorDisabled: true,

			targetSystemGroup:                 null,
			targetSystemGroupOptions:          [],
			targetSystemGroupSelectorDisabled: true,
		};
	}

	clearState() {
		this.setState({
			accountsFD:      {},
			fetchedAccounts: [],

			sourceAccount:                 null,
			sourceAccountOptions:          [],
			sourceAccountSelectorDisabled: true,

			sourceSystemGroup:                 null,
			sourceSystemGroupOptions:          [],
			sourceSystemGroupSelectorDisabled: true,

			sourceSystems:                null,
			sourceSystemOptions:          [],
			sourceSystemSelectorDisabled: true,

			targetAccount:                 null,
			targetAccountOptions:          [],
			targetAccountSelectorDisabled: true,

			targetSystemGroup:                 null,
			targetSystemGroupOptions:          [],
			targetSystemGroupSelectorDisabled: true,
		});
	}

	componentDidMount() {
		this.getAccountsData();
	}

	getImmediateChildrenSystemGroups(m1Key) {
		const {
			accountsFD,
		} = this.state;

		const childrenSystemGroups = Object.keys(accountsFD[m1Key])
			.filter((m2Key) => {
				return m2Key.startsWith('system_group.');
			});

		return childrenSystemGroups;
	}

	getAllChildrenSystemGroups(accountKey) {
		const {
			accountsFD,
		} = this.state;

		const processedSystemGroups = [];
		const systemGroups = [ ...this.getImmediateChildrenSystemGroups(accountKey), ];
		let lastSystemGroupsLength;

		while (processedSystemGroups.length !== lastSystemGroupsLength) {
			lastSystemGroupsLength = processedSystemGroups.length;
			const newSystemGroups = [];

			systemGroups
				.filter((systemGroupKey) => {
					return !processedSystemGroups.includes(systemGroupKey);
				})
				.forEach((systemGroupKey) => {
					newSystemGroups.push(...this.getImmediateChildrenSystemGroups(systemGroupKey));
					processedSystemGroups.push(systemGroupKey);
				});

			systemGroups.push(...newSystemGroups);
		}

		const targetSystemGroupOptions = Object.entries(accountsFD)
			.filter(([ key, ]) => {
				return systemGroups.includes(key);
			})
			.map(([ systemGroupUUID, systemGroupVal, ]) => {
				return {
					label: systemGroupVal.display_name,
					value: systemGroupUUID,
				};
			});

		return targetSystemGroupOptions;
	}

	async getAccountsData() {
		const {
			app,
		} = this.props;

		const {
			accountsFD,
		} = this.state;

		const session = getSession();
		const url = `${broncoURL}/system_assignment/accounts`;
		const body = {
			user: session.user,
		};

		app.setState({
			loading: true,
		});

		const res = await axios.post(url, body, {});
		if (res.status !== 200) {
			app.openModal({
				modalSize:  1,
				showModal:  true,
				modalProps: {
					title: 'Request failed',
					jsx:   (
						<SystemAssignmentConfirmationModal
							closeModal={app.closeModal.bind(app)}
							message={`Failed request to ${broncoURL}/system_assignment/accounts: ${res.statusText}`}
						/>
					),
				},
			});

			app.setState({
				loading: false,
			});

			return;
		}

		const newAccountsFD = {
			...accountsFD,
			...res.data,
		};

		const accountOptions = Object.entries(newAccountsFD)
			.filter(([ accountUUID, ]) => {
				return accountUUID.startsWith('account.');
			})
			.map(([ accountUUID, accountVal, ]) => {
				return {
					label: accountVal.display_name,
					value: accountUUID,
				};
			});

		this.setState({
			accountsFD: newAccountsFD,

			sourceAccountOptions: accountOptions,
			targetAccountOptions: accountOptions,

			sourceAccountSelectorDisabled: false,
			targetAccountSelectorDisabled: false,
		});

		app.setState({
			loading: false,
		});
	}

	async getSystemGroupsData(accountM1UUID) {
		const {
			app,
		} = this.props;

		const {
			accountsFD,
			fetchedAccounts,
		} = this.state;

		if (fetchedAccounts.includes(accountM1UUID)) {
			return accountsFD;
		}

		const session = getSession();
		const url = `${broncoURL}/system_assignment/account`;
		const body = {
			user:         session.user,
			account_uuid: accountM1UUID,
		};

		app.setState({
			loading: true,
		});

		const res = await axios.post(url, body, {});

		if (res.status !== 200) {
			app.openModal({
				modalSize:  1,
				showModal:  true,
				modalProps: {
					title: 'Request failed',
					jsx:   (
						<SystemAssignmentConfirmationModal
							closeModal={app.closeModal.bind(app)}
							message={`Failed request to ${broncoURL}/system_assignment/account: ${res.statusText}`}
						/>
					),
				},
			});

			app.setState({
				loading: false,
			});

			return;
		}

		const newAccountsFD = {
			...accountsFD,
			...res.data,
		};

		this.setState({
			accountsFD: newAccountsFD,

			sourceAccountSelectorDisabled: false,
			targetAccountSelectorDisabled: false,
			fetchedAccounts:               [ ...fetchedAccounts, accountM1UUID, ],
		});

		app.setState({
			loading: false,
		});

		return newAccountsFD;
	}

	checkForBlockers(accountFD) {
		const {
			sourceSystems,
		} = this.state;

		let blockOperation = false;
		let blockMessage = `You can't move systems that belong to groups. The following systems are grouped: `;

		for (const sourceSystem of sourceSystems) {
			let belongsToGroup = false;
			const sourceSystemM1UUID = sourceSystem.value.split('.')[1];
			for (const [ m1Key, m1Val, ] of Object.entries(accountFD)) {
				if (m1Key.startsWith('component_group.')) {
					for (const m2Key of Object.keys(m1Val)) {
						if (m2Key === `system.${sourceSystemM1UUID}`) {
							blockOperation = true;
							belongsToGroup = true;
						}
					}
				}
			}
			if (belongsToGroup) {
				blockMessage += `${sourceSystem.label}, `;
			}
		}

		blockMessage = blockMessage.slice(0, -2) + '.';

		return {
			blockOperation,
			blockMessage,
		};
	}

	async getSystemsData() {
		const {
			sourceAccount,
			sourceSystems,
		} = this.state;

		const {
			app,
		} = this.props;

		let systemsData = {};

		const sourceAccountM1UUID = sourceAccount.value.split('.')[1];

		for (const sourceSystem of sourceSystems) {
			const sourceSystemM1UUID = sourceSystem.value.split('.')[1];
			const systemData = await fetchAccountSystem(sourceAccountM1UUID, sourceSystemM1UUID);
			if (app.handleOutdatedCommithashValidation(systemData)) {
				return;
			}

			systemsData = {
				...systemsData,
				...systemData.data,
			};
		}

		return systemsData;
	}

	getSettingUUIDs(systemsFD) {
		let pelicanJsonUUID = '';
		let customCSSUUID = '';

		Object.entries(systemsFD).forEach(([ m1Key, m1Val, ]) => {
			if (m1Key.startsWith('setting.')) {
				if (m1Val.name === 'pelican_json') {
					pelicanJsonUUID = m1Key;
				}
			}

			if (m1Key.startsWith('setting.')) {
				if (m1Val.name === 'custom_css') {
					customCSSUUID = m1Key;
				}
			}
		});

		return {
			pelicanJsonUUID,
			customCSSUUID,
		};
	}

	reallocateSystemGroupKeys() {
		const {
			sourceSystemGroup,
			targetSystemGroup,
			sourceSystems,
		} = this.state;

		const sourceSystemGroupM1UUID = sourceSystemGroup.value.split('.')[1];
		const targetSystemGroupM1UUID = targetSystemGroup.value.split('.')[1];

		const keys = [];
		const vals = [];

		sourceSystems.forEach((sourceSystem) => {
			const sourceSystemM1UUID = sourceSystem.value.split('.')[1];
			keys.push(`system_group.${sourceSystemGroupM1UUID}.system.${sourceSystemM1UUID}`);
			vals.push(false);
			keys.push(`system_group.${targetSystemGroupM1UUID}.system.${sourceSystemM1UUID}`);
			vals.push(true);
		});

		return {
			keys,
			vals,
		};
	}

	createNewTemplatesKeys(systemsFD) {
		const {
			targetAccount,
			sourceSystems,
		} = this.state;

		const targetAccountM1UUID = targetAccount.value.split('.')[1];

		const keys = [];
		const vals = [];

		const newTemplates = {};

		sourceSystems.forEach((sourceSystem) => {
			Object.entries(systemsFD).forEach(([ m1Key, m1Val, ]) => {
				if (m1Key === sourceSystem.value) {
					Object.keys(m1Val).forEach((m2Key) => {
						if (m2Key.startsWith('template.')) {
							if (!newTemplates[m2Key]) {
								newTemplates[m2Key] = `template._${uuidv4()}`;

								keys.push(`account.${targetAccountM1UUID}.${newTemplates[m2Key]}`);
								vals.push(true);

								Object.entries(systemsFD[m2Key]).forEach(([ m3Key, m3Val, ]) => {
									keys.push(`${newTemplates[m2Key]}.${m3Key}`);
									if (m3Key === 'display_name') {
										vals.push(m3Val);
									} else {
										vals.push(true);
									}
								});
							}
							keys.push(`${m1Key}.${m2Key}`);
							vals.push(false);
							keys.push(`${m1Key}.${newTemplates[m2Key]}`);
							vals.push(true);
						}
					});
				}
			});
		});

		return {
			keys,
			vals,
		};
	}

	countListings(systemsFD) {
		let listingCount = 0;

		Object.entries(systemsFD).forEach(([ m1Key, m1Val, ]) => {
			if (m1Key.startsWith('listing_collection.')) {
				Object.keys(m1Val).forEach((m2Key) => {
					if (m2Key.startsWith('listing.')) {
						listingCount++;
					}
				});
			}
		});

		return listingCount;
	}

	unassignListingsKeys(systemsFD) {
		const keys = [];
		const vals = [];

		Object.entries(systemsFD).forEach(([ m1Key, m1Val, ]) => {
			if (m1Key.startsWith('listing_collection.')) {
				Object.keys(m1Val).forEach((m2Key) => {
					if (m2Key.startsWith('listing.')) {
						keys.push(`${m1Key}.${m2Key}`);
						vals.push(false);
					}
				});
			}
		});

		return {
			keys,
			vals,
		};
	}

	unassignListingsComponentsKeys(systemsFD) {
		const keys = [];
		const vals = [];

		Object.entries(systemsFD).forEach(([ m1Key, m1Val, ]) => {
			if (m1Key.startsWith('listing_collection.')) {
				Object.keys(m1Val).forEach((m2Key) => {
					if (m2Key.startsWith('listing_config.')) {
						keys.push(`${m1Key}.${m2Key}`);
						vals.push(false);
					}
				});
			}

			if (m1Key.startsWith('listing_group.')) {
				Object.keys(m1Val).forEach((m2Key) => {
					if (m2Key.startsWith('listing_column_group.') || m2Key.startsWith('listing_config.')) {
						keys.push(`${m1Key}.${m2Key}`);
						vals.push(false);
					}
				});
			}
		});

		return {
			keys,
			vals,
		};
	}

	async createNewCustomCSSKeys(systemsFD, customCSSUUID) {
		const {
			targetAccount,
		} = this.state;

		const targetAccountM1UUID = targetAccount.value.split('.')[1];

		const keys = [];
		const vals = [];

		const clonedFiles = [];
		const newFiles = {};

		const promisesToWait = [];

		for (const [ m1Key, m1Val, ] of Object.entries(systemsFD)) {
			if (m1Key.startsWith('component.') && m1Val.description === 'top_level_condor') {
				for (const m2Key of Object.keys(m1Val)) {
					if (m2Key === customCSSUUID) {
						const filePath = systemsFD[m1Key][customCSSUUID].string;

						if (!filePath?.length > 0) {
							continue;
						}

						if (!clonedFiles.includes(m2Key)) {
							clonedFiles.push(m2Key);
							const fileName = filePath.split('/').slice(-1)[0];
							const objKey = `${window._getEnv('ENV')}/${targetAccountM1UUID}/${fileName}`;

							const copyFilePromsie = copyFileFromCustomCSSBucket(filePath, objKey)
								.then((newFile) => {
									newFiles[filePath] = newFile;
									keys.push(`${m1Key}.${m2Key}.string`);
									vals.push(newFiles[filePath]);
								});
							promisesToWait.push(copyFilePromsie);

						} else {
							keys.push(`${m1Key}.${m2Key}.string`);
							vals.push(newFiles[filePath]);
						}
					}
				}
			}
		}

		await Promise.allSettled(promisesToWait);

		return {
			keys,
			vals,
		};
	}

	async createNewSlidesKeys(systemsData, pelicanJsonUUID) {
		const {
			targetAccount,
		} = this.state;

		const targetAccountM1UUID = targetAccount.value.split('.')[1];

		const keys = [];
		const vals = [];

		const clonedSlides = [];
		const newSlides = {};

		const promisesToWait = [];

		for (const [ m1Key, m1Val, ] of Object.entries(systemsData)) {
			if (m1Key.startsWith('component.') && m1Val.description === 'pelican') {
				for (const m2Key of Object.keys(m1Val)) {
					if (m2Key.startsWith('component.') && systemsData[m2Key][pelicanJsonUUID]) {
						keys.push(`${m1Key}.${m2Key}`);
						vals.push(false);

						if (!clonedSlides.includes(m2Key)) {
							clonedSlides.push(m2Key);
							const newM2Key = `component._${uuidv4()}`;
							const m2JSON = JSON.parse(systemsData[m2Key][pelicanJsonUUID].json);

							const extension = m2JSON.file.split('.').slice(-1)[0];

							const seededHash = md5(`${new Date().getTime()}_${m2JSON.name}`);
							const objKey = `${window._getEnv('ENV')}/${targetAccountM1UUID}/${seededHash}.${extension}`;

							const type = m2JSON.kind.split('/')[0];

							const copyFilePromise = copyFileFromPelicanBucket(m2JSON.file, objKey, type)
								.then((newFile) => {
									const newM2JSON = {
										...m2JSON,
										file: newFile,
									};

									keys.push(`${m1Key}.${newM2Key}`);
									vals.push(true);

									keys.push(`${newM2Key}.${pelicanJsonUUID}`);
									vals.push(true);

									keys.push(`${newM2Key}.${pelicanJsonUUID}.json`);
									vals.push(JSON.stringify(newM2JSON));

									newSlides[m2Key] = newM2Key;
								});
							promisesToWait.push(copyFilePromise);
						} else {
							const newM2Key = newSlides[m2Key];

							keys.push(`${m1Key}.${newM2Key}`);
							vals.push(true);
						}
					}
				}
			}
		}

		await Promise.allSettled(promisesToWait);

		Object.entries(systemsData).forEach(([ m1Key, m1Val, ]) => {
			if (m1Key.startsWith('component.') && m1Val.description === 'brood') {
				Object.keys(m1Val).forEach((m2Key) => {
					if (m2Key.startsWith('component.') && systemsData[m2Key][pelicanJsonUUID]) {
						const newM2Key = newSlides[m2Key];
						keys.push(`${m1Key}.${m2Key}`);
						vals.push(false);

						keys.push(`${m1Key}.${newM2Key}`);
						vals.push(true);
					}
				});
			}
		});

		return {
			keys,
			vals,
		};
	}

	async moveSystemOnDifferentAccountOperation(systemsData) {
		const {
			app,
		} = this.props;

		const {
			sourceAccount,
			sourceSystemGroup,
			targetAccount,
			targetSystemGroup,
			sourceSystems,
		} = this.state;

		let sourceAccountsLabel = sourceSystems[0].label;
		for (const sourceSystem of sourceSystems.slice(1)) {
			sourceAccountsLabel += `, ${sourceSystem.label}`;
		}

		app.setState({
			loading: true,
		});

		const {
			pelicanJsonUUID,
			customCSSUUID,
		} = this.getSettingUUIDs(systemsData);

		const systemGroupToSystemKeys = this.reallocateSystemGroupKeys();

		const newTemplateKeys = this.createNewTemplatesKeys(systemsData);

		const unassignedListingsKeys = this.unassignListingsKeys(systemsData);

		const unassignedListingsComponentsKeys = this.unassignListingsComponentsKeys(systemsData);

		const customCSSCopyingKeys = await this.createNewCustomCSSKeys(systemsData, customCSSUUID);

		const slidesCopyingKeys = await this.createNewSlidesKeys(systemsData, pelicanJsonUUID);

		const keys = [
			...systemGroupToSystemKeys.keys,
			...newTemplateKeys.keys,
			...unassignedListingsKeys.keys,
			...unassignedListingsComponentsKeys.keys,
			...customCSSCopyingKeys.keys,
			...slidesCopyingKeys.keys,
		];

		const vals = [
			...systemGroupToSystemKeys.vals,
			...newTemplateKeys.vals,
			...unassignedListingsKeys.vals,
			...unassignedListingsComponentsKeys.vals,
			...customCSSCopyingKeys.vals,
			...slidesCopyingKeys.vals,
		];

		app.setValue('system_assignment', keys, vals, () => {
			app.saveData('system_assignment');
			app.openModal({
				modalSize:  1,
				showModal:  true,
				modalProps: {
					title: 'Assignment Finished',
					jsx:   (
						<SystemAssignmentConfirmationModal
							closeModal={() => {
								app.closeModal();
								this.clearState();
								this.getAccountsData();
							}}
							message={`Moved ${sourceAccountsLabel} from ${sourceAccount.label}'s ${sourceSystemGroup.label} to ${targetAccount.label}'s ${targetSystemGroup.label}.`}
						/>
					),
				},
			});
		});

		this.clearState();
		this.getAccountsData();
	}

	async moveSystemOnDifferentAccount() {
		const {
			app,
		} = this.props;

		const {
			sourceAccount,
		} = this.state;

		const sourceAccountM1UUID = sourceAccount.value.split('.')[1];

		app.setState({
			loading: true,
		});

		const accountRes = await fetchAccountFD(sourceAccountM1UUID);

		const {
			blockOperation,
			blockMessage,
		} = this.checkForBlockers(accountRes.data);

		if (blockOperation === true) {
			app.setState({
				loading: false,
			});

			app.openModal({
				modalSize:  2,
				showModal:  true,
				modalProps: {
					title: 'Operation Forbidden',
					jsx:   (
						<SystemAssignmentConfirmationModal
							closeModal={app.closeModal.bind(app)}
							message={blockMessage}
						/>
					),
				},
			});

			return;
		}

		const systemsData = await this.getSystemsData();

		app.setState({
			loading: false,
		});

		const unassignedListingsCount = this.countListings(systemsData);

		if (unassignedListingsCount > 0) {
			app.openModal({
				modalSize:  1,
				showModal:  true,
				modalProps: {
					title: 'Confirm Assignment',
					jsx:   (
						<SystemAssignmentConsentModal
							closeModal={app.closeModal.bind(app)}
							unassignedListingsLength={unassignedListingsCount}
							systemsData={systemsData}
							confirmOperation={this.moveSystemOnDifferentAccountOperation.bind(this)}
						/>
					),
				},
			});
		} else {
			this.moveSystemOnDifferentAccountOperation(systemsData);
		}
	}

	async moveSystemOnSameAccount() {
		const {
			app,
		} = this.props;

		const {
			sourceSystemGroup,
			targetSystemGroup,
			sourceSystems,
		} = this.state;

		let sourceAccountsLabel = sourceSystems[0].label;
		for (const sourceSystem of sourceSystems.slice(1)) {
			sourceAccountsLabel += `, ${sourceSystem.label}`;
		}

		const sourceSystemGroupM1UUID = sourceSystemGroup.value.split('.')[1];
		const targetSystemGroupM1UUID = targetSystemGroup.value.split('.')[1];

		const keys = [];
		const vals = [];

		sourceSystems.forEach((sourceSystem) => {
			const sourceSystemM1UUID = sourceSystem.value.split('.')[1];
			keys.push(`system_group.${sourceSystemGroupM1UUID}.system.${sourceSystemM1UUID}`);
			vals.push(false);
			keys.push(`system_group.${targetSystemGroupM1UUID}.system.${sourceSystemM1UUID}`);
			vals.push(true);
		});

		app.setState({
			loading: true,
		});

		app.setValue('system_assignment', keys, vals, () => {
			app.saveData('system_assignment');
			app.openModal({
				modalSize:  1,
				showModal:  true,
				modalProps: {
					title: 'Assignment Finished',
					jsx:   (
						<SystemAssignmentConfirmationModal
							closeModal={() => {
								app.closeModal();
								this.clearState();
								this.getAccountsData();
							}}
							message={`Moved ${sourceAccountsLabel} to a different system group on the same account`}
						/>
					),
				},
			});
		});
	}

	async moveSystems() {
		const {
			app,
		} = this.props;

		const {
			sourceAccount,
			sourceSystemGroup,
			targetAccount,
			targetSystemGroup,
		} = this.state;

		if (sourceAccount.value !== targetAccount.value) {
			await this.moveSystemOnDifferentAccount();

			app.setState({
				loading: false,
			});
		} else if (sourceSystemGroup.value !== targetSystemGroup.value) {
			await this.moveSystemOnSameAccount();

			app.setState({
				loading: false,
			});
		} else {
			app.openModal({
				modalSize:  1,
				showModal:  true,
				modalProps: {
					title: 'No operation',
					jsx:   (
						<SystemAssignmentConfirmationModal
							closeModal={app.closeModal.bind(app)}
							message={'This is not doing anything.'}
						/>
					),
				},
			});
		}
	}

	render() {
		const {
			accountsFD,
			sourceAccount,
			sourceSystemGroup,
			sourceSystems,
			targetAccount,
			targetSystemGroup,
			sourceAccountSelectorDisabled,
			sourceSystemGroupSelectorDisabled,
			sourceSystemSelectorDisabled,
			targetAccountSelectorDisabled,
			targetSystemGroupSelectorDisabled,
			sourceAccountOptions,
			sourceSystemGroupOptions,
			sourceSystemOptions,
			targetAccountOptions,
			targetSystemGroupOptions,
		} = this.state;

		return (
			<div className={`systems-page fade-in`}>
				<div className={style.pageHeader}>
					<h2>System Assignment</h2>
					<div className={style.headerDetails}>Move systems between system groups.</div  >
				</div>
				<div className={style.assignmentContent}>
					<div className={style.inputsWrapper}>
						<div className={style.label}>
							<label>Source Account:</label>
						</div>
						<Select
							options={sourceAccountOptions}
							onChange={(e) => {
								const accountM1 = e.value;
								const accountM1UUID = accountM1.split('.')[1];

								this.getSystemGroupsData(accountM1UUID)
									.then(() => {
										const _sourceSystemGroupOptions = this.getAllChildrenSystemGroups(accountM1);

										this.setState({
											sourceAccount:                     e,
											sourceSystemGroup:                 null,
											sourceSystemGroupSelectorDisabled: false,
											sourceSystemGroupOptions:          _sourceSystemGroupOptions,
											sourceSystems:                     null,
											sourceSystemSelectorDisabled:      true,
										});
									});
							}}
							isDisabled={sourceAccountSelectorDisabled}
							value={sourceAccount}
						/>
						<div className={style.label}>
							<label>Source System Group:</label>
						</div>
						<Select
							options={sourceSystemGroupOptions}
							onChange={(e) => {
								const systemsUnderSystemGroup = Object.keys(accountsFD[e.value])
									.filter((key) => {
										return key.startsWith('system.');
									});

								const _sourceSystemOptions = Object.entries(accountsFD)
									.filter(([ key, ]) => {
										return systemsUnderSystemGroup.includes(key);
									})
									.map(([ systemUUID, systemVal, ]) => {
										return {
											label: systemVal.display_name,
											value: systemUUID,
										};
									});
								this.setState({
									sourceSystemGroup:            e,
									sourceSystems:                null,
									sourceSystemSelectorDisabled: false,
									sourceSystemOptions:          _sourceSystemOptions,
								});
							}}
							isDisabled={sourceSystemGroupSelectorDisabled}
							value={sourceSystemGroup}
						/>
						<div className={style.label}>
							<label>Systems:</label>
						</div>
						<Select
							isMulti={true}
							allowSelectAll={true}
							closeMenuOnSelect={false}
							hideSelectedOptions={false}
							options={sourceSystemOptions}
							onChange={(e) => {
								this.setState({
									sourceSystems: e,
								});
							}}
							isDisabled={sourceSystemSelectorDisabled}
							value={sourceSystems}
						/>
						<div className={style.pageHeader}>
							<h2></h2>
							<div className={style.headerDetails}></div  >
						</div>
					</div>
					<div className={style.inputsWrapper}>
						<div className={style.label}>
							<label>Target Account:</label>
						</div>
						<Select
							options={targetAccountOptions}
							onChange={(e) => {
								const accountM1 = e.value;
								const _targetSystemGroupOptions = this.getAllChildrenSystemGroups(accountM1);

								this.setState({
									targetAccount:                     e,
									targetSystemGroup:                 null,
									targetSystemGroupSelectorDisabled: false,
									targetSystemGroupOptions:          _targetSystemGroupOptions,
								});
							}}
							isDisabled={targetAccountSelectorDisabled}
							value={targetAccount}
						/>
						<div className={style.label}>
							<label>Target System Group:</label>
						</div>
						<Select
							options={targetSystemGroupOptions}
							onChange={(e) => {
								this.setState({
									targetSystemGroup:            e,
									targetSystemSelectorDisabled: false,
								});
							}}
							isDisabled={targetSystemGroupSelectorDisabled}
							value={targetSystemGroup}
						/>
						<div className={style.moveBtnContainer}>
							<br></br>
							<button
								className='button'
								onClick={this.moveSystems.bind(this)}
								disabled={!(sourceAccount && sourceSystemGroup && sourceSystems && targetAccount && targetSystemGroup)}
							>
								Move
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default SystemAssignmentView;