import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
/*import DatePicker from 'react-datepicker';
import kali from 'kali';*/
import style from './EmergencyMessage.module.css';

import { falconURL } from 'Vulture/ENV';
import { connect, JSONCodec, StringCodec } from '../../../../../../node_modules/nats.ws/lib/src/mod.js';

const sc = StringCodec();
const jc = JSONCodec();

class EmergencyMessageComp extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			message: props.message,
			title: props.title,
			type: props.type,

			falconConnected: false,
			falconToggle: false,
		};

		this.nc;
		this.js;

		this.connect = this.connect.bind(this);
	}

	componentDidMount() {
		this.connect();
	}

	async componentWillUnmount() {
		this.nc.close()
			.catch((err) => {

			});
	}

	connect() {
		const {
			component,
		} = this.props;

		const config = {
			servers: falconURL,
		};

		connect(config)
			.then((nc) => {
				const js = nc.jetstream();

				this.nc = nc;
				this.js = js;

				(async () => {
					for await (const s of nc.status()) {
						if (s.type === 'disconnect') {
							this.setState({
								falconConnected: false,
							});
						}

						if (s.type === 'reconnect') {
							this.setState({
								falconConnected: true,
							});
						}
					}
				})().then();

				js.views.kv('bronco_toggle')
					.then(async (kv) => {
						const toggles = await kv.watch({
							key: component.uuid,
						});
						(async () => {
							for await (let toggle of toggles) {
								this.setState({
									falconToggle: Boolean(parseInt(sc.decode(toggle.value))),
								});
							}
						})();
					})
					.catch((err) => {
						console.error(err);
					});

				this.setState({
					falconConnected: true,
				});
			})
			.catch((err) => {
				if (err) {
					console.error(err);

					window.setTimeout(this.connect, 1000);
					return;
				}
			});
	}

	updateToggle(i) {
		const {
			component,
		} = this.props;

		this.js.views.kv('bronco_toggle')
			.then((kv) => {
				kv.put(component.uuid, sc.encode(`${i}`))
					.then(() => {
						this.setState({
							falconToggle: Boolean(i),
						});
					})
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	updateMessage() {
		const {
			component,
		} = this.props;

		const {
			message,
			title,
			type,
		} = this.state;

		const payload = {
			message,
			title,
			type,
		};

		this.js.views.kv('emergency_message')
			.then((kv) => {
				kv.put(component.uuid, jc.encode(payload))
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((err) => {
				console.error(err);
			});
	}

	handleChange(e, field) {
		let { app, } = this.props;
		this.setState({
			[field === 'title' ? 'title' : 'message']: e.target.value,
		});
		let m1Key = `component.${this.props.component.uuid}`;
		let entitySetting = this.props.componentSettingMap[field];
		let m2Key = `setting.${entitySetting.uuid}`;
		let entityName = `${m1Key}.${m2Key}.${entitySetting.type}`;
		app.setValue('', entityName, e.target.value);
	}

	handleChevron(direction) {
		let { app, } = this.props;
		var newType = '';
		if (direction === 'right') {
			if (this.state.type === 'warning') {
				newType = 'emergency'
			} else if (this.state.type === 'emergency') {
				newType = 'urgent'
			} else {
				newType = 'warning'
			}
		} else {
			if (this.state.type === 'warning') {
				newType = 'urgent'
			} else if (this.state.type === 'emergency') {
				newType = 'warning'
			} else {
				newType = 'emergency'
			}
		}
		this.setState({
			type: newType,
		});
		let m1Key = `component.${this.props.component.uuid}`;
		let entitySetting = this.props.componentSettingMap['type'];
		let m2Key = `setting.${entitySetting.uuid}`;
		let entityName = `${m1Key}.${m2Key}.${entitySetting.type}`;
		app.setValue('', entityName, newType);
	}

	render() {
		let buttonDisabled = false;
		if (this.props.app.hasPendingChanges()) {
			buttonDisabled = true;
		}

		if (!this.state.falconConnected) {
			buttonDisabled = true;
		}

		var messageContent = (
			<div className={style.messageContainer}>
				<div className={style.messageBanner}>
					{this.state.type}
				</div>
				<h1>{this.state.title}</h1>
				<p>{this.state.message}</p>
			</div>
		)

		return (
			<>
				<div className={style.container}>
					<div className={`text-small ${style.field}`}>
						<div className={style.label}>Title</div>
						<input
							data-test-id='titleInput'
							type='text'
							placeholder="Insert title here"
							value={this.state.title}
							maxlength="40"
							onChange={(e) => {
								this.handleChange(e, 'title');
							}}
						/>
					</div>
					<div className={`text-small ${style.field}`}>
						<div className={style.label}>Message</div>
						<textarea
							data-test-id='bodyInput'
							placeholder="Insert message body here"
							value={this.state.message}
							maxlength="140"
							onChange={(e) => {
								this.handleChange(e, 'message');
							}}
						/>
					</div>
					<div className={`messagebox ${this.state.type === 'warning' ? style.warning : ""} 
									${this.state.type === 'urgent' ? style.urgent : ""}
									${this.state.type === 'emergency' ? style.emergency : ""}`}>
						<div className={style.background}>
							<div className={style.textcontainer}>
								<div className={style.text}>
									{messageContent}
								</div>
								<div className={style.arrowsContainer}>
									<div className={style.arrowsLeft} onClick={(param) => this.handleChevron('right')}>
										<CustomIcon icon='chevron-left' />
									</div>
									<div className={style.arrowsRight} onClick={(param) => this.handleChevron('left')}>
										<CustomIcon icon='chevron-right' />
									</div>
								</div>
							</div>
						</div>

					</div>
					<div className={style.buttonContainer}>
						{!this.state.falconToggle &&
							<button
								className={`button button-save-lg ${style.activatebutton} ${buttonDisabled ? 'button-disabled' : ''}`}
								onClick={(e) => {
									this.updateToggle(1);
									this.updateMessage();
								}}
							>
								ACTIVATE NOW
							</button>
						}

						{this.state.falconToggle &&
							<button
								className={`button button-save-lg ${style.activatebutton} ${buttonDisabled ? 'button-disabled' : ''}`}
								onClick={(e) => {
									this.updateToggle(0);
								}}
							>
								DEACTIVATE NOW
							</button>
						}
					</div>
				</div>
			</>
		)
	}
}

export default EmergencyMessageComp;