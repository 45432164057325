import axios from 'axios';

import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
} from 'Vulture/ENV.js';

const fetchProviderURL = async function (provider, componentUUID) {
	const session = getSession();

	let url = `${broncoURL}/raven/connect`;
	let body = {
		user:           session.user,
		component_uuid: componentUUID,
		provider,
	};

	try {
		let res = await axios.post(url, body, {});
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

const fetchProviderAuth = async function (provider, componentUUID, authCode) {
	const session = getSession();

	let url = `${broncoURL}/raven/connect/auth`;
	let body = {
		user:           session.user,
		provider,
		component_uuid: componentUUID,
		auth_code:      authCode,
	};

	try {
		let res = await axios.post(url, body, {});
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

const fetchProviderClearAuth = async function (componentUUID) {
	const session = getSession();

	let url = `${broncoURL}/raven/disconnect/auth`;
	let body = {
		user:           session.user,
		component_uuid: componentUUID,
	};

	try {
		let res = await axios.post(url, body, {});
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

const fetchProviderCalendars = async function (provider, componentUUID) {
	const session = getSession();

	let url = `${broncoURL}/raven/calendars`;
	let body = {
		user:           session.user,
		provider,
		component_uuid: componentUUID,
	};

	try {
		let res = await axios.post(url, body, {});
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

const fetchProviderCalendarEvents = async function (provider, componentUUID, calendarID) {
	const session = getSession();

	let url = `${broncoURL}/raven/calendar/events`;
	let body = {
		user:           session.user,
		provider,
		component_uuid: componentUUID,
		calendar_id:    calendarID,
	};

	try {
		let res = await axios.post(url, body, {});
		if (res.status !== 200) {
			debugger;
		}

		return res.data;
	} catch (err) {
		console.error(err);
	}
};

export {
	fetchProviderURL,
	fetchProviderAuth,
	fetchProviderClearAuth,
	fetchProviderCalendars,
	fetchProviderCalendarEvents,
};