import {
	getSession,
} from 'Vulture/Session.js';

import {
	broncoURL,
} from 'Vulture/ENV.js';

const apiVersion = 'v1';
const apiURL = `${broncoURL}/${apiVersion}`;

const defaultHeaders = () => {
	const session = getSession();

	return {
		'Content-Type':        'application/json',
		'X-Auth-Session':      session.session,
		'X-Spark-API-version': window._getEnv('COMMITHASH'),
	};
};

const makeRequest = async (url, method, headers = {}, body) => {
	headers = {
		...defaultHeaders(),
		...headers,
	};

	try {
		let res = await fetch(url, {
			method,
			headers,
			body: body ? JSON.stringify(body) : undefined,
		});

		if (!res.ok) {
			const errorDetails = await res.text();
			throw new Error(`HTTP error: ${res.status} ${res.statusText} - ${errorDetails}`);
		}

		console.log(res);

		try {
			let data = await res.json();

			return data;
		} catch (jsonError) {
			throw new Error(`Failed to parse JSON: ${jsonError.message}`);
		}
	} catch (err) {
		console.error(`Request to ${url} failed:`, err);

		// re-throw error so the promise gets rejected
		throw err;

		// return {
		// 	error: err.message,
		// };
	}
};

const findRecords = async (m1, params = {}) => {
	const url = `${apiURL}/${m1}s`;

	return makeRequest(url, 'GET', {}, params);
};

const findRecord = async (m1, m1UUID) => {
	const url = `${apiURL}/${m1}s/${m1UUID}`;

	return makeRequest(url, 'GET');
};

const findMapRecords = async (m1, m1UUID, m2) => {
	const url = `${apiURL}/${m1}s/${m1UUID}/${m2}s`;

	return makeRequest(url, 'GET');
};

const findMapRecord = async (m1, m1UUID, m2, m2UUID) => {
	const url = `${apiURL}/${m1}s/${m1UUID}/${m2}s/${m2UUID}`;

	return makeRequest(url, 'GET');
};

const createRecord = async (m1, body) => {
	const url = `${apiURL}/${m1}s`;

	return makeRequest(url, 'PUT', {}, body);
};

const createMapRecord = async (m1, m1UUID, m2, body) => {
	const url = `${apiURL}/${m1}s/${m1UUID}/${m2}s`;

	return makeRequest(url, 'PUT', {}, body);
};

const updateRecord = async (m1, m1UUID, body) => {
	const url = `${apiURL}/${m1}s/${m1UUID}`;

	return makeRequest(url, 'POST', {}, body);
};

const updateMapRecord = async (m1, m1UUID, m2, m2UUID, body) => {
	const url = `${apiURL}/${m1}s/${m1UUID}/${m2}s/${m2UUID}`;

	return makeRequest(url, 'POST', {}, body);
};

const deleteRecord = async (m1, m1UUID) => {
	const url = `${apiURL}/${m1}s/${m1UUID}`;

	return makeRequest(url, 'DELETE');
};

const deleteMapRecord = async (m1, m1UUID, m2, m2UUID) => {
	const url = `${apiURL}/${m1}s/${m1UUID}/${m2}s/${m2UUID}`;

	return makeRequest(url, 'DELETE');
};

export default {
	findRecords,
	findRecord,
	findMapRecords,
	findMapRecord,
	createRecord,
	createMapRecord,
	updateRecord,
	updateMapRecord,
	deleteRecord,
	deleteMapRecord,
};