const vultureENV = window._getEnv('ENV');

const broncoURL = window._getEnv('BRONCO_URL');
const falconURL = window._getEnv('FALCON_WS_URL');

export {
	vultureENV,

	broncoURL,
	falconURL,
};