import React from 'react';

class CrowFieldNumber extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	render() {
		const {
			app,
			entity,
		} = this.props;

		let {
			attr = {},
		} = entity || {};

		let {
			value,
		} = this.state;

		if (typeof value === 'undefined') {
			value = 0;
		}

		return (
			<div className="numberPicker">
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<button onClick={(e) => {
					e.preventDefault();
					if (value === 0) {
						return;
					}
					app.setValue('system', entity.name, value - 1);
					this.setState({
						value: value - 1,
					});
				}}>-</button>

				<span>{value}</span>

				<button onClick={(e) => {
					e.preventDefault();
					app.setValue('system', entity.name, value + 1);
					this.setState({
						value: value + 1,
					});
				}}>+</button>
			</div>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.elements.number'] = CrowFieldNumber;
export default CrowFieldNumber;