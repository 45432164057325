export const getFDValue = (flatData = {}, entityName) => {

	const entityLength = entityName.split('.').length;
	const [ m1, m1UUID, m2, m2UUID, attr, ] = entityName.split('.');

	const results = {};

	try {
		if (entityLength === 3) {
			for (const [ m1Key, m1Data, ] of Object.entries(flatData)) {
				if (m1 !== '*' && !m1Key.startsWith(m1)) {
					continue;
				}
				if (m1UUID !== '*' && !m1Key.endsWith(m1UUID)) {
					continue;
				}
				for (const [ m2Key, m2Data, ] of Object.entries(m1Data)) {
					if (m2 !== '*' && !(m2Key === m2)) {
						continue;
					}
					results[m2Key] = m2Data;
				}
			}
		}

		if (entityLength === 4) {
			for (const [ m1Key, m1Data, ] of Object.entries(flatData)) {
				if (m1 !== '*' && !m1Key.startsWith(m1)) {
					continue;
				}
				if (m1UUID !== '*' && !m1Key.endsWith(m1UUID)) {
					continue;
				}
				for (const [ m2Key, m2Data, ] of Object.entries(m1Data)) {
					if (m2 !== '*' && !m2Key.startsWith(m2)) {
						continue;
					}
					if (m2UUID !== '*' && !m2Key.endsWith(m2UUID)) {
						continue;
					}
					results[m2Key] = m2Data;
				}
			}
		}
		if (entityLength === 5) {
			for (const [ m1Key, m1Data, ] of Object.entries(flatData)) {
				if (m1 !== '*' && !m1Key.startsWith(m1)) {
					continue;
				}
				if (m1UUID !== '*' && !m1Key.endsWith(m1UUID)) {
					continue;
				}
				for (const [ m2Key, m2Data, ] of Object.entries(m1Data)) {
					if (m2 !== '*' && !m2Key.startsWith(m2)) {
						continue;
					}
					if (m2UUID !== '*' && !m2Key.endsWith(m2UUID)) {
						continue;
					}
					for (const [ attrKey, attrData, ] of Object.entries(m2Data)) {
						if (attr !== '*' && !(attrKey === attr)) {
							continue;
						}
						results[attrKey] = attrData;
					}
				}
			}
		}

		const keys = Object.keys(results);
		if (keys.length > 0) {
			if (keys.length === 1) {
				return results[keys[0]];
			}

			return results;
		}

		return undefined;

	} catch (err) {
		console.error(err);
	}
};