
export const downloadCSS = async (bucketName, path) => {
	const customCssUrl = `https://${bucketName}.s3.amazonaws.com/${path}`;
	try {
		const response = await fetch(customCssUrl);
		const blob = await response.blob();
		const blobUrl = window.URL.createObjectURL(blob);
		const temporaryAnchor = document.createElement('a');
		temporaryAnchor.style.display = 'none';
		temporaryAnchor.href = blobUrl;

		temporaryAnchor.download = path.split('/')[2];
		document.body.appendChild(temporaryAnchor);
		temporaryAnchor.click();
		window.URL.revokeObjectURL(blobUrl);
		document.body.removeChild(temporaryAnchor);
	} catch (err) {
		console.error('Custom CSS download failed.');
	}
};