import React from 'react';

import {
	getFDValue,
} from 'Vulture/Helpers';

class CrowFieldCustomVultureTextSmall extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	render() {
		const {
			fd,
			entity,
			app,
		} = this.props;

		let {
			attr = {},
		} = entity || {};

		let {
			value,
		} = this.state;

		if (typeof value === 'undefined') {
			value = '';
		}

		if (value === false) {
			value = 'false';
		}

		return (
			<div className={`text-small ${(this.props.classNameList || []).join(' ')}`}>
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<input
					type='text'
					value={value}
					onChange={(e) => {
						if (value === e.target.value) {
							return;
						}

						this.setState({
							value: e.target.value,
						});
					}}
					onBlur={(e) => {
						let newValue = e.target.value.trim();
						if (newValue === getFDValue(fd, entity.name)) {
							return;
						}

						app.setValue('todo', entity.name, newValue);
					}}
					{...attr.htmlattr || {}}
					data-test-id={attr['data-test-id'] || attr.name}
				/>
			</div>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.elements.text.small'] = CrowFieldCustomVultureTextSmall;
export default CrowFieldCustomVultureTextSmall;