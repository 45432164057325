import React from 'react';
import {
	v4 as uuidv4,
} from 'uuid';

import style from './SystemsModal.module.css';
import CustomIcon from '../Common/CustomIcon/CustomIcon';

class SystemsModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			systems:             {},
			systemGroup:         {},
			systemGroupUUID:     '',
			confirmModal:        false,
			confirmModalContent: '',
		};
	}

	componentDidMount() {
		const {
			isEdit,
			parentUUID,
			onlyAddSystems,
			systemGroup,
		} = this.props;

		// edit existing
		if (isEdit) {
			this.getEditDetails(systemGroup);
		}

		var systemGroupUUID;

		// create new
		if (!isEdit) {
			// create new account.system_group
			if (!parentUUID) {
				let {
					systemGroupUUID,
					systemUUID,
				} = this.makeNewGroup();

				this.addNewSystem(systemGroupUUID, systemUUID);
				this.addNewSystemGroup(parentUUID, systemGroupUUID);
			}

			// create new system_group.system_group
			if (parentUUID) {
				// create a new system_group.system
				if (onlyAddSystems) {
					let {
						systemGroupUUID,
						systemUUID,
					} = this.makeNewSystem(parentUUID);

					this.addNewSystem(systemGroupUUID, systemUUID);
				} else {
					let {
						systemGroupUUID,
						systemUUID,
					} = this.makeNewGroup(parentUUID);

					this.addNewSystem(systemGroupUUID, systemUUID);
					this.addNewSystemGroup(parentUUID, systemGroupUUID);
				}
			}
		}

		this.setState({
			systemGroupUUID,
		});
	}

	makeNewGroup(parentSystemGroupUUID = false) {
		const {
			app,
			accountUUID,
			settingMap,
		} = this.props;

		let systemUUID = uuidv4();
		let systemGroupUUID = uuidv4();

		let entityNames = [];
		let values = [];


		// account.*
		if (!parentSystemGroupUUID) {
			entityNames = [
				`account.${accountUUID}.system_group.${systemGroupUUID}`,
				`system_group.${systemGroupUUID}.system.${systemUUID}`,
				`system_group.${systemGroupUUID}.setting.${settingMap.online_expires.uuid}`,
				`system_group.${systemGroupUUID}.setting.${settingMap.online_expires.uuid}.date`,
				`system_group.${systemGroupUUID}.display_name`,
				`system.${systemUUID}.display_name`,
			];
			values = [
				true,
				true,
				true,
				null,
				'',
				'',
			];
		}

		// system_group.*
		if (parentSystemGroupUUID) {
			entityNames = [
				`system_group.${parentSystemGroupUUID}.system_group.${systemGroupUUID}`,
				`system_group.${systemGroupUUID}.system.${systemUUID}`,
				`system_group.${systemGroupUUID}.display_name`,
				`system.${systemUUID}.display_name`,
			];
			values = [
				true,
				true,
				'',
				'',
			];
		}

		app.setValue('system', entityNames, values);

		return {
			systemUUID,
			systemGroupUUID,
		};
	}

	makeNewSystem(parentSystemGroupUUID) {
		const {
			app,
		} = this.props;

		let systemUUID = uuidv4();

		let entityNames = [
			`system_group.${parentSystemGroupUUID}.system.${systemUUID}`,
			`system.${systemUUID}.display_name`,
		];
		let values = [
			true,
			'',
		];

		app.setValue('system', entityNames, values);

		return {
			systemUUID,
			systemGroupUUID: parentSystemGroupUUID,
		};
	}

	displayWarning() {
		this.setState({
			confirmModal:        true,
			confirmModalContent: (
				<>
					<p>System Group and System names and System Group Online Expires are required.</p>
					<div className="confirm-modal-buttons">
						<button className="button" onClick={(e) => {
							e.preventDefault();
							this.setState({
								confirmModal:        false,
								confirmModalContent: '',
							});
						}}>Okay</button>
					</div>
				</>
			),
		});
	}

	checkDeleteAllowed() {
		let deleteAllowed = true;

		this.props.systemGroup.subRows.forEach((subRow) => {
			if (subRow.m1 === 'system_group') {
				deleteAllowed = false;
			}
		});
		return deleteAllowed;
	}

	// TODO:
	// form.delVals(system.${systemUUID}.*)
	// form.delVals(system.${systemUUID}.*.*)
	removePendingSystemInfo(systemGroupUUID, systemUUID) {
		const {
			app,
		} = this.props;

		let entityNames = [
			`system.${systemUUID}`,
			`system_group.${systemGroupUUID}.system.${systemUUID}`,
		];
		app.delValue('system', entityNames);
	}

	getSystemGroupEntities(uuid) {
		const {
			app,
			fd,
			settingMap,
		} = this.props;

		let entityArr = [
			{
				attr: {
					label:          'Name',
					'data-test-id': 'systemGroupName',
				},
				name:      `system_group.${uuid}.display_name`,
				fieldType: 'custom.vulture.elements.text.small',
				style:     style.detail,
			},
			{
				attr: {
					label:          'Salesforce ID',
					'data-test-id': 'systemGroupSalesforceID',
				},
				name:      `system_group.${uuid}.salesforce_id_val`,
				fieldType: 'custom.vulture.elements.text.small',
				style:     style.detail,
			},
			{
				attr: {
					label:          'Project Number',
					'data-test-id': 'systemGroupProjectNumber',
				},
				name:      `system_group.${uuid}.setting.${settingMap['project_number'].uuid}.string`,
				fieldType: 'custom.vulture.elements.text.small',
				style:     style.detail,
			},
			{
				attr: {
					label:          'Timezone',
					'data-test-id': 'systemGroupTimezone',
				},
				name:      `system_group.${uuid}.setting.${settingMap['time_zone'].uuid}.string`,
				fieldType: 'custom.common.date.timezone',
				style:     `${style.detail} ${style.select}`,
			},
			{
				attr: {
					label:          'Subscription Renewal',
					allowClear:     true,
					'data-test-id': 'systemGroupSubscriptionRenewal',
				},
				name:      `system_group.${uuid}.setting.${settingMap['online_expires'].uuid}.date`,
				fieldType: 'custom.common.date',
				style:     style.detail,
			},
			{
				attr: {
					label:          'Latitude',
					'data-test-id': 'systemGroupLatitude',
				},
				name:      `system_group.${uuid}.setting.${settingMap['static_map_latitude'].uuid}.float`,
				fieldType: 'custom.vulture.elements.float',
				style:     style.detail,
			},
			{
				attr: {
					label:          'Longitude',
					'data-test-id': 'systemGroupLongitude',
				},
				name:      `system_group.${uuid}.setting.${settingMap['static_map_longitude'].uuid}.float`,
				fieldType: 'custom.vulture.elements.float',
				style:     style.detail,
			},
			{
				attr: {
					label:          'Location',
					'data-test-id': 'systemGroupLocation',
				},
				name:      `system_group.${uuid}.setting.${settingMap['monarch_location'].uuid}.string`,
				fieldType: 'custom.vulture.elements.text.small',
				style:     style.detail,
			},
			{
				attr: {
					label:          'Zip Code',
					'data-test-id': 'systemGroupZipCode',
				},
				name:      `system_group.${uuid}.setting.${settingMap['zip_code'].uuid}.string`,
				fieldType: 'custom.vulture.elements.text.small',
				style:     style.detail,
			},
		];
		console.log(entityArr);
		return (
			<React.Fragment
				key={uuid}
			>
				{entityArr.map((entity, i) => {
					let field = app.renderEntity(entity, {
						fd,
					});

					return (
						<div key={i} className={entity.style}>
							{field}
						</div>
					);
				})}
			</React.Fragment>
		);
	}

	hasChildren(m1, m1UUID) {
		const {
			fd,
		} = this.props;

		let children = [];

		for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key === `${m1}.${m1UUID}`) {
				for (let [ m2Key, m2Data, ] of Object.entries(m1Data)) {
					if (m2Key.startsWith('setting.')) {
						continue;
					}

					// skip col values
					if (!~m2Key.indexOf('.')) {
						continue;
					}

					// TODO: show user human readable error instead of UUIDs
					const displayName = m2Data['display_name'];

					let [ m2, m2ID, ] = m2Key.split('.');
					children.push(`${m2}: ${m2ID}`);
				}
			}
		}

		// window.form.getMatchingKeys(`${m1}.${m1UUID}.*.*`).forEach((entityKey) => {
		// 	if (form.getValue(entityKey)) {
		// 		const { m2, m2ID, } = form.parseEntityName(entityKey);

		// 		if (m2 === 'setting') {
		// 			return;
		// 		}

		// 		const displayName = form.getValue(`${m2}.${m2ID}.display_name`);
		// 		children.push(`${m2}: ${m2ID}`);
		// 	}
		// });

		if (children.length) {
			const confirmModalContent = (
				<>
					<p>Please delete the following children before deleting the {m1}</p>
					{children.map((child, i) => {
						return <p key={i}>{child}</p>;
					})}
					<div className="confirm-modal-buttons">
						<button className="button" onClick={(e) => {
							e.preventDefault();
							this.setState({
								confirmModal: false,
							});
						}}>Close</button>
					</div>
				</>
			);

			this.setState({
				confirmModalContent,
			});
			return true;
		}

		return false;
	}

	deleteSystemGroup() {
		const {
			app,
		} = this.props;

		const {
			systemGroup,
		} = this.props;
		const systemGroupUUID = systemGroup.id;

		// If the system_group doesn't have children, delete it.
		if (!this.hasChildren('system_group', systemGroupUUID)) {
			const {
				m1, m1ID,
			} = this.props.parentEntity;
			app.setValue('system', `${m1}.${m1ID}.system_group.${systemGroupUUID}`, false, this.props.save);
		}
	}

	deleteSystem(systemGroupUUID, systemUUID) {
		const {
			app,
		} = this.props;

		// If the system doesn't have children, delete it.
		if (!this.hasChildren('system', systemUUID)) {
			const keys = [
				`system_group.${systemGroupUUID}.system.${systemUUID}`,
				`system.${systemUUID}.mac_address`,
			];

			const vals = [
				false,
				null,
			];

			app.setValue('system', keys, vals);

			let {
				systems,
			} = this.state;
			Reflect.deleteProperty(systems, systemUUID);

			this.setState({
				confirmModal:        false,
				confirmModalContent: false,
				systems,
			});
		}
	}

	getSystemEntites(systemUUID, systemGroupUUID, index) {
		const {
			app,
			fd,
		} = this.props;

		let entityArr = [
			{
				attr: {
					label:          'Salesforce ID',
					'data-test-id': `systemSalesforceID--${index}`,
				},
				name:      `system.${systemUUID}.salesforce_id_val`,
				type:      'FIELD',
				fieldType: 'custom.vulture.elements.text.small',
				style:     style.systemID,
			},
			{
				attr: {
					label:          'Name',
					'data-test-id': `systemName--${index}`,
				},
				name:      `system.${systemUUID}.display_name`,
				type:      'FIELD',
				fieldType: 'custom.vulture.elements.text.small',
				style:     style.systemName,
			},
		];

		let isEdit = this.props.isEdit;

		return (
			<div className={style.system} key={systemUUID}>
				{entityArr.map((entity, i) => {
					let field = app.renderEntity(entity, {
						fd,
					});

					return (
						<div key={i} className={entity.style}>
							{field}
						</div>
					);

					// return (
					// 	<div key={i} className={entity.style}>
					// 		{window.form.renderEntity(entity, { fd })}
					// 	</div>
					// );
				})}

				<div
					className={style.delete}
					onClick={(e) => {
						e.preventDefault();
						this.setState({
							confirmModal:        true,
							confirmModalContent:
							<>
								<p>Are you sure you wish to delete this system?</p>
								<div className="confirm-modal-buttons">
									<button className="button-outlined" onClick={(e) => {
										e.preventDefault();
										if (isEdit) {
											this.deleteSystem(systemGroupUUID, systemUUID);
											return;
										}
										if (!isEdit) {
											this.removePendingSystemInfo(systemGroupUUID, systemUUID);
										}
										let elmsToUpdate = this.state.systems;
										Reflect.deleteProperty(elmsToUpdate, systemUUID);
										this.setState({
											systems: elmsToUpdate,
										});
										this.setState({
											confirmModal: false,
										});
									}}>Yes</button>
									<button className="button" onClick={(e) => {
										e.preventDefault();
										this.setState({
											confirmModal: false,
										});
									}}>No</button>
								</div>
							</>,
						});
					}}
				>
					<CustomIcon icon='times-circle'/>
				</div>
			</div>
		);
	}

	getEditDetails(systemGroup) {
		let uuid = systemGroup.id;

		let systemGroupElm = this.state.systemGroup;
		let systemElms = this.state.systems;

		systemGroupElm[uuid] = this.getSystemGroupEntities(uuid);
		systemGroup.subRows.forEach((sg, index) => {
			if (sg.m1 === 'system') {
				let systemUUID = sg.id;
				systemElms[systemUUID] = this.getSystemEntites(systemUUID, uuid, index);
			}
		});

		this.setState({
			systems:     systemElms,
			systemGroup: systemGroupElm,
		});
	}

	addNewSystem(systemGroupUUID, systemUUID) {
		const {
			app,
		} = this.props;

		let elms = this.state.systems;
		elms[systemUUID] = this.getSystemEntites(systemUUID, systemGroupUUID, Object.keys(elms).length);

		this.setState({
			systems: elms,
		});
	}

	addNewSystemGroup(parentUUID, systemGroupUUID) {
		const {
			app,
		} = this.props;

		let elm = this.state.systemGroup;
		elm[systemGroupUUID] = this.getSystemGroupEntities(systemGroupUUID);

		this.setState({
			systemGroup: elm,
			systemGroupUUID,
		});
	}

	getSystems() {
		let elms = this.state.systems;

		return Object.values(elms);
	}

	getSystemGroup() {
		let elm = this.state.systemGroup;

		return Object.values(elm);
	}

	closeModal() {
		window.form.setState({
			vulture: {
				...window.form.state.vulture,
				showModal:  false,
				modalProps: {},
			},
		});
	}

	getAddOrEditPage() {
		let isEdit = this.props.isEdit;
		let elm = null;
		let addElm = null;

		if (!this.props.onlyAddSystems) {
			addElm = (
				<div className={style.detailsColumn}>
					<div className={style.sectionLabel}>Details</div>
					{this.getSystemGroup()}
				</div>
			);
		}

		if (!isEdit) {
			elm = (
				<div className={style.systemsModalContainer}>
					{addElm}
					<div className={style.systemsColumn}>
						<div className={style.sectionLabel}>New Systems</div>
						{this.getSystems()}
					</div>
					<div className={style.buttons}>
						<button
							className='button button-disabled no-click'
							onClick={(e) => {
								e.preventDefault();
							}}
						>
							<CustomIcon icon='file-import'/>Get Data
						</button>
						<button
							className='button-outlined-dark'
							onClick={(e) => {
								e.preventDefault();
								let systemGroupUUID = Object.keys(this.state.systemGroup)[0];
								if (this.props.onlyAddSystems) {
									systemGroupUUID = this.props.parentUUID;
								}
								let {
									systemUUID,
								} = this.makeNewSystem(systemGroupUUID);
								this.addNewSystem(systemGroupUUID, systemUUID);
							}}
						>
							<CustomIcon icon='plus-circle'/>Add System
						</button>
						<button
							className='button'
							onClick={(e) => {
								e.preventDefault();
								let valid = this.props.checkDataValidity();
								if (valid) {
									this.props.save();
								} else {
									this.displayWarning();
								}
							}}
						>
							<CustomIcon icon='save'/>Save
						</button>
					</div>
				</div>
			);
		}
		if (isEdit) {
			elm = (
				<div className={style.systemsModalContainer}>
					<div className={style.detailsColumn}>
						<div className={style.sectionLabel}>Details</div>
						{this.getSystemGroup()}
					</div>
					<div className={style.systemsColumn}>
						<div className={style.sectionLabel}>Systems</div>
						{this.getSystems()}
					</div>
					<div className={style.buttons}>
						<button
							className="button-outlined-dark"
							onClick={(e) => {
								e.preventDefault();
								let deleteAllowed = (
									<>
										<p>Are you sure you want to delete this system group?</p>
										<div className="confirm-modal-buttons">
											<button className="button-outlined" onClick={(e) => {
												e.preventDefault();
												this.deleteSystemGroup();
											}}>Yes</button>
											<button className="button" onClick={(e) => {
												e.preventDefault();
												this.setState({
													confirmModal: false,
												});
											}}>No</button>
										</div>
									</>
								);

								if (!this.checkDeleteAllowed()) {
									deleteAllowed = (
										<>
											<p>Cannot delete a system group that owns other system groups.</p>
											<div className="confirm-modal-buttons">
												<button className="button" onClick={(e) => {
													e.preventDefault();
													this.setState({
														confirmModal: false,
													});
												}}>Okay</button>
											</div>
										</>
									);
								}
								this.setState({
									confirmModal:        true,
									confirmModalContent: deleteAllowed,
								});
							}}
						>
							<CustomIcon icon='trash'/> Delete
						</button>
						<button
							className='button'
							onClick={(e) => {
								e.preventDefault();
								let valid = this.props.checkDataValidity();
								if (valid) {
									this.props.save();
								} else {
									this.displayWarning();
								}
							}}
						>
							<CustomIcon icon='save'/>Save
						</button>
					</div>
				</div>
			);
		}
		return elm;
	}

	render() {
		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal = (
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>
			);
		}

		let elm = this.getAddOrEditPage();
		console.log('SYSTEMMODAL.JS');
		return (
			<React.Fragment>
				{confirmModal}
				{elm}
			</React.Fragment>
		);
	}
}

export default SystemsModal;