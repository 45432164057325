const buildTestData = function (id) {
	if (~window.location.search.indexOf('test=1')) {
		return id;
	}

	return '';
};

const removeWhitespace = function (str) {
	return str.replace(/\s+/g, '');
};

export {
	buildTestData,
	removeWhitespace,
};