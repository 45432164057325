import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';

library.add(fas);

function CustomIcon (props) {

	let icon = '';
	switch (props.icon) {
		case '4k':
		break;
	case 'horizontal':
		icon = <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" >
			<path className="monitorShape" d="M446.4,378.37H50.07c-9.44,0-17.09-7.65-17.09-17.09V110.32c0-9.44,7.65-17.09,17.09-17.09H446.4 c9.44,0,17.09,7.65,17.09,17.09v250.95C463.5,370.72,455.84,378.37,446.4,378.37z"/>
		</svg>;
		break;
	case 'vertical':
		icon = <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" >
			<path className="monitorShape" d="M105.67,433.97V37.63c0-9.44,7.65-17.09,17.09-17.09h250.95c9.44,0,17.09,7.65,17.09,17.09v396.33 c0,9.44-7.65,17.09-17.09,17.09H122.76C113.32,451.06,105.67,443.41,105.67,433.97z"/>
		</svg>;
		break;
	// TODO: Make 4k icons
	case 'horizontal_4k':
		icon = <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" >
			<path className="monitorShape" d="M446.4,378.37H50.07c-9.44,0-17.09-7.65-17.09-17.09V110.32c0-9.44,7.65-17.09,17.09-17.09H446.4 c9.44,0,17.09,7.65,17.09,17.09v250.95C463.5,370.72,455.84,378.37,446.4,378.37z"/>
			<g>
				<path className="svgText" d="M201.27,285.4v-30.28h-51.66v-9.92l49.61-70.99h16.25v69.11h15.57v11.8h-15.57v30.28H201.27z M201.27,243.32 V206.2c0-5.82,0.17-11.63,0.51-17.45h-0.51c-3.42,6.5-6.16,11.29-9.24,16.42l-27.2,37.81v0.34H201.27z"/>
				<path className="svgText" d="M247.8,170.1h14.88v55.6h0.51c3.08-4.45,6.16-8.55,9.07-12.32l35.24-43.28h18.47l-41.74,48.93l44.99,66.37 H311.6l-37.98-56.62l-10.95,12.66v43.96H247.8V170.1z"/>
			</g>
		</svg>;
		break;
	case 'vertical_4k':
		icon = <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" >
			<path className="monitorShape" d="M105.67,433.97V37.63c0-9.44,7.65-17.09,17.09-17.09h250.95c9.44,0,17.09,7.65,17.09,17.09v396.33 c0,9.44-7.65,17.09-17.09,17.09H122.76C113.32,451.06,105.67,443.41,105.67,433.97z"/>
			<g>
				<path className="svgText" d="M201.27,285.4v-30.28h-51.66v-9.92l49.61-70.99h16.25v69.11h15.57v11.8h-15.57v30.28H201.27z M201.27,243.32 V206.2c0-5.82,0.17-11.63,0.51-17.45h-0.51c-3.42,6.5-6.16,11.29-9.24,16.42l-27.2,37.81v0.34H201.27z"/>
				<path className="svgText" d="M247.8,170.1h14.88v55.6h0.51c3.08-4.45,6.16-8.55,9.07-12.32l35.24-43.28h18.47l-41.74,48.93l44.99,66.37 H311.6l-37.98-56.62l-10.95,12.66v43.96H247.8V170.1z"/>
			</g>
		</svg>;
		break;
	case 'is_custom':
		icon = <FontAwesomeIcon className="customCheckCircle" icon={'check-circle'}/>;
		break;
	case 'no-preview':
		icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1500 1500"><title>no-preview</title><path d="M72,69H1427V1432.92H72V69h0M1427,39H72A30,30,0,0,0,42,69V1432.92a30,30,0,0,0,30,30H1427a30,30,0,0,0,30-30V69a30,30,0,0,0-30-30Z"/><path d="M802.19,865.91A52.21,52.21,0,1,1,750,813.7,52.26,52.26,0,0,1,802.19,865.91ZM703.83,600.44l8.87,177.5a15.66,15.66,0,0,0,15.64,14.88h43.3a15.66,15.66,0,0,0,15.64-14.88l8.87-177.5A15.66,15.66,0,0,0,780.51,584h-61A15.66,15.66,0,0,0,703.83,600.44Z"/></svg>;
		break;
	default:
		if (props.size) {
			icon = <FontAwesomeIcon icon={props.icon} color={props.color} size={props.size}/>;
		} else {
			icon = <FontAwesomeIcon icon={props.icon} color={props.color}/>;
		}
	}

	return (
		<React.Fragment>
			{icon}
		</React.Fragment>
	);
}

export default CustomIcon;