import React from 'react';
import JSONEditor from 'jsoneditor';

class JSONField extends React.Component {
	componentDidMount() {
		const {
			handleChange,
			value,
		} = this.props;

		const options = {
			mode:       'code',
			modes:      [ 'code', 'view', 'form', ],
			onValidate: () => {
				let json = JSON.stringify(this.jsoneditor.get());
				handleChange(json);
			},
			onChange: () => {
				if (this.jsoneditor) {
					let currentJson = this.jsoneditor.get();
					// Use revertUneditableChanges only if this.originalJson is defined
					if (this.originalJson) {
						const revertedJson = this.revertUneditableChanges(currentJson, this.originalJson);

						// Update the editor's content only if changes were made to uneditable fields
						if (JSON.stringify(revertedJson) !== JSON.stringify(currentJson)) {
							this.jsoneditor.update(revertedJson);
							handleChange(JSON.stringify(revertedJson)); // Update the change handler with reverted JSON
						} else {
							handleChange(JSON.stringify(currentJson)); // No uneditable fields changed, use current JSON
						}
					} else {
						// Your existing logic for handling empty text
						let text = this.jsoneditor.getText();
						if (text === '') {
							handleChange(null);
						} else {
							handleChange(JSON.stringify(currentJson));
						}
					}
				}
			},
		};

		this.jsoneditor = new JSONEditor(this.container, options);

		try {
			const parsedValue = JSON.parse(value);
			this.jsoneditor.set(parsedValue);

			if (value.includes('"editable":')) {
				this.originalJson = parsedValue;
			}
		} catch (err) {
			console.error(err);
			this.jsoneditor.setText(''); // Display a blank value in the editor on error
		}
	}


	revertUneditableChanges = (currentJson, originalJson) => {
		const revertChanges = (current, original) => {
		  Object.keys(current).forEach((key) => {
			// Check if the key exists in the original JSON and if it's marked as uneditable
				if (original.hasOwnProperty(key) && original['editable'] === false) {
			  // Revert the change if the current value is different from the original value
			  if (key === 'label' && JSON.stringify(current[key]) !== JSON.stringify(original[key])) {
						current[key] = original[key];
			  }
				} else if (typeof current[key] === 'object' && current[key] !== null && original.hasOwnProperty(key)) {
			  // If it's an object (and not marked as uneditable), recurse
			  revertChanges(current[key], original[key]);
				}
		  });
		};

		// Create a deep copy of currentJson to avoid directly mutating the argument
		let revertedJson = JSON.parse(JSON.stringify(currentJson));

		// Start the recursive reverting process
		revertChanges(revertedJson, originalJson);

		return revertedJson;
	};

	componentWillUnmount() {
		if (this.jsoneditor) {
			this.jsoneditor.destroy();
		}
	}

	render() {
		return (
			<div
				className='jsoneditor-react-container'
				ref={(elm) => {
					this.container = elm;
				}}
			/>
		);
	}
}

export default JSONField;