import React from 'react';
import Select from 'react-select';
import { getFDValue } from 'Vulture/Helpers';

class CrowFieldCustomVultureDropDown extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	render() {
		const {
			fd,
			entity,
			app
		} = this.props;

		let {
			attr = {},
			options = [],
		} = entity || {};

		let {
			value,
		} = this.state;

		let defaultOption = null;
		options.forEach((option, i) => {
			if (option.value === getFDValue(fd, entity.name)) {
				defaultOption = i;
			}
		});

		return (
			<div className={`drop-down ${(this.props.classNameList || []).join(' ')}`}>
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<Select
					className={entity.style}
					options={options}
					defaultValue={options[defaultOption]}
					onChange={(e) => {
						if (value === e.value) {
							return;
						}

						this.setState({
							value: e.value,
						});
					}}
					onBlur={(e) => {
						if (this.state.value === getFDValue(fd, entity.name)) {
							return;
						}

						app.setValue('system', entity.name, this.state.value);
					}}
				/>
			</div>
		);
	}

}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.elements.drop.down'] = CrowFieldCustomVultureDropDown;
export default CrowFieldCustomVultureDropDown;