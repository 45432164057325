import React, {
	useCallback,
	useState,
	useEffect,
} from 'react';
import {
	v4 as uuidv4,
} from 'uuid';

import ListingsModal from './ListingsModal';
import ListingsUploadModal from './ListingsUploadModal';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import VultureTable from './VultureTable';

import {
	dlListingsExportAll,
	dlListingsImport,
	dlViewListingsSettings,
	dlListingsTemplate,
} from 'Vulture/DataLayer';

import {
	getFDValue,
} from 'Vulture/Helpers';

// Import styles
import style from './ListingsTable.module.css';
import {
	getSession,
} from 'Vulture/Session';

function filterByProp(rows, id, filterValue) {
	return rows.filter((row) => {
		if (row.original.vals[id]) {
			const rowValue = row.original.vals[id].toLowerCase();
			return rowValue.includes(filterValue.toLowerCase());
		}
	});
}

const VultureListingsTable = (props) => {
	const {
		app,
		accountUUID,
		activeSystem,
		fd,
		array,
		columnsToHide,
		tableSettings,
		activeListingConfig,
		listingGroups,
		listingCollectionHasListingGroupRelation,
		getParentListingData,
		listingConfigToListingsColumnsMap,
		listingToSystemsMap,
		listingConfigMap,
		toggleSelectAllRows,
		listingConfigUUID,
		tieredListings,
		parentsPrimaryColumnData,
		settingMap,
		setGroupValue,
		getChildRelations,
		findRootListings,
		getParentComponentName,
		uniqueSettingsListForAdd,
		listingsConfig,
		pendingChanges,
		listingToListingCollectionsMap,
		updateListing,
		toggleGroup,
		listingWidthCols,
		openView,
		deleteSelectedListings,
		listingsContentGroupColWidth,
		exportCSV,
		templateCSV,
		listingState,
		allRowsSelected,
		listingConfigHasScheduling,
		listingConfigIsIntegration,
		fetchListingDataByListingConfig,
	} = props;

	const rowsPerPage = 50;
	const [ page, setPage, ] = useState(1);
	const [ pageNavigationBlocked, setPageNavigationBlocked, ] = useState(false);
	const [ filteredRowsCount, setFilteredRowsCount, ] = useState(array.length);

	useEffect(() => {
		if (filteredRowsCount >= rowsPerPage && pageNavigationBlocked === true) {
			setPageNavigationBlocked(false);
		} else if (filteredRowsCount < rowsPerPage && pageNavigationBlocked === false) {
			setPageNavigationBlocked(true);
		}
	}, [ filteredRowsCount, pageNavigationBlocked, page, ]);

	const onFilter = useCallback((_, preFilteredRows) => {
		setFilteredRowsCount(preFilteredRows.length);
	}, []);

	const previousPageAvailable = (page > 1) && !pageNavigationBlocked;
	const nextPageAvailable = (page * rowsPerPage < filteredRowsCount) && !pageNavigationBlocked;

	let canUserImport = false;
	const pg = getSession().pg;
	if (pg === 'iu' || pg === 'ia') {
		canUserImport = true;
	}
	useEffect(() => {
		listingState.setState({
			selectedListings: [],
		});
	}, [ activeSystem, ]);

	let checkIcon = <div className={style.iconWrapper}><CustomIcon icon="check"/></div>;

	let disabledSquare = (
		<div className={style.disabledSquare}>
			<div className={style.checkboxIcon}>
				<CustomIcon icon="check"/>
			</div>
		</div>
	);

	let activeCheckbox = (
		<div className={style.activeCheckbox}>
			<div className={style.checkboxIcon}>
				<CustomIcon icon="check"/>
			</div>
		</div>
	);

	let listingStaticColumns = [
		{
			id:       'checkbox',
			Header:   checkIcon,
			accessor: (d) => {
				return d.isChecked ? activeCheckbox : disabledSquare;
			},
			disableFilters: true,
		},
	];

	if (listingConfigHasScheduling) {
		listingStaticColumns.push({
			id:       'scheduling',
			Header:   'Scheduling',
			accessor: (d) => {
				return d.schedule ? activeCheckbox : disabledSquare;
			},
			disableFilters: true,
		});
	}

	let columnKeys = Object.keys(tableSettings);
	columnKeys.forEach((columnKey, i) => {
		let header = tableSettings[columnKey];

		if (tableSettings[columnKey].includes('\n')) {
			header = tableSettings[columnKey].split('\n')[0];
		}

		if (i === 0) {
			listingStaticColumns.push(
				{
					id:       `${columnKey}`,
					Header:   header,
					accessor: (d) => {
						return d.vals[columnKey];
					},
					Cell: function Cell(row) {
						return (
							<div className={style.name} key={`${i}`}>
								{row.row.original.vals[columnKey]}
								{row.row.original.tags}
							</div>
						);
					},
					filter: filterByProp,
				},
				{
					id:       'columnToHide',
					Header:   'Hidden column for filtering',
					accessor: columnKey,
				}
			);
		}
		if (i > 0) {
			listingStaticColumns.push(
				{
					id:       `${columnKey}`,
					Header:   header,
					accessor: (d) => {
						return d.vals[columnKey];
					},
				}
			);
		}
	});

	if (activeListingConfig && activeListingConfig.root) {
		listingGroups.forEach((listingCollection, i) => {
			let listingCollectionName = listingCollection.name;
			if (listingCollectionName === '') {
				listingCollectionName = listingCollection.uuid;
			}

			if (listingCollection.grouped) {
				let elms = [];
				for (let systemM1Key of listingCollection.systemM1Keys) {
					elms.push(<div key={systemM1Key}>{fd[systemM1Key].display_name}</div>);
				}

				let rolloverConfigData =
				<>
					<div className={style.rolloverHeader}>
						<CustomIcon icon="info-circle"/> {listingCollection.name}
					</div>
					<div className={style.rolloverContent}>
						<p>This Content Group will display selected<br/> items on the following systems:</p>
						<br/>
						{elms}
					</div>
				</>;

				listingCollectionName = (
					<div id={`${listingCollection.uuid}_${i}`} className={style.configInfo}>
						<div className={style.configInfoHeader}>
							<div className={style.nameContainer}>
								{listingCollection.name}
							</div>
							<div className={style.iconContainer}>
								<CustomIcon icon="info-circle"/>
							</div>
						</div>
						<div className={style.rolloverBox}>{rolloverConfigData}</div>
					</div>
				);
			}

			const SelectAllCheckboxFilter = ({
				column: {
					id,
					preFilteredRows,
				},
			  }) => {
				const checkboxRef = React.useRef();
				const rowCount = preFilteredRows.length;
				let checkedRowCount = preFilteredRows.reduce((count, row) => {
				  return count + (listingCollectionHasListingGroupRelation(listingCollection, row.original) ? 1 : 0);
				}, 0);

				const [ checked, setChecked, ] = useState(checkedRowCount === rowCount);

				const onChange = (e) => {
				  let targetChecked = e.target.checked;
				  let toggleRows = [];
				  let groupID = id.split('.')[1];

				  preFilteredRows.forEach((row) => {
						let hasGreenCheck = listingCollectionHasListingGroupRelation(listingCollection, row.original);
						if ((targetChecked && !hasGreenCheck) || (!targetChecked && hasGreenCheck)) {
					  toggleRows.push(row.index);
						}
				  });

				  if (toggleRows.length > 0) {
						let group = listingGroups.find((listingGroup) => {
							return listingGroup.uuid === groupID;
						});
						toggleGroup(group, ...toggleRows);
				  }

				  setChecked(targetChecked);
				};

				React.useEffect(() => {
				  if (checkboxRef.current) {
						checkboxRef.current.indeterminate = checkedRowCount > 0 && checkedRowCount < rowCount;
				  }
				}, [ checkedRowCount, rowCount, ]);

				return (
				  <input
						type="checkbox"
						ref={checkboxRef}
						checked={checked}
						onChange={onChange}
						onClick={(e) => {
							return e.stopPropagation();
						}}
						title={`Select ${(checked) ? 'None' : 'All'}`}
				  />
				);
			  };

			listingStaticColumns.push(
				{
					id:       `groups.${listingCollection.uuid}`,
					Header:   listingCollectionName,
					Filter:   SelectAllCheckboxFilter,
					accessor: (d) => {
						return listingCollectionHasListingGroupRelation(listingCollection, d) ? activeCheckbox : disabledSquare;
					},
					sortType: (a, b) => {
						return listingCollectionHasListingGroupRelation(listingCollection, b.original) - listingCollectionHasListingGroupRelation(listingCollection, a.original);
					},
					// disableFilters: true,
				}
			);
		});
	}

	if (activeListingConfig && !activeListingConfig.root) {
		let parentUUID = activeListingConfig.parentUUID;
		listingStaticColumns.push(
			{
				id:       `parents.${parentUUID}`,
				Header:   getFDValue(fd, `listing_config.${parentUUID}.display_name`),
				accessor: (data) => {
					return getParentListingData(data);
				},
			}
		);
	}

	const columns = React.useMemo(
		() => {
			return [
				{
					Header:  'ListingsTable',
					columns: listingStaticColumns,
				},
			];
		},
		[ listingStaticColumns, ]
	);

	const data = React.useMemo(() => {
		return array;
	}, [ array, ]
	);

	let activeColumns = listingConfigToListingsColumnsMap[`listing_config.${listingConfigUUID}`];
	let validConfig = true;
	if (Object.keys(activeColumns).length === 0) {
		validConfig = false;
	}

	let disabledUnlessChecked = `button ${style.button} button-disabled`;
	array.map((obj) => {
		if (obj.isChecked) {
			disabledUnlessChecked = 'button';
		}
	});

	let totalWidth = 0;

	Object.keys(listingWidthCols).forEach((listingColumnUUID) => {
		if (!fd[`listing_config.${listingConfigUUID}`]) {
			return;
		}
		if (!getFDValue(fd, `listing_config.${listingConfigUUID}.listing_column.${listingColumnUUID}`)) {
			return;
		}
		totalWidth += getFDValue(fd, `listing_config.${listingConfigUUID}.listing_column.${listingColumnUUID}.width`);
	});

	let parentsWidth = 100 - totalWidth;

	const handleDelete = () => {
		let count = 0;
		let listingsHaveChildren = false;
		let numberOfChildren = 0;
		array.map((obj) => {
			if (obj.isChecked) {
				count++;

				const childUUIDs = [];

				for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
					if (m1Key === `listing.${obj.uuid}`) {
						for (let m2Key of Object.keys(m1Data)) {
							const sections = m2Key.split('.').length;
							const entityKey = `${m1Key}.${m2Key}`;
							if (m2Key.startsWith('listing.') && sections === 2) {
								childUUIDs.push(entityKey);
							}
						}
					}
				}

				numberOfChildren = childUUIDs.length;
				if (childUUIDs.length > 0) {
					listingsHaveChildren = true;
				}
			}
		});
		if (count === 0) {
			return;
		}

		if (count > 1 && listingsHaveChildren) {
			listingState.setState({
				confirmModal:        true,
				confirmModalContent: (
					<>
						<p>Cannot delete listings with children.</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								e.preventDefault();
								listingState.setState({
									confirmModal:        false,
									confirmModalContent: '',
								});
							}}>Okay</button>
						</div>
					</>
				),
			});
			return;
		}
		if (listingsHaveChildren) {
			let message = `This listing currently has ${numberOfChildren} record(s) associated to it. Please delete the associated record(s) first.`;
			listingState.setState({
				confirmModal:        true,
				confirmModalContent: (
					<>
						<p>{message}</p>
						<div className="confirm-modal-buttons">
							<button className="button" onClick={(e) => {
								e.preventDefault();
								listingState.setState({
									confirmModal:        false,
									confirmModalContent: '',
								});
							}}>Okay</button>
						</div>
					</>
				),
			});
			return;
		}
		listingState.setState({
			confirmModal:        true,
			confirmModalContent: (
				<>
					<p>{`Delete ${count} listing(s)?`}</p>
					<div className="confirm-modal-buttons">
						<button className="button" onClick={(e) => {
							e.preventDefault();
							deleteSelectedListings();
							listingState.setState({
								confirmModal:        false,
								confirmModalContent: '',
							});
						}}>Yes</button>
						<button className="button" onClick={(e) => {
							e.preventDefault();
							listingState.setState({
								confirmModal:        false,
								confirmModalContent: '',
							});
						}}>No</button>
					</div>
				</>
			),
		});
	}

	let secondaryNavButtons = [
		{
			onClick: (e) => {
				e.preventDefault();
				dlViewListingsSettings();
				openView();
		    },
			className: 'button',
			icon:      'eye',
			text:      'View',
		},
		{
			onClick: (e) => {
				e.preventDefault();
				handleDelete();
			},

			className: disabledUnlessChecked,
			icon:      'trash',
			text:      'Delete',
		},
		{
			onClick: (e) => {
				e.preventDefault();

				let newListingUUID = uuidv4();

				app.openModal({
				    modalSize:  2,
				    showModal:  true,
				    modalProps: {
				        title:          'Add Listings',
				        customClass:    'test',
				        pendingChanges: false,
				        jsx:            (
				            <ListingsModal
								isEdit={false}
								listingFD={fd}
								pendingChanges={pendingChanges}
								listing={{
								    uuid:            newListingUUID,
								    parent_listings: [],
								}}
								listingToListingCollectionsMap={listingToListingCollectionsMap}
								listingToSystemsMap={listingToSystemsMap}
								listingConfigMap={listingConfigMap}
								findRootListings={findRootListings}
								updateListing={updateListing}
								accountUUID={accountUUID}
								settingMap={settingMap}

								listingConfigHasScheduling={listingConfigHasScheduling}
								listingState={listingState}
								activeListingConfig={activeListingConfig}
								listingGroups={listingGroups}
								setGroupValue={setGroupValue}
								getChildRelations={getChildRelations}
								getParentComponentName={getParentComponentName}
								uniqueSettingsListForAdd={uniqueSettingsListForAdd}
								listingConfigUUID={listingConfigUUID}
								parentsPrimaryColumnData={parentsPrimaryColumnData}
								tieredListings={tieredListings}
				            />
				        ),
				    },
				});
			},
			className: 'button',
			icon:      'plus',
			text:      'Add New',
		},
		{
			onClick: (e) => {
				e.preventDefault();
				if (!canUserImport) {
					return;
				}
				dlListingsImport();
				app.openModal({
				    modalSize:  2,
				    showModal:  true,
				    modalProps: {
				        title:          'Import Listings',
				        customClass:    'test',
				        pendingChanges: false,
				        jsx:            (
				            <ListingsUploadModal
								accountUUID={accountUUID}
								listingConfigUUID={listingConfigUUID}
								tableSettings={tableSettings}
								listingConfigMap={listingConfigMap}
								fetchListingDataByListingConfig={fetchListingDataByListingConfig}
				            />
				        ),
				    },
				});
			},
			className: 'button'  + (canUserImport ? '' : ' button-disabled'),
			icon:      'cloud-upload-alt',
			text:      'Import',
		},
		{
			onClick: (e) => {
				e.preventDefault();
				dlListingsExportAll();
				exportCSV();
			},
			className: 'button',
			icon:      'file-download',
			text:      'Export' + (listingState.state.selectedListings.length > 0 ? ' Selected' : ' All'),
		},
		{
			onClick: (e) => {
				e.preventDefault();
				dlListingsTemplate();
				templateCSV();
			},
			className: 'button',
			icon:      'file-download',
			text:      'Get Template',
		},
		{
			className: 'button' + (previousPageAvailable ? '' : ' button-disabled'),
			onClick:   () => {
				if (previousPageAvailable) {
					setPage(page - 1);
				}
			},
			text: '< Previous',
		},
		{
			className: 'button' + (nextPageAvailable ? '' : ' button-disabled'),
			onClick:   () => {
				if (nextPageAvailable) {
					setPage(page + 1);
				}
			},
			text: 'Next >',
		},
	];

	if (listingConfigIsIntegration) {
		secondaryNavButtons = [
			{
				onClick: (e) => {
					e.preventDefault();
					dlViewListingsSettings();
					openView();
				},
				className: 'button',
				icon:      'eye',
				text:      'View',
			},
			{
				onClick: (e) => {
					e.preventDefault();
					handleDelete();
				},
	
				className: disabledUnlessChecked,
				icon:      'trash',
				text:      'Delete',
			},
			{
				onClick: (e) => {
					e.preventDefault();
					dlListingsExportAll();
					exportCSV();
				},
				className: 'button',
				icon:      'file-download',
				text:      'Export' + (listingState.state.selectedListings.length > 0 ? ' Selected' : ' All'),
			},
			{
				className: 'button' + (previousPageAvailable ? '' : ' button-disabled'),
				onClick:   () => {
					if (previousPageAvailable) {
						setPage(page - 1);
					}
				},
				text: '< Previous',
			},
			{
				className: 'button' + (nextPageAvailable ? '' : ' button-disabled'),
				onClick:   () => {
					if (nextPageAvailable) {
						setPage(page + 1);
					}
				},
				text: 'Next >',
			},
		];
	}

	const tertiaryNavButtons = [
		{
			className: 'button' + (previousPageAvailable ? '' : ' button-disabled'),
			onClick:   () => {
				if (previousPageAvailable) {
					setPage(page - 1);
				}
			},
			text: '< Previous',
		},
		{
			className: 'button' + (nextPageAvailable ? '' : ' button-disabled'),
			onClick:   () => {
				if (nextPageAvailable) {
					setPage(page + 1);
				}
			},
			text: 'Next >',
		},
	];

	const theadColStyles = (column) => {
		let colStyles = {};
		if (listingWidthCols[column.id]) {
			let headerInfo = listingWidthCols[column.id];
			if (activeListingConfig.root) {
				colStyles = {
					width:    `${headerInfo.width}vw`,
					left:     `${headerInfo.left}vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `${headerInfo.width}vw`,
				};
			}
		} else if (column.id === 'checkbox') {
			if (activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `0vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		} else if (column.id === 'scheduling') {
			if (props.activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `5vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		} else if (column.id.startsWith('groups.')) {
			colStyles = {
				width: `${listingsContentGroupColWidth}vw`,
			};
		} else if (column.id.startsWith('parents.')) {
			colStyles = {
				width: `${parentsWidth}vw`,
			};
		}

		return colStyles;
	};

	const filterRowColStyles = (column) => {
		let colStyles = {};
		if (listingWidthCols[column.id]) {
			let headerInfo = listingWidthCols[column.id];
			if (activeListingConfig.root) {
				colStyles = {
					width:    `${headerInfo.width}vw`,
					left:     `${headerInfo.left}vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `${headerInfo.width}vw`,
				};
			}
		} else if (column.id === 'checkbox') {
			if (activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `0vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		}  else if (column.id === 'scheduling') {
			if (props.activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `5vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		} else if (column.id.startsWith('groups.')) {
			colStyles = {
				width: `${listingsContentGroupColWidth}vw`,
			};
		} else if (column.id.startsWith('parents.')) {
			colStyles = {
				width: `${parentsWidth}vw`,
			};
		}

		return colStyles;
	};

	const tbodyColStyles = (cell) => {
		let colStyles = {};
		if (listingWidthCols[cell.column.id]) {
			let cellInfo = listingWidthCols[cell.column.id];
			if (activeListingConfig.root) {
				colStyles = {
					width:    `${cellInfo.width}vw`,
					left:     `${cellInfo.left}vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `${cellInfo.width}vw`,
				};
			}
		} else if (cell.column.id === 'checkbox') {
			if (activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `0vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		} else if (cell.column.id === 'scheduling') {
			if (props.activeListingConfig.root) {
				colStyles = {
					width:    `5vw`,
					left:     `5vw`,
					position: 'sticky',
					zIndex:   5,
				};
			} else {
				colStyles = {
					width: `5vw`,
				};
			}
		} else if (cell.column.id.startsWith('groups.')) {
			colStyles = {
				width: `${listingsContentGroupColWidth}vw`,
			};
		} else if (cell.column.id.startsWith('parents.')) {
			colStyles = {
				width: `${parentsWidth}vw`,
			};
		}

		return colStyles;
	};

	const cellOnClick = (cell, row) => {
		return (e) => {
			e.preventDefault();
			if (cell.column.id === 'checkbox') {
				let tableListings = array;
				let selectedListings = listingState.state.selectedListings;
				let listingUUID = cell.row.original.uuid;
				if (~selectedListings.indexOf(listingUUID)) {
					selectedListings.splice(selectedListings.indexOf(listingUUID), 1);
				} else {
					selectedListings.push(listingUUID);
				}
				tableListings[cell.row.index].isChecked = !tableListings[cell.row.index].isChecked;
				listingState.setState({
					tableListings,
					selectedListings,
				});
			} else if (cell.column.id.startsWith('groups.', 0)) {
				let groupID = cell.column.id.split('.')[1];
				let group = listingGroups.filter((listingGroup) => {
					return (listingGroup.uuid === groupID);
				})[0];

				toggleGroup(group, row.index);
			} else {
				// set scroll position manually, not via function because of a race condition
				let scrollX = document.getElementById('table-scroll').scrollLeft;
				let scrollY = document.getElementById('table-scroll').scrollTop;

				let title = 'Edit Listings';

				if (listingConfigIsIntegration) {
					title = 'Listings Details';
				}

				app.openModal({
					scrollX,
					scrollY,
					modalSize:  2,
					showModal:  true,
					modalProps: {
						title,
						customClass:    'test',
						pendingChanges: false,
						jsx:            (
							<ListingsModal
								isEdit={true}
								listingConfigIsIntegration={listingConfigIsIntegration}
								listingFD={fd}
								pendingChanges={pendingChanges}
								listing={array[cell.row.index]}
								listingToListingCollectionsMap={listingToListingCollectionsMap}
								listingToSystemsMap={listingToSystemsMap}
								listingConfigMap={listingConfigMap}
								findRootListings={findRootListings}
								updateListing={updateListing}
								accountUUID={accountUUID}
								settingMap={settingMap}

								listingConfigHasScheduling={listingConfigHasScheduling}
								listingState={listingState}
								activeListingConfig={activeListingConfig}
								setGroupValue={setGroupValue}
								listingGroups={listingGroups}
								getChildRelations={getChildRelations}
								getParentComponentName={getParentComponentName}
								listingCollectionHasListingGroupRelation={listingCollectionHasListingGroupRelation}
								listingsConfig={listingsConfig}
								listingConfigUUID={listingConfigUUID}
								parentsPrimaryColumnData={parentsPrimaryColumnData}
								tieredListings={tieredListings}
							/>
						),
					},
				});
			}
		};
	};

	let areAllRowsChecked = allRowsSelected;
	array.map((obj) => {
		if (!obj.isChecked) {
			areAllRowsChecked = false;
		}
	});

	if (validConfig) {
		return (
			<VultureTable
				style={style}
				columns={columns}
				data={data}
				fd={fd}
				columnsToHide={columnsToHide}
				secondaryNavButtons={secondaryNavButtons}
				tertiaryNavButtons={tertiaryNavButtons}
				cellOnClick={cellOnClick}
				theadColStyles={theadColStyles}
				filterRowColStyles={filterRowColStyles}
				listingTable={true}
				listingConfigUUID={listingConfigUUID}
				listingConfigsWithSystems={props.listingConfigsWithSystems}
				tbodyColStyles={tbodyColStyles}
				allRowsSelected={areAllRowsChecked}
				toggleSelectAllRows={toggleSelectAllRows}
				page={page}
				rowsPerPage={rowsPerPage}
				onFilter={onFilter}
			/>
		);
	}

	return (
		<div>
			This is not a valid Listing Tier. Please return to Account Settings and add at least one data type to this Tier.
		</div>
	);
};

export default VultureListingsTable;