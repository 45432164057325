import React from 'react';

import kali from 'kali';

import ResetPasswordView from 'Vulture/View/ResetPassword.js';
import CustomIcon from 'Crow/Form/Field/Custom/Common/CustomIcon/CustomIcon.js';

import {
	setSession,
	login2FA,
	setSecret,
} from 'Vulture/Session.js';

import style from 'Vulture/View/Login.module.css';
import {
	dlCantLoginPush,
	dlLoginPush,
	dlResetPassword,
} from 'Vulture/DataLayer';
import {
	setEmail,
} from '../Session';

class LoginView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email:    '',
			pass:     '',
			error:    false,
			errorMsg: '',
			mode:     'login',
		};
	}

	async login2FA() {
		const {
			app,
		} = this.props;

		const {
			email,
			pass,
		} = this.state;

		app.load(async () => {
			const {
				err,
				res,
				data,
			} = await login2FA(email, pass);

			if (err) {
				if (res.status === 404) {
					this.setState({
						error:    true,
						errorMsg: 'Invalid email/password.',
					});

					return;
				}

				this.setState({
					error:    true,
					errorMsg: 'Something went wrong.',
					loader:   false,
				});

				return;
			}

			if (data.active === false) {
				this.setState({
					error:    true,
					errorMsg: 'Your User ID is not active, please contact support',
					loader:   false,
				});

				return;
			}

			if (data.is_validated) {
				data.email = email;
				setSession(data);

				app.apiFetchUserAccounts(() => {
					app.redirect({
						to: `/accounts`,
					});
				});
			} else {
				setEmail(email);
				setSecret(data.secret);
				app.redirect({
					to: `/mfa`,
				});
			}
		});
	}

	setMode(mode) {
		this.setState({
			mode,
		});
	}

	forgotPassword() {
		const email = this.state.email;

		let emailValidationRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
		if (!emailValidationRegex.test(email)) {
			this.setState({
				error:    true,
				errorMsg: 'Please enter a valid email address.',
			});
			return;
		}

		let broncoURL = `${window._getEnv('BRONCO_URL')}/password/forgot`;

		new kali({
			body: {
				email,
			},
			headers: {
				'content_type': 'application/json',
			},
			method: 'POST',
		}).post(broncoURL, {
			success: (_kali, res, contents) => {
				this.setMode('emailSent');

				this.setState({
					emailSending: false,
					error:        false,
					errorMsg:     '',
				});
			},

			failure: (_kali, err) => {
				console.error(err);
				this.setState({
					error:    true,
					errorMsg: 'Something went wrong. Please try again.',
				});
			},
		});
	}

	renderLoginForm() {
		if (this.props.mode && this.props.mode !== this.state.mode && !window.location.pathname.startsWith('login')) {
			this.setState({
				mode: this.props.mode,
			});
		} else if (this.state.mode === 'resetPassword' && window.location.pathname.startsWith('/login')) {
			this.setState({
				mode: 'login',
			});
		}

		const {
			app,
		} = this.props;

		const {
			mode,
		} = this.state;

		let loginForm;

		// TODO: mode adjusts style
		// TODO: implement mode with true switching
		// TODO: track a few more state vars

		switch (mode) {
		// case 'splitScreen':
		// 	break;
		case 'forgotPassword':
			loginForm =
				<form
					onSubmit={(e) => {
						e.preventDefault();
						dlResetPassword();
						this.forgotPassword();
					}}
				>
					<h1>Forgot Password</h1>
					<p>To receive a link to reset your password, please enter your email address below.</p>
					<div className={style.email}>
						<CustomIcon icon='user' />
						<input
							placeholder='Email address'
							onChange={(e) => {
								this.setState({
									email: e.target.value,
								});
							}}
							value={this.state.email}
						/>
					</div>

					{this.state.errorMsg ?
						<div className={style.error}>
							<CustomIcon icon='exclamation-triangle' /> {this.state.errorMsg}
						</div> :
						null
					}

					{this.state.emailSending ?
						<div className={style.loader}>
							<div className="loader loader-bars">
								<span></span>
							</div>
						</div> :
						<button type='submit' data-onclick="Reset Password">
							<CustomIcon icon='paper-plane' /> Reset Password
						</button>
					}

					<div
						className={style.link}
						onClick={() => {
							app.redirect({
								to: '/login',
							});

							this.setMode('login');
						}}
					>&larr; Go back</div>
				</form>;
			break;
		case 'resetPassword':
			loginForm = (
				<ResetPasswordView
					app={app}
					// router={router}
					setMode={this.setMode.bind(this)}
				/>
			);
			break;
		case 'register':
			loginForm = (
				// make this more generic
				<ResetPasswordView
					app={app}
					// router={router}
					setMode={this.setMode.bind(this)}
					isRegister={true}
				/>
			);
			break;
		case 'emailSent':
			loginForm =
				<div>
					<h1>Thank You!</h1>
					<p>If the email entered is found associated with an account, we will email password reset instructions.</p><p>Be sure to check your spam folders, just in case.</p>
					<div
						className={style.link}
						onClick={() => {
							app.redirect({
								to: '/login',
							});

							this.setMode('login');
						}}
					>&larr; Go back</div>
				</div>;
			break;
		default:
			loginForm = (
				<form
					onSubmit={(e) => {
						e.preventDefault();
						this.login2FA();
					}}
					data-test-id='loginForm'
				>
					<h1>Login</h1>
					<div className={style.email}>
						<CustomIcon icon='user' />
						<input
							data-test-id='loginEmail'
							placeholder='Email address'
							onChange={(e) => {
								this.setState({
									email: e.target.value,
								});
							}}
							value={this.state.email}
						/>
					</div>
					<div className={style.password}>
						<CustomIcon icon='lock' />
						<input
							data-test-id='loginPassword'
							placeholder='Password'
							type='password'
							onChange={(e) => {
								this.setState({
									pass: e.target.value,
								});
							}}
							value={this.state.pass}
						/>
					</div>
					{this.state.error ?
						<div className={style.error}>
							<CustomIcon icon='exclamation-triangle' /> {this.state.errorMsg}
						</div> :
						null
					}
					<button
						data-test-id='loginButton'
						type='submit' data-onclick="Login"
						onClick={() => {
							dlLoginPush({
								method: 'TouchSource Cloud Login',
							});
						}}
					>
						<CustomIcon icon='sign-in-alt' /> Login
					</button>

					<div
						className={style.link}
						onClick={() => {
							dlCantLoginPush();

							app.redirect({
								to: `/password/forgot`,
							});

							this.setMode('forgotPassword');

							this.setState({
								emailSending: false,
							});
						}}
					> Can't login?</div>
				</form>
			);
		}

		return loginForm;
	}

	render() {
		const {
			mode,
		} = this.state;

		return (
			<>
				<div className={style.header}>
					<div className={style.left}>
						<div className={style.logo}>
							{/* <img src='https://ts-public-images.s3.amazonaws.com/touchsource-cloud.png' alt="The Touchsource Cloud logo" /> */}
							<img src='https://ts-public-images.s3.amazonaws.com/TouchSourceSPARK_logo_FINAL_TS_whiteblue.png' alt='The Touchsource Cloud Beta logo' />
						</div>
					</div>
				</div>
				<div className={`${style[`${mode}`]} ${style.defaultContainer}`}>
					<div className={style.formContainer}>
						<div className={style.form}>
							{this.renderLoginForm()}
							<div className={style.links}>
								<div>Secure login subject to our <br /><a target='_blank' rel='noreferrer' href='https://ts-public-documents.s3.amazonaws.com/Terms2020.pdf'>Terms</a> & <a target='_blank' rel='noreferrer' href='https://ts-public-documents.s3.amazonaws.com/TouchSource+Privacy+Policy_v1.pdf'>Privacy Policies</a></div>
							</div>
						</div>
					</div>
					<div className={style.marketingContainer}>
						<div className={style.marketingContent}>
							{<img src='https://ts-public-images.s3.amazonaws.com/TouchsourceSPARKLogo_allwhite.png' alt='The Touchsource Cloud Next Gen Platform logo' />}
							{/*<img src='https://ts-public-images.s3.amazonaws.com/touchsource-cloud-beta.png' alt="The Touchsource Cloud Beta logo" />*/}
							<p>Powerfully simple place-based communications</p>
							{/* <button className='button-white'>CTA Button</button> */}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default LoginView;