import React, {
	useCallback,
	useEffect,
	useState,
} from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import SlideshowModal from './SlideshowModal';
import {
	dlViewSlideshowSettings,
} from 'Vulture/DataLayer';

// Import styles
import style from './SlideshowTable.module.css';
import VultureTable from './VultureTable';

const daysFilter = (rows, id, filterValue) => {
	return rows.filter((row) => {
		let isInAllSearch = false;
		if (row.original.daysForSearch) {
			let isInSearch = true;
			const rowValue = row.original.daysForSearch;
			const filterValues = filterValue.split(' ');
			filterValues.forEach((val) => {
				let inSearch = false;
				if (val === '' || !isInSearch) {
					return;
				}
				rowValue.forEach((rowVal) => {
					if (rowVal.includes(val)) {
						inSearch = true;
					}
				});
				isInSearch = inSearch;
			});
			isInAllSearch = isInSearch;
		}
		return isInAllSearch;
	});
};

const VultureSlideshowTable = (props) => {
	const {
		app,
		fd,
		columnsToHide,
		toggleGroup,
		systemToSystemGroupObjects,
		array,
		accountUUID,
		componentUUID,
		addObject,
		editObject,
		deleteObjects,
		copyImageFromLibrary,
		checkGroupForComponentRelation,
		openView,
		exportCSV,
	} = props;

	const rowsPerPage = 50;
	const [ showAssigned, setShowAssigned, ] = useState(true);
	const [ page, setPage, ] = useState(1);
	const [ pageNavigationBlocked, setPageNavigationBlocked, ] = useState(false);
	const [ filteredRowsCount, setFilteredRowsCount, ] = useState(array.length);

	useEffect(() => {
		if (filteredRowsCount >= rowsPerPage && pageNavigationBlocked === true) {
			setPageNavigationBlocked(false);
		} else if (filteredRowsCount < rowsPerPage && pageNavigationBlocked === false) {
			setPageNavigationBlocked(true);
		}
	}, [ filteredRowsCount, pageNavigationBlocked, ]);

	const onFilter = useCallback((_, preFilteredRows) => {
		setFilteredRowsCount(preFilteredRows.length);
	}, []);

	const previousPageAvailable = (page > 1) && !pageNavigationBlocked;
	const nextPageAvailable = (page * rowsPerPage < filteredRowsCount) && !pageNavigationBlocked;

	let systems = [];
	let disabledSquare = (
		<div className={style.disabledSquare}>
			<div className={style.checkboxIcon}>
				<CustomIcon icon="check"/>
			</div>
		</div>
	);

	let activeCheckbox = (
		<div className={style.activeCheckbox}>
			<div className={style.checkboxIcon}>
				<CustomIcon icon="check"/>
			</div>
		</div>
	);

	let slideshowColumns = [
		{
			id:             'imgElm',
			Header:         'Image',
			accessor:       'imgElm',
			disableFilters: true,
		},
		{
			id:       'description',
			Header:   'Name',
			accessor: 'description',
		},
		{
			id:       'resolution',
			Header:   'Resolution',
			accessor: 'resolution',
		},
		{
			id:       'order',
			Header:   'Order',
			accessor: 'order',
		},
		{
			id:       'startDate',
			Header:   'Start Date',
			accessor: 'startDate',
			sortType: (a, b, _, isDescending) => {
				let modifier = isDescending ? 1 : -1;
				if (!a.original.start_date) {
					return modifier * -1;
				}
				if (!b.original.start_date) {
					return modifier * 1;
				}

				const aDate = new Date(a.original.start_date);
				const bDate = new Date(b.original.start_date);
				return aDate > bDate ? 1 : -1;
			},
		},
		{
			id:       'endDate',
			Header:   'End Date',
			accessor: 'endDate',
			sortType: (a, b, _, isDescending) => {
				let modifier = isDescending ? 1 : -1;
				if (!a.original.end_date) {
					return modifier * -1;
				}
				if (!b.original.end_date) {
					return modifier * 1;
				}

				const aDate = new Date(a.original.end_date);
				const bDate = new Date(b.original.end_date);
				return aDate > bDate ? 1 : -1;
			},
		},
		{
			id:       'startTime',
			Header:   'Start Time',
			accessor: 'startTime',
		},
		{
			id:       'endTime',
			Header:   'End Time',
			accessor: 'endTime',
		},
		{
			id:       'daysCol',
			Header:   'Days',
			accessor: 'daysCol',
			filter:   daysFilter,
		},
	];

	let slideshowColumnsToPush = [];

	if (systemToSystemGroupObjects) {
		systemToSystemGroupObjects.forEach((systemToSystemGroupObject, i) => {
			if (systems.includes(systemToSystemGroupObject.systemID)) {
				return;
			}
			let displayName = systemToSystemGroupObject.systemDisplayName;

			if (systemToSystemGroupObject.isBundled) {
				if (systems.includes(systemToSystemGroupObject.bundleID)) {
					return;
				}
				let elms = [];
				systemToSystemGroupObject.systems.forEach((system, j) => {
					let systemDisplayName = fd[system].display_name;
					if (!elms.map((elm) => {
						return elm.props.children;
					}).includes(systemDisplayName)) {
						elms.push(<div key={`${systemDisplayName}_${j}`}>{systemDisplayName}</div>);
					}
				});

				elms = elms.sort((a, b) => {
					if (a.props.children > b.props.children) {
						return 1;
					}
					return -1;
				});

				let rolloverConfigData =
				<>
					<div className={style.rolloverHeader}>
						<CustomIcon icon="info-circle"/> {systemToSystemGroupObject.bundleName}
					</div>
					<div className={style.rolloverContent}>
						<div>This Content Group will display selected items on the following systems:</div>
						<br/>
						{elms}
					</div>
				</>;

				displayName = (
					<div key={`${systemToSystemGroupObject.systemID}_${i}`} className={style.configInfo}>
						<div className={style.configInfoHeader}>
							<div className={style.nameContainer}>
								{systemToSystemGroupObject.bundleName}
							</div>
							<div className={style.iconContainer}>
								<CustomIcon icon="info-circle"/>
							</div>
						</div>
						<div className={style.rolloverBox}>{rolloverConfigData}</div>
					</div>
				);
				systems.push(systemToSystemGroupObject.bundleID);
			} else {
				systems.push(systemToSystemGroupObject.systemID);
			}

			let idToUse = Object.keys(systemToSystemGroupObject.componentUUIDs).filter((uuid) => {
				if (uuid === systemToSystemGroupObject.bundleID) {
					return false;
				}

				return true;
			})[0];

			idToUse = systemToSystemGroupObject.systemID;

			const SelectAllCheckboxFilter = ({
				column: {
					id,
					preFilteredRows,
				},
			}) => {
				const checkboxRef = React.useRef();

				let rowCount = preFilteredRows.length;
				if (rowCount === 0) {
					return null;
				}

				let checkedRowCount = 0;
				for (let i = 0; i < preFilteredRows.length; i++) {
					if (checkGroupForComponentRelation(systemToSystemGroupObject, preFilteredRows[i].original)) {
						checkedRowCount++;
					}
				}

				let allRowsChecked = (checkedRowCount === rowCount);
				const [ checked, setChecked, ] = useState(allRowsChecked);

				const onChange = (e) => {
					let checked = e.target.checked;

					let toggleRows = [];

					let groupID = id.split('.')[1];
					for (let i = 0; i < preFilteredRows.length; i++) {
						let hasGreenCheck = checkGroupForComponentRelation(systemToSystemGroupObject, preFilteredRows[i].original);

						if (checked && !hasGreenCheck) {
							toggleRows.push(preFilteredRows[i].original);
						} else if (!checked && hasGreenCheck) {
							toggleRows.push(preFilteredRows[i].original);
						}
					}

					if (toggleRows.length > 0) {
						toggleGroup(groupID, ...toggleRows);
					}

					setChecked(checked);
				};

				if (checkedRowCount > 0 && checkedRowCount < rowCount) {
					if (checkboxRef.current) {
						checkboxRef.current.indeterminate = true;
					}
				}

				return (
					<input
						type="checkbox"
						ref={checkboxRef}
						checked={checked}
						onChange={onChange}
						onClick={(e) => {
							// prevents sorting when interacting with the checkbox
							e.stopPropagation();
						}}
						title={`Select ${(allRowsChecked) ? 'None' : 'All'}`}
					/>
				);
			};

			slideshowColumnsToPush.push({
				id:       `groups.${idToUse}`,
				Header:   displayName,
				Filter:   SelectAllCheckboxFilter,
				accessor: (d) => {
					return checkGroupForComponentRelation(systemToSystemGroupObject, d) ? activeCheckbox : disabledSquare;
				},
				sortType: (a, b) => {
					return checkGroupForComponentRelation(systemToSystemGroupObject, b.original) - checkGroupForComponentRelation(systemToSystemGroupObject, a.original);
				},
				// disableFilters: true,
			});
		});
	}

	slideshowColumnsToPush = slideshowColumnsToPush.sort((a, b) => {
		if (typeof a.Header !== 'string' && typeof b.Header !== 'string') {
			return 1;
		} else if (typeof a.Header !== 'string') {
			return -1;
		} else if (typeof b.Header !== 'string') {
			return 1;
		}
		return a.Header > b.Header ? 1 : -1;
	});

	slideshowColumnsToPush.forEach((column) => {
		slideshowColumns.push(column);
	});

	const columns = React.useMemo(
		() => {
			return [
				{
					Header:  'SlideshowHeader',
					columns: slideshowColumns,
				},
			];
		},
		[ slideshowColumns, ]
	);

	const data = React.useMemo(() => {
		return array.filter((row) => {
			let assigned = (row.component_uuids || []).length > 0;

			return (showAssigned) ? assigned : !assigned;
		});
	}, [ array, showAssigned, ]);

	const secondaryNavButtons = [
		{
			className: 'button',
			icon:      'list',
			text:      'List',
		},
		{
			className: 'button button-disabled',
			icon:      'images',
			text:      'Gallery',
		},
		{
			onClick: (e) => {
				e.preventDefault();
				dlViewSlideshowSettings();
				openView();
			},
			className: 'button',
			icon:      'eye',
			text:      'View',
		},
		{
			onClick: (e) => {
				e.preventDefault();
				setShowAssigned(!showAssigned);
			},
			className: 'button',
			icon:      'filter',
			text:      (showAssigned) ? 'Assigned Slides' : 'Unassigned Slides',
		},
		{
			className: 'button button-disabled',
			icon:      'tags',
			text:      'Tag',
		},
		{
			className: 'button button-disabled',
			icon:      'trash',
			text:      'Delete',
		},
		{
			className: 'button',
			onClick:   (e) => {
				e.preventDefault();
				app.openModal({
					modalSize:  2,
					showModal:  true,
					modalProps: {
						title:          'Add Slide',
						customClass:    'test',
						pendingChanges: false,
						jsx:            (
							<SlideshowModal
								isEdit={false}
								accountUUID={accountUUID}
								addObject={addObject}
								componentUUID={componentUUID}
								copyImageFromLibrary={copyImageFromLibrary}
								systemToSystemGroupObjects={systemToSystemGroupObjects}
							/>
						),
					},
				});
			},
			icon: 'plus',
			text: 'Add New',
		},
		{
			onClick: (e) => {
				e.preventDefault();
				exportCSV();
			},
			className: 'button',
			icon:      'file-download',
			text:      'Export',
		},
		{
			className: `button` + (previousPageAvailable ? '' : ` button-disabled`),
			onClick:   () => {
				if (previousPageAvailable) {
					setPage(page - 1);
				}
			},
			text: '< Previous',
		},
		{
			className: `button` + (nextPageAvailable ? '' : ` button-disabled`),
			onClick:   () => {
				if (nextPageAvailable) {
					setPage(page + 1);
				}
			},
			text: 'Next >',
		},
	];

	const tertiaryNavButtons = [
		{
			className: `button` + (previousPageAvailable ? '' : ` button-disabled`),
			onClick:   () => {
				if (previousPageAvailable) {
					setPage(page - 1);
				}
			},
			text: '< Previous',
		},
		{
			className: `button` + (nextPageAvailable ? '' : ` button-disabled`),
			onClick:   () => {
				if (nextPageAvailable) {
					setPage(page + 1);
				}
			},
			text: 'Next >',
		},
	];

	const cellOnClick = (cell, row) => {
		return (e) => {
			e.preventDefault();
			if (cell.column.id.startsWith('groups.', 0)) {
				let groupID = cell.column.id.split('.')[1];
				toggleGroup(groupID, row.original);
			} else {
				app.openModal({
					modalSize:  2,
					showModal:  true,
					modalProps: {
						title:          'Edit Slide',
						customClass:    'test',
						pendingChanges: false,
						jsx:            (
							<SlideshowModal
								isEdit={true}
								accountUUID={accountUUID}
								file={cell.row.original}
								editObject={editObject}
								deleteObjects={deleteObjects}
								systemToSystemGroupObjects={systemToSystemGroupObjects}
							/>
						),
					},
				});
			}
		};
	};

	return (
		<VultureTable
			style={style}
			columns={columns}
			data={data}
			fd={fd}
			columnsToHide={columnsToHide}
			secondaryNavButtons={secondaryNavButtons}
			tertiaryNavButtons={tertiaryNavButtons}
			cellOnClick={cellOnClick}
			page={page}
			rowsPerPage={rowsPerPage}
			onFilter={onFilter}
		/>
	);
};

export default VultureSlideshowTable;