import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';

// Styles
import style from './Screenshot.module.css';

import {
	broncoURL,
} from 'Vulture/ENV.js';

import {
	getSession,
} from 'Vulture/Session';

import axios from 'axios';

class ScreenshotModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			displayName:  '',
			macAddress:   '',
			salesforceID: '',
			systemUUID:   '',
			versions:     [],
			currentImage: props.screnshotURL,
			currentDate:  props.obj.lastModified,
			currentIndex: 0,
		};
	}

	componentDidMount() {
		const {
			app,
			obj,
		} = this.props;

		app.state.s3.listObjectVersions({
			Bucket:	   'ts-screen-shots',
			Delimiter: '/',
			Prefix:	   `${obj.imgKey}`,
		},
		(err, data) => {
			if (err) {
				console.error(err);
				return;
			}

			this.setState({
				versions: data.Versions,
			});
		});
		const session = getSession();
		const body = {
			tlc: obj.tlcUUID,
		};
		const headers = {
			'X-Auth-Session': session.session,
		};
		axios.post(`${broncoURL}/tlc`, body, headers)
			.then((result) => {
				this.setState({
					displayName:  result.data.displayName,
					macAddress:   result.data.macAddress,
					salesforceID: result.data.salesforceID,
					systemUUID:   result.data.systemUUID,
				});
			});
	}

	closeModal() {
		const {
			app,
		} = this.props;

		app.closeModal();
	}

	render() {
		const {
			obj,
		} = this.props;

		return (
			<div className={style.screenshotModal}>
				<div className={style.sectionLabel}>{`Screenshot ${this.state.currentIndex + 1}/${this.state.versions.length}`}</div>
				<div className={style.sectionImage}>
					<button
						className={`${style.arrowButton} button`}
						onClick={() => {
							const nextIndex = ((this.state.currentIndex - 1) + this.state.versions.length) % this.state.versions.length;
							const nextImage = `https://ts-screen-shots.s3.amazonaws.com/${(this.state.versions[nextIndex].Key)}?versionId=${this.state.versions[nextIndex].VersionId}`;
							const nextDate = this.state.versions[nextIndex].LastModified;

							this.setState({
								currentIndex: nextIndex,
								currentImage: nextImage,
								currentDate:  nextDate,
							});
						}
						}>
						<CustomIcon icon="arrow-circle-left" />
					</button>
					<div className={style.imageSection}>
						<a href={this.state.currentImage} target="_blank" rel="noopener noreferrer">
							<img id="modalImage" src={this.state.currentImage} alt="test" />
						</a>
					</div>
					<button
						className={`${style.arrowButton} button`}
						onClick={() => {
							const nextIndex = (this.state.currentIndex + 1) % this.state.versions.length;
							const nextImage = `https://ts-screen-shots.s3.amazonaws.com/${(this.state.versions[nextIndex].Key)}?versionId=${this.state.versions[nextIndex].VersionId}`;
							const nextDate = this.state.versions[nextIndex].LastModified;

							this.setState({
								currentIndex: nextIndex,
								currentImage: nextImage,
								currentDate:  nextDate,
							});
						}
						}>
						<CustomIcon icon="arrow-circle-right" />
					</button>
				</div>
				<div className={style.fileSize}>
					{`${obj.resolution}, ${obj.size}`}
				</div>
				<div className={style.twoInfoContainer}>
					<div className={`text-small ${style.description}`}>
						<div className={`label ${style.label}`}>
							<label>System</label>
						</div>
						<input
							value={this.state.displayName}
							type='text'
							disabled
						/>
					</div>
					<div className={`text-small ${style.description}`}>
						<div className={`label ${style.label}`}>
							<label>System UUID</label>
						</div>
						<input
							value={this.state.systemUUID}
							type='text'
							disabled
						/>
					</div>
				</div>
				<div className={style.twoInfoContainer}>
					<div className={`text-small ${style.description}`}>
						<div className={`label ${style.label}`}>
							<label>Mac Address</label>
						</div>
						<input
							value={this.state.macAddress}
							type='text'
							disabled
						/>
					</div>
					<div className={`text-small ${style.description}`}>
						<div className={`label ${style.label}`}>
							<label>Salesforce ID</label>
						</div>
						<input
							value={this.state.salesforceID}
							type='text'
							disabled
						/>
					</div>
				</div>
				<div className={`text-small ${style.description}`}>
					<div className={`label ${style.label}`}>
						<label>Date</label>
					</div>
					<input
						value={this.state.currentDate.toString()}
						type='text'
						disabled
					/>
				</div>
			</div>
		);
	}
}

export default ScreenshotModal;