import React from 'react';

import {
	broncoURL,
} from 'Vulture/ENV';

import {
	getSession,
} from 'Vulture/Session';

import CustomIcon from '../Common/CustomIcon/CustomIcon';

// Styles
import style from './Listings.module.css';

const generateChecksum = async (CSVfile) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = async (e) => {
		  try {
				const fileContent = e.target.result;
				const buffer = new Uint8Array(fileContent);
				const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
				const hashArray = Array.from(new Uint8Array(hashBuffer));
				const checksum = hashArray
					.map((item) => {
						return item.toString(16).padStart(2, '0');
					})
					.join('');
				resolve(checksum);
			} catch (error) {
				reject(error);
			}
		};
		reader.onerror = (error) => {
			reject(error.target.error);
		};
		reader.readAsArrayBuffer(CSVfile);
	});
};

class ListingsUploadModal extends React.Component {
	constructor(props) {
		super(props);

		this.uploadCSVRef = React.createRef();

		this.state = {
			CSVfile:        null,
			fileName:       '',
			lastModified:   '',
			lineCount:      0,
			firstFourLines: [],
		};
	}

	async uploadCSV() {
		const {
			accountUUID,
			listingConfigUUID,
			tableSettings,
			app,
			listingConfigMap,
			fetchListingDataByListingConfig,
		} = this.props;

		const {
			CSVfile,
		} = this.state;

		// logic for getting the uuid to keep ordering state of the columns
		const headers = Object.values(tableSettings);

		let columnHeaders = '';
		headers.forEach((header) => {
			const [ uuid, ] = Object.entries(tableSettings).find(([ , val, ]) => {
				return header === val;
			});

			if (columnHeaders.length === 0) {
				columnHeaders = uuid;
			} else {
				columnHeaders += `,${uuid}`;
			}
		});

		const session = getSession();

		let checksum;
		try {
			checksum = await generateChecksum(CSVfile);
		} catch (err) {
			alert(`Failed doing the checksum: ${err}`);
			this.setState({
				CSVfile: null,
			});
			this.uploadCSVRef.current.value = '';
			return;
		}

		const activeListingConfig = listingConfigMap[listingConfigUUID];

		const getTierLevel = (config, configMap) => {
			let level = 1;
			while (config.parentUUID in configMap) {
				config = configMap[config.parentUUID];
				level++;
			}
			return level;
		};

		const tierLevel = getTierLevel(activeListingConfig, listingConfigMap);

		const body = new FormData();
		body.append('file', CSVfile);
		body.append('user', session.user);
		body.append('account_uuid', accountUUID);
		body.append('listing_config_uuid', listingConfigUUID);
		body.append('listing_column_uuids', columnHeaders);
		body.append('tier', tierLevel);
		body.append('checksum', checksum);

		app.load(async () => {
			const url = `${broncoURL}/listings/import`;

			try {
				const res = await fetch(url, {
					body,
					method: 'POST',
				});

				if (res.status !== 200) {
					const err = await res.text();
					throw new Error(err);
				}

				app.closeModal();
				app.fetchAccount(accountUUID);
				fetchListingDataByListingConfig();
			} catch(err)  {
				alert(`Failed request: ${err}`);
				this.setState({
					CSVfile: null,
				});
				this.uploadCSVRef.current.value = '';
			}

			app.setState({
				loading: false,
			});
		});
	}

	setCSVFile(file) {
		if (file) {
			const reader = new FileReader();
			reader.onload = (evt) => {
				const content = evt.target.result;
				const contentWithoutQuotes = content.replace(/"/g, ''); // Remove all quotation marks
				const lines = contentWithoutQuotes.split('\n');
				const lineCount = lines.length - (lines[lines.length - 1] === '' ? 1 : 0); // Subtract 1 if the last line is empty
				const firstFourLines = lines.slice(0, 4);
				this.setState({
					CSVfile:      file,
					fileName:     file.name,
					lastModified: file.lastModifiedDate.toString(),
					lineCount,
					firstFourLines,
				});
			};
			reader.readAsText(file);
		}
	}

	render() {
		const {
			app,
		} = this.props;

		const {
			CSVfile,
			fileName,
			lastModified,
			lineCount,
			firstFourLines,
		} = this.state;

		return (
			<>
				<div className={style.listingsModal}>
					<div className={style.listingsModalContainer}>
						<div className={style.column1}>
							<button
								onClick={(e) => {
									this.uploadCSVRef.current.click();
								}}
								className="button"
							>
								Upload
							</button>
							<p><span className={style.infoTitle}>Filename: </span>{`${fileName}`}</p>
							<p><span className={style.infoTitle}>Lines: </span>{`${lineCount}`}</p>
							<p><span className={style.infoTitle}>Last Modified: </span>{`${lastModified}`}</p>
							<div className={style.tableContainer}>
								{firstFourLines.map((line, i) => {
									if (i === 0) {
										return (<div className={style.tableRow} key={i}>
											{line.split(',').map((piece, j) => {
												return (
													<b className={style.tableItem} key={`${i}_${j}`}>
														<span>
															{piece}
														</span>
													</b>
												);
											})}
										</div>);
									}
									return (<div className={style.tableRow} key={i}>
										{line.split(',').map((piece, j) => {
											return (
												<span className={style.tableItem} key={`${i}_${j}`}>
													{piece}
												</span>
											);
										})}
									</div>);
								})}
							</div>
						</div>
					</div>
					<div className={style.bottom}>
						<button
							onClick={(e) => {
								app.closeModal();
							}}
							className="button-outlined"
						>
							Cancel
				        </button>
						<button
							onClick={(e) => {
								this.uploadCSV();
							}}
							className={`button ${!CSVfile ? 'button-disabled no-click' : ''}`}
						>
							<CustomIcon icon='save' /> Save
						</button>
					</div>
				</div>
				<input
					onChange={(e) => {
						this.setCSVFile(e.target.files[0]);
					}}
					accept='.csv'
					ref={this.uploadCSVRef}
					type='file'
					style={{
						display: 'none',
					}}
				/>
			</>
		);
	}
}

export default ListingsUploadModal;