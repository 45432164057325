import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import SystemErrorIcon from './SystemErrorIcon';
import SystemsTable from './SystemsTable';
import kali from 'kali';
import Select from 'react-select';

import { buildTestData } from 'Vulture/TestData';

import style from './Systems.module.css';
import SystemsModal from './SystemsModal.js';
import SystemsDetailModal from './SystemsDetailModal.js';
import { dlImagePreviewPush } from 'Vulture/DataLayer';
import { getFDValue } from 'Vulture/Helpers';
import { ConsumerOptsBuilderImpl } from 'nats.ws/lib/nats-base-client/jsconsumeropts';
import { getSession } from 'Vulture/Session';

class SystemsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			accountDropdownEnabled: false,
			accountDropdownJson: {},
			activeAccount: '',
			condorThemeID: '',
			defaultExpandedRows: [],
			isAllRowsExpanded: false,
			orientationID: '',
			renderObj: {},
			systemsTableData: [],
			userRole: '',
			resolutionID: '',
			idsForModal: {},
			systemState: '',
			topLevelSystemGroupID: null,
			nameFilter: '',
			statusFilter: '',
			resolutionFilter: '',
			macAddressFilter: '',
			orientationFilter: '',
			directoryNumberFilter: '',
			projectNumberFilter: '',
			systemUUIDs: [],
			systemStatusInfo: {},
			systemsAboutToExpire: [],
			// Column widths -- should add up to 100
			systemNameColumn: {
				'flexGrow': '1',
			},
			statusColumn: {
				'width': '10em',
			},
			resolutionColumn: {
				'width': '10em',
			},
			macAddressColumn: {
				'width': '10em',
			},
			orientationColumn: {
				'width': '10em',
			},
			directoryNumberColumn: {
				'width': '10em',
			},
			componentGroupColumn: {
				'display': 'none',
			},
			themeColumn: {
				'display': 'none',
			},
		};
	}

	componentDidMount() {
		const {
			fd,
		} = this.props;

		this.queryHummmingbird();

		let condorThemeID;
		let	orientationID;
		let	resolutionID;
		const userRole = getSession().pg;

		let settingNameToSettingUUIDMap = {};
		for (let [m1Key, m1Data] of Object.entries(fd)) {
			if (m1Key.startsWith('setting.')) {
				let m1UUID = m1Key.split('.')[1];
				let m1Name = m1Data['name'];
				settingNameToSettingUUIDMap[m1Data['name']] = m1UUID;

				switch (m1Name) {
				case 'condor_theme':
					condorThemeID = m1UUID;
					break;
				case 'orientation':
					orientationID = m1UUID;
					break;
				case 'resolution':
					resolutionID = m1UUID;
					break;
				}
			}
		}
		this.setAnimPhaseDropdowns();

		this.setState({
			idsForModal: settingNameToSettingUUIDMap,
			condorThemeID,
			orientationID,
			resolutionID,
			userRole,
		}, () => {
			this.makeTable();
		});

		// TODO: move somewhere else?
		var script = document.createElement('script');
		var subscript = document.createElement('script');
		script.text = 'window.STONLY_WID = "fabebc3c-71ad-11ec-82f1-064cee365e5a"';
		subscript.text = "!function(e,t,n,s,o,a,i,p){e.StonlyWidget||((i=e.StonlyWidget=function(){i._api?i._api.apply(i,arguments):i.queue.push(arguments)}).scriptPath=s,i.queue=[],(a=t.createElement(n)).async=!0,(p=new XMLHttpRequest).open('GET',s+'version?v='+Date.now(),!0),p.onreadystatechange=function(){4===p.readyState&&(a.src=s+'stonly-widget.js?v='+(200===p.status?p.responseText:Date.now()),(o=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,o))},p.send())}(window,document,'script','https://stonly.com/js/widget/v2/');"
		document.head.appendChild(script);
		document.head.appendChild(subscript);
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			fd,
			accountUUID,
			app,
		} = this.props;

		const {
			systemStatusInfo
		} = this.state;

		if (JSON.stringify(prevProps.fd) !== JSON.stringify(fd) || JSON.stringify(prevState.systemStatusInfo) !== JSON.stringify(systemStatusInfo)) {
			const settingNameToSettingUUIDMap = {};
			for (let [m1Key, m1Data] of Object.entries(fd)) {
				if (m1Key.startsWith('setting.')) {
					let m1UUID = m1Key.split('.')[1];
					settingNameToSettingUUIDMap[m1Data['name']] = m1UUID;
				}
			}

			this.setState({
				activeAccount: accountUUID,
				accountDropdownJson: [],
				accountDropdownEnabled: false,
			}, () => {
				this.setAnimPhaseDropdowns();
			});
			this.setState({
				idsForModal: settingNameToSettingUUIDMap,
			}, () => {
				this.makeTable();
			});
		}
	}

	setAnimPhaseDropdowns() {
		const {
			app,
			settingMap,
		} = this.props;

		Object.keys(app.state.accountData).forEach((m1Key) => {
			if (m1Key.startsWith('account.')) {
				Object.keys(app.state.accountData[m1Key]).forEach((m2Key) => {
					if (m2Key.startsWith('setting.')) {
						let settingUUID = m2Key.split('.')[1];

						if (settingUUID === settingMap['animation_phase_options'].uuid) {
							this.setState({
								accountDropdownJson: JSON.parse(app.state.accountData[m1Key][m2Key].json),
							});
						}

						if (settingUUID === settingMap['animation_phase_toggle'].uuid) {
							this.setState({
								accountDropdownEnabled: app.state.accountData[m1Key][m2Key].bool,
							}, () => {
								this.makeTable();
							});
						}
					}
				});
			}
		});
	}

	makeTable() {
		const {
			accountUUID,
		} = this.props;

		let sortedTableData = [];
		let systemsTableData = this.findInitialSystemGroupsForTable(accountUUID);
		sortedTableData = Object.values(systemsTableData).sort(this.sortByName);
		this.setState({
			systemsTableData: sortedTableData,
		});

		let diffDays = this.getEarliestExpiration();
		this.props.app.setState({
			nearestSystemExpiration: diffDays,
		})
	}

	queryHummmingbird() {
		let systemStatusInfo = {};
		let macAddresses = [];
		const {
			fd
		} = this.props;

		for (let [m1Key, m1Data] of Object.entries(fd)) {
			if (m1Key.startsWith('system.')) {
				for (let [m2Key, m2Data] of Object.entries(m1Data)) {
					if (m2Key === "mac_address") {
						macAddresses.push(m2Data);
					}
				}
			}
		}


		if (macAddresses.length === 0) {
			return;
		}

		const url = `${window._getEnv('HUMMINGBIRD_URL')}/listdevices`;
		new kali({
			body: {
				'mac_addresses': macAddresses,
			},
			headers: {
				'content_type': 'application/json',
			},
			method: 'POST',
		}).post(url, {
			success: (_kali, res, contents) => {

				if (contents && contents.data) {
					contents.data.forEach((object) => {
						if (object.agent?.tag) {
							systemStatusInfo[object.agent.tag] = {
								conn: object.conn,
								os: object.osdesc,
								ip: object.ip,
							};
						}
					});
				}
				this.setState({
					systemStatusInfo,
				});
			},

			failure: (_kali, res, err) => {
				console.error(err);

				if (res.status === 503) {
					this.props.form.props.on503(true);
				}
			},
		});
	}

	getSystemStatus(macAddress) {
		let statusInfo = {};
		statusInfo.statusText = 'Not Connected';
		statusInfo.statusJSX = <span className="errorIcon"><CustomIcon icon="exclamation-circle" /> {statusInfo.statusText}</span>;

		let systemStatusInfo = this.state.systemStatusInfo;
		if (systemStatusInfo[macAddress]) {
			if (systemStatusInfo[macAddress].conn === 1) {
				statusInfo.statusText = 'Online';
				statusInfo.statusJSX = <span><CustomIcon icon="check-circle" color="green" /> {statusInfo.statusText}</span>;
			}
		}

		return statusInfo;
	}

	submitAndUpdateChanges() {
		const {
			app,
		} = this.props;
		this.setState({
			systemsAboutToExpire: [],
		})
		app.saveDataAndCloseModal('system');
		// app.saveData(app.closeModal.bind(app));
		// this.props.form.postFormData(null, this.closeModal.bind(this));
	}

	checkDataValidity() {
		const {
			app,
			settingMap,
		} = this.props;
		let keys = Object.keys(app.state.pendingChanges);
		let dataValid = true;
		keys.forEach((key, index) => {
			if (key.includes('display_name')) {
				if (app.state.pendingChanges[key] === '') {
					dataValid = false;
				}
			}
			if (key.includes(`setting.${settingMap.online_expires.uuid}.date`)) {
				if (app.state.pendingChanges[key] === '' || app.state.pendingChanges[key] === null) {
					dataValid = false;
				}
			}
		});

		return dataValid;
	}

	addButtonsToSystemGroup(display_name, uuid) {
		const {
			fd,
			settingMap,
		} = this.props;

		let displayName = display_name;
		let onlineExpires = null;
		let projectNumber = '';
		let projectNumberStyle = '';
		
		for (let [m2Key, m2Data] of Object.entries(fd[`system_group.${uuid}`])) {
			if (m2Key === `setting.${settingMap['online_expires'].uuid}`) {
				let dateTimezone = m2Data['date'] || '';
				let yearMonthDate = dateTimezone.split('T')[0];
				let ymd = yearMonthDate.split('-');
				let y = ymd[0];
				let m = ymd[1];
				let d = ymd[2];
				let todayDate = new Date();
				let expirationDate = new Date(yearMonthDate);
				let paymentWarning = (Math.round(todayDate - expirationDate) / (1000 * 60 * 60 * 24 * -1)) < 90;

				if (paymentWarning) {
					let expirationArray = this.state.systemsAboutToExpire;
							expirationArray.push(expirationDate);
					this.setState({
						systemsAboutToExpire: expirationArray,
							})
				}

				let diffTime = Math.abs(todayDate - expirationDate);
				let diffDays = 0;
				let expirationMessage = ` Subscription expired on ${m}.${d}.${y}. Updates made in the user portal will not publish to display. Please contact TouchSource (renewals@touchsource.com) to renew.`

				if (todayDate > expirationDate) {
					diffDays = Math.ceil(-diffTime / (1000 * 60 * 60 * 24));
				} else {
					diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
					expirationMessage = ` Subscription renewal date: ${m}.${d}.${y}. Please renew within ${diffDays} days.`;
				}

				onlineExpires = (
					<div className={style.expirationContainer}>
						{paymentWarning ? <p className={`${diffDays <= 90 && diffDays >= 60 ? style.yellowwarning : ""} 
							${diffDays < 60 && diffDays >= 30 ? style.orangewarning : ""}
							${diffDays < 30 ? style.redwarning : ""}`}>{expirationMessage}</p> : null}
					</div>
				);

				if (this.getEarliestExpiration() === diffDays) {
					this.props.app.setState({
						expirationDate: expirationMessage,
					});
				};
			}

			if (m2Key === `setting.${this.state.idsForModal.project_number}`) {
				projectNumber = m2Data['string'];
				projectNumberStyle = 'projectNumber';
			}
		}

		let pg = this.state.userRole;

		if (pg === 'iu' || pg === 'ia') {
			let editIcon = (
				<div
					title="Edit Hierarcy"
					data-test-id={`editHierarchy--${display_name}`}
					onClick={(e) => {
						e.preventDefault();
						this.openEditModal(uuid);
					}}
				>
					<CustomIcon icon='edit' color='#00BAFF' />
				</div>
			);

			let subGroup = (
				<div
					title="Add Sub Hierarchy"
					data-test-id={`addSubHierarchy--${display_name}`}
					onClick={(e) => {
						this.props.app.openModal({
							modalSize:  2,
							showModal:  true,
							modalProps: {
								title:          'New Hierarchy',
								pendingChanges: false,
								jsx:            (
									<SystemsModal
										app={this.props.app}
										accountUUID={this.props.accountUUID}
										fd={this.props.fd}
										isEdit={false}
										form={this.props.form}
										save={this.submitAndUpdateChanges.bind(this)}
										activeAccount={this.state.activeAccount}
										checkDataValidity={this.checkDataValidity.bind(this)}
										closeModal={this.closeModal.bind(this)}
										idsForModal={this.state.idsForModal}
										parentUUID={uuid}
									/>
								),
							},
						})
					}}
				>
					<CustomIcon icon='folder-plus' color='#00BAFF' />
				</div>
			);

			let subSystem = (
				<div
					title="Add system"
					data-test-id={`addSystem--${display_name}`}
					onClick={(e) => {
						this.props.app.openModal({
							modalSize:  2,
							showModal:  true,
							modalProps: {
								title:          'New System',
								pendingChanges: false,
								jsx:            (
									<SystemsModal
										app={this.props.app}
										accountUUID={this.props.accountUUID}
										fd={this.props.fd}
										isEdit={false}
										form={this.props.form}
										save={this.submitAndUpdateChanges.bind(this)}
										activeAccount={this.state.activeAccount}
										checkDataValidity={this.checkDataValidity.bind(this)}
										closeModal={this.closeModal.bind(this)}
										idsForModal={this.state.idsForModal}
										parentUUID={uuid}
										onlyAddSystems={true}
									/>
								),
							},
						});
					}}
				>
					<CustomIcon icon='plus-circle' color='#00BAFF' />
				</div>
			);
			displayName = (
				<div
					className={style.oneLine}
				>
					<div className={style[projectNumberStyle]}>{projectNumber}</div>
					<div className={style.displayName}>{display_name}</div>
					<div className={style.icons}>{editIcon} {subGroup} {subSystem}</div>
					<div className={style.onlineExpires}>{onlineExpires}</div>
				</div>
			);
		} else {
			displayName = (
				<div
					className={style.oneLine}
				>
					<div className={style[projectNumberStyle]}>{projectNumber}</div>
					<div className={style.displayName}>{display_name}</div>
					<div className={style.onlineExpires}>{onlineExpires}</div>
				</div>
			);
		}

		return displayName;
	}

	findInitialSystemGroupsForTable() {
		const {
			fd,
			accountUUID,
		} = this.props;

		let systemGroupObj = {};
		let updatedSystemGroupObj = {};
		const pg = getSession().pg;

		for (let [m1Key, m1Data] of Object.entries(fd)) {
			if (m1Key === `account.${accountUUID}`) {
				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('system_group.')) {
						let m2Data = fd[m2Key];
						let m2UUID = m2Key.split('.')[1];
						let m2Name = m2Data['display_name'] || '';
						let projectNumber = '';

						let hideGroup = false;

						if (pg !== 'iu' && pg !== 'ia' && pg !== 'ea') {
							hideGroup = true;
							for (let [systemKey, systemData] of Object.entries(m2Data)) {
								if (systemKey.startsWith('system.') || systemKey.startsWith('system_group.')) {
									if (fd[systemKey]) {
										hideGroup = false;
										break; 
									}
								}
							}
						}

						for (let [mKey, mData] of Object.entries(m2Data)) {
							if (mKey === `setting.${this.state.idsForModal.project_number}`) {
								projectNumber = mData['string'];
							}

						}
						if (!hideGroup) {
							systemGroupObj[m2Key] = {
								id: m2UUID,
								name: this.addButtonsToSystemGroup(m2Name, m2UUID),
								m1: 'system_group',
								projectNumber: projectNumber,
								status: '',
								subRows: [],
								components: [],
								path: '',
								searchValues: {
									name: m2Name,
									projectNumber: projectNumber,
									resolution: '',
									status: '',
									macAddress: '',
									orientation: '',
									directoryNumber: '',
								},
							};
						}
						updatedSystemGroupObj = this.findChildrenForTable(m2UUID, systemGroupObj);
					}
				}
			}
		}

		return updatedSystemGroupObj;
	}

	findSystemGroupsForTable(m1ParentKey, systemGroupObj) {
		const {
			fd,
		} = this.props;

		let updatedSystemGroupObj = systemGroupObj;
		const pg = getSession().pg;

		for (let [m1Key, m1Data] of Object.entries(fd)) {
			if (m1ParentKey === m1Key) {
				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('system_group.')) {
						let m2Data = fd[m2Key];
						let m2UUID = m2Key.split('.')[1];
						let m2Name = m2Data['display_name'];
						let hideGroup = false; 

						if (pg !== 'iu' && pg !== 'ia' && pg !== 'ea') {
							hideGroup = true;
							for (let [systemKey, systemData] of Object.entries(m2Data)) {
								if (systemKey.startsWith('system.') || systemKey.startsWith('system_group.')) {
									if (fd[systemKey]) {
										hideGroup = false;
										break;
									}
								}
							}
						}

						const alreadyASubRow = systemGroupObj.subRows.findIndex((element) => {
							if (element.id === m2UUID) {
								return true;
							}
						}) !== -1;

						if (alreadyASubRow) {
							continue;
						}

						let projectNumber = '';

						for (let [mKey, mData] of Object.entries(m2Data)) {
							if (mKey === `setting.${this.state.idsForModal.project_number}`) {
								projectNumber = mData['string'];
							}

						}
						if (!hideGroup) {
							systemGroupObj.subRows.push({
								id: m2UUID,
								name: this.addButtonsToSystemGroup(m2Name, m2UUID),
								m1: 'system_group',
								status: '',
								subRows: [],
								components: [],
								path: '',
								searchValues: {
									name: m2Name,
									projectNumber: projectNumber,
									resolution: '',
									status: '',
									macAddress: '',
									orientation: '',
									directoryNumber: '',
								},
							});
						}
						updatedSystemGroupObj = this.findChildrenForTable(m2UUID, systemGroupObj);
					}
				}
			}
		}

		return updatedSystemGroupObj;
	}

	findChildrenForTable(id, systemGroupObj) {
		const {
			fd,
		} = this.props;

		const pg = getSession().pg;

		let systemM1Keys = [];
		for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key === `system_group.${id}`) {
				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('system.')) {
						systemM1Keys.push(m2Key);
					}
				}
			}
		}

		let systemUUIDToTLCUUIDMap = {};
		for (let m1Key of systemM1Keys) {
			let m1UUID = m1Key.split('.')[1];
			for (let m2Key of Object.keys(fd[m1Key])) {
				if (m2Key.startsWith('component.')) {
					let m2Data = fd[m2Key];
					let m2UUID = m2Key.split('.')[1];

					systemUUIDToTLCUUIDMap[m1UUID] = m2UUID;
				}
			}
		}

		for (let [m1Key, m1Data] of Object.entries(fd)) {
			if (m1Key === `system_group.${id}`) {
				let m1UUID = m1Key.split('.')[1];

				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('system.')) {
						let m2Data = fd[m2Key];
						let m2UUID = m2Key.split('.')[1];
						let m2Name = m2Data['display_name'];

						if ((pg !== 'iu' && pg !== 'ia') && m2Data['hidden']) {
							continue;
						}
						let orientationValue = '';
						Object.keys(m2Data).forEach((key) => {
							if (key.startsWith('setting.')) {
								switch (m2Data[key].string) {
									case 'portraitright':
										orientationValue = 'Portrait (right)';
										break;
									case 'portraitleft':
										orientationValue = 'Portrait (left)';
										break;
									case 'landscapeflipped':
										orientationValue = 'Landscape (flipped)';
										break;
									case 'landscape':
										orientationValue = 'Landscape';
										break;
									default:
									// code block
								}
							}
						});
						let components = this.findComponentsForTable(m2UUID);
						let componentOrientation = components.orientation;
						if (componentOrientation !== 'vertical' && componentOrientation !== 'horizontal') {
							componentOrientation = 'no-preview';
						}
						let componentResolution = components.resolutionForSearch;
						if (!componentResolution) {
							componentResolution = '';
						}

						let resolutionForSearch = '';
						let resolution = '';
						if (m2Data['resolution']) {
							resolutionForSearch = m2Data['resolution'];

							resolution = (
								<div className={style.resolution}>
									{resolutionForSearch.includes(3840) ? <>{resolutionForSearch} <CustomIcon icon="4k" /></> : `${resolutionForSearch}`}
								</div>
							);
						}

						let env = window._getEnv('ENV');
						let tlcUUID = systemUUIDToTLCUUIDMap[m2UUID];

						let d = new Date();
						let screenshotSrc = `https://ts-screen-shots.s3.amazonaws.com/${env}/${tlcUUID}.jpg#${d.getFullYear()}-${d.getMonth()}-${d.getDay()}`;

						let orientation = (
							<div
								data-test-id="systemPreviewWindow"
								className={style.orientationIcon}
								onClick={(e) => {
									e.preventDefault();

									dlImagePreviewPush();

									e.stopPropagation();
									this.openPreviewModal(screenshotSrc, m2Name, env, m2Key);
								}}
							>
								<SystemErrorIcon
									src={screenshotSrc}
								/>
							</div>
						);
						let isSystemEnabled = this.checkForDisabledSystems(m2UUID);
						let animDropdown = <></>
						if (this.state.accountDropdownEnabled && isSystemEnabled) {
							animDropdown = <>
								<span>Active screen: </span>
								<Select
									menuPlacement="auto"
									menuPosition='fixed'
									options={this.state.accountDropdownJson.options}
									value={this.getTLCAnimValues(m2UUID) || { value: '', label: '' }}
									onChange={(e) => {
										this.handleChange(m2UUID, e);
									}}
								/>
							</>
						}

						let hasMobile = false;

						if (fd?.['component.' + tlcUUID]) {
							hasMobile = Object.keys(fd['component.' + tlcUUID]).findIndex((m2Key) => {
								if (fd[m2Key]?.description === 'mobile') {
									return true;
								}
							}) !== -1;
						}

						const nameWithLinks = (
							<div
								className={style.systemName}	
							>
								<div
									alt='Open Preview'
									className={style.condorPreviewIcon}
									onClick={(e) => {
										e.stopPropagation();

										if (!tlcUUID) {
											return;
										}

										this.openIFramePreviewModal(m2Name, env, `system.${m2UUID}`);
									}}
								>
									<CustomIcon icon='eye' color={tlcUUID ? '#4BA046' : '#4B4B4B'} />
								</div>
								<div
									alt='Open Mobile App'
									className={style.condorMobileIcon}
									onClick={(e) => {
										e.stopPropagation();

										if (!tlcUUID || !hasMobile) {
											return;
										}

										this.openIframeMobileModal(m2Name, env, tlcUUID);
									}}
								>
									<CustomIcon icon='mobile-alt' color={(tlcUUID && hasMobile) ? '#4BA046' : '#aeaeae'} />
								</div>
								{m2Name}
							</div>
						);

						let orientationAndName = (
							<>
								<div className={style.systemTitle}>
									{orientation} {nameWithLinks}
								</div>
								<div className={style.animDropdown}>
									{animDropdown}
								</div>
							</>
						);

						let macAddress = m2Data['mac_address'] || '';
						let directoryNumber = m2Data.directory_number;
						let statusInfo = this.getSystemStatus(macAddress);

						if (systemGroupObj[m1Key]) {
							systemGroupObj[m1Key].subRows.push({
								id: m2UUID,
								name: orientationAndName,
								m1: 'system', // m2,
								macAddress,
								resolution,
								directoryNumber,
								orientationValue,
								status: statusInfo.statusJSX,
								subRows: [],
								components,
								searchValues: {
									name: m2Name,
									projectNumber: '',
									resolution: resolutionForSearch,
									status: statusInfo.statusText,
									macAddress: macAddress,
									orientation: orientationValue,
									directoryNumber: (m2Data.directory_number) ? m2Data.directory_number.toString() : '',
								},
							});
							systemGroupObj[m1Key].subRows.sort(this.sortByM1AndName);
						} else if (systemGroupObj.subRows) {
							systemGroupObj.subRows.forEach((subRow, i) => {
								if (subRow.id === m1UUID) {
									const alreadyASubRow = systemGroupObj.subRows[i].subRows.findIndex((element) => {
										if (element.id === m2UUID) {
											return true;
										}
									}) !== -1;

									if (alreadyASubRow) {
										return;
									}

									let directoryNumber = m2Data.directory_number;
									systemGroupObj.subRows[i].subRows.push({
										id: m2UUID,
										name: orientationAndName,
										m1: 'system', // m2,
										macAddress,
										resolution,
										status: statusInfo.statusJSX,
										orientationValue,
										directoryNumber,
										subRows: [],
										components,
										searchValues: {
											name: m2Name,
											projectNumber: '',
											resolution: resolutionForSearch,
											status: statusInfo.statusText,
											macAddress: (m2Data.mac_address) ? m2Data.mac_address.replaceAll(':', '') : '',
											orientation: orientationValue,
											directoryNumber: (m2Data.directory_number) ? m2Data.directory_number.toString() : '',
										},
									});
									systemGroupObj.subRows[i].subRows.sort(this.sortByM1AndName);
								}
							});
						}
					}
					if (m2Key.startsWith('system_group.')) {
						if (systemGroupObj[m1Key]) {
							systemGroupObj[m1Key] = this.findSystemGroupsForTable(m1Key, systemGroupObj[m1Key]);
						}
					} else if (systemGroupObj.subRows) {
						systemGroupObj.subRows = systemGroupObj.subRows.map((subRow) => {
							if (subRow.id === m1UUID) {
								subRow = this.findSystemGroupsForTable(m1Key, subRow);
							}
							return subRow;
						});
					}
				}
			}
		}

		return systemGroupObj;
	}

	findComponentsForTable(id) {
		const {
			fd,
		} = this.props;

		let updatedComponentObj = {};
		for (let [m1Key, m1Data] of Object.entries(fd)) {
			if (m1Key === `system.${id}`) {
				for (let [m2Key, m2Data] of Object.entries(m1Data)) {
					if (m2Key.startsWith('component.')) {
						let m2UUID = m2Key.split('.')[1];

						updatedComponentObj = this.findComponentSettings(m2UUID, updatedComponentObj);

						updatedComponentObj.id = m2UUID;
						updatedComponentObj.name = m2UUID;
						updatedComponentObj.m1 = 'component';
					}
				}
			}
		}

		return updatedComponentObj;
	}

	findComponentSettings(id, obj) {
		const {
			fd,
		} = this.props;

		const {
			condorThemeID,
			orientationID,
			resolutionID,
		} = this.state;

		obj.condor_theme = getFDValue(fd, `component.${id}.setting.${condorThemeID}.string`);
		obj.orientation = getFDValue(fd, `component.${id}.setting.${orientationID}.string`);
		obj.resolutionForSearch = getFDValue(fd, `component.${id}.setting.${resolutionID}.string`);
		if (obj.resolutionForSearch) {
			obj.resolution = (
				<div className={style.resolution}>
					{obj.resolutionForSearch.includes(3840) ? <>{obj.resolutionForSearch} <CustomIcon icon="4k" /></> : `${obj.resolutionForSearch}`}
				</div>
			);
		}

		return obj;
	}

	findEntityName(m, id) {
		let {
			value,
		} = this.props;
		let keys = Object.keys(value);

		let name = '';

		keys.forEach((key) => {
			const {
				m1, m1ID, col,
			} = this.props.form.parseEntityName(key);
			if (m1 === m && m1ID === id && col === 'name') {
				name = value[key].value;
			}
		});

		return name;
	}


	handleChange(system, event) {
		let { app, } = this.props;

		let setting = app.state.settingMap['animation_start_phase'];
		let componentKey = '';

		Object.keys(app.state.accountData)
			.forEach((m1Key) => {
				if (m1Key.startsWith('system.') && m1Key.split('.')[1] === system) {

					Object.keys(app.state.accountData[m1Key]).forEach((m2Key) => {

						if (m2Key.startsWith('component.')) {
							componentKey = m2Key.split('.')[1];
							let entityKey = `component.${componentKey}.setting.${setting.uuid}.${setting.type}`;
							app.setValue('tlc_setting', entityKey, event.value, () => {
								app.saveData();
							})
						}
					});
				}
			});
	}

	checkForDisabledSystems(systemUUID) {
		const {
			app,
		} = this.props;

		const {
			accountData,
		} = app.state;
		if (accountData[`system.${systemUUID}`] !== undefined) {
			let keysStr = Object.keys(accountData[`system.${systemUUID}`]).join('');
			return Boolean(~keysStr.indexOf('component.'));
		} else {
			return false;
		}

	}

	getTLCAnimValues(systemid) {
		let { app, fd } = this.props;
		let newValue = { label: '', value: '' };
		let componentKey = '';
		Object.keys(app.state.accountData)
			.forEach((m1Key) => {
				if (m1Key.startsWith('system.') && m1Key.split('.')[1] === systemid) {

					Object.keys(app.state.accountData[m1Key]).forEach((m2Key) => {

						if (m2Key.startsWith('component.')) {

							componentKey = m2Key.split('.')[1];
							let dropdownValue = '';
							dropdownValue = getFDValue(fd, `component.${componentKey}.setting.${app.state.settingMap['animation_start_phase'].uuid}.string`);

							this.state.accountDropdownJson.options.forEach((option) => {
								if (option.value === dropdownValue) {
									newValue = {
										label: option.label,
										value: option.value,
									};
								}
							});
						}
					});
				}
			});
		return newValue;
	}

	toggleTree(target) {
		if (target) {
			let sibling = target.nextSibling;
			if (sibling) {
				if (sibling.classList.contains(style.hide)) {
					sibling.classList.remove(style.hide);
					sibling.classList.add(style.show);
				} else {
					sibling.classList.remove(style.show);
					sibling.classList.add(style.hide);
				}

				this.toggleTree(sibling);
			}
		}
	}

	sortByName(a, b) {
		let aName = a.searchValues.name || '';
		let bName = b.searchValues.name || '';

		if (aName.toLowerCase() > bName.toLowerCase()) {
			return 1;
		}

		return -1;
	}

	sortByM1AndName(a, b) {
		if (a.m1 > b.m1) {
			return 1;
		}

		if (a.m1 < b.m1) {
			return -1;
		}

		let aName = a.searchValues.name || '';
		let bName = b.searchValues.name || '';

		if (aName.toLowerCase() > bName.toLowerCase()) {
			return 1;
		}

		return -1;
	}

	closeModal() {
		let form = this.props.form;

		form.setState({
			vulture: {
				...form.state.vulture,
				modalSize: 0,
				showModal: false,
				modalProps: {},
			},
		});
	}

	openPreviewModal(src, entityName, env, systemUUID) {
		this.props.app.openModal({
			showModal: true,
			modalSize: 5,
			modalProps: {
				title: entityName,
				jsx: (
					<div className={style.previewFullContainer}>
						<img
							className={style.previewFull}
							src={src}
						/>
						{/* Buttons to eventually be hooked up */}
						<div className={style.previewButtons}>
							<button
								className="button"
								onClick={() => {
									this.openIFramePreviewModal(entityName, env, systemUUID);
								}}>
								<CustomIcon icon="link" />Preview
							</button>
							<button className="button button-disabled"><CustomIcon icon="link" />Connect</button>
							<button className="button button-disabled"><CustomIcon icon="info" />Identify</button>
						</div>
					</div>
				),
			},
		});
	}

	openIFramePreviewModal(entityName, env, systemUUID) {
		const {
			fd,
		} = this.props;

		let condorComponent = '';
		let condorURL = '';
		let width = 1920;
		let height = 1080;

		Object.entries(fd[systemUUID]).forEach(([key, val,]) => {
			if (key.startsWith('component.')) {
				condorComponent = key.slice(10);
			} else if (key === 'resolution') {
				[width, height,] = val.split('x').map((dimension) => {
					return Number(dimension);
				});
			}
		});

		// Calculates the largest possible size the iframe can have to fit into 80% of the screen's height or width
		const windowHeight = window.innerHeight;
		const windowWidth = window.innerWidth;
		const maxIframeWidth = windowWidth * 0.8;
        const maxIframeHeight = windowHeight * 0.8;

        const widthScaleFactor = maxIframeWidth / width;
        const heightScaleFactor = maxIframeHeight / height;
        const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);

		const scaledWidth = width * scaleFactor;
        const scaledHeight = height * scaleFactor;

		switch (env) {
		// for now, dev points to citest
		case 'dev':
			condorURL = 'https://condor-client-citest.touchsource.com';
			break;
		case 'citest':
			condorURL = 'https://condor-client-citest.touchsource.com';
			break;
		case 'uat':
			condorURL = 'https://condor-client-uat.touchsource.com';
			break;
		case 'ciprod':
			condorURL = 'https://condor-client-ciprod.touchsource.com';
			break;
		case 'stage':
			condorURL = 'https://condor-client-stage.touchsource.com';
			break;
		default:
			condorURL = 'https://condor-client-citest.touchsource.com';
		}

		const containerStyle = {
			width: scaledWidth + 'px',
			height: scaledHeight + 'px',
			overflow: 'hidden',
		};

		const iframeStyle = {
			transform: `scale(${scaleFactor})`,
			transformOrigin: '0 0'
		}
		
		this.props.app.openModal({
			showModal: true,
			modalSize: 5,
			modalProps: {
				title: `Preview ${entityName}`,
				jsx: (
					<div className={style.previewIFrameFullContainer} style={containerStyle}>
						<iframe
							className={style.previewIFrame}
							width={width}
							height={height}
							style={iframeStyle}
							src={`${condorURL}/?condor_component=${condorComponent}`}
							title="Preview">
						</iframe>
					</div>
				),
			},
		});
	}

	openIframeMobileModal(entityName, env, tlcUUID) {
		let width = 540;
		let height = 960;

		// Calculates the largest possible size the iframe can have to fit into 80% of the screen's height or width
		const windowHeight = window.innerHeight;
		const windowWidth = window.innerWidth;
		const maxIframeWidth = windowWidth * 0.8;
        const maxIframeHeight = windowHeight * 0.8;

        const widthScaleFactor = maxIframeWidth / width;
        const heightScaleFactor = maxIframeHeight / height;
        const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);

		const scaledWidth = width * scaleFactor;
        const scaledHeight = height * scaleFactor;

		let mobileURL = '';

		switch (env) {
		// for now, dev points to citest
		case 'dev':
			mobileURL = 'https://web-citest.touchsource.com';
			break;
		case 'citest':
			mobileURL = 'https://web-citest.touchsource.com';
			break;
		case 'uat':
			mobileURL = 'https://web-uat.touchsource.com';
			break;
		case 'ciprod':
			mobileURL = 'https://web.touchsource.com';
			break;
		case 'stage':
			mobileURL = 'https://web-stage.touchsource.com';
			break;
		default:
			mobileURL = 'https://web-citest.touchsource.com';
		}

		const containerStyle = {
			width: scaledWidth + 'px',
			height: scaledHeight + 'px',
			overflow: 'hidden',
		};

		const iframeStyle = {
			transform: `scale(${scaleFactor})`,
			transformOrigin: '0 0'
		}
		
		this.props.app.openModal({
			showModal: true,
			modalSize: 6,
			modalProps: {
				title: `Mobile Preview ${entityName}`,
				jsx: (
					<div className={style.previewIFrameFullContainer} style={containerStyle}>
						<iframe
							className={style.previewIFrame}
							width={width}
							height={height}
							style={iframeStyle}
							src={`${mobileURL}/?id=${tlcUUID}`}
							title="Mobile Preview">
						</iframe>
					</div>
				),
			},
		});
	}

	getTableData(uuid, parentTableData = {}) {
		for (let tableData of parentTableData) {
			if (tableData.id === uuid) {
				return tableData;
			}

			let childTableData = this.getTableData(uuid, tableData.subRows);
			if (childTableData.id === uuid) {
				return childTableData;
			}
		}

		return {};
	}

	openEditModal(uuid) {
		const {
			fd,
		} = this.props;

		const {
			systemsTableData,
		} = this.state;

		let systemGroup = this.getTableData(uuid, systemsTableData);

		let parentEntity = {};
		for (let [m1Key, m1Data] of Object.entries(fd)) {
			for (let m2Key of Object.keys(m1Data)) {
				if (m2Key === `system_group.${uuid}`) {
					let s = m1Key.split('.');

					parentEntity = {
						m1: s[0],
						m1ID: s[1],
					};
				}
			}
		}

		this.props.app.openModal({
			modalSize: 2,
			showModal: true,
			modalProps: {
				title: 'Edit Hierarchy',
				pendingChanges: false,
				jsx: (
					<SystemsModal
						app={this.props.app}
						accountUUID={this.props.accountUUID}
						fd={this.props.fd}
						isEdit={true}
						save={this.submitAndUpdateChanges.bind(this)}
						activeAccount={this.state.activeAccount}
						checkDataValidity={this.checkDataValidity.bind(this)}
						closeModal={this.closeModal.bind(this)}
						systemGroup={systemGroup}
						idsForModal={this.state.idsForModal}
						parentEntity={parentEntity}
					/>
				),
			},
		});
	}

	openSystemsDetailModal(uuid, macAddress) {
		this.props.app.openModal({
			modalSize: 2,
			showModal: true,
			modalProps: {
				title: 'Edit System',
				pendingChanges: false,
				jsx: (
					<SystemsDetailModal
						app={this.props.app}
						fd={this.props.fd}
						form={this.props.form}
						save={this.submitAndUpdateChanges.bind(this)}
						activeAccount={this.state.activeAccount}
						checkDataValidity={this.checkDataValidity.bind(this)}
						closeModal={this.closeModal.bind(this)}
						// systemGroup={systemGroup}
						idsForModal={this.state.idsForModal}
						systemStatusInfo={this.state.systemStatusInfo}
						// parentEntity={parentEntity}
						uuid={uuid}
						macAddress={macAddress}
						value={this.props.value}
					/>
				),
			},
		});
	}

	updateSearch(columnName, value) {
		this.setState({
			macAddressFilter: '',
			statusFilter: '',
			nameFilter: '',
			directoryNumberFilter: '',
			projectNumberFilter: '',
			[columnName]: value.replaceAll(':', ''),
		});

		if (columnName === 'directoryNumberFilter') {
			this.expandAllRows();
		} else {
			if (value.length >= 3) {
				this.expandAllRows();
			}
		}
	}

	getFilteredData() {
		let shallowCopiedTableData = this.state.systemsTableData.sort(this.sortByName);
		let sortedTableData = this.deepCopyFunction(shallowCopiedTableData);
    	sortedTableData.forEach((systemGroup) => {
			this.filterEntity(systemGroup);
		});
		this.addParentToRows(sortedTableData);
		this.addMatchingToRows(sortedTableData);
		if (this.state.nameFilter.length > 0) {
			this.makeSiblingsAvailable(sortedTableData);
		}
		const filteredData = this.removeUnmatchedRows(sortedTableData);
		return filteredData;
	}

	deepCopyFunction(inObject) {
		let outObject, value, key;

		if (typeof inObject !== "object" || inObject === null) {
			return inObject;
		}

		outObject = Array.isArray(inObject) ? [] : {};

		for (key in inObject) {
			value = inObject[key];
			outObject[key] = this.deepCopyFunction(value);
		}
		return outObject;
	}

	removeUnmatchedRows(rows) {
		const items = rows.filter((item) => item.matchesSearch)

		for (let i = 0; i < items.length; i++) {
			items[i].subRows = this.filterSubRows(items[i].subRows);
		}

		return items;
	}

	filterSubRows(subRowsArg) {
		const subRows = subRowsArg.filter(subRow => subRow.matchesSearch)
		for (let i = 0; i < subRows.length; i++) {
			if (subRows[i].subRows)  // has subRows, then filter
				subRows[i].subRows = this.filterSubRows(subRows[i].subRows);
		}
		return subRows
	}

	filterEntity(entity) {
		let nameInSearch = (entity.searchValues.name || '').toLowerCase().includes(this.state.nameFilter.toLowerCase());
		let resolutionInSearch = (entity.searchValues.resolution || '').toLowerCase().includes(this.state.resolutionFilter.toLowerCase());
		let statusInSearch = (entity.searchValues.status || '').toLowerCase().includes(this.state.statusFilter.toLowerCase());
		let macAddressInSearch = (entity.searchValues.macAddress || '').toLowerCase().includes(this.state.macAddressFilter.toLowerCase());
		let orientationInSearch = (entity.searchValues.orientation || '').toLowerCase().includes(this.state.orientationFilter.toLowerCase());
		let directoryNumberInSearch = (entity.searchValues.directoryNumber || '').toLowerCase().includes(this.state.directoryNumberFilter.toLowerCase());
		let projectNumberInSearch = (entity.searchValues.projectNumber || '').toLowerCase().includes(this.state.nameFilter.toLowerCase());

		let isMatch = (nameInSearch || projectNumberInSearch) && resolutionInSearch && statusInSearch && macAddressInSearch && orientationInSearch && directoryNumberInSearch;

		if (isMatch) {
			entity.matchesSearch = true;
		} else {
			entity.matchesSearch = false;
		}

		entity.subRows.forEach((subEntity) => {
			this.filterEntity(subEntity);
		});

	}

	addParentToRows(rows, parent = false) {
		for (let row of rows) {
			if (parent) {
				row.parent = parent;
			}

			for (let subRow of row.subRows) {
				subRow.parent = row;
				this.addParentToRows(subRow.subRows, subRow);
			}
		}
	}

	makeSiblingsAvailable(rows) {
		for (let row of rows) {
			if (row.m1 === 'system') {
				if (row.parent.matchesSearch) {
					row.parent.subRows.forEach((subRow) => {
						subRow.matchesSearch = true;
					})
				}
			}
			this.makeSiblingsAvailable(row.subRows);
		}
	}

	addMatchingToRows(rows) {
		for (let row of rows) {
			this.addMatchingToRows(row.subRows);

			if (row.matchesSearch) {
				while (typeof row.parent !== 'undefined') {
					row.parent.matchesSearch = true;
					row = row.parent;
				}
			} else {
				/* let i = rows.indexOf(row);
				rows.splice(i, 1);  */
			}
		}
	}

	getEarliestExpiration() {
		let today = new Date();
		let prevDate = this.state.systemsAboutToExpire[0] || today;
		for (let i = 0; i < this.state.systemsAboutToExpire.length; i++) {
			if (this.state.systemsAboutToExpire[i] < prevDate) {
				prevDate = this.state.systemsAboutToExpire[i];
			}
		}
		let diffTime = 0;
		if (prevDate <= today) {
			diffTime = Math.abs(prevDate - today);
		} else {
			diffTime = Math.abs(today - prevDate);
		}
		let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


		let y = prevDate.getFullYear();
		let m = prevDate.getMonth();
		let d = prevDate.getDate();
		let expirationMessage = ` Subscription expired on ${m + 1}.${d}.${y}. Please contact TouchSource (renewals@touchsource.com) to renew.`
		if (today > prevDate) {
			diffDays = Math.ceil(-diffTime / (1000 * 60 * 60 * 24));
		} else {
			diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
			expirationMessage = ` Subscription renewal date: ${m + 1}.${d + 1}.${y}. Please renew within ${diffDays} days.`;
		}

		return diffDays;
	}

	getSearchBars() {
		return (
			<div className={style.table}>
				<div className={style.thead}>
					<div className={style.tr}>
						<div className={style.th} style={this.state.systemNameColumn}>
							System Name
						</div>
						<div className={style.th} style={this.state.statusColumn}>
							Status
						</div>
						<div className={style.th} style={this.state.resolutionColumn}>
							Resolution
						</div>
						<div className={style.th} style={this.state.orientationColumn}>
							Orientation
						</div>
						<div className={style.th} style={this.state.macAddressColumn}>
							MAC Address
						</div>
						<div className={style.th} style={this.state.directoryNumberColumn}>
							Dir Num
						</div>
					</div>
				</div>
				<div className={style.systemsFilter}>
					<div className={style.td} style={this.state.systemNameColumn}>
						<input
							className="filter"
							type="text"
							placeholder="Filter..."
							data-testing-info={buildTestData('input--systemName')}
							onChange={(e) => {
								e.preventDefault();
								this.updateSearch('nameFilter', e.target.value);
							}}
							value={this.state.nameFilter}
						/>
					</div>
					<div className={style.td} style={this.state.statusColumn}>
						<input
							className="filter"
							type="text"
							placeholder="Filter..."
							data-testing-info={buildTestData('input--status')}
							onChange={(e) => {
								e.preventDefault();
								this.updateSearch('statusFilter', e.target.value);
							}}
							value={this.state.statusFilter}
						/>
					</div>
					<div className={style.td} style={this.state.resolutionColumn}>

					</div>

					<div className={style.td} style={this.state.orientationColumn}>

					</div>
					<div className={style.td} style={this.state.macAddressColumn}>
						<input
							className="filter"
							type="text"
							placeholder="Filter..."
							data-testing-info={buildTestData('input--macAddress')}
							onChange={(e) => {
								e.preventDefault();
								this.updateSearch('macAddressFilter', e.target.value);
							}}
							value={this.state.macAddressFilter}
						/>
					</div>
					<div className={style.td} style={this.state.directoryNumberColumn}>
						<input
							className="filter"
							type="text"
							placeholder="Filter..."
							data-testing-info={buildTestData('input--directoryNumber')}
							onChange={(e) => {
								e.preventDefault();
								this.updateSearch('directoryNumberFilter', e.target.value);
							}}
							value={this.state.directoryNumberFilter}
						/>
					</div>
				</div>
			</div>
		);
	}

	expandAllRows() {
		let rowsToExpand = this.getFilteredData();
		rowsToExpand.map((element, index) => {
			let el = element;
			if (el.subRows) {
				el.subRows.map((subrow, index) => {
					return { index: true }
				})
			}
			return { index: true }
		});
		this.setState({
			isAllRowsExpanded: false,
			defaultExpandedRows: rowsToExpand.map((element, index) => {
				let el = element;
				if (el.subRows) {
					el.subRows.map((subrow, index) => {
						return { index: true }
					})
				}
				return { index: true }
			})
		});
	}


	collapseAllRows() {
		this.setState({
			defaultExpandedRows: []
		});
	}

	render() {
		let newGroup = null;
		let pg = this.state.userRole;
		/* let defaultExpandedRows = this.getFilteredData().map((element, index) => {return {index: true}}) */
		let defaultExpandedRows = this.state.defaultExpandedRows;
		if (pg === 'iu' || pg === 'ia') {
			newGroup = (
				<div className='secondaryNav'>
					<div className='button' data-testing-info={buildTestData('button--newGroup')} onClick={((e) => {
						this.props.app.openModal({
							modalSize: 2,
							showModal: true,
							modalProps: {
								title: 'New Hierarchy',
								pendingChanges: false,
								jsx: (
									<SystemsModal
										app={this.props.app}
										accountUUID={this.props.accountUUID}
										fd={this.props.fd}
										settingMap={this.props.settingMap}
										isEdit={false}
										form={this.props.form}
										save={this.submitAndUpdateChanges.bind(this)}
										activeAccount={this.props.accountUUID}
										checkDataValidity={this.checkDataValidity.bind(this)}
										closeModal={this.closeModal.bind(this)}
										idsForModal={this.state.idsForModal}
									/>
								),
							},
						});
					})}>
						<CustomIcon icon='folder-plus' />New Hierarchy
					</div>

				</div>
			);
		}


		let searchBars = this.getSearchBars();
		return (
			<div className={`systems-page`}>
				<div className='settings-header'>
					<h2>Hierarchy</h2>
				</div>
				{newGroup}
				{searchBars}
				{this.state.systemsTableData.length > 0 ?
					<SystemsTable
						key={this.state.defaultExpandedRows}
						array={this.getFilteredData()}
						openSystemsDetailModal={this.openSystemsDetailModal.bind(this)}
						defaultExpanded={defaultExpandedRows}
						isAllRowsExpanded={this.state.isAllRowsExpanded}
						expandAllRows={this.expandAllRows.bind(this)}
						collapseAllRows={this.collapseAllRows.bind(this)}
					/> :
					<p>Create a New Hierarchy to begin adding systems.</p>
				}
			</div>
		);
	}
}

export default SystemsView;