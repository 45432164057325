import React from 'react';
import axios from 'axios';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import style from './Screenshot.module.css';

import {
	getSession,
} from 'Vulture/Session';

import {
	broncoURL,
	vultureENV,
} from 'Vulture/ENV';

import ScreenshotModal from './ScreenshotModal';

class ScreenshotView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			photos: [],
			sort:   {
				label: 'Date',
				value: 'lastModified',
			},
			startDate:   '',
			endDate:     '',
			sortAscDesc: 'desc',

			selectedAccount:         null,
			accountOptions:          [],
			accountSelectorDisabled: true,

			tlcs: {},
		};

		this.sortingOptions = [
			{
				label: 'Date',
				value: 'lastModified',
			},
			{
				label: 'Time',
				value: 'time',
			},
			{
				label: 'File Size',
				value: 'size',
			},
		];

		this.sortingMethods = {
			lastModified: (a, b) => {
				if (this.state.sortAscDesc === 'desc') {
					return b.lastModified - a.lastModified;
				}

				return a.lastModified - b.lastModified;
			},
			time: (a, b) => {
				const dateA = new Date(a.lastModified);
				const dateB = new Date(b.lastModified);

				const timeA = dateA.getHours() * 3600 + dateA.getMinutes() * 60 + dateA.getSeconds();
				const timeB = dateB.getHours() * 3600 + dateB.getMinutes() * 60 + dateB.getSeconds();
				if (this.state.sortAscDesc === 'desc') {
					return timeB - timeA;
				}
				return timeA - timeB;
			},
			size: (a, b) => {
				if (this.state.sortAscDesc === 'desc') {
					return Number(b.size.split(' ')[0]) - Number(a.size.split(' ')[0]);
				}
				return Number(a.size.split(' ')[0]) - Number(b.size.split(' ')[0]);
			},
		};
	}

	getScreenshots() {
		const prefix = `${vultureENV}`;
		const {
			app,
		} = this.props;
		app.state.s3.listObjects(
			{
				Bucket:    'ts-screen-shots',
				Delimiter: '/',
				Prefix:    `${prefix}/`,
			},
			(err, data) => {
				if (err) {
					console.error(err);
					return;
				}
				if (data && data.Contents) {
					const photosToReturn = [];
					if (data.Contents.length === 0) {
						this.setState({
							photos: [],
						});

						return;
					}

					data.Contents.forEach((obj, i) => {
						let keyArray = obj.Key.split('/');
						if (keyArray[1] === '') {
							return;
						}
						let imgObj = {};
						imgObj.assetURL = `https://s3.amazonaws.com/ts-screen-shots/${obj.Key}`;
						imgObj.imgURL = `https://ts-screen-shots.s3.amazonaws.com/${(obj.Key)}`;
						imgObj.tlcUUID = obj.Key.split('/')[1].split('.')[0];

						let img = new Image();
						img.addEventListener('load', function () {
							imgObj.resolution = `${this.naturalWidth}px x ${this.naturalHeight}px`;
						});
						img.src = imgObj.imgURL;

						let k = `img-${i}`;
						imgObj.imgElm = (
							<img key={k} src={imgObj.imgURL} />
						);
						imgObj.lastModified = obj.LastModified;
						imgObj.size = `${Math.floor(obj.Size / 1024)} kb`;
						imgObj.imgKey = obj.Key;
						photosToReturn.push(imgObj);
					});
					let sortedPhotosToReturn = [];
					sortedPhotosToReturn = photosToReturn.sort(this.sortingMethods[this.state.sort.value]);

					this.setState({
						photos: sortedPhotosToReturn,
					});
				}
			}
		);
	}

	componentDidMount() {
		this.getScreenshots();
		this.getAccountsData();
	}

	getAccountsData() {
		const session = getSession();
		const url = `${broncoURL}/account/list`;
		const body = {
			user: session.user,
		};

		axios.post(url, body, {})
			.then((res) => {
				if (res.status !== 200) {
					throw Error(`Invalid response from ${broncoURL}/account/list.`);
				}

				return res.data;
			})
			.then((accountsFD) => {
				const accountOptions = Object.entries(accountsFD)
					.map(([ accountUUID, accountVal, ]) => {
						return {
							value: accountUUID,
							label: accountVal.display_name,
							tlcs:  accountVal.tlcs,
						};
					});

				this.setState({
					accountOptions,
					accountSelectorDisabled: false,
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	requestTLCList(accountUUID) {
		const {
			tlcs,
		} = this.state;

		if (tlcs[accountUUID]) {
			return;
		}

		const session = getSession();
		const url = `${broncoURL}/account/tlc`;
		const body = {
			user:    session.user,
			account: accountUUID,
		};

		axios.post(url, body, {})
			.then((res) => {
				if (res.status !== 200) {
					throw Error(`Invalid response from ${broncoURL}/account/list.`);
				}

				return res.data;
			})
			.then((data) => {
				this.setState({
					tlcs: {
						...tlcs,
						[accountUUID]: data,
					},
				});
			})
			.catch((err) => {
				alert(err);
			});
	}

	render() {
		const {
			accountOptions,
			selectedAccount,
			accountSelectorDisabled,
			tlcs,
		} = this.state;

		const filteredPhotos = this.state.photos
			.filter((photo) => {
				if (this.state.startDate === '' || this.state.endDate === '') {
					return true;
				}

				const lastModifiedDate = new Date(photo.lastModified);
				lastModifiedDate.setHours(0);
				lastModifiedDate.setMinutes(0);
				lastModifiedDate.setSeconds(0);
				lastModifiedDate.setMilliseconds(0);

				if (this.state.endDate >= lastModifiedDate && lastModifiedDate >= this.state.startDate) {
					return true;
				}

				return false;
			})
			.filter((photo) => {
				if (selectedAccount === null) {
					return true;
				} else if (tlcs[selectedAccount.value] && tlcs[selectedAccount.value].includes(photo.tlcUUID)) {
					return true;
				}

				return false;
			});

		return (
			<div className={`systems-page fade-in`}>
				<div className={style.pageHeader}>
					<h2>Screenshots</h2>
					<div className={style.headerDetails}>The screenshots taken from your systems.</div  >
				</div>
				<div className={style.content}>
					<div className="label">
						<label>Sort by (</label>
						<button className={style.ascDescButton} onClick={() => {
							const nextPhotos = this.state.photos.reverse();
							const nextSortAscDesc = this.state.sortAscDesc === 'desc' ? 'asc' : 'desc';

							this.setState({
								photos:      nextPhotos,
								sortAscDesc: nextSortAscDesc,
							});
						}}>{this.state.sortAscDesc}</button>
						<label>):</label>
					</div>
					<Select
						options={this.sortingOptions}
						onChange={(e) => {
							const sortedPhotos = this.state.photos.sort(this.sortingMethods[e.value]);

							this.setState({
								sort:   e,
								photos: sortedPhotos,
							});
						}}
						value={this.state.sort}
					/>
					<div className={`${style.datePicker} ${style.show}`}>
						<div className={style.startDate}>
							<div className={`label ${style.label}`}>
								<label>Start Date:</label>
								<button className={style.clearButton} onClick={() => {
									this.setState({
										startDate: '',
									});
								}}>clear</button>
							</div>
							<DatePicker
								selected={this.state.startDate}
								maxDate={this.state.endDate ? Date.parse(this.state.endDate) : new Date()}
								onChange={(e) => {
									this.setState({
										startDate: e,
									});
								}}
							/>
						</div>
						<div className={style.endDate}>
							<div className={`label ${style.label}`}>
								<label>End Date:</label>
								<button className={style.clearButton} onClick={() => {
									this.setState({
										endDate: '',
									});
								}}>clear</button>
							</div>
							<DatePicker
								selected={this.state.endDate}
								minDate={this.state.startDate ? Date.parse(this.state.startDate) : null}
								maxDate={new Date()}
								onChange={(e) => {
									this.setState({
										endDate: e,
									});
								}}
							/>
						</div>
						<div className="label">
							<label>Source Account:</label>
							<button className={style.clearButton} onClick={() => {
								this.setState({
									selectedAccount: null,
								});
							}}>clear</button>
						</div>
						<Select
							options={accountOptions}
							onChange={(e) => {
								this.setState({
									selectedAccount: e,
								});
								this.requestTLCList(e.value);
							}}
							isDisabled={accountSelectorDisabled}
							value={selectedAccount}
						/>
						<div className={style.endDate}>
							<div className={`label ${style.label}`}>
								<label>{`Results: ${filteredPhotos.length}`}</label>
							</div>
						</div>
					</div>
					<div className={style.gallery}>
						{filteredPhotos.map((obj, i) => {
							return (
								<div className={style.card} key={i}>
									<div className={style.cardContainer}>
										<div className={style.image}
											onClick={(e) => {
												e.preventDefault();
												this.props.app.openModal({
													modalSize:  2,
													showModal:  true,
													modalProps: {
														title:          obj.tlcUUID,
														customClass:    'test',
														pendingChanges: false,
														jsx:            (
															<ScreenshotModal
																obj={obj}
																screnshotURL={obj.imgURL}
																app={this.props.app}
															/>
														),
													},
												});
											}}
										>
											{obj.imgElm}
										</div>
										<div className={style.dimensions}>TLC UUID: {obj.tlcUUID}</div>
										<div className={style.dimensions}>Date: {obj.lastModified.toString()}</div>
										<div className={style.dimensions}>Size: {obj.size}</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default ScreenshotView;