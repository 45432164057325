import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import { useTable, useGlobalFilter, useAsyncDebounce } from 'react-table';

import style from './Users.module.css';
import { buildTestData } from 'Vulture/TestData';
import { dlAddNewUser } from 'Vulture/DataLayer';
import {
	getSession,
} from 'Vulture/Session';

function UserTable(props) {
	const data = React.useMemo(() => {
		return props.data;
	}, [props.data]);

	const columns = React.useMemo(() => {
		return props.columns;
	}, [props.columns]);

	const pg = getSession().pg;

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		preGlobalFilteredRows,
		prepareRow,
		rows,
		setGlobalFilter,
		state,
	} = useTable(
		{
			columns,
			data,
		},
		useGlobalFilter
	);

	return (
		<React.Fragment>
			<div className='secondaryNav'>
				<div
					data-testing-info={buildTestData('button--addNew')}
					data-test-id='addNewButton'
					className='button'
					onClick={(e) => {
						e.preventDefault();
						dlAddNewUser();
						props.openModal({
							original: {
								accounts: [],
								active:   true,
								email:    '',
								role:     '',
								uuid:     '',
								has_2fa:  true,
							},
						});
					}}
				>
					<CustomIcon icon="plus"/>Add New
				</div>
				<div>
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						handleRootUserReadRequest={props.handleRootUserReadRequest}
					/>
				</div>
			</div>
			<div className={style.table} {...getTableProps()}>
				<div className={style.thead}>
					{headerGroups.map((headerGroup, i) => {
						return (
							<div className={style.tr} key={i} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, i) => {
									return (
										<div className={style.th}
											key={i}
											{...column.getHeaderProps()}
										>
											{column.render('Header')}
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
				<div className={style.tbody} {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<div className={style.tr}
								key={i}
								{...row.getRowProps()}
								onClick={(e) => {
									e.preventDefault();
									if (props.isAccountUser) {
										let selectedUserPg = row.original.permission_group_display_name;
										if  (pg !== 'ia') {
											if (selectedUserPg === 'external_admin') {
												if  (pg !== 'iu') {
													return;
												}
											}
											if (selectedUserPg === 'internal_user' || selectedUserPg === 'internal_admin') {
												return;
											}
										}
									}
									props.openModal(row);
								}}
								data-testing-info={buildTestData(`listItem--${row.values.email}`)}
							>
								{row.cells.map((cell, j) => {
									return (
										<div className={style.td}
											key={j}
											{...cell.getCellProps()}
										>
											<div className={style.tdContainer}>
												{cell.render('Cell')}
											</div>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		</React.Fragment>
	);
}

function GlobalFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	handleRootUserReadRequest,
}) {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);

	// TODO: get search working on all columns
	const onChange = useAsyncDebounce(value => {
		if (handleRootUserReadRequest) {
			if (value.length >= 3) {
				setGlobalFilter(value || undefined);
				handleRootUserReadRequest(value);
			}
		} else {
			setGlobalFilter(value || undefined);
		}
	}, 300);

	return (
		<div className={style.userSearch}>
			<CustomIcon icon='search' color='var(--accent)' />
			<input
				value={value || ''}
				onChange={e => {
					setValue(e.target.value);
					onChange(e.target.value);
				}}
				placeholder={`${count} records...`}
				data-testing-info={buildTestData('input--search')}
			/>
		</div>
	);
}

export default UserTable;