import React from 'react';
import Select from 'react-select';
import {
	getFDValue,
} from 'Vulture/Helpers';

class CrowFieldCustomVultureResolutionDropDown extends React.Component {
	constructor(props) {
		super(props);
		for (const property in props.fd) {
			if (props.fd[property].name === 'orientation') {
				this.settinguuid = property.split('.')[1];
			}
		}

		if (this.settinguuid === undefined) {
			this.settinguuid = props.settingMap.orientation.uuid;
		}

		this.state = {
			resolutionValue:  getFDValue(props.fd, props.entity.name),
			orientationValue: getFDValue(props.fd, props.entity.altName + '.setting.' + this.settinguuid + '.string'),
		};

	}

	setOrientationValues(isNewValue = false) {
		const {
			fd,
			settingMap,
			entity,
			app,
		} = this.props;

		let {
			orientationOptions = [],
		} = entity || {};

		let originalOrientationValue = getFDValue(fd, entity.altName + '.setting.' + this.settinguuid + '.string');
		let orientation = '';
		let resolution = getFDValue(fd, entity.name);

		if (resolution) {
			let XY = resolution.split('x');
			let X = XY[0];
			let Y = XY[1];
			if (Y > X) {
				orientation = 'Vertical';
				orientationOptions = [
					{
						value: 'portraitleft',
						label: 'Portrait Left',
					},
					{
						value: 'portraitright',
						label: 'Portrait Right',
					},
				];
			} else {
				orientation = 'Horizontal';
				orientationOptions = [
					{
						value: 'landscape',
						label: 'Landscape',
					},
					{
						value: 'landscapeflipped',
						label: 'Landscape (flipped)',
					},
				];
			}
		}

		if (isNewValue || this.state.orientationValue === undefined && resolution !== undefined) {
			this.setState({
				orientationValue: orientationOptions[0].value || 'landscape',
			});
		}

		if (originalOrientationValue !== this.state.orientationValue) {
			app.setValue('system', entity.altName + '.setting.' + this.settinguuid + '.string', this.state.orientationValue);
		}


		return orientationOptions;
	}

	render() {
		const {
			fd,
			entity,
			app,
		} = this.props;

		let {
			attr = {},
			options = [],
			orientationOptions = [],
		} = entity || {}; //might be entity instead of field

		let defaultOption = null;
		options.forEach((option, i) => {
			if (option.value === getFDValue(fd, entity.name)) {
				defaultOption = i;
			}
		});

		orientationOptions = this.setOrientationValues();
		let orientationIndex = 0;
		let defaultOrientationOption = orientationOptions[0];
		orientationOptions.forEach((option, i) => {
			if (option.value === this.state.orientationValue) {
				defaultOrientationOption = orientationOptions[i];
				orientationIndex = i;
			}
		});

		return (
			<div className={`drop-down`}>
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<Select
					className={entity.style}
					options={options}
					defaultValue={options[defaultOption]}
					onChange={(value) => {
						this.setState({
							value,
							resolutionValue: value.value,
						}, () => {
							this.setOrientationValues(true);
						});
						app.setValue('system', entity.name, value.value);
					}}
				/>
				<div className="text-small">
					<div className="label">
						<label>Orientation</label>
					</div>
					<Select
						className={entity.style}
						options={orientationOptions}
						value={orientationOptions[orientationIndex]}
						onChange={(orientation) => {
							this.setState({
								orientationValue: orientation.value,
							});
							app.setValue('system', entity.altName + '.setting.' + this.settinguuid + '.string', orientation.value);
						}}
					/>
				</div>
			</div>
		);
	}

}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.elements.resolution.drop.down'] = CrowFieldCustomVultureResolutionDropDown;
export default CrowFieldCustomVultureResolutionDropDown;