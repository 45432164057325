import React from 'react';
import Cookie from 'js-cookie';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import kali from 'kali';
import md5 from 'md5';
import TemplateSkeleton from './TemplateSkeleton';
import { v4 as uuidv4, } from 'uuid';
import zlib from 'zlib';

import style from './TemplateSkeletonBuilder.module.css';
import { getSession } from 'Vulture/Session.js';

const broncoURL = window._getEnv('BRONCO_URL');

class TemplateSkeletonBuilder extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			configName: '',
			skeletonData: {},
		};
	}

	componentDidMount() {
		const { templateConfig } = this.props;
		if (templateConfig && templateConfig.display_name) {
			this.setState({ configName: templateConfig.display_name, });
		}
	}

	handleSubmit() {
		const { configName, skeletonData, } = this.state;
		const { templateCategory, templateConfig, } = this.props;

		const url = `${broncoURL}/w/edit`;
		const sessionKey = getSession().session;

		let templateConfigUUID = uuidv4();
		let templateSkeletonUUID = uuidv4();

		if (Object.keys(templateConfig).length > 0) {
			templateConfigUUID = templateConfig.uuid;
			templateSkeletonUUID = templateConfig?.template_skeleton_list?.template_skeleton?.uuid;
		}

		// TODO: move to bronco when _cache is gone
		let data = zlib.gzipSync(JSON.stringify(skeletonData)).toString('base64');
		let hash = md5(data);

		let body = {
			data: {
				[`template_config.${templateConfigUUID}.display_name`]: configName,
				[`template_config.${templateConfigUUID}.is_global`]: true,
				[`template_skeleton.${templateSkeletonUUID}.display_name`]: configName,
				[`template_skeleton.${templateSkeletonUUID}.data`]: data,
				[`template_skeleton.${templateSkeletonUUID}.hash`]: hash,

				[`template_category.${templateCategory.uuid}.template_config.${templateConfigUUID}`]: true, // template_category_to_template_config_map
				[`template_config.${templateConfigUUID}.template_skeleton.${templateSkeletonUUID}`]: true, // template_config_to_template_skeleton_map
			},
			data_format: 'DD',
		};

		new kali({
			body,
			headers: {
				'content_type': 'application/json',
				'X-Auth-Session': sessionKey,
			},
			method: 'POST',
		}).post(url, {
			success: (_kali, res, contents) => {
				if (contents && contents.data) {
					alert('Save Successful');
					// Update the Theme Editor in case the user moves back to it.
					this.props.fetchTemplateData();
					this.props.stateCallback('renderSkeletonBuilder', false);
				}
			},

			failure: (_kali, err) => {
				console.error(err);
				alert(`Error Saving: ${err}`);
			},
		});
	}

	updateSkeletonData(skeletonData) {
		this.setState({ skeletonData, });
	}

	renderSkeletonBuilder() {
		const { templateConfig } = this.props;

		let disabled = true;
		let saveButtonClassName = 'button button-save button-disabled';
		if (this.state.configName !== '') {
			disabled = false;
			saveButtonClassName = 'button button-save';
		}

		let existingFlatData = null;
		if (templateConfig?.template_skeleton_list?.template_skeleton) {
			try {
				if (typeof templateConfig.template_skeleton_list.template_skeleton.data === 'string') {
					existingFlatData = JSON.parse(templateConfig.template_skeleton_list.template_skeleton.data);
				}

				// this should not be needed, but if data was corrupted as [object Object]
				// this can help return it to using the corect logic in teh condition above
				if (typeof templateConfig.template_skeleton_list.template_skeleton.data === 'object') {
					existingFlatData = templateConfig.template_skeleton_list.template_skeleton.data;
				}
			} catch (err) {
				console.error('Error parsing skeleton data: ', err);
			}
		}

		return (
			<div className={style.builderPage}>
				<div className={style.title}>
					<h2>Template Skeleton Builder</h2>
					<button
						className='button'
						onClick={(e) => {
							e.preventDefault();
							this.props.stateCallback('renderSkeletonBuilder', false);
						}}
					>
						<CustomIcon icon='arrow-circle-left' />Theme Editor
					</button>
					<button
						className={saveButtonClassName}
						disabled={disabled}
						onClick={(e) => {
							e.preventDefault();
							this.handleSubmit();
						}}
					>
						<CustomIcon icon='save' />Save
					</button>
				</div>

				<div className={`${style.secondaryInfo}`}>
					<div className={style.categoryName}>{this.props.templateCategory.display_name}
						<span className={style.categoryNameSpacer}>{`>`}</span>
						<input
							onChange={(e) => {
								this.setState({ configName: e.target.value, });
							}}
							value={this.state.configName}
							className={style.configName}
						/>
					</div>
				</div>

				<TemplateSkeleton
					app={this.props.app}
					listingConfigMap={this.props.listingConfigMap}
					accountUUID={this.props.accountUUID}
					existingFlatData={existingFlatData}
					form={this.props.form}
					stateCallback={this.props.stateCallback}
					pendingUpdates={this.props.pendingUpdates}
					updateSkeletonData={this.updateSkeletonData.bind(this)}
					settingMap={this.props.settingMap}
					fd={this.props.fd}
				/>
			</div>
		);
	}

	render() {
		return (
			<React.Fragment>
				{this.renderSkeletonBuilder()}
			</React.Fragment>
		);
	}
}

export default TemplateSkeletonBuilder;