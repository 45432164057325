import React from 'react';

import CustomIcon from 'Crow/Form/Field/Custom/Common/CustomIcon/CustomIcon.js';

import style from 'Vulture/View/Sidebar.module.css';

import {
	hasPermission,
	PGP_VIEW_EXTERNAL,
	PGP_VIEW_INTERNAL,
} from 'Vulture/PermissionGroup.js';
import { dlNavigationPush } from 'Vulture/DataLayer';
import { buildTestData, removeWhitespace } from 'Vulture/TestData';

class SidebarView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			sidebarClass: 'collapsed'
		};
	}

	checkDataValidity() {
		const {
			app,
		} = this.props;
		let keys = Object.keys(app.state.pendingChanges);
		let dataValid = true;

		keys.forEach((key) => {
			if (key.includes('display_name')) {
				if (app.state.pendingChanges[key] === '') {
					dataValid = false;
				}
			}
		});

		return dataValid;
	}

	render() {
		const {
			app,
			accountUUID,
		} = this.props;

		const {
			router,
		} = app.props;

		const {
			mode,
			account,
		} = router.params;

		let sidebarItems = [
			{
				'name':  'systems',
				'label': 'Hierarchy',
				'icon':  'sitemap',
			},
			{
				'name':  'content',
				'label': 'Content',
				'icon':  'th-list',
			},
			{
				'name':  'library',
				'label': 'Library',
				'icon':  'book',
			},
		];

		let internalSidebarItems = [
			{
				'name':  'account_settings',
				'label': 'Settings',
				'icon':  'cog',
			},
			{
				'name':  'system_programming',
				'label': 'Programming',
				'icon':  'layer-group',
			},
		];

		let internalAndExternalOnlyItems = [
			{
				'name':  'accountUsers',
				'label': 'Users',
				'icon':  'users',
			},
		];

		if (hasPermission(PGP_VIEW_INTERNAL)) {
			sidebarItems = sidebarItems.concat(internalSidebarItems);
		}
		if (hasPermission(PGP_VIEW_EXTERNAL)) {
			sidebarItems = sidebarItems.concat(internalAndExternalOnlyItems);
		}

		let sidebarClass = this.state.sidebarClass;
		let sidebarCondition = style.disabled;
		sidebarItems.forEach((item) => {
			if (mode === item.name) {
				sidebarCondition = '';
			}
		});
		internalSidebarItems.forEach((item) => {
			if (mode === item.name) {
				sidebarCondition = '';
			}
		});

		if (accountUUID) {
			sidebarCondition = '';
		}

		return (
			<div id="sidebar" className={`${style.sidebar} ${style[sidebarClass]} ${sidebarCondition} ${(this.props.classNameList || []).join(' ')}`}
				onMouseEnter={(e) => {
					if (mode !== 'accounts') {
						e.preventDefault();
						this.setState({
							sidebarClass: 'expanded',
						});
					}
				}}
				onMouseLeave={(e) => {
					e.preventDefault();
					
					this.setState({
						sidebarClass: 'collapsed',
					});
				}}
			>
				<ul className={style.menu}>
					{sidebarItems.map((elm, i) => {
						return (
							<li
								className={mode === elm.name ? `${style.active}` : ''}
								key={`sidebarItem_${i}`}
								value={i}
								data-testing-info={buildTestData(`listItem--${removeWhitespace(elm.label)}`)}
								onClick={(e) => {
									e.preventDefault();

									dlNavigationPush({
										destination: elm.label
									});

									const {
										app,
									} = this.props;

									let handleClick = function() {
										this.setState({
											sidebarClass: 'collapsed',
										});

										let path = `/${elm.name}`;
										if (account) {
											path = `/${elm.name}/${account}`;
										}

										app.redirect({
											to: path,
										});
									}
									handleClick = handleClick.bind(this);
									if (app.hasPendingChanges()) {
										console.log(mode);
										let noSaveCB = () => {
											handleClick();
										};

										let saveCB = () => {
											app.saveDataAndCloseModal('IGNORE', () => {
												handleClick();
											});
										};

										if (mode == 'account_settings'){
											console.log(this.checkDataValidity())
										} else {
											app.openPendingChangesModal('content', noSaveCB, saveCB);
										}
									} else if (app.hasPendingSlideshowChanges()) {
										let noSaveCB = () => {
											handleClick();
										};

										let saveCB = () => {
											app.saveDataAndCloseModal('IGNORE', () => {
												handleClick();
											});
										};

										app.openPendingChangesModal('content', noSaveCB, saveCB);
									} else {
										handleClick();
									}
								}}
								data-onclick={elm.label}
							>
								<div className={style.icon}><CustomIcon icon={elm.icon} /></div>
								<div className={style.name}>{elm.label}</div>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
}

export default SidebarView;