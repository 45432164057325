import React from 'react';
import AWS from 'aws-sdk';
import md5 from 'md5';

import CustomIcon from 'Crow/Form/Field/Custom/Common/CustomIcon/CustomIcon.js';
import CustomCheckbox from './Elements/Checkbox';
import style from './Library.module.css';

import {
	vultureENV,
} from 'Vulture/ENV.js';
import { dlUploadFilePush } from 'Vulture/DataLayer';
import { buildTestData } from 'Vulture/TestData';
class LibraryView extends React.Component {
	constructor(props) {
		super(props);

		AWS.config.update({
			region: 'us-east-1',
			credentials: new AWS.CognitoIdentityCredentials({
				IdentityPoolId: 'us-east-1:dd01a076-d5c9-474e-a031-cb3bcc6f105c'
			})
		});

		this.state = {
			photosChecked: false,
			photos: props.photos || [],
			stockPhotos: props.stockPhotos || [],
			activeAccount: '',
			activeTab: 'My Images',
			bucketName: 'ts-condor-assets',
			s3: new AWS.S3({
				apiVersion: '2006-03-01',
				params: {
					Bucket: 'ts-condor-assets'
				}
			})
		};
	}

	componentDidMount() {
		const { app } = this.props;
		var script= document.createElement('script');
		var subscript = document.createElement('script');
		script.text = 'window.STONLY_WID = "fabebc3c-71ad-11ec-82f1-064cee365e5a"';
		subscript.text = "!function(e,t,n,s,o,a,i,p){e.StonlyWidget||((i=e.StonlyWidget=function(){i._api?i._api.apply(i,arguments):i.queue.push(arguments)}).scriptPath=s,i.queue=[],(a=t.createElement(n)).async=!0,(p=new XMLHttpRequest).open('GET',s+'version?v='+Date.now(),!0),p.onreadystatechange=function(){4===p.readyState&&(a.src=s+'stonly-widget.js?v='+(200===p.status?p.responseText:Date.now()),(o=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,o))},p.send())}(window,document,'script','https://stonly.com/js/widget/v2/');"
		document.head.appendChild(script);
		document.head.appendChild(subscript);

		app.getStockAndDynamicPhotos();
	}

	componentDidUpdate(prevProps) {
		const {
			accountUUID,
			photos,
			stockPhotos,
			app,
		} = this.props;

		if (prevProps.accountUUID !== accountUUID) {
			app.getStockAndDynamicPhotos();
		}

		if (prevProps.photos !== photos) {
			this.setState({
				photos,
			});
		}

		if (prevProps.stockPhotos !== stockPhotos) {
			this.setState({
				stockPhotos,
			});
		}
	}



	deletedSelectedPhotos() {

		const { app, } = this.props;
		let photosToDelete = [];

		this.state.photos.map((obj) => {
			if (obj.checked) {
				photosToDelete.push({ Key: obj.imgKey });
			}
		});

		if (photosToDelete.length === 0) {
			return;
		}
		app.setState({
			loading: true,
		})
			this.state.s3.deleteObjects(
				{
					Bucket: this.state.bucketName,
					Delete: {
						Objects: photosToDelete
					}
				},
				(err, data) => {
					if (err) {
						console.log(err);
						return;
					}
					app.getPhotosFromBucket(`${vultureENV}/ts-library/${this.getHashedActiveAccountName()}`);
					this.setState({
						photosChecked: false,
					});
					app.setState({
						loading: false,
					})
				}
			);
	}

	addPhotoToBucket(e) {
		const { app, } = this.props;
		e.preventDefault();

		if (e.target && e.target.files && e.target.files.length > 0) {
			let file = e.target.files[0];
			let fileType = file.type;
			let fileArray = fileType.split('/');
			let type = fileArray[0];
			let extension = '';
			if (fileArray[1]) {
				extension = fileArray[1];
			}

			if (type === 'image') {
				let seededHash = md5(`${new Date().getTime()}_${file.name}`);
				let objKey = `${vultureENV}/ts-library/${this.getHashedActiveAccountName()}/${seededHash}.${extension}`;
				app.setState({
					loading: true,
				})
				this.state.s3.upload(
					{
						Body: file,
						Bucket: this.state.bucketName,
						Key: objKey,
						ACL: 'public-read'
					},
					(err, data) => {
						if (err) {
							console.log(err);
							return;
						}
						app.getPhotosFromBucket(`${vultureENV}/ts-library/${this.getHashedActiveAccountName()}`);
						this.photosAreSelected();
						app.setState({
							loading: false,
						})
					}
				);
			}
		}
	}

	getHashedActiveAccountName() {
		const {
			accountUUID,
		} = this.props;

		return md5(accountUUID);
	}

	photosAreSelected() {
		let photosChecked = false;
		this.state.photos.map((obj) => {
			if (obj.checked) {
				photosChecked = true;
			}
		});

		this.setState({
			photosChecked: photosChecked
		});
	}

	render() {
		const {
			app,
		} = this.props;
		let buttonClasses = 'button button-disabled no-click';
		let stockButtonClasses = 'button';
		
		if (this.state.photosChecked === true) {
			buttonClasses = 'button';
		}

		if (this.state.activeTab === 'Stock Images') {
			buttonClasses = 'button button-disabled no-click';
			stockButtonClasses = 'button button-disabled no-click';
		}
		if(app.state.photos && app.state.photos.length > 0){

		}
		let tabContent = (
			<></>
		)
		
		if (this.state.photos !== undefined) { // && this.state.stockPhotos !== undefined) {
			tabContent = (
				<React.Fragment>
					{this.state.photos.map((obj, i) => {
						return (
							<div className={style.card} key={i}>
								<div className={style.cardContainer}>
									<div className={style.image}>
										{obj.imgElm}
									</div>
									<div className={style.dimensions}>{obj.size}</div>
									{/* <div className={style.dimensions}>{obj.resolution}</div> */}
									<div className={style.checkbox} onClick={() => {
										let photos = this.state.photos;
										photos[i].checked = !photos[i].checked;
										this.setState({
											photos: photos
										}, () => {
											this.photosAreSelected();
										});
									}}>
										<CustomCheckbox checked={obj.checked} />
									</div>
								</div>
							</div>
						);
					})}
					
				</React.Fragment>
			);

			if (this.state.activeTab === 'Stock Images') {
				tabContent = (
					<React.Fragment>
						{(this.state.stockPhotos || []).map((obj, i) => {
							return (
								<div className={style.card} key={i}>
									<div className={style.cardContainer}>
										<div className={style.image}>
											{obj.imgElm}
										</div>
										<div className={style.dimensions}>{obj.size}</div>
										{/* <div className={style.dimensions}>{obj.resolution}</div> */}
									</div>
								</div>
							);
						})}
					</React.Fragment>
				);
			}
		}

		return (
			<React.Fragment>
				<div className={`systems-page fade-in`}>
					<div className={style.pageHeader}>
						<h2>Library</h2>
						<div className={style.headerDetails}>Your collection of reusable images for logos and property info.</div  >
					</div>
					<div className={`uploader-buttons ${style.buttons}`}>
						<button
							data-testing-info={buildTestData('button--delete')}
							onClick={(e) => {
								e.preventDefault();
								this.deletedSelectedPhotos();
							}}
							className={buttonClasses}
						>
							<CustomIcon icon='trash' />Delete
						</button>
						<button
							data-testing-info={buildTestData('button--uploadFile')}
							onClick={(e) => {
								document.getElementById('libraryUploadButton').click();
							}}
							className={stockButtonClasses}
						>
							<CustomIcon icon='cloud-upload-alt' />Upload File
						</button>
						<input
							id="libraryUploadButton"
							onChange={(e) => {
								dlUploadFilePush();
								this.addPhotoToBucket(e);
								e.target.value = '';
							}}
							accept='.jpg, .jpeg, .png, .pdf'
							type='file'
						/>
					</div>
					<ul className={style.tabs}>
						<li className={this.state.activeTab === 'My Images' ? style.active : ''}
							data-testing-info={buildTestData('listItem--myImages')}
							onClick={(e) => {
								this.setState({ activeTab: 'My Images' });
							}}>My Images</li>
						<li className={this.state.activeTab === 'Stock Images' ? style.active : ''}
							data-testing-info={buildTestData('listItem--stockImages')}
							onClick={(e) => {
							this.setState({ activeTab: 'Stock Images' });
						}}>Stock Slides</li>
					</ul>
					<div className={style.content}>
						<div className={style.restrictions}>
							<div>Image format restrictions go here.</div>
						</div>
						<div className={style.gallery}>
							{tabContent}
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default LibraryView;