/* eslint-disable no-console */
import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import ViewWindow from '../Common/ViewWindow/ViewWindow.js';
import CustomCheckbox from './Elements/Checkbox';
import VultureListingsTable from './VultureListingsTable';
import style from './Listings.module.css';
import kali from 'kali';
import showdown from 'showdown';

import {
	getSession,
} from 'Vulture/Session';

import {
	getFDValue,
} from 'Vulture/Helpers';

import {
	fetchListingDataByListingConfig,
} from 'Vulture/Content';

const getParentM1Keys = function (fd, childM2Key) {
	let parentM1Keys = [];
	for (let [ m1Key, m1Data, ] of Object.entries(fd)) {
		for (let m2Key of Object.keys(m1Data)) {
			if (m2Key === childM2Key && m2Key !== m1Key) {
				parentM1Keys.push(m1Key);
			}
		}
	}

	return parentM1Keys;
};

const getComponentM1Keys = function (fd, m1Key) {
	let components = [ m1Key, ];
	if (typeof fd[m1Key] === 'object' && fd[m1Key] !== null) {
		for (let m2Key of Object.keys(fd[m1Key])) {
			if (m2Key.startsWith('component.') && m2Key !== m1Key) {
				components = [ ...components, ...getComponentM1Keys(fd, m2Key), ];
			}
		}
	}

	return components;
};

const buildScheduleCheckmarks = (tableListings, fd, settingMap) => {
	const tableListingsWithSchedules = tableListings.map((tableListing) => {
		const retval = {
			...tableListing,
		};

		retval.schedule = false;
		retval.scheduleForSearch = [ 'false', ];

		const schedulingJSONString = fd?.[`listing.${tableListing.uuid}`]?.[`setting.${settingMap?.listing_scheduling?.uuid}`]?.json;

		if (schedulingJSONString === undefined) {
			return retval;
		}

		const schedulingJSON = JSON.parse(schedulingJSONString);

		if (schedulingJSON.scheduling) {
			retval.schedule = true;
			retval.scheduleForSearch = [ 'true', ];
		}

		return retval;
	});

	return tableListingsWithSchedules;
};

class ListingsView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			// activeListingConfig:          {},
			// activeListingConfig:          props.listingConfigMap[props.listingConfigUUID],
			listings:                     {},
			tableListings:                [],
			listingConfigTableSettings:   {},
			uniqueSettingsListForAdd:     {},
			keyValMap:                    {},
			listingComponentTypeID:       '',
			listingGroups:                [],
			sortBy:                       '',
			showTagsWindow:               false,
			tags:                         [],
			bundleOnLoad:                 {},
			showViewWindow:               false,
			columnsToHide:                [ 'columnToHide', ],
			needsFetch:                   false,
			needsUpdate:                  false,
			showTagSearchWindow:          false,
			tagsInSearch:                 [],
			tagUUIDsInSearch:             [],
			confirmModal:                 false,
			confirmModalContent:          '',
			clearFilters:                 false,
			// pendingGroupChanges:          {},
			parentsPrimaryColumnData:     {},
			tieredListings:               {},
			listingWidthCols:             {},
			listingsContentGroupColWidth: '',
			needsSetVariables:            true,
			selectedListings:             [],
			activeAccountUUID:            '',
			allRowsSelected:              false,
			typeAheadFilter:              '',
			listingFD:                    {},
		};
	}

	componentDidMount() {
		this.makeMaps();
		this.fetchListingDataByListingConfig();
	}

	componentDidUpdate(prevProps) {
		const {
			listingConfigUUID,
			fd,
			pendingChanges,
		} = this.props;

		if (prevProps.listingConfigUUID !== listingConfigUUID) {
			this.fetchListingDataByListingConfig();
			// pendingChanges cleared or saved and no longer pending
		} else if (Object.keys(prevProps.pendingChanges).length > 0 && Object.keys(pendingChanges).length === 0) {
			this.fetchListingDataByListingConfig();
		} else {
			const prevListingCount = Object.keys(prevProps.fd)
				.filter((m1Key) => {
					return m1Key.startsWith('listing.');
				}).length;
			const currentListingCount = Object.keys(fd)
				.filter((m1Key) => {
					return m1Key.startsWith('listing.');
				}).length;

			// if listings were deleted or created
			if (prevListingCount !== currentListingCount) {
				this.makeMaps();
			}
		}
	}

	async fetchListingDataByListingConfig() {
		const {
			listingConfigUUID,
			listingConfigMap,
			accountUUID,
		} = this.props;

		const listingConfigToListingConfigTierMap = this.buildListingConfigToListingConfigTierMap(`account.${accountUUID}`, {});

		let rootConfigList = [];
		let rootConfig;

		for (const uuid in listingConfigMap) {
			if ('root' in listingConfigMap[uuid] && listingConfigMap[uuid].root === true) {
				rootConfigList.push(uuid);
			}
		}

		if (rootConfigList.includes(listingConfigUUID)) {
			rootConfig = listingConfigUUID;
		} else {
			rootConfig = this.findRootConfig(listingConfigToListingConfigTierMap, `listing_config.${listingConfigUUID}`);
		}

		const response = await fetchListingDataByListingConfig(rootConfig);
		this.setState({
			listingFD: response.data,
		}, () => {
			this.makeMaps();
		});
	}

	buildListingConfigToListingConfigTierMap(parentUUID, listingConfigToListingConfigTierMap = {}) {
		const {
			fd,
		} = this.props;

		const parentObj = fd[parentUUID] || {};

		for (let m2Key of Object.keys(parentObj)) {
			if (m2Key.startsWith('listing_config.')) {
				listingConfigToListingConfigTierMap[m2Key] = parentUUID;
				listingConfigToListingConfigTierMap = this.buildListingConfigToListingConfigTierMap(m2Key, listingConfigToListingConfigTierMap);
			}
		}

		return listingConfigToListingConfigTierMap;
	}

	makeMaps() {
		console.time('makeMaps');
		const {
			listingConfigMap,
			settingMap,
			accountUUID,
			app,
		} = this.props;

		app.setState({
			loading: true,
		});

		const fd = {
			...this.props.fd,
			...this.state.listingFD,
		};

		const systemMap = {};
		const systemToListingCollectionsMap = {};

		const listingM1Keys = [];
		const listingToTierMap = {};
		const listingToParentListingsMap = {};
		const listingToListingCollectionsMap = {};
		const listingToListingConfigMap = {};
		const listingToSystemsMap = {};

		const listingConfigToListingConfigTierMap = this.buildListingConfigToListingConfigTierMap(`account.${accountUUID}`, {});
		const listingCollectionToListingConfigTierMap = {};
		const listingCollectionToParentComponentMap = {};
		const listingCollectionToSystemsMap = {};

		const listingConfigToListingCollectionsMap = {};
		const listingConfigToListingsColumnsMap = {};
		const listingConfigToListingsMap = {};
		const listingConfigsWithSystems = [];
		const systemToComponentMap = {};
		for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key.startsWith('system.')) {
				systemToComponentMap[m1Key] = [];

				for (const m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('component.')) {
						// TLC
						systemToComponentMap[m1Key] = getComponentM1Keys(fd, m2Key);
					}
				}
			}
		}

		const componentToSystemsMap = {};
		for (const [ systemM1Key, componentM1Keys, ] of Object.entries(systemToComponentMap)) {
			for (const componentM1Key of componentM1Keys) {
				if (!componentToSystemsMap[componentM1Key]) {
					componentToSystemsMap[componentM1Key] = [];
				}

				componentToSystemsMap[componentM1Key].push(systemM1Key);
			}
		}

		for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key.startsWith('system.')) {
				systemMap[m1Key] = {
					name: m1Data.display_name,
				};
			}

			if (m1Key.startsWith('listing.')) {
				listingM1Keys.push(m1Key);

				for (const m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('listing.')) {
						if (!listingToParentListingsMap[m2Key]) {
							listingToParentListingsMap[m2Key] = [];
						}

						listingToParentListingsMap[m2Key].push(m1Key);
					}
				}
			}

			if (m1Key.startsWith('listing_collection.')) {
				if (!listingCollectionToListingConfigTierMap[m1Key]) {
					listingCollectionToListingConfigTierMap[m1Key] = {};
				}

				for (const m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('listing_config.')) {
						let m2Data = fd[m2Key];

						// T1 listing_config
						listingCollectionToListingConfigTierMap[m1Key][0] = m2Key;

						if (!listingConfigToListingCollectionsMap[m2Key]) {
							listingConfigToListingCollectionsMap[m2Key] = [];
						}
						listingConfigToListingCollectionsMap[m2Key].push(m1Key);

						// T(n) listing_config
						let i = 0;
						while (~Object.keys(m2Data).join('').indexOf('listing_config.')) {
							i++;
							let childListingConfigM1Key = Object.keys(m2Data).filter((k) => {
								return k.startsWith('listing_config.');
							})[0];

							m2Data = fd[childListingConfigM1Key];
							listingCollectionToListingConfigTierMap[m1Key][i] = childListingConfigM1Key;

							if (!listingConfigToListingCollectionsMap[childListingConfigM1Key]) {
								listingConfigToListingCollectionsMap[childListingConfigM1Key] = [];
							}
							listingConfigToListingCollectionsMap[childListingConfigM1Key].push(m1Key);
						}
					}
				}
			}

			if (m1Key.startsWith('listing_config.')) {
				if (!listingConfigToListingsColumnsMap[m1Key]) {
					listingConfigToListingsColumnsMap[m1Key] = {};
				}

				for (const [ m2Key, m2Data, ] of Object.entries(m1Data)) {
					if (m2Key.startsWith('listing_column.')) {
						listingConfigToListingsColumnsMap[m1Key][m2Key] = m2Data;
						listingConfigToListingsColumnsMap[m1Key][m2Key].name = fd[m2Key].display_name;

						let listingColumnTypeM1Key = Object.keys(fd[m2Key]).filter((k) => {
							return k.startsWith('listing_column_type.');
						})[0];

						listingConfigToListingsColumnsMap[m1Key][m2Key].type = fd[listingColumnTypeM1Key].type;
						listingConfigToListingsColumnsMap[m1Key][m2Key].fieldType = fd[listingColumnTypeM1Key].display_name;
					}
				}
			}

			if (m1Key.startsWith('component.')) {
				for (let m2Key of Object.keys(m1Data)) {
					if (m2Key.startsWith('listing_collection.')) {
						listingCollectionToParentComponentMap[m2Key] = m1Key;

						let systemM1Keys = componentToSystemsMap[m1Key];
						if (!listingCollectionToSystemsMap[m2Key]) {
							listingCollectionToSystemsMap[m2Key] = [];
						}

						if (!systemM1Keys) {
							continue;
						}

						for (let systemM1Key of systemM1Keys) {
							listingCollectionToSystemsMap[m2Key].push(systemM1Key);
						}
					}
				}
			}
		}

		const systemToComponentGroupMap = {};
		for (const [ listingCollectionM1Key, systemM1Keys, ] of Object.entries(listingCollectionToSystemsMap)) {
			for (const systemM1Key of systemM1Keys) {
				if (!systemToListingCollectionsMap[systemM1Key]) {
					systemToListingCollectionsMap[systemM1Key] = [];
				}

				systemToListingCollectionsMap[systemM1Key].push(listingCollectionM1Key);
			}

			for (const m1Key of Object.keys(fd)) {
				if (m1Key.startsWith('component_group.')) {
					const group = fd[m1Key];
					if (group.group_label !== this.props.activeMenu) {
						continue;
					}

					for (const m2Key of Object.keys(fd[m1Key])) {
						if (m2Key.startsWith('system.')) {
							if (~systemM1Keys.indexOf(m2Key)) {
								systemToComponentGroupMap[m2Key] = group;
							}
						}
					}
				}
			}
		}

		for (const listingM1Key of listingM1Keys) {
			let isTier1 = true;
			let parents = getParentM1Keys(fd, listingM1Key);

			if (parents.filter((parentKey) => {
				return parentKey.startsWith('listing.');
			}).length > 0) {
				isTier1 = false;
			}

			let i = 0;
			while (!~parents.join('').indexOf('listing_collection.')) {
				i++;
				parents = getParentM1Keys(fd, parents[0]);

				if (parents.length === 0) {
					break;
				}
			}

			listingToTierMap[listingM1Key] = i;

			if (!listingToListingCollectionsMap[listingM1Key]) {
				listingToListingCollectionsMap[listingM1Key] = [];
			}

			if (!listingToSystemsMap[listingM1Key]) {
				listingToSystemsMap[listingM1Key] = [];
			}

			for (const parentM1Key of parents) {
				if (parentM1Key.startsWith('listing_collection.')) {
					let listingCollectionM1Key = parentM1Key;
					listingToListingCollectionsMap[listingM1Key].push(listingCollectionM1Key);

					let systemM1Keys = listingCollectionToSystemsMap[listingCollectionM1Key] || [];
					for (const systemM1Key of systemM1Keys) {
						listingToSystemsMap[listingM1Key].push(systemM1Key);
					}

					const listingConfigM1Key = listingCollectionToListingConfigTierMap[listingCollectionM1Key][i];
					if (!listingConfigM1Key) {
						// debugger;
						continue;
					}
					listingToListingConfigMap[listingM1Key] = listingConfigM1Key;
				}
			}

			if (!listingToListingConfigMap[listingM1Key] && isTier1) {
				for (let m2Key of Object.keys(fd[listingM1Key])) {
					if (m2Key.startsWith('listing_config.')) {
						listingToListingConfigMap[listingM1Key] = m2Key;
					}
				}
			} else if (!isTier1) {
				Object.keys(fd[listingM1Key]).forEach((m2Key) => {
					if (m2Key.startsWith('listing_config.')) {
						listingToListingConfigMap[listingM1Key] = m2Key;
					}
				});
			}

		}

		for (let [ listingM1Key, listingConfigM1Key, ] of Object.entries(listingToListingConfigMap)) {
			if (!listingConfigToListingsMap[listingConfigM1Key]) {
				listingConfigToListingsMap[listingConfigM1Key] = {};
			}

			let listing = {
				vals:    {},
				systems: new Set(listingToSystemsMap[listingM1Key]),
				tier:    listingToTierMap[listingM1Key],
			};

			if (listingToParentListingsMap[listingM1Key]) {
				listing.parent_listings = listingToParentListingsMap[listingM1Key];
			}

			let cols = listingConfigToListingsColumnsMap[listingConfigM1Key];
			listing.cols = cols;

			for (let [ colM1Key, col, ] of Object.entries(cols)) {
				if (typeof fd[listingM1Key][colM1Key] === 'undefined') {
					listing.vals[col.name] = null;
				} else {
					let type = col.type;

					if (col.fieldType === 'Array') {
						const arrayOptions = getFDValue(fd, `account.${accountUUID}.setting.${settingMap['array_options'].uuid}`);

						if (arrayOptions) {
							type = JSON.parse(arrayOptions.json)[col.name].type;
						}
					}

					listing.vals[col.name] = fd[listingM1Key][colM1Key][type];
				}
			}

			let listingCollectionM1Keys = listingToListingCollectionsMap[listingM1Key];
			for (let listingCollectionM1Key of listingCollectionM1Keys) {
				for (let listingCollectionM1Key of listingCollectionM1Keys) {
					let systemM1Keys = listingCollectionToSystemsMap[listingCollectionM1Key];

					if (!systemM1Keys) {
						continue;
					}

					let grouped = (systemM1Keys.length > 1);
					if (grouped && systemToComponentGroupMap[systemM1Keys[0]]) {
						let group = systemToComponentGroupMap[systemM1Keys[0]];
						for (let m1Key of Object.keys(group)) {
							if (m1Key.startsWith('system.')) {
								listing.systems.add(m1Key);
							}
						}
					}
				}

				let componentM1Key = listingCollectionToParentComponentMap[listingCollectionM1Key];

				if (typeof fd?.[componentM1Key]._bundle !== 'undefined') {
					if (!listing.componentGroups) {
						listing.componentGroups = [];
					}

					listing.componentGroups.push(componentM1Key);
				}
			}
			listing.systems = Array.from(listing.systems);

			listingConfigToListingsMap[listingConfigM1Key][listingM1Key] = listing;
		}


		const listings = {
			systems:         systemMap,
			componentGroups: systemToComponentGroupMap,
			listings:        {},
			cols:            {},
		};

		for (const listingConfigM1Key of Object.keys(listingConfigToListingsMap)) {
			listings.cols = {
				...(listings.cols || {}),
				...listingConfigToListingsColumnsMap[listingConfigM1Key],
			};
			listings.listings[listingConfigM1Key] = listingConfigToListingsMap[listingConfigM1Key];
		}

		for (const listingConfigUUID of Object.keys(listingConfigMap)) {
			const listingConfigM1Key = `listing_config.${listingConfigUUID}`;
			if (!listings.listings[listingConfigM1Key]) {
				listings.listings[listingConfigM1Key] = {};
			}
		}

		Object.keys(listingCollectionToSystemsMap).forEach((collectionKey) => {
			const systems = listingCollectionToSystemsMap[collectionKey];

			// Check if the collection has any systems
			if (systems.length > 0) {
				const listingConfigsByTier = listingCollectionToListingConfigTierMap[collectionKey];
				if (listingConfigsByTier) {
					Object.values(listingConfigsByTier).forEach((listingConfigWithPrefix) => {
						const uuid = listingConfigWithPrefix.split('.')[1];
						if (uuid && !listingConfigsWithSystems.includes(uuid)) {
							listingConfigsWithSystems.push(uuid);
						}
					});
				}
			}
		});
		// Update contentview with configs to display.
		this.props.setListingConfigsToDisplay(listingConfigsWithSystems);

		this.setState({
			systemToListingCollectionsMap,
			listingCollectionToParentComponentMap,
			listingCollectionToSystemsMap,
			listingConfigToListingConfigTierMap,
			listingConfigToListingCollectionsMap,
			listingConfigToListingsColumnsMap,
			listingConfigsWithSystems,
			listingToListingCollectionsMap,
			listingToParentListingsMap,
			listingToSystemsMap,
			tieredListings: listings.listings,
		}, () => {
			this.makeListings(() => {
				this.getListingGroups();
				this.setVariables();
				app.setState({
					loading: false,
				});
			});
		});
		console.timeEnd('makeMaps');
	}

	injectColumnElm(listing) {
		let tags = []; // this.getTagsFromBundle();
		let selectedListings = this.state.selectedListings;

		listing.tags = this.getTagsForListing(listing.uuid, tags);
		listing.isChecked = false;

		for (let [ listingColumnM1Key, col, ] of Object.entries(listing.cols)) {
			let val = listing.vals[col.name];

			let elm;
			switch (col.fieldType) {
			case 'Checkbox':
				if (val) {
					elm = <CustomIcon icon='check-circle' color='#00baff'></CustomIcon>;
				} else {
					elm = <CustomIcon icon='circle' color='#00baff'></CustomIcon>;
				}
				break;

			case 'Image':
				if (val) {
					elm = <CustomIcon icon='image' color='#00baff'></CustomIcon>;
				} else {
					elm = null;
				}
				break;

			case 'PDF':
				if (val) {
					elm = <CustomIcon icon='file-pdf' color='#00baff'></CustomIcon>;
				} else {
					elm = null;
				}
				break;
			case 'Video':
				if (val) {
					elm = <CustomIcon icon='video' color='#00baff'></CustomIcon>;
				} else {
					elm = null;
				}
				break;

			case 'Phone':
			case 'Primary Phone':
				if (val) {
					let jsonCellData = JSON.parse(val);
					elm = '+' + jsonCellData.cc + ' ' + jsonCellData.area + '-' + jsonCellData.n;
				} else {
					elm = null;
				}
				break;
			case 'SMS Message':
				if (val) {
					let jsonCellData = JSON.parse(val);
					elm = '+' + jsonCellData.cc + ' ' + jsonCellData.area + '-' + jsonCellData.n;
				} else {
					elm = null;
				}
				break;
			case 'Email Message':
				if (val) {
					let jsonCellData = JSON.parse(val);
					elm = jsonCellData.email + '@' + jsonCellData.domain;
				} else {
					elm = null;
				}
				break;
			case 'Logo':
			case 'Photo':
				if (val) {
					elm = <CustomIcon icon='check' color='#00baff'></CustomIcon>;
				} else {
					elm = null;
				}
				break;
			case 'Text Large':
				(() => {
					const converter = new showdown.Converter();
					const convertedtext = converter.makeHtml(val);
					const html = (convertedtext ? convertedtext.substring(0, 25) + '...' : '');
					if (val) {
						elm = <div dangerouslySetInnerHTML={{
							__html: html,
						}}></div>;
					} else {
						elm = null;
					}
				})();
				break;

			default:
				elm = val;
			}

			let listingColumnUUID = listingColumnM1Key.split('.')[1];
			listing.vals[listingColumnUUID] = elm;
		}

		if (selectedListings.length > 0) {
			if (selectedListings.includes(listing.uuid)) {
				listing.isChecked = true;
			}
		}

		return listing;
	}

	// find the attribute and entity key for the tier 2+ parent's column with the order of 1
	getParentsPrimaryColumnData() {
		const {
			listingConfigMap,
			listingConfigUUID,
		} = this.props;

		let activeListingConfig = listingConfigMap[listingConfigUUID];

		let parentPrimaryColumnInfo = {
			parentsFirstColumnEntityKey: '',
			parentsFirstColumnAttr:      '',
		};
		let activeColumns = this.state.listingConfigToListingsColumnsMap[`listing_config.${listingConfigUUID}`];
		let validConfig = true;

		if (Object.keys(activeColumns).length === 0) {
			validConfig = false;
		}
		if (validConfig) {
			let parentListingConfig = listingConfigMap[activeListingConfig.parentUUID];
			for (const [ m1Key, m1Data, ] of Object.entries(parentListingConfig)) {
				if (m1Key.startsWith('listing_column.') && m1Data.order === 1) {
					parentPrimaryColumnInfo.parentsFirstColumnEntityKey = m1Key;

					for (const [ m2Key, m2Data, ] of Object.entries(m1Data)) {
						if (m2Key.startsWith('listing_column_type.')) {
							parentPrimaryColumnInfo.parentsFirstColumnAttr = m2Data.type;

							return parentPrimaryColumnInfo;
						}
					}
				}
			}
		}

		return parentPrimaryColumnInfo;
	}

	updateBundleOnLoad() {
		const {
			accountUUID,
			fd,
		} = this.props;

		let bundleOnLoad = {};
		if (fd[`account.${accountUUID}`]._bundle) {
			try {
				bundleOnLoad = JSON.parse(JSON.stringify(fd[`account.${accountUUID}`]._bundle));
			} catch (err) {
				console.error(err);
			}
		}

		this.setState({
			bundleOnLoad,
		});
	}

	resetTags() {
		const {
			app,
			accountUUID,
		} = this.props;

		let bundle = this.state.bundleOnLoad;
		let key = `account.${accountUUID}._bundle`;
		let value = bundle.value;

		app.setValue('listings', key, value);
		this.setState({
			needsFetch: true,
		});
		this.clearPendingChangesFunctions();
		this.submitChanges();
	}

	needsUpdate() {
		this.setState({
			needsUpdate: true,
		});

		this.clearPendingChangesFunctions();
	}

	clearPendingChangesFunctions() {
		let form = this.props.form;

		form.setState({
			vulture: {
				...form.state.vulture,
				dontSaveCallback: () => { },
				saveCallback:     () => { },
			},
		});
	}

	getTagsFromBundle() {
		const {
			accountUUID,
			fd,
		} = this.props;

		let tags = [];
		if (!fd) {
			return tags;
		}

		let accountM1Data = fd[`account.${accountUUID}`];
		if (!accountM1Data) {
			return tags;
		}

		if (accountM1Data._bundle) {
			accountM1Data._bundle.forEach((bundle) => {
				if (bundle.attr.type === 'tag') {
					let tag = {
						label:   bundle.attr.label,
						icon:    bundle.attr.icon,
						color:   bundle.attr.color,
						type:    bundle.attr.type,
						order:   bundle.attr.order,
						m2_list: bundle.m2_list,
						uuid:    bundle.uuid,
					};

					tags.push(tag);
				}
			});
		}

		tags.sort((a, b) => {
			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		});

		return tags;
	}

	getTagsForListing(m1ID, tags) {
		let icons = [];

		tags.forEach((tag, i) => {
			if (tag.m2_list.includes(m1ID)) {
				icons.push(<CustomIcon icon={tag.icon} color={tag.color} key={`${i}`} />);
			}
		});

		return icons;
	}

	getListingCollectionInfo(listingConfigUUID) {
		const {
			accountUUID,
			activeMenu,
			fd,
		} = this.props;

		const {
			systemToListingCollectionsMap,
			listingCollectionToParentComponentMap,
			listingCollectionToSystemsMap,
			listingConfigToListingCollectionsMap,
		} = this.state;

		let info = {};
		let bundles = [];
		let systems = [];

		let componentGroupMap = {};
		let systemToComponentGroupMap = {};

		let accountM1Key = `account.${accountUUID}`;
		for (let m1Key of Object.keys(fd[accountM1Key])) {
			if (m1Key.startsWith('component_group.')) {
				let componentGroup = fd[m1Key];

				// TODO: check by component type as well
				if (componentGroup.group_label === activeMenu) {
					componentGroupMap[m1Key] = fd[m1Key];

					for (let m2Key of Object.keys(componentGroup)) {
						if (m2Key.startsWith('system.')) {
							systemToComponentGroupMap[m2Key] = componentGroup;
						}
					}
				}
			}
		}

		let listingConfigM1Key = `listing_config.${listingConfigUUID}`;
		let currentListingConfigListingCollectionM1Keys = listingConfigToListingCollectionsMap[listingConfigM1Key] || [];

		let listingCollectionObjs = {};
		let groupedSystemM1Keys = [];

		for (const listingCollectionM1Key of Object.keys(listingCollectionToParentComponentMap)) {
			if (!~currentListingConfigListingCollectionM1Keys.indexOf(listingCollectionM1Key)) {
				continue;
			}

			let systemM1Keys = listingCollectionToSystemsMap[listingCollectionM1Key];
			let listingCollectionM1Keys = new Set();
			for (let systemM1Key of systemM1Keys) {
				for (let m1Key of systemToListingCollectionsMap[systemM1Key]) {
					listingCollectionM1Keys.add(m1Key);
				}
			}
			listingCollectionM1Keys = Array.from(listingCollectionM1Keys)
				.filter((m1Key) => {
					if (~currentListingConfigListingCollectionM1Keys.indexOf(m1Key)) {
						return true;
					}

					return false;
					// return Boolean(~listingConfigToListingCollectionsMap[`listing_config.${listingConfigUUID}`].indexOf(m1Key));
				});

			let grouped = (systemM1Keys.length > 1);
			if (grouped) {
				if (groupedSystemM1Keys.length > 0 && ~groupedSystemM1Keys.indexOf(systemM1Keys[0])) {
					groupedSystemM1Keys = [ ...groupedSystemM1Keys, ...systemM1Keys, ];
				}

				let systemOrGroupName;
				let systemOrGroupUUID;
				for (let m1Key of Object.keys(fd)) {
					if (m1Key.startsWith('component_group.')) {
						let group = fd[m1Key];
						if (group.group_label !== this.props.activeMenu) {
							continue;
						}

						for (let m2Key of Object.keys(fd[m1Key])) {
							if (m2Key.startsWith('system.')) {
								if (~systemM1Keys.indexOf(m2Key)) {
									systemOrGroupName = group.display_name;
									systemOrGroupUUID = m1Key.split('.')[1];
									break;
								}
							}
						}
					}
				}

				listingCollectionObjs[systemOrGroupUUID] = {
					grouped,
					name: systemOrGroupName,
					uuid: systemOrGroupUUID,
					listingCollectionM1Keys,
					systemM1Keys,
				};
			}
			if (!grouped) {
				for (let systemM1Key of systemM1Keys) {
					let systemOrGroupName = fd[systemM1Key].display_name;
					let systemOrGroupUUID = systemM1Key.split('.')[1];

					listingCollectionObjs[systemOrGroupUUID] = {
						grouped,
						name: systemOrGroupName,
						uuid: systemOrGroupUUID,
						listingCollectionM1Keys,
					};
				}
			}
		}

		for (let listingCollectionObj of Object.values(listingCollectionObjs)) {
			if (listingCollectionObj.grouped) {
				bundles.push(listingCollectionObj);
			}

			if (!listingCollectionObj.grouped) {
				systems.push(listingCollectionObj);
			}
		}

		info.bundles = bundles;
		info.systems = systems;

		return info;
	}

	// gather all Listing Groups by name and ID and sort them by name
	getListingGroups() {
		const {
			listingConfigUUID,
		} = this.props;

		let info = this.getListingCollectionInfo(listingConfigUUID);

		info.bundles.sort((a, b) => {
			if (b.name > a.name) {
				return -1;
			}
			if (b.name < a.name) {
				return 1;
			}
			return 0;
		});

		info.systems.sort((a, b) => {
			if (b.name > a.name) {
				return -1;
			}
			if (b.name < a.name) {
				return 1;
			}
			return 0;
		});

		let listingGroups = [];
		listingGroups.push(...info.bundles);
		listingGroups.push(...info.systems);

		this.setState({
			listingGroups,
		});
	}

	// given a component ID, find the parent system's diplay name
	getSystemDisplayNameForComponentID(listingGroupID) {
		let relationArray = [ listingGroupID, ];
		relationArray = this.getParentComponent(relationArray);
		let {
			fd,
		} = this.props;
		let systemUUID = relationArray[relationArray.length - 1];
		let systemName = '';

		if (fd[`system.${systemUUID}`].display_name) {
			systemName = fd[`system.${systemUUID}`].display_name;
		}

		return {
			systemName,
			systemUUID,
		};
	}

	// given an array with an ID, recur until a system ID is found
	getParentComponent(relationArray) {
		let uuid = relationArray[relationArray.length - 1];
		const {
			fd,
		} = this.props;
		let end = false;

		const entityNames = [];

		for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
			for (const m2Key of Object.keys(m1Data)) {
				if (m2Key === `component.${uuid}`) {
					entityNames.push(`${m1Key}.${m2Key}`);
				}
			}
		}

		entityNames.forEach((entityKey) => {
			let m1UUID = entityKey.split('.')[1];

			if (entityKey.startsWith('component.') || entityKey.startsWith('system.')) {
				relationArray.push(m1UUID);

				if (entityKey.startsWith('system.')) {
					end = true;
				}
			}
		});

		if (!end) {
			this.getParentComponent(relationArray);
		}
		return relationArray;
	}

	makeListings(cb = () => { }) {
		const {
			listingConfigMap,
			listingConfigUUID,
			fd,
			settingMap,
		} = this.props;

		const {
			tieredListings,
		} = this.state;

		if (!listingConfigUUID) {
			return;
		}

		let listingConfigEntityKey = `listing_config.${listingConfigUUID}`;
		if (!tieredListings[listingConfigEntityKey]) {
			return;
		}

		let tags = this.getTagsFromBundle();

		let listingObjects = [];
		for (const [ k, v, ] of Object.entries(tieredListings[listingConfigEntityKey])) {
			v.uuid = k.split('.')[1];
			listingObjects.push(v);
		}

		let tableSettings = this.getListingsConfigComponent();
		let listingConfigTableSettings = tableSettings.listingTableSettings;
		let listingsConfig = tableSettings.listingsConfig;

		let sortBy = this.state.sortBy;
		if (tableSettings.sortBy) {
			sortBy = tableSettings.sortBy;
		}
		if (~sortBy.indexOf('.')) {
			// sort by UUID of listing_column.UUID
			sortBy = sortBy.split('.')[1];
		}

		// let listingComponentTypeID = this.props.form.state?.vulture?.componentTypeToUUID?.['listing'];
		let listingComponentTypeID = '';

		if (listingObjects) {
			const listings = {};
			let tableListings = [];
			let uniqueSettingsListForAdd = {};

			Object.values(listingObjects).forEach((listing) => {
				// if we are not searching by tag, add all listings
				if (this.state.tagUUIDsInSearch.length === 0) {
					tableListings.push(this.injectColumnElm(listing));
				} else {
					// ensure each listing is vaild for all searchUUIDs
					let isValid = true;
					this.state.tagUUIDsInSearch.forEach((tagUUID) => {
						// track each searchUUID individually
						let tagIsValid = true;

						// if the uuid in the search is a group uuid, check for a relation...
						this.state.listingGroups.forEach((group) => {
							if (group.uuid === tagUUID) {
								if (!this.listingCollectionHasListingGroupRelation(tagUUID, listing)) {
									tagIsValid = false;
								}
							}
						});
						// if the uuid is a tag
						this.state.tags.forEach((tag) => {
							// if the tagUUID matches a tag.uuid in state
							if (tag.uuid === tagUUID) {
								// check the m2_list to see if it includes the listing listing's uuid
								if (!tag.m2_list.includes(listing.uuid)) {
									tagIsValid = false;
								}
							}
						});
						if (!tagIsValid) {
							isValid = false;
						}
					});
					if (isValid) {
						tableListings.push(this.injectColumnElm(listing));
					}
				}
			});

			if (sortBy !== '') {
				tableListings.sort((a, b) => {
					if (a.vals[sortBy] && b.vals[sortBy]) {
						let listingA = String(a.vals[sortBy]).toLowerCase();
						let listingB = String(b.vals[sortBy]).toLowerCase();

						if (listingA > listingB) {
							return 1;
						}

						if (listingA < listingB) {
							return -1;
						}

						return 0;
					}
				});
			}

			let parentsPrimaryColumnData = {};
			if (!listingConfigMap[listingConfigUUID].root) {
				parentsPrimaryColumnData = this.getParentsPrimaryColumnData();
			}

			let activeListingConfig = listingConfigMap[listingConfigUUID];
			let pg = getSession().pg;
			if (pg !== 'iu' && pg !== 'ia') {
				if (activeListingConfig.root === true) {
					tableListings = tableListings.filter((row) => {
						return row.systems.length > 0;
					});
				} else {
					tableListings = tableListings.filter((row) => {
						let rootListings = this.findRootListings(`listing.${row.uuid}`);
						// Use 'every' to make sure all parents have systems. Replace with 'some' if at least one parent should have systems.
						return rootListings.some((parentId) => {
							const _rootListings = this.state.listingToSystemsMap[parentId];
							return _rootListings && _rootListings.length > 0;
						});
					  });
				}
			}

			const tableListingsWithSchedules = buildScheduleCheckmarks(tableListings, fd, settingMap);

			this.setState({
				listings,
				tableListings: tableListingsWithSchedules,
				listingComponentTypeID,
				uniqueSettingsListForAdd,
				listingConfigTableSettings,
				tags,
				listingsConfig,
				parentsPrimaryColumnData,
			}, () => {
				if (typeof cb === 'function') {
					return cb();
				}
			});

			if (Object.keys(this.props.pendingChanges).length > 0) {
				this.resetPendingChangesForUI();
			}
		}
	}

	sortTable(sortBy) {
		let tableListings = this.state.tableListings;

		switch (typeof tableListings[0][sortBy]) {
		case 'string':
			tableListings.sort((a, b) => {
				if (a[sortBy] && b[sortBy]) {
					let listingA = a[sortBy].toLowerCase();
					let listingB = b[sortBy].toLowerCase();
					if (listingB > listingA) {
						return -1;
					}
					if (listingB < listingA) {
						return 1;
					}
					return 0;
				}
			});
			break;

		case 'number':
			tableListings.sort((a, b) => {
				if (a[sortBy] && b[sortBy]) {
					return b[sortBy] - a[sortBy];
				}
			});
			break;

		case 'bool':
			tableListings.sort((a, b) => {
				if (a[sortBy]) {
					return 1;
				}
				if (b[sortBy]) {
					return -1;
				}
			});
			break;

		case 'undefined':
			tableListings.sort((a, b) => {
				return this.listingCollectionHasListingGroupRelation(sortBy, b) - this.listingCollectionHasListingGroupRelation(sortBy, a);
			});
			break;

		default:
			break;
		}

		this.setState({
			sortBy,
			tableListings,
		});
	}

	// create a key[value] map of data[displayName] (or accessor[header] for react table)
	getListingsConfigComponent() {
		const {
			listingConfigUUID,
			listingConfigMap,
		} = this.props;

		let listingTableSettings = {};
		let tableSettings = {};
		let candidateColumns = [];
		let activeListingConfig = listingConfigMap[listingConfigUUID];
		let disableColumn = false;
		for (const [ m1Key, m1Data, ] of Object.entries(activeListingConfig)) {
			if (m1Key.startsWith('listing_column.')) {
				/* for (let [m2Key, m2Data,] of Object.entries(m1Data)) {
					if (m2Key.startsWith('listing_column_type.')) {
						if (m2Data.display_name === 'Text Large') {
							disableColumn = true;
						}
					}
				} */
				if (m1Data.order === 1) {
					tableSettings.sortBy = m1Key;
				}
				if (!disableColumn) {
					candidateColumns.push({
						uuid:  m1Key.split('.')[1],
						name:  m1Data.display_name,
						order: m1Data.order,
					});
				}
			}
		}

		candidateColumns.sort((a, b) => {
			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		});

		candidateColumns.forEach((info) => {
			listingTableSettings[info.uuid] = info.name;
		});

		tableSettings.listingTableSettings = listingTableSettings;

		return tableSettings;
	}

	getParentAndChildUUIDsForListing(listingUUID) {
		let {
			form,
			fd,
		} = this.props;
		let parentUUIDs = [];
		let childUUIDs = [];

		for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
			for (const m2Key of Object.entries(m1Data)) {
				if (m2Key === `listing.${listingUUID}`) {
					const entityKey = `${m1Key}.${m2Key}`;
					if (getFDValue(fd, entityKey)) {
						let {
							m1ID,
						} = form.parseEntityName(entityKey);
						parentUUIDs.push(m1ID);
					}
				}
			}
		}

		for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key === `listing.${listingUUID}`) {
				for (const m2Key of Object.entries(m1Data)) {
					if (m2Key.startsWith('listing.')) {
						const entityKey = `${m1Key}.${m2Key}`;
						const sections = m2Key.split('.').length;
						if (getFDValue(fd, entityKey) && sections === 2) {
							let {
								m2ID: childListingUUID,
							} = form.parseEntityName(entityKey);
							childUUIDs.push(childListingUUID);
						}
					}
				}
			}
		}

		let ids = {
			parentUUIDs,
			childUUIDs,
		};

		return ids;
	}

	isPositiveInteger(str) {
		if (typeof str !== 'string') {
			return false;
		}

		const num = Number(str);

		if (Number.isInteger(num) && num > 0) {
			return true;
		}

		return false;
	}

	getParentListingData(listing) {
		const {
			fd,
		} = this.props;

		let {
			parentsFirstColumnEntityKey,
			parentsFirstColumnAttr,
		} = this.state.parentsPrimaryColumnData;

		let parentData = [];
		for (let parentM1Key of (listing.parent_listings || [])) {
			parentData.push(
				getFDValue(fd, `${parentM1Key}.${parentsFirstColumnEntityKey}.${parentsFirstColumnAttr}`)
			);
		}

		// /* sort the data, keeping in mind address formats
		// (456 Fake St. goes before 1234 Fake St., which goes before 11 Placeholder Street)*/
		// var reA = /[^a-zA-Z]/g;
		// var reN = /[^0-9]/g;
		// function sortAlphaNum(a, b) {
		// 	var aA = a.replace(reA, "");
		// 	var bA = b.replace(reA, "");
		// 	//if the street names match, sort by number value (NOT alphabetically. 5 goes before 12)
		// 	if(aA === bA) {
		// 		var aN = parseInt(a.replace(reN, ""), 10);
		// 		var bN = parseInt(b.replace(reN, ""), 10);
		// 		return aN === bN ? 0 : aN > bN ? 1 : -1;
		// 	} else {
		// 		//different street names: sort alphabetically by non-number part (the street name)
		// 		if (/^\d/.test(a) && /^\d/.test(b)){
		// 			return aA > bA ? 1 : -1;
		// 		}
		// 		//else just compare normally
		// 		return a > b ? 1 : -1;
		// 	}
		// }
		// parentData.sort(sortAlphaNum);

		parentData.sort((a, b) => {
			a = a.toLowerCase();
			b = b.toLowerCase();

			return (a > b) ? 1 : -1;
		});

		return parentData.join(', ');
	}

	// Using the order of the parent's columns, determine what name to display
	getParentComponentName(listingConfigEntityKey, parentsFirstColumnEntityKey, parentsFirstColumnAttr) {
		const {
			fd,
		} = this.props;

		return getFDValue(fd, `${listingConfigEntityKey}.${parentsFirstColumnEntityKey}.${parentsFirstColumnAttr}`);
	}

	deleteSelectedListings() {
		const {
			app,
		} = this.props;

		app.deleteM1('listing', this.state.selectedListings);
	}

	async submitAndUpdateChangesAndDelete(IDsToDelete = []) {

		await this.submitAndUpdateChanges();

		IDsToDelete.forEach((IDToDelete, i) => {
			this.deleteComponent(IDToDelete, i === IDsToDelete.length - 1);
		});
	}

	setScrollPosition() {
		let scrollX = document.getElementById('table-scroll').scrollLeft;
		let scrollY = document.getElementById('table-scroll').scrollTop;

		this.props.form.setState({
			vulture: {
				...this.props.form.state.vulture,
				scrollX,
				scrollY,
			},
		});
	}

	getScrollPosition() {
		let vultureState = this.props.form.state.vulture;
		let x = vultureState.scrollX;
		let y = vultureState.scrollY;

		if (x || y) {
			document.getElementById('table-scroll').scroll(x, y);
		}
	}

	toggleSelectAllRows(areAllRowsChecked) {
		let newTableListings = this.state.tableListings;
		let newSelectedListings = [];
		newTableListings.forEach((element) => {
			element.isChecked = !this.state.allRowsSelected;
			if (!areAllRowsChecked) {
				newSelectedListings.push(element.uuid);
			}
		});
		//this.state.selectedListings
		this.setState({
			tableListings:    newTableListings,
			allRowsSelected:  !this.state.allRowsSelected,
			selectedListings: newSelectedListings,
		});

	}
	selectAllRows() {
		let newTableListings = this.state.tableListings;
		newTableListings.forEach((element) => {
			element.isChecked = true;
		});
		this.setState({
			tableListings: newTableListings,
		});
	}

	deselectAllRows() {
		let newTableListings = this.state.tableListings;
		newTableListings.forEach((element) => {
			element.isChecked = false;
		});
		this.setState({
			tableListings: newTableListings,
		});
	}

	setSearchText(searchText) {
		this.props.form.setState({
			vulture: {
				...this.props.form.state.vulture,
				searchText,
			},
		});
	}

	getSearchText() {
		let searchText = this.props.form.state.vulture.searchText;

		return searchText;
	}

	// NOTE: check for usage later
	submitAndUpdateChanges(entityKey = '', entityKeys = []) {
		let {
			form,
		} = this.props;
		if (entityKey) {
			form.postFormData(null, this.addOrUpdateListing.bind(this), entityKey);
		} else {
			form.postFormData(null);
		}

		if (entityKey.length > 0) {
			this.props.form.getMatchingKeys(`${entityKey}.*.*`, true);
			this.props.form.getMatchingKeys(`*.*.${entityKey}`, true);
		}

		if (entityKeys.length > 0) {
			entityKeys.forEach((eKey) => {
				this.props.form.getMatchingKeys(eKey, true);
			});
		}

		this.makeListings();
	}

	submitChanges() {
		let formSubmit = document.getElementById('crow-form-submit-btn');
		formSubmit.click();
	}

	deleteComponent(IDToDelete, final) {
		let idsToCheck = [];
		idsToCheck.push(IDToDelete);

		let sessionKey = getSession().session;

		let opts = {
			method:  'POST',
			headers: {
				'content_type':   'application/json',
				'X-Auth-Session': sessionKey,
			},
			body: {},
		};

		new kali(opts).post(window._getEnv('BRONCO_URL') + `/w/delete/listing/${IDToDelete}`, {
			success: (_kali, res, data) => {
				console.log(data);
				if (final) {
					this.fetchListings(true);
				}
			},

			failure: (_kali, res, err) => {
				console.error(err);

				if (res.status === 503) {
					this.props.form.props.on503(true);
				}
			},
		});
	}

	listingCollectionHasListingGroupRelation(listingCollection, listing) {
		let uuid = listingCollection.uuid;


		if (listingCollection.grouped) {
			let systemM1Key = listingCollection.systemM1Keys[0];
			if (listing.systems.includes(systemM1Key)) {
				return true;
			}
		}

		if (listing.systems && listing.systems.includes(`system.${uuid}`)) {
			return true;
		}

		return false;
	}

	getListingStaticIDsForListingGroupID(listingGroupID) {
		let {
			value,
		} = this.props;
		let entityKeys = Object.keys(value);
		let childListingStatics = [];

		entityKeys.forEach((entityKey) => {
			const {
				m1, m1ID, m2, m2ID,
			} = this.props.form.parseEntityName(entityKey);
			if (m1 === 'component' && m1ID === listingGroupID && m2 === 'component') {
				childListingStatics.push(m2ID);
			}
		});
		return childListingStatics;
	}

	getChildRelations(listingUUID) {
		const {
			fd,
		} = this.props;

		const componentRelations = [];

		for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
			if (m1Key === `listing.${listingUUID}`) {
				for (const m2Key of Object.keys(m1Data)) {
					if (m2Key.includes('.')) {
						componentRelations.push(`${m1Key}${m2Key}`);
					}
				}
			}
		}

		return componentRelations;
	}

	exportCSV() {
		const {
			listingConfigMap,
			listingConfigUUID,
			fd,
			accountUUID,
		} = this.props;

		const {
			listingConfigTableSettings,
			tableListings,
			selectedListings,
			listingToParentListingsMap,
			listingToSystemsMap,
		} = this.state;

		const activeListingConfig = listingConfigMap[listingConfigUUID];

		const isTier1 = !(activeListingConfig.parentUUID in listingConfigMap);
		const isTier2 = !isTier1 && !(listingConfigMap[activeListingConfig.parentUUID].parentUUID in listingConfigMap);

		let parentNameColumn = '';

		const accountName = fd[`account.${accountUUID}`].display_name;
		const accountNameWithUnderscores = accountName.split(' ').join('_');

		// logic for getting the uuid to keep ordering state of the columns
		const headers = Object.values(listingConfigTableSettings);

		const columnHeaders = [];

		headers.forEach((header) => {
			const [ , columnName, ] = Object.entries(listingConfigTableSettings).find(([ , val, ]) => {
				return header === val;
			});
			columnHeaders.push(`"${columnName}"`);
		});

		if (isTier1) {
			columnHeaders.push(`"Systems"`);
		} else {
			columnHeaders.push(`"Parent Listings"`);
			if (isTier2) {
				columnHeaders.push(`"Systems"`);
			}
			Object.entries(fd[`listing_config.${activeListingConfig.parentUUID}`]).forEach(([ key, val, ]) => {
				if (key.startsWith('listing_column.')) {
					if (val.order === 1) {
						parentNameColumn = key;
					}
				}
			});
		}

		const dataToCollect = Object.keys(listingConfigTableSettings);

		const bodyRows = [];

		tableListings
			.filter((listing) => {
				return selectedListings.length === 0 || selectedListings.includes(listing.uuid);
			})
			.forEach((listing) => {
				const listingM1Key = `listing.${listing.uuid}`;

				const row = [];
				dataToCollect.forEach((listingColumnM1UUID) => {
					const listingColumnM1Key = `listing_column.${listingColumnM1UUID}`;

					const listingColumnType = Object.keys(fd[listingColumnM1Key])
						.find((key) => {
							return key.startsWith('listing_column_type.');
						});

					const {
						type,
					} = fd[listingColumnType];


					const hasValue = !!fd[listingM1Key][listingColumnM1Key];
					if (hasValue) {
						let value = fd[listingM1Key][listingColumnM1Key][type];
						if (value === undefined || value === null) {
							row.push(`"null"`);
							return;
						}

						if (type === 'json' || fd[listingColumnType].display_name === 'SMS Message') {
							const {
								area,
								cc,
								country_name,
								n,
							} = JSON.parse(value);
							value = `${country_name};${cc};${area};${n}`;
						} else if (fd[listingColumnType].display_name === 'Email Message') {
							const {
								email,
								domain,
							} = JSON.parse(value);
							value = `${email}@${domain}`;
						}

						try {
							value = value.toString().replace(/\n/g, '\\n');
							row.push(`"${value.replace(/"/g, '""')}"`);
						} catch (err) {
							console.warn(`Skipping this value: ${value};`, err);
							row.push(`"null"`);
						}
					} else {
						row.push(`"null"`);
					}
				});

				if (isTier1) {
					let systems;
					if (listingM1Key in listingToSystemsMap) {
						systems = '"';
						systems += listingToSystemsMap[listingM1Key]
							.filter((systemM1Key, index, self) => {
								return self.indexOf(systemM1Key) === index;
							})
							.map((systemM1Key) => {
								const retVal = fd[systemM1Key].display_name.replace(/"/g, '""');
								return retVal;
							}).join(';');
						systems += '"';
					} else {
						systems = '""';
					}

					row.push(systems);
				} else {
					let parentsText;
					if (listingM1Key in listingToParentListingsMap) {
						parentsText = '"';
						parentsText += listingToParentListingsMap[listingM1Key]
							.filter((parentM1Key, index, self) => {
								return self.indexOf(parentM1Key) === index;
							})
							.map((parentM1Key) => {
								const retVal = fd[parentM1Key][parentNameColumn].string.replace(/"/g, '""');
								return retVal;
							}).join(';');
						parentsText += '"';
					} else {
						parentsText = '""';
					}

					row.push(parentsText);

					if (isTier2) {
						let systemsText = '"';

						const grandParents = {};
						(listingToParentListingsMap[listingM1Key] || []).forEach((parentM1Key) => {
							if (parentM1Key in listingToSystemsMap) {
								listingToSystemsMap[parentM1Key].forEach((systemM1Key) => {
									grandParents[systemM1Key] = true;
								});
							}
						});

						systemsText += Object.keys(grandParents).map((key) => {
							const retVal = fd[key].display_name.replace(/"/g, '""');
							return retVal;
						}).join(';');

						systemsText += '"';

						row.push(systemsText);
					}
				}

				bodyRows.push(row);
			});

		for (let shift = 0; shift < bodyRows.length; shift += 5000) {
			let csvContent = 'data:text/csv;charset=utf-8,';

			const rows = [];

			rows.push(columnHeaders);

			rows.push(...bodyRows.slice(0 + shift, 5000 + shift));

			rows.forEach((rowArray) => {
				let row = rowArray.join(',');
				csvContent += row + '\r\n';
			});

			let encodedUri = encodeURI(csvContent);
			encodedUri = encodedUri.replaceAll('#', '%23');
			let link = document.createElement('a');
			link.setAttribute('href', encodedUri);

			link.setAttribute('download', `${accountNameWithUnderscores}_${activeListingConfig.display_name}_${(shift / 5000) + 1}.csv`);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	templateCSV() {
		const {
			listingConfigMap,
			listingConfigUUID,
			fd,
			accountUUID,
		} = this.props;

		const {
			listingConfigTableSettings,
		} = this.state;

		const accountName = fd[`account.${accountUUID}`].display_name;
		const accountNameWithUnderscores = accountName.split(' ').join('_');

		const activeListingConfig = listingConfigMap[listingConfigUUID];

		const isTier1 = !(activeListingConfig.parentUUID in listingConfigMap);
		const isTier2 = !isTier1 && !(listingConfigMap[activeListingConfig.parentUUID].parentUUID in listingConfigMap);
		let csvContent = 'data:text/csv;charset=utf-8,';

		const rows = [];

		// logic for getting the uuid to keep ordering state of the columns
		const headers = Object.values(listingConfigTableSettings);

		const columnHeaders = [];

		headers.forEach((header) => {
			const [ , columnName, ] = Object.entries(listingConfigTableSettings).find(([ , val, ]) => {
				return header === val;
			});
			columnHeaders.push(`"${columnName}"`);
		});


		if (isTier1) {
			columnHeaders.push(`"Systems"`);
		} else {
			columnHeaders.push(`"Parent Listings"`);
			if (isTier2) {
				columnHeaders.push(`"Systems"`);
			}
		}

		rows.push(columnHeaders);

		rows.forEach((rowArray) => {
			const row = rowArray.join(',');
			csvContent += row + '\r\n';
		});

		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);

		link.setAttribute('download', `${accountNameWithUnderscores}_${activeListingConfig.display_name}_template.csv`);
		document.body.appendChild(link);
		link.click();
	}

	openModal(modalProps) {
		let form = this.props.form;

		form.setState({
			vulture: {
				...form.state.vulture,
				modalSize: 1,
				showModal: true,
				modalProps,
			},
		});
	}

	closeModal() {
		this.props.form.setState({
			vulture: {
				...this.props.form.state.vulture,
				modalSize:  0,
				showModal:  false,
				modalProps: {},
			},
		});
	}

	tagInSearch(uuid) {
		let tagsInSearch = this.state.tagsInSearch;
		let inSearch = false;

		tagsInSearch.forEach((tagInSearch) => {
			if (tagInSearch.key === uuid) {
				inSearch = true;
			}
		});

		return inSearch;
	}

	getMyTags() {
		let elms = [];
		let tags = this.state.tags;
		let tagsInSearch = this.state.tagsInSearch;
		let tagUUIDsInSearch = this.state.tagUUIDsInSearch;

		tags.forEach((tag) => {

			let tagColor = '';
			switch (tag.color) {
			case 'red':
				tagColor = '#990000';
				break;
			case 'orange':
				tagColor = '#cc6600';
				break;
			case 'yellow':
				tagColor = '#ffcc00';
				break;
			case 'green':
				tagColor = '#009933';
				break;
			case 'blue':
				tagColor = '#0099ff';
				break;
			case 'purple':
				tagColor = '#990099';
				break;
			case 'grey':
				tagColor = '#cccccc';
				break;
			default:
				tagColor = '#cccccc';
			}

			let tagStyles = {
				backgroundColor: `${tagColor}`,
			};

			if (tag.color === 'yellow') {
				tagStyles = {
					backgroundColor: `${tagColor}`,
					color:           'var(--black)',
				};
			}

			elms.push(
				<div className={style.tag} key={`${tag.uuid}`} onClick={(e) => {
					e.preventDefault();
					if (this.tagInSearch(tag.uuid)) {
						return;
					}
					tagsInSearch.push(
						<div className={`${style.tag} ${style.searchTag}`} key={`${tag.uuid}`} onClick={(e2) => {
							e2.preventDefault();
							e2.stopPropagation();
							this.removeTagFromSearch(tag.uuid);
						}} style={tagStyles}>
							{tag.label}&nbsp;<CustomIcon icon="times-circle" />
						</div>
					);
					tagUUIDsInSearch.push(tag.uuid);
					this.setState({
						tagsInSearch,
						tagUUIDsInSearch,
					});
					this.makeListings();
				}} style={tagStyles}>
					{tag.label}
				</div>
			);
		});

		return elms;
	}

	getContentGroupTags() {
		let elms = [];
		let listingGroups = this.state.listingGroups;
		let tagsInSearch = this.state.tagsInSearch;
		let tagUUIDsInSearch = this.state.tagUUIDsInSearch;

		listingGroups.forEach((group) => {
			let tagStyles = {
				backgroundColor: 'var(--black)',
			};

			elms.push(
				<div className={style.tag} key={`${group.uuid}`} onClick={(e) => {
					e.preventDefault();
					if (this.tagInSearch(group.uuid)) {
						return;
					}
					tagsInSearch.push(
						<div className={`${style.tag} ${style.searchTag}`} key={`${group.uuid}`} onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							this.removeTagFromSearch(group.uuid);
						}} style={tagStyles}>
							{group.name}&nbsp;<CustomIcon icon="times-circle" />
						</div>
					);
					tagUUIDsInSearch.push(group.uuid);
					this.setState({
						tagsInSearch,
						tagUUIDsInSearch,
					});
					this.makeListings();
				}} style={tagStyles}>
					{group.name}
				</div>
			);
		});

		return elms;
	}

	removeTagFromSearch(uuid) {
		let tagsInSearch = this.state.tagsInSearch;
		let tagUUIDsInSearch = this.state.tagUUIDsInSearch;
		let indexToSplice = 0;

		tagsInSearch.forEach((tag, i) => {
			if (tag.key === uuid) {
				indexToSplice = i;
			}
		});

		tagsInSearch.splice(indexToSplice, 1);

		indexToSplice = tagUUIDsInSearch.indexOf(uuid);

		tagUUIDsInSearch.splice(indexToSplice, 1);

		this.setState({
			tagsInSearch,
			tagUUIDsInSearch,
		});
		this.makeListings();
	}

	openTagSearch() {
		this.setState({
			showTagSearchWindow: true,
		});
	}

	closeTagSearch() {
		this.setState({
			showTagSearchWindow: false,
		});
	}

	openTags() {
		this.setState({
			showTagsWindow: true,
		});
	}

	closeTags() {
		this.setState({
			showTagsWindow: false,
		});
	}

	prepTagsForDataUpdate() {
		let {
			accountUUID,
		} = this.props;
		let tags = this.state.tags;
		let key = `account.${accountUUID}._bundle`;
		let value = [];
		const {
			app,
		} = this.props;

		tags.forEach((tag) => {
			let obj = {};
			obj.uuid = tag.uuid;
			obj.m2_list = tag.m2_list;
			obj.attr = {
				label: tag.label,
				icon:  tag.icon,
				color: tag.color,
				type:  'tag',
				order: tag.order,
			};

			value.push(obj);
		});

		app.setValue('listings', key, value);
	}

	updateTags(i, checked, dashed) {
		let tags = this.state.tags;
		let selectedListingIDs = [];
		let m2_list = tags[i].m2_list;

		this.state.tableListings.forEach((listing) => {
			if (listing.isChecked) {
				selectedListingIDs.push(listing.uuid);
			}
		});

		if (!checked && !dashed) {
			selectedListingIDs.forEach((listingID) => {
				if (!m2_list.includes(listingID)) {
					m2_list.push(listingID);
				}
			});
		}
		if (!checked && dashed) {
			selectedListingIDs.forEach((listingID) => {
				if (!m2_list.includes(listingID)) {
					m2_list.push(listingID);
				}
			});
		}
		if (checked && !dashed) {
			selectedListingIDs.forEach((listingID) => {
				let indexToRemove = m2_list.indexOf(listingID);
				m2_list.splice(indexToRemove, 1);
			});
		}

		tags[i].m2_list = m2_list;

		this.setState({
			tags,
		});
		this.prepTagsForDataUpdate();
		this.makeListings();
	}

	editTagLabel(index, label) {
		let tags = this.state.tags;

		tags[index].label = label;

		this.setState({
			tags,
		});

		this.prepTagsForDataUpdate();
	}

	getTags() {
		let tags = this.state.tags;
		let elms = [];
		let selectedListingIDs = [];

		this.state.tableListings.forEach((listing) => {
			if (listing.isChecked) {
				selectedListingIDs.push(listing.uuid);
			}
		});

		tags.forEach((tag, i) => {
			let dashed = false;
			let checked = false;
			let m2IDCount = selectedListingIDs.length;
			let count = 0;

			tag.m2_list.forEach((m2ID) => {
				if (selectedListingIDs.includes(m2ID)) {
					count++;
				}
			});

			if (m2IDCount === 0) {
				checked = false;
			} else if (m2IDCount === count) {
				checked = true;
			} else if (count > 0) {
				dashed = true;
			}

			let tagColor = '';
			switch (tag.color) {
			case 'red':
				tagColor = '#990000';
				break;
			case 'orange':
				tagColor = '#cc6600';
				break;
			case 'yellow':
				tagColor = '#ffcc00';
				break;
			case 'green':
				tagColor = '#009933';
				break;
			case 'blue':
				tagColor = '#0099ff';
				break;
			case 'purple':
				tagColor = '#990099';
				break;
			case 'grey':
				tagColor = '#cccccc';
				break;
			default:
				tagColor = '#cccccc';
			}

			elms.push(
				<div className={style.assignRow} key={`${tag.label}_${i}`}>
					<div className={style.assignCheckbox} onClick={(e) => {
						e.preventDefault();
						this.updateTags(i, checked, dashed);
					}}>
						<CustomCheckbox checked={checked} dashed={dashed} />
					</div>
					<div className={style.assignIcon}>
						<CustomIcon icon={tag.icon} color={tagColor} />
					</div>
					<div className={style.assignName}>
						{tag.label} <div className={style.editLabel} onClick={(e) => {
							e.preventDefault();
							this.openModal({
								title: 'Edit Tag Label',
								jsx:   (
									<LabelEditModal
										closeModal={this.closeModal.bind(this)}
										editTagLabel={this.editTagLabel.bind(this)}
										tagIndex={i}
									/>
								),
							});
						}}><CustomIcon icon="edit" /></div>
					</div>
				</div>
			);
		});

		return elms;
	}

	openView() {
		this.setState({
			showViewWindow: true,
		});
	}

	closeView() {
		this.setState({
			showViewWindow: false,
		});
	}

	hideUnhideAllColumns(groupUUIDs, selectAllFlag) {
		let allColumnsArray = [ 'columnToHide', ];

		if (selectAllFlag) {
			// If selectAllFlag is true, hide nothing
			this.setState({
				columnsToHide: allColumnsArray,
			}, () => {
				this.setVariables();
			});
		} else {
			// If selectAllFlag is false, merge arrays and filter for unique values
			groupUUIDs.forEach((group) => {
				allColumnsArray.push('groups.' + group.uuid);
			});

			const updatedColumnsToHide = [ ...new Set([ ...this.state.columnsToHide, ...allColumnsArray, ]), ];

			this.setState({
				columnsToHide: updatedColumnsToHide,
			}, () => {
				this.setVariables();
			});
		}
	}

	hideUnhideColumn(id, checkedCount, parentWidth = 0) {
		const {
			listingConfigUUID, fd,
		} = this.props;

		let columnsToHide = this.state.columnsToHide;
		let additionalColumnWidths = 0;
		if (!columnsToHide.includes(id)) {
			if (checkedCount === 1) {
				return;
			}
			columnsToHide.push(id);
			if (!id.startsWith('parents.')) {
				additionalColumnWidths += getFDValue(fd, `listing_config.${listingConfigUUID}.listing_column.${id}.width`);
			} else {
				additionalColumnWidths += parentWidth;
			}
		} else if (columnsToHide.includes(id)) {
			let indexToRemove = columnsToHide.indexOf(id);
			columnsToHide.splice(indexToRemove, 1);
		}

		this.setState({
			columnsToHide,
		}, () => {
			this.setVariables(additionalColumnWidths);
		});
	}

	decimal_or_1(value) {
		let num = parseInt(value, 10);
		if (num === 100) {
			return 1;
		}
		return num / 100;
	}

	// setVariables takes an optional param called additionalColumnWidths and determines
	// the size (in vw) for each column to display
	setVariables(additionalColumnWidths = 0) {
		const {
			listingConfigMap,
			listingConfigUUID,
			fd,
		} = this.props;

		let activeListingConfig = listingConfigMap[listingConfigUUID];

		let listingWidthCols = {};
		const preComputedWidth = getComputedStyle(document.body).getPropertyValue('--sideNavWidth');
		let sideNavWidth = parseInt(preComputedWidth, 10);
		if (!sideNavWidth) {
			sideNavWidth = 21;
		}

		let listingsWidth = 100 - sideNavWidth;

		let listingConfigEntityKey = `listing_config.${listingConfigUUID}`;
		let listingConfigWidth = getFDValue(fd, `${listingConfigEntityKey}.data_width`);
		let listingConfigWidthPercentage = listingConfigWidth / 100;
		let listingConfigContentGroupCount = getFDValue(fd, `${listingConfigEntityKey}.content_group_count`);
		let listingColumnUUIDs = Object.keys(this.state.listingConfigTableSettings);

		let listingDataWidth = listingsWidth * listingConfigWidthPercentage;
		let numGroupColsShown = listingConfigContentGroupCount;
		let columnsToHide = this.state.columnsToHide;

		let uncheckedCount = 0;
		listingColumnUUIDs.forEach((listingColumnUUID) => {
			if (this.state.columnsToHide.includes(listingColumnUUID)) {
				return;
			}
			uncheckedCount++;
		});

		let checkboxCol = 5;
		let columnTotal = checkboxCol;
		if (this.props.listingConfigHasScheduling) {
			columnTotal += 5;
		}
		let currentTotalWidth = 0;
		let firstColumnUUID = '';

		// divide the additionalColumnWidths (total width of all hidden columns) by the
		// uncheckedCount to determine how much additional width to give to visible columns
		let additionalWidthForColumn = additionalColumnWidths / uncheckedCount;

		listingColumnUUIDs.forEach((listingColumnUUID, i) => {
			if (columnsToHide.includes(listingColumnUUID)) {
				return;
			}

			// track the first column for rounding reasons
			if (firstColumnUUID === '') {
				firstColumnUUID = listingColumnUUID;
			}

			let columnWidth = getFDValue(fd, `${listingConfigEntityKey}.listing_column.${listingColumnUUID}.width`);
			let columnPercent = this.decimal_or_1(columnWidth);
			let listingColumnWidth = Math.floor(columnPercent * listingDataWidth);

			if (columnWidth === 0 && !columnsToHide.includes(listingColumnUUID)) {
				columnsToHide.push(listingColumnUUID);
			}

			// if there is an additionalWidthForColumn, convert this to a percent and add
			// the value to the current columnPercent and multiply it by the listingDataWidth
			// to determine it's current width
			if (additionalWidthForColumn > 0) {
				let additionalPercent = this.decimal_or_1(additionalWidthForColumn);
				listingColumnWidth = Math.floor((columnPercent + additionalPercent) * listingDataWidth);
			}

			listingWidthCols[listingColumnUUID] = {
				width: listingColumnWidth,
				left:  columnTotal,
			};
			columnTotal += listingColumnWidth;
			currentTotalWidth += listingColumnWidth;
		});

		// check to see if a tier 1 listing does not have a currentTotalWidth that equals
		// the expected total width, or listingDataWidth. If it does not, then find the rounding
		// difference and add it to the first column. This keeps the columns the correct total.
		let roundingDifference = 0;
		if (listingWidthCols[firstColumnUUID] && activeListingConfig.root && listingDataWidth !== currentTotalWidth) {
			roundingDifference = listingDataWidth - currentTotalWidth;
			listingWidthCols[firstColumnUUID].width = listingWidthCols[firstColumnUUID].width + roundingDifference;
		}

		// for each column that is visible and not the first column the 'left' property needs to
		// be adjusted to account for the roundingDifference.
		listingColumnUUIDs.forEach((listingColumnUUID) => {
			if (columnsToHide.includes(listingColumnUUID)) {
				return;
			}
			if (listingColumnUUID !== firstColumnUUID) {
				listingWidthCols[listingColumnUUID].left = listingWidthCols[listingColumnUUID].left + roundingDifference;
			}
		});

		let listingsContentGroupsTotalWidth = (listingsWidth * (1 - listingConfigWidthPercentage)) - checkboxCol;
		let listingsContentGroupColWidth = listingsContentGroupsTotalWidth / numGroupColsShown;

		this.setState({
			listingWidthCols,
			listingsContentGroupColWidth,
		});
	}

	typeAheadListings(showAdvanced, e) {
		this.getColumns(showAdvanced, e);
	}

	getColumns(showAdvanced, filterTerms = '') {
		const {
			app,
			listingConfigMap,
			listingConfigUUID,
		} = this.props;

		const fd = {
			...this.state.listingFD,
			...this.props.fd,
		};

		let activeListingConfig = listingConfigMap[listingConfigUUID];

		let data = [];
		let contentGroups = [];
		let groupNames = [];
		let columnsToHide = this.state.columnsToHide;
		let listingColumnUUIDs = Object.keys(this.state.listingConfigTableSettings);
		let listingConfigEntityKey = `listing_config.${listingConfigUUID}`;
		let listingConfigWidth = getFDValue(fd, `${listingConfigEntityKey}.data_width`);
		let listingConfigContentGroupCount = getFDValue(fd, `${listingConfigEntityKey}.content_group_count`);
		let checkedDataColumnCount = 0;
		let totalColumnWidth = 0;

		let parentEntityKey = `listing_collection.${activeListingConfig.parentUUID}`;
		if (!activeListingConfig.root) {
			parentEntityKey = `listing.${activeListingConfig.parentUUID}`;
		}

		listingColumnUUIDs.forEach((listingColumnUUID, i) => {
			let columnWidth = 0;
			if (showAdvanced) {
				columnWidth = getFDValue(fd, `${listingConfigEntityKey}.listing_column.${listingColumnUUID}.width`);
				totalColumnWidth += columnWidth;
			}

			let checked = !columnsToHide.includes(listingColumnUUIDs[i]);
			if (checked) {
				checkedDataColumnCount++;
			}

			let individualWidthInput = (
				<div className={style.devRow}>
					<div className={`${style.columnLabel} ${style.inputLabel}`}>
						{this.state.listingConfigTableSettings[listingColumnUUID]}
					</div>
					<input className={`${style.input} ${style.inputSmall}`}
						id={`${listingColumnUUID}HeaderWidth`}
						defaultValue={columnWidth}
						onBlur={(e) => {
							let newValue = parseInt(e.target.value, 10);
							let orgValue = getFDValue(fd, `${listingConfigEntityKey}.listing_column.${listingColumnUUID}.width`);
							if (orgValue !== newValue) {
								let keys = [
									`${listingConfigEntityKey}.listing_column.${listingColumnUUID}.width`,
									`${listingConfigEntityKey}.listing_column.${listingColumnUUID}.order`,
								];

								let values = [
									newValue,
									getFDValue(fd, `${listingConfigEntityKey}.listing_column.${listingColumnUUID}.order`),
								];

								app.setValue('listings', keys, values);

								if (!activeListingConfig.root) {
									// update the parentWidthInput accordingly
									let parentWidthInput = document.getElementById(`${parentEntityKey}HeaderWidth`);
									let parentWidthValue = (100 - totalColumnWidth) + (orgValue - newValue);
									parentWidthInput.value = parentWidthValue;
								}
							}
						}}
					></input>
					<div>%</div>
				</div>
			);

			let elm = (
				<div className={style.tagElement} key={`${listingColumnUUID}_${i}-${i}`}>
					<div key={`${listingColumnUUID}_${i}`} onClick={(e) => {
						e.preventDefault();
						this.hideUnhideColumn(listingColumnUUID, checkedDataColumnCount);
					}}>
						<CustomCheckbox checked={checked} />
					</div>
					{!showAdvanced ? this.state.listingConfigTableSettings[listingColumnUUID] : individualWidthInput}
				</div>
			);
			data.push(elm);
		});

		if (!activeListingConfig.root) {
			let checked = !columnsToHide.includes(`parents.${parentEntityKey}`);
			if (checked) {
				checkedDataColumnCount++;
			}

			let individualWidthInput = (
				<div className={style.devRow}>
					<div className={`${style.columnLabel} ${style.inputLabel}`}>
						{getFDValue(fd, `${parentEntityKey}.display_name`)}
					</div>
					<input className={`${style.input} ${style.inputSmall}`}
						id={`${parentEntityKey}HeaderWidth`}
						defaultValue={100 - totalColumnWidth}
						disabled={true}
					>
					</input>
					<div>%</div>
				</div>
			);

			let elm = (
				<div className={style.tagElement} key={`${parentEntityKey}`}>
					<div key={`${parentEntityKey}_1`} onClick={(e) => {
						e.preventDefault();
						this.hideUnhideColumn(`parents.${parentEntityKey}`, checkedDataColumnCount, (100 - totalColumnWidth));
					}}>
						<CustomCheckbox checked={checked} />
					</div>
					{!showAdvanced &&
						getFDValue(fd, `${parentEntityKey}.display_name`)
					}
					{showAdvanced &&
						individualWidthInput
					}
				</div>
			);
			data.push(elm);
		}

		let saveButton = null;
		let widthInput = null;
		if (activeListingConfig.root) {
			let groupsInput = (
				<>
					<div className={style.sectionHeaderText}>Content Groups</div>
				</>
			);
			if (showAdvanced) {

				let directions = (
					<div className={totalColumnWidth === 100 ? style.colDirectionsCont : `${style.colDirectionsContError} ${style.colDirectionsCont}`}>
						Columns must = 100%
					</div>
				);
				data.push(directions);

				groupsInput = (
					<React.Fragment>
						<div className={style.sectionHeader}>
							<div className={style.sectionHeaderText}>Content Groups</div>
							<div className={style.contentGroupSettings}>
								<div className={style.contentGroupWidth}>
									<input className={`${style.input} ${style.inputSmall}`}
										id='groupColumnWidth'
										size='2'
										maxLength='2'
										defaultValue={listingConfigContentGroupCount}
										onBlur={(e) => {
											let newValue = parseInt(e.target.value, 10);
											if (getFDValue(fd, `${listingConfigEntityKey}.content_group_count`) !== newValue) {
												app.setValue('listings', `${listingConfigEntityKey}.content_group_count`, newValue);
											}
										}}
									></input>
								</div>
							</div>
						</div>
					</React.Fragment>
				);
				widthInput = (
					<div className={style.dataColumnWidth}>
						<input className={`${style.input} ${style.inputSmall}`}
							id='dataColumnWidth'
							size='2'
							maxLength='2'
							defaultValue={listingConfigWidth}
							onBlur={(e) => {
								let newValue = parseInt(e.target.value, 10);
								if (getFDValue(fd, `${listingConfigEntityKey}.data_width`) !== newValue) {
									app.setValue('listings', `${listingConfigEntityKey}.data_width`, newValue);
								}
							}}
						></input>
						<p>%</p>
					</div>
				);
			}

			contentGroups.push(groupsInput);
			this.state.listingGroups
				.sort((groupA, groupB) => {
					if ((groupA?.name || '').toLowerCase() > (groupB?.name || '').toLowerCase()) {
						return 1;
					}

					return -1;
				}).forEach((group, i) => {
					if (group.name && group.name.toLowerCase().includes(filterTerms.toLowerCase())) {
						let groupName = group.name;
						if (groupNames.includes(groupName)) {
							return;
						}
						groupNames.push(groupName);

						let idToUse = group.uuid;
						let groupID = `groups.${idToUse}`;
						let checked = !columnsToHide.includes(groupID);
						let elm = (
							<div className={style.tagElement} key={`${groupID}_${i}-${i}`}>
								<div key={`${groupID}_${i}`} onClick={(e) => {
									e.preventDefault();
									this.hideUnhideColumn(groupID);
								}}>
									<CustomCheckbox checked={checked} />
								</div>
								{groupName}
							</div>
						);
						contentGroups.push(elm);
					}
				});
		}

		if (showAdvanced) {
			saveButton = (
				<button
					className='button'
					onClick={(e) => {
						e.preventDefault();
						app.saveData('', this.setVariables.bind(this));
					}}
				>
					<CustomIcon icon='save' /> Save
				</button>
			);
		}

		return (
			<div className={style.viewWindowContent}>
				<div className={style.dataColumn}>
					<div className={style.sectionHeader}>
						<div className={style.sectionHeaderText}>Data</div>
						{showAdvanced ? widthInput : null}
					</div>
					{data}
				</div>
				<div className={style.contentGroupsColumn}>
					{contentGroups}
				</div>
				<div className={style.saveSettingsButton}>
					{saveButton}
				</div>
			</div>
		);
	}

	findRootConfig(configMap, startingConfig) {
		let currentConfig = startingConfig;
		if (configMap) {
			while (configMap[currentConfig] && !configMap[currentConfig].startsWith('account.')) {
				currentConfig = configMap[currentConfig];
			}
		}
		return currentConfig.split('.')[1];
	}

	findRootListings(targetListing) {
		const {
			listingToParentListingsMap,
		} = this.state;
		const visited = new Set();
		const roots = [];

		function dfs(currentListing) {
			// visited this node before, return
			if (visited.has(currentListing)) {
				return;
			}
			visited.add(currentListing);

			const parents = listingToParentListingsMap[currentListing] || [];

			// If the listing has no parents, it is a root
			if (parents.length === 0) {
				roots.push(currentListing);
				return;
			}

			// Otherwise, recurse on all parents
			for (const parent of parents) {
				dfs(parent);
			}
		}

		dfs(targetListing);

		return roots;
	}

	resetPendingChangesForUI() {
		const {
			pendingChanges,
		} = this.props;

		let entityKeys = Object.keys(pendingChanges);
		let tableListings = this.state.tableListings;

		entityKeys.forEach((entityKey) => {
			const [ m1, m1ID, m2, m2ID, ] = entityKey.split('.');

			tableListings.forEach((tableListing) => {
				if (tableListing.componentID === m2ID) {
					let index = tableListing.parentUUIDs.indexOf(m1ID);

					let addOrRemove = pendingChanges[entityKey];
					if (addOrRemove && index === -1) {
						tableListing.parentUUIDs.push(m1ID);
					}
					if (!addOrRemove && index > -1) {
						tableListing.parentUUIDs.splice(index, 1);
					}
				}
			});
		});

		this.setState({
			tableListings,
		});
	}

	dontSaveCallback() {

		this.setState({
			pendingGroupChanges: {},
			needsFetch:          true,
		});
		this.removeSaveCallbacksAndAdditionalChanges();
	}

	saveCallback() {

		this.prepPendingChanges();
	}

	removeSaveCallbacksAndAdditionalChanges() {
		let vultureWithoutSaveCallbacks = this.props.form.state.vulture;
		Reflect.deleteProperty(vultureWithoutSaveCallbacks, 'dontSaveCallback');
		Reflect.deleteProperty(vultureWithoutSaveCallbacks, 'saveCallback');
		Reflect.deleteProperty(vultureWithoutSaveCallbacks, 'goBackCallback');
		vultureWithoutSaveCallbacks.additionalChanges = {};

		this.props.form.setState({
			vulture: vultureWithoutSaveCallbacks,
		});
	}

	toggleContentGroups(toggle) {
		let listingGroupValues = Object.values(this.state.listingGroups);
		this.hideUnhideAllColumns(listingGroupValues, toggle);
	}

	toggleGroup(group, ...listingIndexes) {
		const {
			app,
			listingConfigUUID,
		} = this.props;

		let tableListings = this.state.tableListings;
		let obj = {};

		for (let listingIndex of listingIndexes) {
			let listing = tableListings[listingIndex];
			console.log('listing', listing, tableListings);
			if (!listing.componentGroups) {
				listing['componentGroups'] = [];
			}

			let index = listing.systems.indexOf(`system.${group.uuid}`);
			if (group.grouped) {
				index = listing.systems.indexOf(group.systemM1Keys[1]);
			}

			// remove system(s)
			if (~index) {
				if (group.grouped) {
					for (let systemM1Key of group.systemM1Keys) {
						const _index = listing.systems.indexOf(systemM1Key);
						listing.systems.splice(_index, 1);
					}
				} else {
					listing.systems.splice(index, 1);
				}

				for (let listingCollectionM1Key of group.listingCollectionM1Keys) {
					obj[`${listingCollectionM1Key}.listing.${listing.uuid}`] = false;
					obj[`${listingCollectionM1Key}.listing_config.${listingConfigUUID}`] = true;
				}
			}

			// add system(s)
			if (!~index) {
				if (group.grouped) {
					for (let systemM1Key of group.systemM1Keys) {
						listing.systems.push(systemM1Key);
					}
				} else {
					listing.systems.push(`system.${group.uuid}`);
				}

				for (let listingCollectionM1Key of group.listingCollectionM1Keys) {
					obj[`${listingCollectionM1Key}.listing.${listing.uuid}`] = true;
					obj[`${listingCollectionM1Key}.listing_config.${listingConfigUUID}`] = true;
				}
			}

			tableListings[listingIndex] = listing;
			obj[`listing.${listing.uuid}.listing_config.${listingConfigUUID}`] = true;
		}

		this.setState({
			tableListings,
		}, () => {
			app.setValue('listings', Object.keys(obj), Object.values(obj));
		});
	}

	prepPendingChanges() {
		let pendingGroupChanges = this.props.pendingChanges;
		let entityKeys = [];
		const {
			app,
		} = this.props;

		Object.keys(pendingGroupChanges).forEach((entityKey) => {
			app.setValue('listings', entityKey, pendingGroupChanges[entityKey]);
			entityKeys.push(entityKey);
		});
		this.submitAndUpdateChanges('', entityKeys);

		this.setState({
			pendingGroupChanges: {},
			needsUpdate:         true,
		});
		this.removeSaveCallbacksAndAdditionalChanges();
	}

	render() {
		const {
			app,
			accountUUID,
			listingConfigUUID,
			listingConfigMap,
			pendingChanges,
			activeSystem,
			settingMap,
			listingConfigHasScheduling,
			listingConfigIsIntegration,
		} = this.props;

		const {
			allRowsSelected,
			listingToListingCollectionsMap,
			listingToSystemsMap,
			listingCollectionToParentComponentMap,
			listingCollectionToSystemsMap,
			listingConfigsWithSystems,
			listingConfigToListingsColumnsMap,
		} = this.state;

		let activeListingConfig = listingConfigMap[listingConfigUUID];
		if (!activeListingConfig) {
			return '';
		}

		if (Object.keys(this.state.tieredListings).length === 0) {
			return '';
		}

		let confirmModal = '';
		if (this.state.confirmModal === true) {
			confirmModal = (
				<div className="confirm-modal-container">
					<div className="confirm-modal-content">
						{this.state.confirmModalContent}
					</div>
				</div>
			);
		}

		let originalColumns = this.getColumns(false);

		return (
			<>
				{confirmModal}

				{/* <div className={`settings-page fade-in`}> */}
				<>


					<ViewWindow
						app={this.props.app}
						show={this.state.showViewWindow}
						showViewAllButtons={this.state.parentsPrimaryColumnData}
						customWidth={'50vw'}
						close={this.closeView.bind(this)}
						getColumns={this.getColumns.bind(this)}
						originalColumns={originalColumns}
						toggleContentGroups={this.toggleContentGroups.bind(this)}
					/>

					{/* <TagSearchWindow
						app={this.props.app}
						show={this.state.showTagSearchWindow}
						close={this.closeTagSearch.bind(this)}
						getMyTags={this.getMyTags.bind(this)}
						getContentGroupTags={this.getContentGroupTags.bind(this)}
						activeListingConfig={activeListingConfig}
					/> */}

					<VultureListingsTable
						app={app}
						accountUUID={accountUUID}
						settingMap={settingMap}
						activeSystem={activeSystem}
						fd={{
							...this.props.fd,
							...this.state.listingFD,
						}}
						listingConfigUUID={listingConfigUUID}
						pendingChanges={pendingChanges}

						listingToSystemsMap={listingToSystemsMap || {}}
						listingConfigMap={listingConfigMap}
						listingConfigToListingsColumnsMap={listingConfigToListingsColumnsMap || {}}
						listingConfigHasScheduling={listingConfigHasScheduling}
						listingConfigIsIntegration={listingConfigIsIntegration}
						listingToListingCollectionsMap={listingToListingCollectionsMap || {}}
						listingCollectionToParentComponentMap={listingCollectionToParentComponentMap || {}}
						listingCollectionToSystemsMap={listingCollectionToSystemsMap || {}}
						listingConfigsWithSystems={listingConfigsWithSystems}
						allRowsSelected={allRowsSelected || false}

						array={this.state.tableListings}
						listingState={this}
						findRootListings={this.findRootListings.bind(this)}
						deleteSelectedListings={this.deleteSelectedListings.bind(this)}
						tableSettings={this.state.listingConfigTableSettings}
						uniqueSettingsListForAdd={this.state.uniqueSettingsListForAdd}
						listingComponentTypeID={this.state.listingComponentTypeID}
						activeListingConfig={activeListingConfig}
						getParentComponentName={this.getParentComponentName.bind(this)}
						getParentListingData={this.getParentListingData.bind(this)}
						parentsPrimaryColumnData={this.state.parentsPrimaryColumnData}
						listingCollectionHasListingGroupRelation={this.listingCollectionHasListingGroupRelation.bind(this)}
						listingGroups={this.state.listingGroups}
						getListingStaticIDsForListingGroupID={this.getListingStaticIDsForListingGroupID.bind(this)}
						setGroupValue={this.props.setGroupValue}
						submitAndUpdateChanges={this.submitAndUpdateChanges.bind(this)}
						getChildRelations={this.getChildRelations.bind(this)}
						submitAndUpdateChangesAndDelete={this.submitAndUpdateChangesAndDelete.bind(this)}
						exportCSV={this.exportCSV.bind(this)}
						templateCSV={this.templateCSV.bind(this)}
						openView={this.openView.bind(this)}
						columnsToHide={this.state.columnsToHide}
						getScrollPosition={this.getScrollPosition.bind(this)}
						toggleGroup={this.toggleGroup.bind(this)}
						listingsConfig={this.state.listingsConfig}
						tieredListings={this.state.tieredListings}
						listingWidthCols={this.state.listingWidthCols}
						listingsContentGroupColWidth={this.state.listingsContentGroupColWidth}
						selectAllRows={this.selectAllRows.bind(this)}
						toggleSelectAllRows={this.toggleSelectAllRows.bind(this)}
						fetchListingDataByListingConfig={this.fetchListingDataByListingConfig.bind(this)}
					/>
				</>
			</>
		);
	}
}

class TagSearchWindow extends React.Component {

	render() {
		let {
			activeListingConfig = {},
		} = this.props;
		let show = this.props.show;
		let contentGroupTags = '';

		if (activeListingConfig.tier === 1) {
			contentGroupTags = (
				<React.Fragment>
					<div className={style.tagsHeader}>
						<CustomIcon icon="object-group" /> Content Group Tags
					</div>
					<div className={style.contentGroupTags}>
						{this.props.getContentGroupTags()}
					</div>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				{show ?
					<React.Fragment>
						<div className={style.popoverBackground}
							onClick={(e) => {
								e.preventDefault();
								this.props.close();
							}} />
						<div className={style.tagsWindow}
							onMouseLeave={(e) => {
								e.preventDefault();
								this.props.close();
							}}>
							<div className={style.closeTagsWindow}>
								<button onClick={(e) => {
									e.preventDefault();
									this.props.close();
								}}>
									<CustomIcon icon="times" />
								</button>
							</div>
							<div className={style.tagsHeader}>
								<CustomIcon icon="user-tag" /> My Tags
							</div>
							<div className={style.myTags}>
								{this.props.getMyTags()}
							</div>
							{contentGroupTags}
						</div>
					</React.Fragment> :
					null
				}
			</React.Fragment>
		);
	}
}

class TagsWindow extends React.Component {

	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		let show = this.props.show;
		return (
			<React.Fragment>
				{show ?
					<React.Fragment>
						<div className={style.popoverBackground}
							onClick={(e) => {
								e.preventDefault();
								this.props.close();
							}} />
						<div className={`${style.tagsWindow} ${style.editLabelWindow}`}
							onMouseLeave={(e) => {
								e.preventDefault();
								this.props.close();
							}}>
							<div className={style.closeTagsWindow}>
								<button onClick={(e) => {
									e.preventDefault();
									this.props.close();
								}}>
									<CustomIcon icon="times" />
								</button>
							</div>
							<div className={style.tagsHeader}>
								<CustomIcon icon="tags" /> Assign Tag
							</div>
							<div className={style.assignTags}>
								{this.props.getTags()}
							</div>
							<div className={style.saveTags}>
								<button className='button' onClick={(e) => {
									e.preventDefault();
									this.props.submitAndUpdateChanges();
									this.props.updateBundleOnLoad();
									this.props.close();
								}}>
									Save Tags
								</button>
							</div>
						</div>
					</React.Fragment> :
					null
				}
			</React.Fragment>
		);

	}
}

class LabelEditModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			labelName: '',
		};
	}

	render() {

		return (
			<React.Fragment>
				<div className={`${style.editLabelContent} text-small`}>
					<div className="label">Label Name</div>
					<input
						onChange={(e) => {
							this.setState({
								labelName: e.target.value,
							});
						}}
						value={this.state.labelName}
						type="text"
						placeholder="Enter New Name"
					/>
				</div>
				<div className={style.editLabelButtons}>
					<button
						onClick={this.props.closeModal}
						className="button button-outlined"
					><CustomIcon icon="ban" />Cancel</button>
					<button
						onClick={(e) => {
							e.preventDefault();
							if (this.state.labelName === '') {
								// TODO: Error message
								return;
							}
							this.props.editTagLabel(this.props.tagIndex, this.state.labelName);
							this.props.closeModal();
						}}
						className="button"
					><CustomIcon icon="tag" />Rename Label</button>
				</div>
			</React.Fragment>
		);
	}
}

export default ListingsView;