export const dlAccountSelectPush = (payload) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event:       'Account Select',
	    accountName: payload.accountName,
	});
};

export const dlAddNewUser = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Add New User',
	});
};

export const dlCantLoginPush = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Can\'t Login',
	});
};

export const dlContentSelectPush = (payload) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Content Select',
	    label: payload.label,
	});
};

export const dlToggleCreateAnotherListing = (payload) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event:            'Create Another Listing',
	    newListingToggle: payload.option,
	});
};

export const dlImagePreviewPush = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Image Preview',
	});
};

export const dlSubcontentSelectPush = (payload) => {
	window.dataLayer = window.dataLayer || [];
	if (payload.isListings) {
	    window.dataLayer.push({
	        event: 'Listing Select',
	        tier:  payload.tier,
	        label: payload.label,
	    });
	} else if (payload.isSlideshow) {
	    window.dataLayer.push({
	        event: 'Slideshow Select',
	        tier:  payload.tier,
	        label: payload.label,
	    });
	} else {
	    window.dataLayer.push({
	        event: 'Subcontent Select',
	        tier:  payload.tier,
	        label: payload.label,
	    });
	}
};

export const dlListingsExportAll = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Listings Export All',
	});
};

export const dlListingsImport = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Listings Import',
	});
};

export const dlListingsTemplate = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Listings Template',
	});
};

export const dlLoginPush = (payload) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event:  'Login',
	    method: payload.method,
	});
};

export const dlLoginAttemptPush = (payload) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event:  'Login Attempt',
	    method: payload.method,
	});
};

export const dlLogoutPush = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Logout',
	});
};

export const dlNavigationPush = (payload) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event:       'Navigation',
	    destination: payload.destination,
	});
};

export const dlResetPassword = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Reset Password',
	});
};

export const dlSessionStartPush = (payload) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event:    'Session Start',
		userType: payload.userType,
		userUUID: payload.userUUID,
	    internal: payload.internal,
	});
};

export const dlSystemDetailsPush = (payload) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event:     'System Details',
	    expansion: payload.expansion,
	});
};

export const dlToolsMenu = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Tools Menu',
	});
};

export const dlUploadFilePush = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'Upload File',
	});
};

export const dlUserMenu = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'User Menu',
	});
};

export const dlViewListingsSettings = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'View Listings Settings',
	});
};

export const dlViewSlideshowSettings = () => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
	    event: 'View Slideshow Settings',
	});
};