import React from 'react';

import {
	getFDValue,
} from 'Vulture/Helpers';

class CrowFieldCustomVulturePhoneMessage extends React.Component {
	constructor(props) {
		super(props);
        const phoneNumber = getFDValue(this.props.fd, this.props.entity.name);

         this.state = {
            phoneNumber: phoneNumber || ''
        };
	}

    sanitizePhoneNumber = (input) => {
        // Remove all non-numeric characters
        const numericInput = input.replace(/\D/g, '');
        // Prepend "+1" if the number doesn't start with it and is of valid length
        if (numericInput.length === 10 && !numericInput.startsWith("1")) {
            return `+1${numericInput}`;
        }
        return numericInput;
    }

    handlePhoneNumberChange = (e) => {
        const sanitizedNumber = this.sanitizePhoneNumber(e.target.value);
        this.setState({ phoneNumber: sanitizedNumber });
    }

    handleBlur = () => {
        const {
			app,
		} = this.props;
        const { phoneNumber } = this.state;
        const newValue = phoneNumber
        // You might need to adjust the following line based on how your app.setValue function works
        console.log("this.props.entity.name",this.props.entity.name, newValue)
        app.setValue('todo', this.props.entity.name, newValue);
    }

    render() {
        const { phoneNumber } = this.state;
        const { attr } = this.props;

        return (
            <div className={`phone-message ${(this.props.classNameList || []).join(' ')}`}>
                <div className="label">
                    <label>{attr.label}</label>
                </div>
                <input
                    type="tel"
                    value={phoneNumber}
                    onChange={this.handlePhoneNumberChange}
                    placeholder="Phone number"
                    onBlur={this.handleBlur}
                    {...(attr.htmlattr || {})}
                />
            </div>
        );
    }
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.elements.phone.message'] = CrowFieldCustomVulturePhoneMessage;
export default CrowFieldCustomVulturePhoneMessage;