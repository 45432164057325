import React from 'react';
import {
	v4 as uuidv4,
} from 'uuid';
import CustomIcon from '../Common/CustomIcon/CustomIcon';
import TemplateSkeletonBuilder from './TemplateSkeletonBuilder';
import AWS from 'aws-sdk';
import {
	vultureENV,
} from 'Vulture/ENV.js';

import style from './ThemeEditor.module.css';
import {
	fetchSettingsForTemplateTheme,
	fetchTemplateData,
	handleDelete,
	handleSubmit,
	submitPendingUpdates,
} from 'Vulture/Theme';
import {
	buildTestData,
} from 'Vulture/TestData';

class CrowFieldCustomVultureThemeEditor extends React.Component {
	constructor(props) {
		super(props);

		this.fileUpload = React.createRef();

		AWS.config.update({
			region:      'us-east-1',
			credentials: new AWS.CognitoIdentityCredentials({
				IdentityPoolId: 'us-east-1:dd01a076-d5c9-474e-a031-cb3bcc6f105c',
			}),
		});


		this.state = {
			data:                  [],
			hasFetchedData:        false,
			pendingUpdates:        {},
			renderSkeletonBuilder: false,
			settingList:           [],
			template_category:     {},
			template_config:       {},
			template_theme:        {},
			photosArray:           [],
			bucketName:            'ts-theme-editor',
			s3:                    new AWS.S3({
				apiVersion: '2006-03-01',
				params:     {
					Bucket: 'ts-theme-editor',
				},
			}),
		};

	}

	componentDidMount() {
		const {
			mode,
		} = this.props;

		if (mode === 'theme_editor') {
			this.fetchTemplateData();
			this.fetchSettingsForTemplateTheme();

			this.setState({
				hasFetchedData: true,
			});
		}
	}

	componentDidUpdate() {
		const {
			mode,
		} = this.props;

		if (mode === 'theme_editor' && !this.state.hasFetchedData) {
			if (this.state.data.length === 0) {
				this.fetchTemplateData();
			}

			if (this.state.settingList.length === 0) {
				this.fetchSettingsForTemplateTheme();
			}

			this.setState({
				hasFetchedData: true,
			});
		}
	}

	async fetchTemplateData() {
		const data = await fetchTemplateData(this.props.form);

		if (data) {
			this.setState({
				data,
			});

			this.updateActiveEntities(data);
		}
	}

	async fetchSettingsForTemplateTheme() {
		const data = await fetchSettingsForTemplateTheme(this.props.form);

		if (data) {
			this.setState({
				settingList: data,
			});
		}
	}

	// Update entities in state for render methods
	updateActiveEntities(data) {
		let {
			template_category,
			template_config,
			template_theme,
		} = this.state;

		data.forEach((template_category_update) => {
			if (template_category_update.uuid === template_category.uuid) {
				this.setState({
					template_category: template_category_update,
				});

				template_category_update.template_config_list.forEach((template_config_update) => {
					if (template_config_update.template_config.uuid === template_config.uuid) {
						this.setState({
							template_config: template_config_update.template_config,
						});

						template_config_update.template_config.template_theme_list.forEach((template_theme_update) => {
							if (template_theme_update.template_theme.uuid === template_theme.uuid) {
								this.setState({
									template_theme: template_theme_update.template_theme,
								});
							}
						});
					}
				});
			}
		});
	}

	handleNameEdit(m1) {
		// If an m1 isn't selected, return.
		if (!this.state[m1].uuid) {
			return;
		}

		this.openModal('Edit', m1, this.state[m1]);
	}

	closeModal() {
		this.props.form.setState({
			vulture: {
				...this.props.form.state.vulture,
				modalSize:  0,
				showModal:  false,
				modalProps: {},
			},
		});

		this.fetchTemplateData();
	}

	openModal(mode, m1, entity = {}, parentM1, parentUUID) {
		const {
			app,
		} = this.props;

		let title = m1.split('_')[1];
		title = title[0].toUpperCase() + title.substring(1);

		app.openModal({
			modalSize:  1,
			showModal:  true,
			modalProps: {
				title: `${mode} ${title}`,
				jsx:   (
					<ThemeModal
						closeModal={this.closeModal.bind(this)}
						entity={entity}
						m1={m1}
						mode={mode}
						parentM1={parentM1}
						parentUUID={parentUUID}
						settingList={this.state.settingList}
					/>
				),
			},
		});
	}

	openDeleteModal(m1) {
		// If an m1 isn't selected, return.
		if (!this.state[m1].uuid) {
			return;
		}

		let title = m1.split('_')[1];
		title = title[0].toUpperCase() + title.substring(1);

		const {
			display_name,
		} = this.state[m1];

		this.props.app.openModal({
			modalSize:  1,
			showModal:  true,
			modalProps: {
				title: 'Confirm Delete',
				jsx:   (
					<ThemeDeleteModal
						closeModal={this.closeModal.bind(this)}
						entity={this.state[m1]}
						m1={m1}
						stateCallback={this.stateCallback.bind(this)}
						statusText={`Permanantly Delete the ${title}: ${display_name}?`}
					/>
				),
			},
		});
	}

	stateCallback(key, val) {
		if (key && typeof val !== 'undefined') {
			this.setState({
				[key]: val,
			});
		}
	}

	handleTemplateThemeUpdate(key, val) {
		let {
			pendingUpdates,
		} = this.state;

		pendingUpdates = {
			...pendingUpdates,
			[key]: val,
		};

		this.setState({
			pendingUpdates,
		});
	}

	async submitPendingUpdates() {
		const didSubmitWork = await submitPendingUpdates(this.state.pendingUpdates, this.props.form);

		if (didSubmitWork) {
			this.setState({
				pendingUpdates: {},
			});

			this.fetchTemplateData();
		}
	}

	renderConfigs() {
		let {
			template_config_list,
		} = this.state.template_category;

		if (template_config_list) {
			// Sort by display_name
			template_config_list.sort((a, b) => {
				if (a.template_config.display_name > b.template_config.display_name) {
					return 1;
				}

				return -1;
			});

			return (
				template_config_list.map((template_config_map_model) => {
					let {
						template_config,
					} = template_config_map_model;
					let active = '';
					if (template_config.uuid === this.state.template_config.uuid) {
						active = style.rowActive;
					}

					return (
						<div
							className={`${style.row} ${active}`}
							key={template_config.uuid}
							onClick={() => {
								this.setState({
									template_config,
									template_theme: {},
								});
							}}
						>
							{template_config.display_name}
						</div>
					);
				})
			);
		}
	}

	renderThemes() {
		let {
			template_theme_list,
		} = this.state.template_config;

		if (template_theme_list) {
			// Sort by display_name
			template_theme_list.sort((a, b) => {
				if (a.template_theme.display_name > b.template_theme.display_name) {
					return 1;
				}

				return -1;
			});
			return (
				template_theme_list.map((template_theme_map_model) => {
					let template_theme = template_theme_map_model.template_theme;
					let active = '';
					if (template_theme.uuid === this.state.template_theme.uuid) {
						active = style.rowActive;
					}

					// Sort settings to fit UI design
					let order = [ 'horizontal', 'vertical', 'horizontal_4k', 'vertical_4k', 'is_custom', 'notes', ];
					template_theme.setting_list = template_theme.setting_list.sort((a, b) => {
						if (order.indexOf(a.setting.name) > order.indexOf(b.setting.name)) {
							return 1;
						}
						return -1;
					});

					let settingElmList = [];

					template_theme.setting_list.forEach((settingMapModel, i) => {
						// Notes rendered separately.
						if (settingMapModel.setting.name === 'notes' || settingMapModel.bool === undefined) {
							return;
						}

						let val = settingMapModel.bool;

						let entityKey = `template_theme.${template_theme.uuid}.setting.${settingMapModel.setting.uuid}.bool`;

						// Check for value in pendingUpdates and update activeIcon accordingly.
						for (const [ pendingKey, pendingValue, ] of Object.entries(this.state.pendingUpdates)) {
							if (pendingKey === entityKey) {
								val = pendingValue;
								break;
							}
						}

						let activeIcon = '';
						if (val) {
							activeIcon = style.activeIcon;
						}
						if (settingMapModel.setting.name === 'is_custom') {
							settingElmList.push(
								<span
									key={settingMapModel.setting.name}
									className={`${style.icon} ${activeIcon} ${settingMapModel.setting.name}`}
								>
									<span
										onClick={(e) => {
											e.preventDefault();
											let newVal = !val;
											this.handleTemplateThemeUpdate(entityKey, newVal);
										}}>
										<CustomIcon icon={settingMapModel.setting.name} />
									</span>
								</span>
							);
						} else {
							settingElmList.push(
								<span
									key={settingMapModel.setting.name}
									className={`${style.icon} ${activeIcon} ${settingMapModel.setting.name}`}
									onClick={(e) => {
										e.preventDefault();
										let newVal = !val;
										this.handleTemplateThemeUpdate(entityKey, newVal);
									}}
								>
									<CustomIcon icon={settingMapModel.setting.name} />
								</span>
							);
						}
					});

					return (
						<div
							className={`${style.row} ${active}`}
							key={template_theme.uuid}
							onClick={() => {
								this.setState({
									template_theme,
								});
							}}
						>
							<div className={style.themeRow}>
								<div className={style.themeName}>{template_theme.display_name}</div>

								{settingElmList.map((elm) => {
									return elm;
								})}
							</div>
						</div>
					);
				})
			);
		}
	}

	renderNotes() {
		let notes = [];
		let {
			template_theme,
		} = this.state;
		let resolutionsArray = [
			{
				label:        'Horizontal (1920x1080)',
				notesValue:   'horizontal_notes',
				photosValue:  'horizontal_image',
				settingValue: 'horizontal',
			},
			{
				label:        'Vertical (1080x1920)',
				notesValue:   'portrait_notes',
				photosValue:  'portrait_image',
				settingValue: 'vertical',
			},
			{
				label:        '4K Horizontal (3840x2160)',
				notesValue:   '4k_horizontal_notes',
				photosValue:  '4k_horizontal_image',
				settingValue: 'horizontal_4k',
			},
			{
				label:        '4K Vertical (2160x3840)',
				notesValue:   '4k_portrait_notes',
				photosValue:  '4k_portrait_image',
				settingValue: 'vertical_4k',
			},
		];
		if (template_theme.setting_list) {
			template_theme.setting_list.forEach((settingMapModel) => {
				let settingOptionSelected = [];
				resolutionsArray.forEach((option, i) => {
					if (settingMapModel.setting.name === option.settingValue) {
						settingOptionSelected = resolutionsArray[i];
					}
				});

				let val = '';
				let photo = '';
				if (settingOptionSelected.length !== 0) {
					template_theme.setting_list.forEach((model) => {
						if (settingOptionSelected.notesValue === model.setting.name) {
							val = model.text;
						}
						if (settingOptionSelected.photosValue === model.setting.name) {
							photo = model.text;
						}
					});

					let notesEntityKey = `template_theme.${template_theme.uuid}.setting.${this.props.settingMap[settingOptionSelected.notesValue].uuid}.text`;
					let photoEntityKey = `template_theme.${template_theme.uuid}.setting.${this.props.settingMap[settingOptionSelected.photosValue].uuid}.text`;

					for (const [ pendingKey, pendingValue, ] of Object.entries(this.state.pendingUpdates)) {
						if (pendingKey === notesEntityKey) {
							val = pendingValue;
						}
						if (pendingKey === photoEntityKey) {
							photo = pendingValue;
						}
					}
					let photourl = `https://${this.state.bucketName}.s3.amazonaws.com/${vultureENV}${photo}`;
					notes.push(
						<div className={style.notesContainer}>
							<div className={style.notesSub}>
								<span className={style.notesHeader}>{settingOptionSelected.label}
								</span>
							</div>
							<div className={style.notesSub}>
								<textarea
									rows={2}
									onChange={(e) => {
										this.handleTemplateThemeUpdate(notesEntityKey, e.target.value);
									}}
									value={val}
								/>
								<div className={style.notesUploader}>
									{photo ? <img src={photourl}></img> : <div className={style.iconContainer}><CustomIcon icon='image' /></div>}
									<div className={`${style.uploadButtonContainer} uploader-buttons`}>
										<button
											className={`${style.uploadButton} button`}
											onClick={(e) => {
												this.displayUpload(settingOptionSelected.photosValue);
											}}
											data-testing-info={buildTestData('button--uploadFile')}
										>
											<CustomIcon icon='cloud-upload-alt' />
										</button>
										<input
											id={settingOptionSelected.photosValue}
											onChange={(e) => {
												this.handleImageUpload(e, photoEntityKey, template_theme.uuid);
											}}
											accept='.jpg, .jpeg, .pdf, .png'
											ref={this.fileUpload}
											type='file'
										/>
									</div>
								</div>

							</div>
						</div>
					);
				}
			});
		}

		return notes;
	}

	displayUpload(id) {
		let formSubmit = document.getElementById(id);
		formSubmit.click();
	}

	handleImageUpload(e, key, templateUUID) {
		e.preventDefault();
		if (e.target && e.target.files && e.target.files.length > 0) {
			let file = e.target.files[0];
			let fileSize = file.size;
			let fileType = file.type;
			let fileArray = fileType.split('/');
			let type = fileArray[0];

			let previewSrc = null;
			if (type === 'image') {
				this.uploadContent(file, templateUUID, key);
				return;
			}

			// TODO: Handle PDFs?
		}
	}

	uploadContent(file, templateUUID, key) {
		const {
			app,
		} = this.props;
		let {
			pendingUpdates,
		} = this.state;

		/* app.setState({ loading: true }); */
		app.setState({
			loading: true,
		});
		let objKey = `${vultureENV}/${templateUUID}/${file.name}`;

		this.state.s3.upload(
			{
				Body:   file,
				Bucket: this.state.bucketName,
				Key:    objKey,
				ACL:    'public-read',
			},
			(err, data) => {
				if (err) {
					console.error(err, data);
					alert(`There was an error uploading your file: `, err.message);
					return;
				}

				pendingUpdates = {
					...pendingUpdates,
					[key]: `/${templateUUID}/${file.name}`,
				};

				this.setState({
					pendingUpdates,
				});
				app.setState({
					loading: false,
				});
				return;
			}
		);
	}

	renderThemeEditor() {
		let {
			mode,
		} = this.props;

		let saveButtonClassName = 'button button-save button-disabled';
		if (Object.keys(this.state.pendingUpdates).length > 0) {
			saveButtonClassName = 'button button-save';
		}

		if (mode === 'theme_editor') {
			if (this.state.renderSkeletonBuilder) {
				const {
					template_category,
					template_config,
				} = this.state;
				return (
					<TemplateSkeletonBuilder
						app={this.props.app}
						fd={this.props.fd}
						fetchTemplateData={this.fetchTemplateData.bind(this)}
						form={this.props.form}
						templateCategory={template_category}
						templateConfig={template_config}
						stateCallback={this.stateCallback.bind(this)}
						settingMap={this.props.settingMap}
					/>
				);
			}

			return (
				<div className='systems-page'>
					<div className={style.title}>
						<h2>THEME EDITOR</h2>
						<button
							className={saveButtonClassName}
							onClick={this.submitPendingUpdates.bind(this)}
						>
							<CustomIcon icon='save' />Save
						</button>
					</div>

					<div className={style.container}>
						<div className={`${style.column} ${style.shortColumn}`}>
							<div className={style.header}>
								<div className={style.headerLabel}>Category</div>
								<div className={style.headerBtnContainer}>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											this.openModal('New', 'template_category');
										}}
									>
										<CustomIcon icon='plus' />
									</button>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											this.handleNameEdit('template_category');
										}}
									>
										<CustomIcon icon='edit' />
									</button>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											this.openDeleteModal('template_category');
										}}
									>
										<CustomIcon icon='trash' />
									</button>
								</div>
							</div>
							<div className={style.scrollContainer}>
								{this.state.data.map((template_category) => {
									let active = '';
									if (template_category.uuid === this.state.template_category.uuid) {
										active = style.rowActive;
									}

									return (
										<div
											className={`${style.row} ${active}`}
											key={template_category.uuid}
											onClick={() => {
												this.setState({
													template_category,
													template_config: {},
													template_theme:  {},
												});
											}}
										>
											{template_category.display_name}
										</div>
									);
								})}
							</div>
						</div>

						<div className={`${style.column} ${style.shortColumn}`}>
							<div className={style.header}>
								<div className={style.headerLabel}>Configuration</div>
								<div className={style.headerBtnContainer}>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											// Only add a config if a category is selected
											if (this.state.template_category.uuid) {
												// Reset template_config so the builder knows to make a new template_config.
												this.setState({
													renderSkeletonBuilder: true,
													template_config:       {},
												});
											}
										}}
									>
										<CustomIcon icon='plus' />
									</button>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											// Only edit a config if a config is selected
											if (this.state.template_config.uuid) {
												this.setState({
													renderSkeletonBuilder: true,
												});
											}
										}}
									>
										<CustomIcon icon='edit' />
									</button>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											this.openDeleteModal('template_config');
										}}
									>
										<CustomIcon icon='trash' />
									</button>
								</div>
							</div>
							<div className={style.scrollContainer}>
								{this.renderConfigs()}
							</div>
						</div>

						<div className={`${style.column}`}>
							<div className={style.header}>
								<div className={style.headerLabel}>Themes</div>
								<div className={style.headerLabel}>Orientations</div>
								<div className={style.headerLabel}>Custom</div>
							</div>
							<div className={style.scrollContainer}>
								{this.renderThemes()}
							</div>
						</div>

						<div className={`${style.column}`}>
							<div className={style.header}>
								<div className={style.headerLabel}>Notes</div>
								<div className={style.headerBtnContainer}>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											// Only add a theme if a config is selected
											if (this.state.template_config.uuid) {
												this.openModal('New', 'template_theme', {}, 'template_config', this.state.template_config.uuid);
											}
										}}
									>
										<CustomIcon icon='plus' />
									</button>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											this.handleNameEdit('template_theme');
										}}
									>
										<CustomIcon icon='edit' />
									</button>
									<button
										className='button'
										onClick={(e) => {
											e.preventDefault();
											this.openDeleteModal('template_theme');
										}}
									>
										<CustomIcon icon='trash' />
									</button>
								</div>
							</div>
							{this.renderNotes()}
						</div>
					</div>
				</div>
			);
		}

		return '';
	}

	render() {

		return (
			<React.Fragment>
				{this.renderThemeEditor()}
			</React.Fragment>
		);
	}
}

class ThemeModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: this.props.entity.display_name || '',
		};
	}

	setThemeData(data, m1, m1UUID) {
		this.props.settingList.forEach((setting) => {
			let attr = 'bool';
			let val = false;
			if (setting.name === 'notes') {
				attr = 'text';
				val = '';
			}

			data = {
				...data,
				[`${m1}.${m1UUID}.setting.${setting.uuid}.${attr}`]: val,
			};
		});

		return data;
	}

	async handleSubmit() {
		const {
			app,
			entity,
			m1,
			parentM1,
			parentUUID,
		} = this.props;

		// Create UUID for new entity
		let m1UUID = uuidv4();

		// If there's an entity, we're editing an existing entity
		if (Object.keys(entity).length > 0) {
			m1UUID = entity.uuid;
		}

		let data = {
			[`${m1}.${m1UUID}.display_name`]: this.state.name,
		};

		// If there is a parentM1 and parentUUID, save the new parent to m1 relation.
		if (parentM1 && parentUUID) {
			data = {
				...data,
				[`${parentM1}.${parentUUID}.${m1}.${m1UUID}`]: true,
			};

			// If m1 is template_theme
			if (m1 === 'template_theme') {
				data = this.setThemeData(data, m1, m1UUID);
			}
		}

		handleSubmit(data, this.props.form)
			.then(() => {
				app.closeModal();
			}).catch(() => {
				app.closeModal();
			});
	}

	render() {
		let btnText = 'Add';
		let icon = 'plus';
		if (this.props.mode === 'Edit') {
			btnText = 'Save';
			icon = 'edit';
		}

		return (
			<React.Fragment>
				<div className={`${style.modalContent} text-small`}>
					<div className='label'>Name</div>
					<input
						onChange={(e) => {
							this.setState({
								name: e.target.value,
							});
						}}
						value={this.state.name}
						type='text'
						placeholder='Enter New Name'
					/>
				</div>

				<div className={style.modalButtonContainer}>
					<button
						onClick={this.handleSubmit.bind(this)}
						className='button'
					><CustomIcon icon={icon} />{btnText}</button>
				</div>
			</React.Fragment>
		);
	}
}

class ThemeDeleteModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error:      false,
			serverRes:  '',
			statusText: this.props.statusText,
		};
	}

	async handleDelete() {
		const {
			app,
		} = this.props;

		const body = {
			m1:      this.props.m1,
			m1_uuid: this.props.entity.uuid,
		};

		const contents = await handleDelete(body, this.props.form);

		if (contents) {
			if (contents.error && contents.payload) {
				this.setState({
					error:      true,
					serverRes:  JSON.stringify(contents?.payload, null, 2),
					statusText: `${this.props.m1} is currently being used by:`,
				});
			}

			this.props.stateCallback(this.props.m1, {});
			app.closeModal();
		}
	}

	render() {
		let deleteButton = null;
		if (!this.state.error) {
			deleteButton = (
				<div className={style.modalButtonContainer}>
					<button
						onClick={(e) => {
							e.preventDefault();
							this.handleDelete();
						}}
						className='button'
					><CustomIcon icon={'trash'} />Delete</button>
				</div>
			);
		}
		return (
			<React.Fragment>
				<div className={`${style.modalContent} text-small`}>
					{this.state.statusText}
					<pre>{this.state.serverRes}</pre>
				</div>
				{deleteButton}
			</React.Fragment>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.header'] = CrowFieldCustomVultureThemeEditor;

export default CrowFieldCustomVultureThemeEditor;