import React from 'react';
import style from './ViewWindow.module.css';
import CustomIcon from '../CustomIcon/CustomIcon';
import {
	getSession,
} from 'Vulture/Session';

class ViewWindow extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			showAdvancedView: false,
			testCols:         [],
			searchTerm:       '',
		};
	}

	render() {
		let show = this.props.show;
		let editButton = null;
		const pg = getSession().pg;
		if (pg === 'iu' || pg === 'ia') {
			editButton = (
				<div className={style.editButtonCont}
					title="Edit"
					onClick={(e) => {
						e.preventDefault();
						this.setState({
							showAdvancedView: !this.state.showAdvancedView,
						});
					}}
				>
					<CustomIcon icon='edit' color='#00baff' />
				</div>
			);
		}

		return (
			<>
				{show ?
					<>
						<div className={style.popoverBackground}
							onClick={(e) => {
								e.preventDefault();
								this.props.close();
							}} />
						<div
							className={style.viewWindow}
							style={{
								width: this.props.customWidth ? this.props.customWidth : '',
							}}
							onMouseLeave={(e) => {
								e.preventDefault();
								this.props.close();
							}}
						>
							<div className={style.closeViewWindow}>
								<button onClick={(e) => {
									e.preventDefault();
									this.props.close();
								}}>
									<CustomIcon icon="times-circle" />
								</button>
							</div>
							<div className={style.viewHeaderContainer}>
								<div className={style.viewHeader}>
									<CustomIcon icon="eye" /> Filter Column View {editButton}
								</div>
								{this.props.isSlideshow || this.props.showViewAllButtons.parentsFirstColumnAttr === undefined ?
									<React.Fragment>
										<button
											className={`button ${style.toggleButton}`}
											onClick={(e) => {
												e.preventDefault();
												this.props.toggleContentGroups(true);
											}}
										>
											View All
										</button>
										<button
											className={`button ${style.toggleButton}`}
											onClick={(e) => {
												e.preventDefault();
												this.props.toggleContentGroups(false);
											}}
										>
											View None
										</button>
									</React.Fragment> :
									null
								}

							</div>
							<div>
								<input className={style.thFilter}
									id='datafilter'
									placeholder='Filter...'
									value={this.state.searchTerm}
									onChange={(e) => {
										let testCols = this.props.getColumns(this.state.showAdvancedView, e.target.value);
										this.setState({
											testCols,
											searchTerm: e.target.value,
										});
									}}/>
							</div>
							{this.state.searchTerm === '' ? this.props.getColumns(this.state.showAdvancedView) : this.props.getColumns(this.state.showAdvancedView, this.state.searchTerm)}
							{/* {this.state.testCols !== [] ? this.state.testCols : this.props.getColumns(this.state.showAdvancedView)} */}
							{/* {this.props.getColumns(this.state.showAdvancedView)} */}
						</div>
					</> :
					null
				}
			</>
		);
	}
}

export default ViewWindow;