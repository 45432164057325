// This file is autogenerated, any changes you make
// will be lost the next time it is autogenerated.

import React, { useState } from 'react';

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useParams as useRouteParams
} from 'react-router-dom';

import VultureFormLogin from './Vulture/Form/Login/App.js';

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={[
'/password/reset/:user/:auth',
'/password/forgot',
'/password/reset',
'/register/:user/:auth',
'/login',
'/:mode/:account/:section',
'/:mode/:account',
'/:mode',
'/',
          ]}
          component={RedirectWrapper}
        />
      </Switch>
    </Router>
  );
}

function RedirectWrapper(props) {
  const [redirect, setRedirect] = useState({});
  const routeParams = useRouteParams();

  let shouldRedirect = false;
  if (redirect.to) {
    if (redirect.to !== window.location.pathname) {
      shouldRedirect = true;
    }
  }

  if (shouldRedirect && redirect.reload) {
    let l = window.location;
    return window.location = `${l.protocol}//${l.host}${redirect.to}`;
  }

let routeVultureFormLoginMatches = [
'/password/reset/:user/:auth',
'/password/forgot',
'/password/reset',
'/register/:user/:auth',
'/login',
'/:mode/:account/:section',
'/:mode/:account',
'/:mode',
'/',
].indexOf(props.match.path);

  return (
    <>
      {shouldRedirect &&
        <Redirect
          to={redirect.to}
          // NOTE: add to history?
          // push
        />
      }

{Boolean(~routeVultureFormLoginMatches) &&
<VultureFormLogin
router={{
redirect: setRedirect,
params: routeParams,
}}
/>
}
    </>
  )
}

export default App;
