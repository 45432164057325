import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

class CrowFieldCustomCommonDate extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.value,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	formatDate (date) {
		const { entity, value, app } = this.props;

		if (date === null) {
			app.setValue('todo', entity.name, null);
			this.setState({
				value: null,
			});
		} else {
			let iso = date.toISOString();
			app.setValue('todo', entity.name, iso);
			this.setState({
				value: date,
			});
		}
	};

	clearDate () {
		const { entity, value, app } = this.props;

		app.setValue('todo', entity.name, null);
		this.setState({
			value: null,
		});
	};

	render() {

		const { entity, } = this.props;
		let { attr = {}, } = entity || {};
		let { htmlattr, } = attr || {};

		let value = this.state.value;
		if (!value) {
			value = '';
		} else {
			// Create date object from string
			value = new Date(value);

			// Set value to current date if invalid date object
			let isValid = Date.parse(value);
			if (isNaN(isValid)) {
				value = new Date();
			}
		}

		return (
			<div className={`text-small ${(this.props.classNameList || []).join(' ')}`}>
				<div className="label">
					<label>{entity.attr.label}</label>
					{entity.attr.allowClear ? <label onClick={this.clearDate.bind(this)}> | Clear Date</label> : null}
				</div>
				<DatePicker
					id={attr['data-test-id'] || attr.name}
					selected={value}
					onChange={this.formatDate.bind(this)}
					dropdownMode="select"
					showMonthDropdown
					showYearDropdown
					{...htmlattr}
				/>
			</div>
		);
	}
}

const _exports = {
	name:  'custom.common.date',
	desc:  'Standard Date Field',
	scope: {},
	field: CrowFieldCustomCommonDate,
};

export default _exports;