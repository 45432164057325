import React from 'react';
import Select from 'react-select';

class CrowFieldCustomVultureElementsEmail extends React.Component {
	constructor(props) {
		super(props);
		let emailJSON = {};
		if (!props.value) {
			emailJSON = {
				email:  '',
				domain: '',
				label:  '',
			};
		} else {
			emailJSON = JSON.parse(props.value);
		}

		this.state = {
			value:        (props.value ? props.value : emailJSON),
			emailValue:   (emailJSON.email !== '' && emailJSON.domain !== '' ? emailJSON.email + '@' + emailJSON.domain : ''),
			emailJSON,
			isValidEmail: true,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
	}

	handleChange(e) {
		const {
			value,
		} = e.target;
		const emailParts = value.split('@');
		let newJSON = {
			...this.state.emailJSON,
		};
		newJSON.label = this.props.attr.label;

		if (value === '') { // Check if the input is cleared and set to an empty string
			newJSON.email = null;
			newJSON.domain = null;
		} else if (emailParts.length === 2) {
			newJSON.email = emailParts[0];
			newJSON.domain = emailParts[1];
		} else {
			// Handle incomplete email case, e.g., when user is still typing
			newJSON.email = value;
			newJSON.domain = '';
		}

		this.setState({
			emailJSON:  newJSON,
			emailValue: value,
		});
	}

	handleBlur() {
		const {
			app,
			entity,
		} = this.props;

		const isValid = this.validateEmail(this.state.emailValue);
		this.setState({ isValidEmail: isValid });

		// Check if the email input is empty
		if (!this.state.emailValue) {
			console.log('Email is empty, setting value to null.');
			app.setValue('todo', entity.name, null); // Directly set the value to null
		} else if (isValid) {
			// If the email is valid, proceed to set the value
			console.log("Valid email, setting value.");
			app.setValue('todo', entity.name, JSON.stringify(this.state.emailJSON));
		} else {
			// If the email is invalid and not empty, do nothing
			console.error('Invalid email:', this.state.emailValue);
		}
	}

	validateEmail(email) {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern
		return regex.test(email);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	render() {
		const {
			attr,
		} = this.props;
		const {
			isValidEmail,
		} = this.state;

		return (
			<div className={`text-small ${(this.props.classNameList || []).join(' ')}`}>
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<div className={`phoneContainer`}>
					<input
						type='email'
						className='number'
						value={this.state.emailValue}
						placeholder="example@domain.com"
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						{...attr.htmlattr || {}}
					/>
					{!isValidEmail && <div className="error">Invalid email address</div>}
				</div>
			</div>
		);
	}
}

if (!window.CrowFormField) {
	window.CrowFormField = {};
}
window.CrowFormField['custom.vulture.elements.email'] = CrowFieldCustomVultureElementsEmail;
export default CrowFieldCustomVultureElementsEmail;
