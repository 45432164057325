import React from 'react';

const LoadingView = function (props) {
	return (
		<React.Fragment>
			<div className="loader-opacity" />
			<div className="loader_overlay">
				<div className="loader_wrap">
					<div className="loader loader-bars">
						<span></span>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
};

export default LoadingView;