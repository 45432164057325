import React, { Component } from 'react';
import Showdown from 'showdown';
import { Editor } from '@tinymce/tinymce-react';
const converter = new Showdown.Converter();
const apiKey = window._getEnv('TINYMCE_API_KEY');
class CrowFieldCustomVultureTextLarge extends Component {
	constructor(props) {
		super(props);
		this.state = {
			markdown: '',
		};
		this.handleMarkdownChange = this.handleMarkdownChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}

	componentDidMount() {
		console.log(apiKey);
		const { value } = this.props;
		if (value) {
			const markdown = converter.makeHtml(value);
			this.setState({ markdown });
		}
	}

	handleMarkdownChange = (newValue, delta, source) => {
		this.setState({ markdown: newValue });
		if (delta.ops[delta.ops.length - 1].delete) {
			return;
		} else {
			console.log("adding new value");
			console.log(newValue);
			this.setState({ markdown: newValue });
		}
	}

	handleSave() {
		const {
			entity,
			app,
		} = this.props;

		const markdownContent = converter.makeMarkdown(this.state.markdown);
		app.setValue('todo', entity.name, markdownContent);

	}

	handleEditorChange = (content, editor) => {
		const {
			entity,
			app,
		} = this.props;

		this.setState({ markdown: content });
	}


	render() {
		const {
			entity,
			app,
		} = this.props;

		let {
			attr = {},
		} = entity || {};

		return (
			<div>
				<div className="label">
					<label>{attr.label}</label>
				</div>
				<Editor
					value={this.state.markdown}
					apiKey={apiKey}
					init={{
						height: 200,
						menubar: false,
						force_br_newlines: true,
						force_p_newlines: true,
						plugins: [
							'lists',
							'visualblocks',
							'table paste'
						],
						toolbar:
							'undo redo | formatselect | bold italic | \
            				bullist numlist',
						branding: false,
					}}
					onEditorChange={this.handleEditorChange}
					onBlur={this.handleSave}
				/>
			</div>
		);
	}
}
if (!window.CrowFormField) {
	window.CrowFormField = {};
}

window.CrowFormField['custom.vulture.elements.text.large'] = CrowFieldCustomVultureTextLarge;
export default CrowFieldCustomVultureTextLarge;
