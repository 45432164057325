import React, {
	useState,
} from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon.js';
import Select from 'react-select';
import style from './ListingConfig.module.css';
import TextSmall from 'Vulture/Field/TextSmall.js';
import {
	getFDValue,
} from 'Vulture/Helpers';
import {
	buildTestData,
} from 'Vulture/TestData.js';
import JSONField from './JSONField.js';

const typeOptions = [
	// {
	// 	label: 'Int',
	// 	value: 'int',
	// },
	{
		label: 'String',
		value: 'string',
	},
	// {
	// 	label: 'Number',
	// 	value: 'float',
	// },
	// {
	// 	label: 'Bool',
	// 	value: 'bool',
	// },
	// {
	// 	label: 'Text',
	// 	value: 'text',
	// },
	// {
	// 	label: 'Date',
	// 	value: 'date',
	// },
	// {
	// 	label: 'Time',
	// 	value: 'time',
	// },
	// {
	// 	label: 'Datetime',
	// 	value: 'datetime',
	// },
	// {
	// 	label: 'Timestamp',
	// 	value: 'timestamp',
	// },
	// {
	// 	label: 'JSON',
	// 	value: 'json',
	// },
];

const styles = {
	control: (base) => {
		return {
			...base,
			maxHeight: 30,
		};
	},
	dropdownIndicator: (base) => {
		return {
			...base,
			paddingTop:    0,
			paddingBottom: 0,
		};
	},
	clearIndicator: (base) => {
		return {
			...base,
			paddingTop:    0,
			paddingBottom: 0,
		};
	},
	menu: (base) => {
		return {
			...base,
			top:      20,
			position: 'absolute',
		};
	},
};

class MessagingOptions extends React.Component {
	constructor(props) {
		super(props);

		const {
			fd,
			app,
			accountUUID,
			settingMap,
		} = props;

		const messagingOptions = getFDValue(fd, `account.${accountUUID}.setting.${settingMap['messaging_options'].uuid}`);

		this.state = {};

		if (messagingOptions) {
			this.state.settingsWithOptions = JSON.parse(messagingOptions.json);
		} else {
			this.state.settingsWithOptions = {
				'messaging': {
					'type':    'string',
					'options': [
						{
							'label': 'Text Message',
							'value': 'There is a visitor at the door.',
						},
						{
							'label': 'Email Message',
							'value': 'There is a visitor at the door.',
						},
					],
				},
			};

			app.setValue('account_settings', `account.${accountUUID}.setting.${settingMap['messaging_options'].uuid}`, true);
			app.setValue('account_settings', `account.${accountUUID}.setting.${settingMap['messaging_options'].uuid}.json`, JSON.stringify(this.state.settingsWithOptions));
		}
	}

    updateSettingValue = (label, json) => {
    	const {
    		settingsWithOptions,
    	} = this.state;
    	const parsedJson = JSON.parse(json);
    	settingsWithOptions[label].options = parsedJson;
    	this.setState({
    		settingsWithOptions,
    	});

    	const {
    		fd, accountUUID, settingMap,
    	} = this.props;
    	const oldJson = getFDValue(fd, `account.${accountUUID}.setting.${settingMap['messaging_options'].uuid}.json`);

    	if (oldJson !== JSON.stringify(settingsWithOptions)) {
    		this.props.app.setValue('account_settings', `account.${accountUUID}.setting.${settingMap['messaging_options'].uuid}.json`, JSON.stringify(settingsWithOptions));
    	}
    };

    render() {
    	const {
    		fd,
    		app,
    		accountUUID,
    		settingMap,
    	} = this.props;

    	const {
    		settingsWithOptions,
    	} = this.state;

    	const labelList = Object.keys(settingsWithOptions);

    	return (
    		<>
    			<h2 className={style.headerText}>Messaging Options</h2>

    			<div style={{
    				display:       'flex',
    				flexDirection: 'row',
    			}}>
    				{Object.entries(settingsWithOptions).map(([
    					label, {
    						type, options,
    					},
    				], optionNumber) => {
    					return (
    						<div className={style.listingContainer} key={label}>
    							<MessagingOptionsEntity
    								initialLabel={label}
    								labelList={labelList}
    								type={type}
    								options={JSON.stringify(options)}
    								app={app}
    								accountUUID={accountUUID}
    								fd={fd}
    								updateSettingValue={this.updateSettingValue}
    								messagingOptions={this}
    								settingsWithOptions={settingsWithOptions}
    								optionNumber={optionNumber}
    								settingMap={settingMap}
    							/>
    						</div>
    					);
    				})}
    			</div>
    		</>
    	);
    }
}

const MessagingOptionsEntity = (props) => {
	const {
		app,
		fd,
		initialLabel,
		updateSettingValue,
		options,
		messagingOptions,
		settingsWithOptions,
		optionNumber,
		accountUUID,
		settingMap,
	} = props;

	const [ label, setLabel, ] = useState(initialLabel);

	const listingColumns = [];
	let isDisabled = false;

	let smsColumnTypeUUID;
	let emailColumnTypeUUID;


	Object.entries(fd).forEach(([ m1Key, m1Data, ]) => {
		if ((!smsColumnTypeUUID || !emailColumnTypeUUID) && m1Key.startsWith('listing_column_type.')) {
			if (m1Data.display_name === 'SMS Message') {
				smsColumnTypeUUID = m1Key;
			}

			if (m1Data.display_name === 'Email Message') {
				emailColumnTypeUUID = m1Key;
			}

		}
	});

	Object.entries(fd).forEach(([ m1Key, m1Data, ]) => {
		if (m1Key.startsWith('listing_config.')) {
			Object.keys(m1Data).forEach((m2Key) => {
				if (m2Key.startsWith('listing_column.')) {
					Object.keys(fd[m2Key]).forEach((m3Key) => {
						if (m3Key === smsColumnTypeUUID) {
							listingColumns.push(m2Key);
						}

						if (m3Key === emailColumnTypeUUID) {
							listingColumns.push(m2Key);
						}
					});
				}
			});
		}
	});

	let uneditableLabels = new Set();
	listingColumns.forEach((columnKey) => {
		const displayName = fd[columnKey]?.['display_name'];
		if (displayName) {
			uneditableLabels.add(displayName);
		}
	});

	let parsedOptions = [];
	try {
	  parsedOptions = JSON.parse(options);
	} catch (e) {
	  console.error('Error parsing options:', e);
	}
	// Step 2: Process the options as needed
	const processedOptions = parsedOptions.map((option) => {
		return {
	  ...option,
	  editable: !uneditableLabels.has(option.label),
		};
	});
	// Step 3: Convert the processed options back into a JSON string
	const processedOptionsString = JSON.stringify(processedOptions);

	for (const [ m1Key, m1Data, ] of Object.entries(fd)) {
		if (isDisabled) {
			break;
		}

		if (m1Key.startsWith('listing.') || m1Key.startsWith('listing_column_group.')) {
			for (const m2Key of Object.keys(m1Data)) {
				if (listingColumns.includes(m2Key)) {
					isDisabled = true;
					break;
				}
			}
		}
	}

	return (
		<div
			className={style.listingConfigContainer}
			data-testing-info={buildTestData(`list--arrayOption${optionNumber}`)}
		>
			<div className={style.listingConfigLabel}>
				<h1>Messaging Options
					{!isDisabled && <div
						className={style.deleteIcon}
						key='delete-btn'
						onClick={() => {
							Reflect.deleteProperty(settingsWithOptions, label);
							messagingOptions.setState({
								settingsWithOptions,
							});
							app.setValue('account_settings', `account.${accountUUID}.setting.${settingMap['messaging_options'].uuid}.json`, JSON.stringify(settingsWithOptions));
						}}
					>
						<CustomIcon icon='minus-circle' />
					</div>}
				</h1>
			</div>

			<div className={style.listingConfigData}>
				<JSONField
					handleChange={(json) => updateSettingValue(label, json)}
					value={processedOptionsString}
					originalJson={processedOptionsString}
				/>
			</div>
		</div>
	);
};

export default MessagingOptions;