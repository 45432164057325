import {
	camelCase,
	capitalCase,
	pascalCase,
	snakeCase,
} from 'change-case';

const toLowerCamelCase = function (s) {
	return camelCase(s);
};

const toCamelCase = function (s) {
	return pascalCase(s);
};

const toSnakeCase = function (s) {
	return snakeCase(s);
};

const toCapitalCase = function(s) {
	return capitalCase(s);
};

const parseEntityName = function (name) {
	if (!~name.indexOf('.')) {
		return false;
	}

	let s = name.split('.');
	let c = s.length;

	let m2;
	let [ m1, m1ID, col, m2ID, attr, ] = s;
	if (m2ID) {
		m2 = col;
	}

	let types = {
		3: 'col',
		4: 'rel',
		5: 'attr',
	};
	let type = types[c];

	let info = {
		type,

		// m1.id.col
		m1,
		m1ID,
		col,

		// m1.id.m2.id
		// m1.id.m2.id.attr
		m2,
		m2ID,
		attr,
	};

	if (m2ID) {
		delete (info.col);
	}

	return info;
};

const getModel1Name = function (s) {
	let {
		m1,
	} = parseEntityName(s);

	return m1;
};

const getModel2Name = function (s) {
	let {
		m2,
	} = parseEntityName(s);

	return m2;
};

const mergeFD = function (orgObj = {}, newObj = {}) {
	let obj = Object.assign({}, orgObj);

	for (let [ k, v, ] of Object.entries(newObj)) {
		if (typeof v !== 'object' || v === null) {
			obj[k] = v;
			continue;
		}

		if (typeof v === 'object') {
			if (Array.isArray(v)) {
				obj[k] = v;
			} else {
				obj[k] = mergeFD(obj[k], v);
			}
		}
	}

	// remove relation set to false
	for (let [ m1Key, m1Data, ] of Object.entries(obj || {})) {
		for (let [ m2Key, m2Data, ] of Object.entries(m1Data || {})) {
			if (!~m2Key.indexOf('.')) {
				continue;
			}

			if (m2Data === false) {
				Reflect.deleteProperty(obj[m1Key], m2Key);
			}
		}
	}

	return obj;
};

const makeFDFromDD = function (dd = {}) {
	let fd = {};

	for (let [ entityName, value, ] of Object.entries(dd)) {
		let s = entityName.split('.');
		let l = s.length;

		let m1Key, m2Key;

		if (l === 3) {
			let [ m1, m1ID, col, ] = s;

			m1Key = `${m1}.${m1ID}`;

			if (!fd[m1Key]) {
				fd[m1Key] = {};
			}

			// col
			fd[m1Key][col] = value;
		}

		if (l === 4 || l === 5) {
			let [ m1, m1ID, m2, m2ID, attr, ] = s;

			m1Key = `${m1}.${m1ID}`;
			m2Key = `${m2}.${m2ID}`;

			if (!fd[m1Key]) {
				fd[m1Key] = {};
			}
			if (!fd[m1Key][m2Key]) {
				fd[m1Key][m2Key] = {};
			}

			if (!attr) {
				// rel
				if (value === false) {
					fd[m1Key][m2Key] = false;
				}
			}

			if (attr) {
				// attr
				fd[m1Key][m2Key][attr] = value;
			}
		}
	}

	return fd;
};

const makeDDFromFD = function (fd = {}) {
	// m1.id.col: val
	// m1.id.m2.id: true/false
	// m1.id.m2.id.attr: val
	let dd = {};

	Object.entries(fd).forEach(([ m1Key, m1Data, ]) => {
		// cols
		Object.entries(m1Data).forEach(([ col, val, ]) => {
			if (!~col.indexOf('.')) {
				let k = `${m1Key}.${col}`;
				dd[k] = val;
			}
		});

		// rels/attr
		Object.entries(m1Data).forEach(([ m2Key, m2Data, ]) => {
			if (~m2Key.indexOf('.')) {
				let k = `${m1Key}.${m2Key}`;
				dd[k] = Boolean(fd[m1Key][m2Key]);

				Object.entries(m2Data).forEach(([ attr, val, ]) => {
					let k = `${m1Key}.${m2Key}.${attr}`;
					dd[k] = val;
				});
			}
		});
	});

	return dd;
};

export {
	toLowerCamelCase,
	toCamelCase,
	toCapitalCase,
	toSnakeCase,

	parseEntityName,
	getModel1Name,
	getModel2Name,

	mergeFD,
	makeFDFromDD,
	makeDDFromFD,
};