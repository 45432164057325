import React from 'react';
import CustomIcon from '../Common/CustomIcon/CustomIcon';

import style from './CustomModal.module.css';

import { buildTestData } from 'Vulture/TestData';

class ModalView extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			// modalOpen: this.props.modalOpen,
			// canOpen: this.props.canOpen
			confirmModal:        false,
			confirmModalContent: ''
		};
	}

	// componentDidUpdate(prevProps) {
	// 	debugger;
	// }

	render() {
		let {
			modalProps,
			modalSize,
		} = this.props;
		
		switch (modalSize || (modalProps || {}).modalSize) {
			case 1:
				modalSize = 'small';
				break;
			case 2:
				modalSize = 'medium';
				break;
			case 3:
				modalSize = 'large';
				break;
			case 4:
				modalSize = 'custom'
				break;
			case 5:
				modalSize = 'flexible';
				break;
			case 6:
				modalSize = 'mobile_preview';
				break;
			default:
				modalSize = 'large';
		}
		console.log(modalSize);
		let modalBody = React.cloneElement(modalProps.jsx, {
			...modalProps,
			...this.props,
		}, modalProps.jsx.props.children);
		

		let closeButton = true;
		if (modalProps && modalProps.omitClose) {
			closeButton = false;
		}

		let confirmModal = "";
		if (this.state.confirmModal === true) {
			confirmModal = 
			<div className="confirm-modal-container">
				<div className="confirm-modal-content">
					{this.state.confirmModalContent}
				</div>
			</div>;
		}

		return (
			<React.Fragment>
				<div className={`${style.modalWrapper}`}>
					<div id="modal" className={`${style.modal} ${style[`${modalSize}`]}`}>
						{/* <div className={style.modal}> */}
						<div className={style.modalContainer}>
							<div className={style.modalHeader}>
								<div data-test-id='customModalTitle'>{modalProps.title}</div>
								{closeButton ?
									<div className={style.modalClose}
										data-testing-info={buildTestData('button--close')}
										onClick={(e) => {
											e.preventDefault();
											if (modalProps.pendingChanges || window.form.hasNewVals()) {
												this.setState({
													confirmModal: true,
													confirmModalContent:
														<>
															<p>By cancelling you will lose your unsaved changes. Are you sure you want to continue?</p>
															<div className="confirm-modal-buttons">
																<button className="button-outlined" onClick={(e) => {
																	this.setState({
																		confirmModal: false,
																	});

																	// TODO: would wipe all changes???
																	window.form.newVals = {};
																	this.props.app.closeModal();
																}}>Yes</button>
																<button className="button" onClick={(e) => {
																	this.setState({ confirmModal: false, });
																}}>No</button>
															</div>
														</>,
												});
											} else {
												this.setState({
													heightOfModal: 0,
												});

												this.props.app.closeModal();
												this.props.app.closeLibraryModal();
											}
										}}>
										<CustomIcon icon='times' />
									</div> :
									null
								}
							</div>
							<div className={style.modalContent}>
								{confirmModal}
								{modalBody}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ModalView;