
import {
	getSession,
} from 'Vulture/Session.js';

export const updateViewData = async (data, entityKey) => {
	const broncoURL = window._getEnv('BRONCO_URL');
	const url = `${broncoURL}/w/edit`;
	const sessionKey = getSession().session;

	let retVal;

	let body = {
		data: {
			[entityKey]: data,
		},
		data_format: 'DD',
	};

	await fetch(url, {
		method:  'POST',
		headers: {
			'Content-Type':   'application/json',
			'X-Auth-Session': sessionKey,
		},
		body: JSON.stringify(body),
	})
		.then((res) => {
			return res.json();
		})
		.then((resJSON) => {
			retVal = resJSON.data;
		})
		.catch((err) => {
			console.error(err);
		});

	return retVal;
};