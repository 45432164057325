import React from 'react';
import CustomIcon from '../../Common/CustomIcon/CustomIcon';

class CustomCheckbox extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let isChecked = this.props.checked;
		let isDashed = this.props.dashed;
		let iconToUse = 'check-square';
		if (isChecked) {
			iconToUse = 'check-square';
		} else if (isDashed) {
			iconToUse = 'minus-square';
		} else {
			iconToUse = 'square';
		}
		return (
			<div className={`checkbox ${iconToUse}`}>
				<CustomIcon icon={iconToUse} />
			</div>
		);
	}
}

export default CustomCheckbox;