import axios from 'axios';

import {
	broncoURL,
} from './ENV';

import {
	getSession,
} from './Session';


export const rootUserWrite = async (body) => {
	const url = `${broncoURL}/root_user/w`;

	try {
		const res = await axios.post(url, body, {
			headers: {
				'content_type':   'application/json',
				'X-Auth-Session': getSession().session,
			},
		});

		return {
			res,
			data: res.data,
		};
	} catch (err) {
		if (axios.isAxiosError(err)) {
			return {
				err:  true,
				res:  err?.response || {},
				data: err?.response?.data || {},
			};
		}

		return {
			err:  true,
			res:  {},
			data: {},
		};
	}
};

export const rootUserDelete = async (body) => {
	const url = `${broncoURL}/root_user/d`;

	try {
		const res = await axios.post(url, body, {
			headers: {
				'content_type':   'application/json',
				'X-Auth-Session': getSession().session,
			},
		});

		return {
			res,
			data: res.data,
		};
	} catch (err) {
		if (axios.isAxiosError(err)) {
			return {
				err:  true,
				res:  err?.response || {},
				data: err?.response?.data || {},
			};
		}

		return {
			err:  true,
			res:  {},
			data: {},
		};
	}
};

export const sendForgotPasswordEmail = async (email) => {
	const url = `${broncoURL}/password/forgot`;

	const body = {
		email,
	};

	try {
		const res = await axios.post(url, body, {
			headers: {
				'content_type': 'application/json',
			},
		});

		return {
			res,
			data: res.data,
		};
	} catch (err) {
		if (axios.isAxiosError(err)) {
			return {
				err:  true,
				res:  err?.response || {},
				data: err?.response?.data || {},
			};
		}

		return {
			err:  true,
			res:  {},
			data: {},
		};
	}
};

export const registerUser = async (body) => {
	const url = `${broncoURL}/register_user`;

	try {
		const res = await axios.post(url, body, {
			headers: {
				'content_type':   'application/json',
				'X-Auth-Session': getSession().session,
			},
		});

		return {
			res,
			data: res.data,
		};
	} catch (err) {
		if (axios.isAxiosError(err)) {
			return {
				err:  true,
				res:  err?.response || {},
				data: err?.response?.data || {},
			};
		}

		return {
			err:  true,
			res:  {},
			data: {},
		};
	}
};


